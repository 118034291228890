import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";
import { useTranslation } from "react-i18next";
import { ContactVariableModel } from "../../../ContactModel";
import React, { SetStateAction } from "react";
import { CustomVariableType } from "../../../../../common/AppEnums";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  contactVar: ContactVariableModel;
  getValueInput: (contactVar: ContactVariableModel, isMobile?: boolean) => JSX.Element;
  variablesDescription: Record<string, unknown> | undefined;
  setVariablesDescription: (value: SetStateAction<Record<string, unknown> | undefined>) => void;
  handleOnBlurDescription: (contactVar: ContactVariableModel, variablesDescription: Record<string, unknown> | undefined) => void;
  executeMobileModal?: (type: CustomVariableType, contactVar: ContactVariableModel) => void;
}

export const EditContactMobileEditModal = (props: Props) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const t = useTranslation("translation", { keyPrefix: "contact" }).t;

  function onSubmit() {
    if (props.executeMobileModal) {
      props.executeMobileModal(props.contactVar.type, props.contactVar);
      props.onClose();
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent width={{ base: "340px", sm: "448px" }}>
        <ModalHeader>{t("Edit contact field")}</ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody paddingTop={"0"}>
          <Box className="loaderContainer">
            <Loading scope={"checkVariableUsage"} />
          </Box>
          <Text mb={"8px"}>Name</Text>
          <Input isDisabled={true} placeholder={props.contactVar.key}></Input>
          <Text mb={"8px"} mt={"16px"}>
            Type
          </Text>
          <Input isDisabled={true} placeholder={props.contactVar.type}></Input>
          <Text mb={"8px"} mt={"16px"}>
            Value
          </Text>
          <Box ml={"15px"} mr={"-15px"}>
            {props.contactVar.type === CustomVariableType.Order
              ? props.getValueInput(props.contactVar, false)
              : props.getValueInput(props.contactVar, true)}
          </Box>
          <Text mb={"8px"} mt={"16px"}>
            Description
          </Text>
          <Textarea
            h="120px"
            lineHeight="20px"
            resize="none"
            onFocus={e => (e.target.placeholder = t("Enter field's description"))}
            value={props.variablesDescription && (props.variablesDescription[props.contactVar.id] as string)}
            onChange={e => {
              props.setVariablesDescription(prevState => ({
                ...prevState,
                [props.contactVar.id]: e.target.value,
              }));
            }}
            onBlur={e => {
              e.target.placeholder = t("Description");
              props.handleOnBlurDescription(props.contactVar, props.variablesDescription);
            }}
            maxLength={250}
          ></Textarea>
        </ModalBody>
        <ModalFooter paddingBottom="32px" justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={props.onClose}>
            {ct("Cancel")}
          </Button>
          <Button variant="dominoViolet" mr={3} onClick={onSubmit}>
            {ct("Done")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
