import { AppSettings } from "../../common/AppSettings";
import { BadRequestError } from "../../common/ErrorModel";
import { SignupModel } from "./SignUpModel";

declare const appSettings: AppSettings;

export const SignUp = async (data: SignupModel) => {
  const res = await fetch(`${appSettings.apiIdentityProvider}/signUp`, {
    method: "POST",
    headers: {
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": "application/vnd.domino.v1+json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
    },
    body: data ? JSON.stringify(data) : undefined,
  });

  if (res?.status === 400) {
    const error = { message: "User already exists" };
    throw new BadRequestError(error.message);
  }

  if (res.status === 200) {
    return res.status;
  }
};
