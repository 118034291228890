import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import "dayjs/locale/ru";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(relative);
dayjs.extend(updateLocale);

export function fromNowChat(
  date?: string | number | dayjs.Dayjs | Date | null | undefined,
  locale = "en",
  withoutSuffix?: boolean | undefined,
) {
  dayjs.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "few s",
      m: "few m",
      mm: "%d m",
      h: "1 h",
      hh: "%d h",
      d: "1 d",
      dd: "%d d",
      M: "1 M",
      MM: "%d M",
      y: "year",
      yy: "%d years",
    },
  });
  dayjs.updateLocale("ru", {
    relativeTime: {
      future: "в %s",
      past: "%s назад",
      s: "только что",
      m: "1 мин",
      mm: "%d мин",
      h: "1 ч",
      hh: "%d ч",
      d: "1 д",
      dd: "%d д",
      M: "1 М",
      MM: "%d М",
      y: "год",
      yy: "%d лет",
    },
  });
  if (locale === "ru") {
    dayjs.locale("ru");
  } else {
    dayjs.locale("en");
  }
  if (dayjs().diff(date, "hour") < 1) {
    return dayjs(date).fromNow(withoutSuffix);
  }
  if (dayjs().diff(date, "hour") >= 1 && dayjs().diff(date, "hour") < 24) {
    return dayjs(date).format("HH:mm");
  }
  if (dayjs().diff(date, "hour") >= 24 && dayjs().diff(date, "day") < 7) {
    if (locale === "ru") return dayjs(date).format("dd");
    return dayjs(date).format("ddd");
  }
  if (dayjs().diff(date, "day") >= 7) {
    return dayjs(date).format("DD.MM");
  }
}
