import { Field, FieldProps } from "formik";
import { Button, Flex } from "@chakra-ui/react";
import { ButtonTypeDiscriminator, InlineKeyboardTypeDiscriminatorList } from "../../../../../common/AppEnums";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";

interface Props {
  isBroadcast?: boolean;
  newTab: string;
  setNewTab: Dispatch<SetStateAction<string>>;
}
export const InlineButtonItem = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  return (
    <Field name="typeDiscriminator">
      {({ field }: FieldProps) => (
        <Flex w="100%" mb="16px" justifyContent={"center"} alignItems={"center"}>
          <Button
            width="100%"
            borderRightRadius="0"
            variant={InlineKeyboardTypeDiscriminatorList.includes(field.value) ? "dominoViolet" : "dominoOutlineViolet"}
            onClick={() => {
              field.onChange("typeDiscriminator")(
                ButtonTypeDiscriminator.FlowMessageCallbackButton || ButtonTypeDiscriminator.FlowMessageUrlButton,
              );
              props.setNewTab("static");
            }}
            isDisabled={props.isBroadcast}
            data-pw="static-button"
          >
            {t("Static")}
          </Button>
          <Button
            isDisabled={props.isBroadcast}
            ml="0"
            borderLeftRadius="0"
            width="100%"
            variant={!InlineKeyboardTypeDiscriminatorList.includes(field.value) ? "dominoViolet" : "dominoOutlineViolet"}
            onClick={() => {
              field.onChange("typeDiscriminator")(ButtonTypeDiscriminator.FlowMessageDynamicButton);
              props.setNewTab("dynamic");
            }}
            data-pw="dynamic-button"
          >
            {t("Dynamic")}
          </Button>
        </Flex>
      )}
    </Field>
  );
};
