import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const popupCloseButton = defineStyle({
  minWidth: "auto",
  cursor: "pointer",
  color: "midDeepBlue",
  borderRadius: "50%",
  backgroundColor: "white",

  _hover: {
    backgroundColor: "defaultGrey",
    color: "mainPurple",
  },
});

export const closeButtonTheme = defineStyleConfig({
  variants: { popupCloseButton },
});
