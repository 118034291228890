import { Box, Flex, Show } from "@chakra-ui/react";
import { ReactComponent as LogoIcon } from "../../../assets/icons/sidebar/logoIcon.svg";
import { ReactComponent as LogoText } from "../../../assets/icons/sidebar/logoText.svg";
import s from "./ProtectedHeaderContainer.module.scss";
import { useAppSelector } from "../../../common/state/store";
import { selectIsOrganizationTrial, selectTrialPlan } from "../../organisation/OrganisationSlice";
import { TrialPlanComponent } from "../../organisation/components/TrialPlan/TrialPlanComponent";
import { useMobileView, useTabletView } from "../../layout/LayoutHelper/ResolutionHooks";
import { memo } from "react";

export const ProtectedHeaderContainer = memo(function ProtectedHeaderContainer(props: { children?: React.ReactNode }) {
  const trialPlan = useAppSelector(selectTrialPlan);
  const isMobile = useMobileView();
  const isTablet = useTabletView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  return (
    <Flex className={s.headerContainer}>
      <Show breakpoint="(max-width: 768px)">
        <Flex className={s.logoContainerWrapper}>
          <Flex className={s.logoContainer}>
            <LogoIcon width={"31px"} />
            <LogoText width={"94px"} />
          </Flex>
          {isTrial && trialPlan && trialPlan.isAvailable && isTablet && !isMobile && (
            <Box overflow="hidden" flex={1}>
              <TrialPlanComponent trialPlan={trialPlan} />
            </Box>
          )}
        </Flex>
      </Show>
      {isTrial && trialPlan && trialPlan.isAvailable && isMobile && (
        <Box>
          <TrialPlanComponent trialPlan={trialPlan} />
        </Box>
      )}
      <Flex className={s.headerContent}>{props.children}</Flex>
    </Flex>
  );
});
