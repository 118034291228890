import { useBrowserFitContent } from "../../../LayoutHelper/ResolutionHooks";
import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { AddBotContainer } from "../../../../addBot/AddBotContainer";
import { AddBotHeader } from "../../../../addBot/components/AddBotHeader/AddBotHeader";

export const AddBotPage = () => {
  const browserFill = useBrowserFitContent();

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <AddBotHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={browserFill}>
        <AddBotContainer />
      </Box>
    </Flex>
  );
};
