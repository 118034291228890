import { Avatar, Box, Flex } from "@chakra-ui/react";
import { ConversationNoteModel } from "../../ConversationModel";
import s from "../../Conversation.module.scss";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";
import { FormattedText } from "../../../../common/formattedText/formattedText";
import { Key } from "react";

interface Props {
  message: ConversationNoteModel;
}

interface NoteTextPart {
  text: string;
  isMention: boolean;
}

export const NoteMessageItem = (props: Props) => {
  const timeString = new Date(props?.message?.date).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  });
  const getNoteTextParts = (): NoteTextPart[] => {
    const operators = props.message.taggedOperatorsNames;
    const text = props.message.text;
    const operatorsRegex = /\(mention\[(.*?)\]\)/gm;
    const result: NoteTextPart[] = [];
    const parts = text.split(operatorsRegex);

    let index = 0;
    for (const part of parts) {
      const isMention = index++ % 2 === 1;
      result.push({
        isMention: isMention,
        text: isMention ? operators.find(i => i.id === part)?.username ?? "" : part,
      });
    }
    return result;
  };

  return (
    <Box className={s.messageItemWrapperNote}>
      <TooltipWithTouch label={props.message.authorName} bg={"#FEF6DC"} variant={"dominoLight"} placement="top">
        <Avatar bg={"#3E5E95"} color={"#FFFFFF"} size={"sm"} name={props.message.authorName} />
      </TooltipWithTouch>
      <Flex direction="column">
        <Box className={s.message}>
          <Box className={s.messageTextWrapper}>
            <Box className={s.messageTextContent}>
              {props.message.taggedOperatorsNames?.length ? (
                getNoteTextParts().map((part, index: Key | null | undefined) => {
                  if (part.isMention)
                    return (
                      <span key={part.text} className={s.mention}>
                        @{part.text}
                      </span>
                    );
                  else return <FormattedText key={part.text} text={part.text} entities={props.message.entities || []} />;
                })
              ) : (
                <FormattedText text={props.message.text} entities={props.message.entities || []} />
              )}
            </Box>
            <Box className={s.date}>{timeString}</Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
