/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Show,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTokenFromStorage } from "../../common/auth/AuthRepo";
import { useAppDispatch } from "../../common/state/store";
import { TelegramAuthButton } from "../../common/TelegramAuth/TelegramAuthButton";
import s from "./Login.module.scss";
import sm from "../headers/publicHeaderContainer/PublicHeaderContainer.module.scss";
import { getTokenRequested, getTokenByAuthCodeRequested } from "./LoginSlice";
import { ReactComponent as EyeIcon } from "../../assets/icons/eyeIcon.svg";
import { ReactComponent as EyeClosedIcon } from "../../assets/icons/eyeClosedIcon.svg";
import { redirect } from "../../common/router/RouterSlice";
import { useDocumentTitle } from "../../common/hooks/useDocumentTitle";
import { getFingerprint } from "react-fingerprint";
import { AppSettings } from "../../common/AppSettings";
import { NotificationModel } from "../../common/notifications/NotificationModel";
import { notify } from "../../common/notifications/NotificationSlice";

declare const appSettings: AppSettings;

export const Login = () => {
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = getTokenFromStorage();
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  const [searchParams] = useSearchParams("");
  const inviteToken = searchParams.get("inviteToken");
  const authCode = searchParams.get("authCode");
  const redirecturl = searchParams.get("redirect_url");
  const orgIdInSearchParams = new URLSearchParams(window.location.search).get("organizationId");
  const isUsernameError = username?.length === 0;
  const isPasswordError = password?.length === 0;
  const [fingerprint, setFingerprint] = useState<string | null>(null);

  const handleSubmit = () => {
    if (username && password) {
      dispatch(
        getTokenRequested({
          tokenModel: inviteToken
            ? { username, password, fingerprint, inviteToken }
            : {
                username,
                password,
                fingerprint,
              },
          redirectUrl:
            redirecturl === null
              ? undefined
              : orgIdInSearchParams === null
              ? decodeURI(redirecturl)
              : decodeURI(`${redirecturl}?organizationId=${orgIdInSearchParams}`),
        }),
      );
    } else {
      const notification: NotificationModel = {
        message: t("Enter username and password"),
        type: "error",
      };
      dispatch(notify(notification));
    }
  };

  const handleKeyDown = (key: string) => {
    if (key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (token) {
      if (!redirecturl) {
        dispatch(redirect("/bots"));
      } else if (redirecturl && redirecturl === appSettings.billingPortalUrl) {
        if (orgIdInSearchParams) {
          console.log(orgIdInSearchParams);
          window.location.replace(decodeURI(`${redirecturl}?organizationId=${orgIdInSearchParams}`));
        } else {
          window.location.replace(decodeURI(redirecturl));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getFingerprint();
      setFingerprint(data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (authCode?.length) {
      dispatch(getTokenByAuthCodeRequested({ authCode: authCode, fingerprint: fingerprint }));
    }
  }, [authCode]);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  useDocumentTitle(t("Sign In"), "Sign In");

  return (
    <Flex flexDirection={"column"} w={"100%"} bg={"bgLight"}>
      <Box className={sm.authBlock}>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
          className={sm.authForm}
        >
          <h1 className={sm.loginTitle}>{t("Sign In")}</h1>
          <FormControl isInvalid={isUsernameError} mt={4}>
            <Input
              variant="auth"
              onKeyDown={e => handleKeyDown(e.key)}
              placeholder={`${t("Username")}`}
              className={s.input}
              type="text"
              value={username ?? ""}
              onChange={e => setUsername(e.target.value)}
              data-pw="username"
            />
            {isUsernameError ? <FormErrorMessage m="0">{t("Username Is Required")}</FormErrorMessage> : <Box h="17px"></Box>}
          </FormControl>
          <FormControl isInvalid={isPasswordError} mt={4}>
            <InputGroup>
              <Input
                onKeyDown={e => handleKeyDown(e.key)}
                placeholder={`${t("Password")}`}
                variant="auth"
                className={s.input}
                type={show ? "text" : "password"}
                autoComplete="password"
                value={password ?? ""}
                onChange={e => setPassword(e.target.value)}
                data-pw="password"
              />
              <InputRightElement>
                <Button className={s.passIcon} onClick={handleClick}>
                  {show ? (
                    <EyeClosedIcon data-pw="hide-password" width="20px" height="20px" title={`${t("Hide password")}`} />
                  ) : (
                    <EyeIcon data-pw="show-password" width="20px" height="20px" title={`${t("Show password")}`} />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
            {isPasswordError ? <FormErrorMessage m="0">{t("Password Is Required")}</FormErrorMessage> : <Box h="17px"></Box>}
          </FormControl>
          {/* To Do forgot password */}
          {/* <Link className={`${sm.authTextLink} ${sm.authForgPassLink}`} to="">Forgot password?</Link> */}
          <Button data-pw="sign-in-button" variant="dominoViolet" w="100%" mt="32px" h="44px" onClick={handleSubmit}>
            {t("Sign In")}
          </Button>
          <Box className={s.dividerBlock}>
            <p className={s.dividerText}>{t("Or")}</p>
          </Box>
          <Box mt="10px" w={"100%"} data-pw="telegram-button">
            <TelegramAuthButton />
          </Box>
        </form>
      </Box>
      <Show breakpoint="(max-width: 479px)">
        <Flex justifyContent={"center"} className={sm.mobileBottomBlock}>
          <p>{t("Do not have an account?")}</p>
          <Link
            color="blueLink"
            _hover={{ color: "mainPurple", textDecoration: "underline" }}
            ml={"8px"}
            onClick={() => navigate("/auth/signUp")}
            data-pw="sign-up-mobile"
          >
            {t("Sign Up Mobile")}
          </Link>
        </Flex>
      </Show>
    </Flex>
  );
};
