export enum ComplexFilterFieldTypes {
  "Text" = "Text",
  "Number" = "Number",
  "Date" = "Date",
  "DateTime" = "DateTime",
  "Boolean" = "Boolean",
  "Array" = "Array",
  "Order" = "Order",
}

export enum VariableScope {
  "System Fields" = "System",
  "System" = "System",
  "Contact" = "Contact",
  "Bot" = "Bot",
  "Flow" = "Flow",
}

export enum ComplexFilterGroupTypes {
  "CustomVariables" = "CustomVariables",
  "ContactFields" = "FieldNames",
}

export enum ComplexFilterRecipientGroupTypes {
  "BroadcastRecipientGroupFilter" = "BroadcastRecipientGroupFilter",
}

export enum ComplexFilterMemberTypes {
  "BroadcastRecipientFilter" = "BroadcastRecipientFilter",
}

export enum MenuButtonTypes {
  "Underline" = "Underline",
  "Icon" = "Icon",
}

export enum FilterParamTypes {
  "field" = "field",
  "condition" = "condition",
  "conditionValue" = "conditionValue",
}

export const ConditionsTitles = {
  titles: [
    { title: "is True", value: "true" },
    { title: "is False", value: "false" },
    { title: "less than", value: "LessThan" },
    { title: "more than", value: "GreaterThan" },
    { title: "equal", value: "Equal" },
    { title: "not equal", value: "NotEqual" },
    { title: "contains", value: "Contains" },
    { title: "not contain", value: "NotContain" },
    { title: "begins with", value: "BeginsWith" },
    { title: "has value", value: "HasValue" },
    { title: "has no value", value: "HasNoValue" },
    { title: "is True", value: "true" },
    { title: "is False", value: "false" },
  ],
};

export const ConditionsByField = {
  textConditions: [
    { title: "equal", value: "Equal" },
    { title: "not equal", value: "NotEqual" },
    { title: "contains", value: "Contains" },
    { title: "not contain", value: "NotContain" },
    { title: "begins with", value: "BeginsWith" },
    { title: "has value", value: "HasValue" },
    { title: "has no value", value: "HasNoValue" },
  ],
  numberDateConditions: [
    { title: "equal to", value: "equal" },
    { title: "not equal to", value: "not equal" },
    { title: "more than", value: "greater than" },
    { title: "less than", value: "less than" },
    { title: "has value" },
    { title: "has no value" },
  ],
  dateTimeDateConditions: [
    { title: "equal to", value: "equal" },
    { title: "not equal to", value: "not equal" },
    { title: "more than", value: "greater than" },
    { title: "less than", value: "less than" },
    { title: "has value" },
    { title: "has no value" },
  ],
  booleanConditions: [
    { title: "is True", value: "true" },
    { title: "is False", value: "false" },
    { title: "has value" },
    { title: "has no value" },
  ],
  arrayConditions: [{ title: "contains" }, { title: "not contain" }, { title: "has value" }, { title: "has no value" }],
  noValueConditions: ["HasValue", "HasNoValue", "True", "False"],
  allowedFieldTypes: ["Text", "Number", "Date", "DateTime", "Array", "Boolean"],
};

export interface MenuButtonInfo {
  buttonType: MenuButtonTypes;
  title?: string;
  isMenuButtonDisabled?: boolean;
}

export interface FilterParams {
  id?: string;
  field?: FieldParamModel;
  condition?: FieldParamModel;
  conditionValue?: FieldParamModel;
}

export interface FieldParamModel {
  title: string;
  value: string;
  variableValue?: string;
  type?: ComplexFilterFieldTypes;
  groupType?: ComplexFilterGroupTypes;
  id?: string | null;
}

export interface ComplexFilterItemsModel {
  groups: GroupModel[];
}

export interface GroupModel {
  label: string;
  items: GroupItemModel[];
}

export interface GroupItemModel {
  fieldName: string;
  fieldValue: string;
  type?: ComplexFilterFieldTypes;
  groupType?: ComplexFilterGroupTypes;
  description?: string;
  id?: string | null;
}

export interface SearchConditionModel {
  field: string;
  condition: string;
  value: string;
}

export const ContactsFilterFields = [
  { title: "Id", value: "id" },
  { title: "Username", value: "username" },
  { title: "First Name", value: "firstName" },
  { title: "Last Name", value: "lastName" },
  { title: "Phone Number", value: "phoneNumber" },
];
