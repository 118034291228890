import { CustomVariablesModel } from "../../components/flowBuilder/FlowBuilderModel";
import { CustomVariableType } from "../AppEnums";

export const convertPascalCaseToText = (pascalText: string) => {
  const result =
    pascalText.slice(0, 1).toLowerCase() +
    pascalText
      .slice(1)
      .replace(/([A-Z])/g, " $1")
      .toLowerCase();
  return result;
};

export const getVariableType = (variables: CustomVariablesModel[] | undefined, conditionCustomVariableId: string) => {
  const conditionType = variables?.find(variable => variable.id === conditionCustomVariableId)?.type;
  return conditionType;
};

export function addTranslationPrefix(text: string, type: string) {
  switch (type.toLocaleLowerCase()) {
    case CustomVariableType.Date.toLocaleLowerCase():
      return `date.${convertPascalCaseToText(text)}`;
    case CustomVariableType.DateTime.toLocaleLowerCase():
      return `date.${convertPascalCaseToText(text)}`;
    case CustomVariableType.Number.toLocaleLowerCase():
      return `number.${convertPascalCaseToText(text)}`;
    default:
      return convertPascalCaseToText(text);
  }
}
