import s from "../../ContactList.module.scss";
import se from "../../components/ContactListAdaptive/ContactListMobile.module.scss";
import { Avatar, Box, Text, Tooltip } from "@chakra-ui/react";
import { formatDateTimeToView } from "../../../../common/utils/formatDate";
import { fromNow } from "../../../../common/utils/fromNow";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContactListModel, ContactModel } from "../../ContactListModel";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { LanguageCodeEnum } from "../../../../common/user/UserModel";
import { ReactComponent as EditIcon } from "../../../../assets/icons/editIcon.svg";
import { ReactComponent as RedTrashIcon } from "../../../../assets/icons/trash.svg";
import DotMenuContainer from "../../../../UI/atoms/DotMenu/DotMenuContainer";

interface Props {
  apiBaseUrl: string;
  contactList: ContactListModel | undefined;
  navigate: NavigateFunction;
  setDeletePopupState: (value: React.SetStateAction<{ id: string; itemName: string } | null>) => void;
  lng: LanguageCodeEnum;
  loadingContactList: boolean;
  search: string;
}

export const ContactListMobile = (props: Props) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const { t, i18n } = useTranslation("translation", { keyPrefix: "contactList" });
  const navigate = useNavigate();

  const onEditContactItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
    contact: ContactModel,
  ) => {
    e.stopPropagation();
    navigate(`/contacts/${contact.id}`);
  };

  return (
    <Box className={s.contactListGrid}>
      {props.contactList &&
        props.contactList.items.map(contact => (
          <Box
            borderBottom="1px solid"
            borderColor="bgMiddle"
            className={i18n.language === "en" ? s.contactListItem : s.contactListItemOverride}
            key={contact.id}
            onClick={() => props.navigate(`/contacts/${contact.id}`)}
          >
            <Box color={"darkGrey"}>
              <Text noOfLines={1} variant="medium" paddingLeft={"16px"} paddingBottom={"8px"} paddingTop={"7px"}>
                {t("First Name")}
              </Text>
              <Text noOfLines={1} variant="medium" paddingLeft={"16px"} paddingBottom={"8px"}>
                {t("Last Name")}
              </Text>
              <Text noOfLines={1} variant="medium" paddingLeft={"16px"} paddingBottom={"8px"}>
                {t("UsernameShort")}
              </Text>
              <Text noOfLines={1} variant="medium" paddingLeft={"16px"} paddingBottom={"8px"}>
                {t("Created")}
              </Text>
            </Box>
            <div>
              <Text className={se.name} variant="medium" paddingTop={"7px"}>
                {contact.firstName && contact.firstName.length ? contact.firstName : ct("Not Set")}
              </Text>
              <Text className={se.name} variant="medium">
                {contact.lastName && contact.lastName.length ? contact.lastName : ct("Not Set")}
              </Text>
              <Text className={se.name} variant="medium">
                {contact.username ? `@${contact.username}` : contact.externalId}
              </Text>
              <Text variant="medium" noOfLines={1} paddingBottom={"8px"}>
                <Tooltip variant={"dominoLight"} label={formatDateTimeToView("Date", contact.createDate)}>
                  {fromNow(contact.createDate, props.lng)}
                </Tooltip>
              </Text>
            </div>
            <div>
              <Box className={s.boxIconButton} paddingBottom={"39px"}>
                <DotMenuContainer
                  menuItems={[
                    {
                      MenuItem: {
                        icon: <EditIcon width="18px" height="18px" />,
                        text: ct("Edit"),
                        clickEvent: e => onEditContactItem(e, contact),
                      },
                    },
                    {
                      MenuItem: {
                        icon: <RedTrashIcon width="18px" height="18px" />,
                        text: ct("Delete"),
                        clickEvent: e => {
                          e.stopPropagation();
                          props.setDeletePopupState({
                            id: contact.id,
                            itemName: `${contact.firstName ?? ""} ${contact.lastName ?? ""}`,
                          });
                        },
                        color: "mainRed",
                      },
                    },
                  ]}
                  MenuHover={{ bg: "line" }}
                />
              </Box>
              <Box paddingBottom={"5px"}>
                {contact.avatars.length ? (
                  <Avatar
                    className={s.Avatar}
                    name={contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}
                    boxSize="36px"
                    bg="midDeepBlue"
                    color={"white"}
                    loading="eager"
                    src={`${props.apiBaseUrl}/file/${contact.avatars[0][0].fileId}`}
                  />
                ) : (
                  <Avatar
                    className={s.Avatar}
                    boxSize="36px"
                    name={contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}
                    bg="midDeepBlue"
                    color={"white"}
                  />
                )}
              </Box>
            </div>
          </Box>
        ))}
    </Box>
  );
};
