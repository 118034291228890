import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../state/store";
import { RouterState } from "./RouterState";

const initialState: RouterState = {
  path: "",
};

export const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    redirect: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        path: action.payload,
      };
    },
  },
});

export const { redirect } = routerSlice.actions;

export default routerSlice.reducer;

export const selectRouter = (state: RootState) => state.routerState;
