import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Button, Spinner, Menu, MenuButton, Icon, MenuList, MenuItem } from "@chakra-ui/react";
import { BroadcastFormModel, BroadcastRecipientsItemsModel, FilterStatus } from "../../BroadcastModel";
import { useTranslation } from "react-i18next";
import { ReactComponent as checkedTrue } from "../../../../assets/icons/checkChatList.svg";
import { ReactComponent as checkedFalse } from "../../../../assets/icons/crossChatList.svg";
import { ReactComponent as skippedRecipientsIcon } from "../../../../assets/icons/skippedRecipients.svg";
import s from "./RecipientList.module.scss";
import { useAppSelector } from "../../../../common/state/store";
import { selectBroadcastRecipientList } from "../../BroadcastSlice";
import { ReactComponent as DownArrowMenu } from "../../../../assets/icons/chevronChatList.svg";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../../common/hooks/useDebounce";

interface Props {
  broadcastFormData?: BroadcastFormModel;
  getRecipients: (queryFilterParams: string | undefined, isScrolling?: boolean) => void;
  setBroadcastFormData?: Dispatch<SetStateAction<BroadcastFormModel>>;
  validate?: (field: string, broadcastData: BroadcastFormModel) => () => void;
  isError?: boolean;
  disabled?: boolean;
}

export const ContactListView = ({ disabled = false, getRecipients }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const recipientList = useAppSelector(selectBroadcastRecipientList);
  const contactListRef = useRef<null | HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [searchContactValue, setSearchContactValue] = useState<string>(search);
  const debouncedSearchText = useDebounce(searchContactValue, 500);
  const [selectedFilter, setSelectedFilte] = useState(FilterStatus.All);
  const [nextListPage, setNextListPage] = useState(1);

  useEffect(() => {
    getRecipientsWithParams(debouncedSearchText, 1, selectedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, setSearchParams]);

  useEffect(() => {
    if (nextListPage !== 1 && recipientList) {
      getRecipientsWithParams(searchContactValue, recipientList.currentPage + 1, selectedFilter, true);
    }
  }, [nextListPage]);

  const getRecipientsWithParams = (searchValue?: string, page?: number, statusValue?: string, isOnScrollPayload?: boolean) => {
    const queryString = createQueryString(searchValue, page, statusValue);
    getRecipients(queryString, isOnScrollPayload);
  };

  const getAdditionalContactsOnScroll = () => {
    if (contactListRef.current) {
      const scrollHeight = contactListRef.current.scrollHeight;
      const scrollTop = contactListRef.current.scrollTop;
      const scrollPosition = scrollHeight - scrollTop;
      const isBottom = scrollPosition < contactListRef.current?.getBoundingClientRect().bottom;
      if (
        isBottom &&
        recipientList &&
        recipientList?.currentPage < recipientList?.totalPages &&
        nextListPage !== recipientList.currentPage + 1
      ) {
        setNextListPage(recipientList.currentPage + 1);
      }
    }
  };

  const createQueryString = (searchValue?: string, page?: number, statusValue?: string) => {
    statusValue = statusValue === "Failed" ? "Error" : statusValue;

    const searchParams = searchValue ? `filter=${encodeURIComponent(searchValue)}&filterBy=FirstName` : "";
    let statusParams = "";
    if (statusValue === "Sent") {
      statusParams = `status=${statusValue}`;
    } else if (statusValue === "Error") {
      statusParams = `status=Skipped&status=${statusValue}`;
    } else if (statusValue === "Skipped") {
      statusParams = `status=${statusValue}`;
    }
    const pageParams = page ? `page=${page}` : "";
    const paramsArray = [searchParams, statusParams, pageParams].filter(param => param !== "");
    const queryString = paramsArray.join("&");
    return queryString;
  };

  const getContactTitle = (contact: BroadcastRecipientsItemsModel) => {
    if (contact.firstName || contact.lastName) {
      return `${contact.firstName ?? ""} ${contact.lastName ?? ""}`;
    } else if (!contact.firstName && !contact.firstName) {
      if (contact.username) {
        return contact.username;
      } else {
        return contact.conversationId;
      }
    }
  };

  return (
    <Box className={s.broadastContactListView}>
      <Box className={`${s.broadastContactListHeaderView} ${s.broadastContactListGrid}`}>
        <Box>
          <Menu variant="dominoBroadcastMenu" gutter={0} offset={[-30, 0]}>
            {({ isOpen, onClose }) => (
              <>
                <MenuButton
                  data-pw="contact-list-view-button"
                  isActive={isOpen}
                  as={Button}
                  variant="broadcastBar"
                  _hover={{
                    ".icon": {
                      backgroundColor: `${isOpen ? "#DCE7FB" : "#EFF5FE"}`,
                      color: "#4F3DED",
                    },
                  }}
                  onClick={e => e.stopPropagation()}
                  leftIcon={
                    <Icon
                      className="icon"
                      as={DownArrowMenu}
                      boxSize="24px"
                      color={isOpen ? "#6D5BF7" : "black"}
                      borderRadius={"4px"}
                      _hover={{
                        backgroundColor: `${isOpen ? "#DCE7FB" : "#EFF5FE"}`,
                        color: "#4F3DED",
                      }}
                      transition="ease-out .2s"
                      bg={isOpen ? "#DCE7FB" : ""}
                    />
                  }
                >
                  <Flex maxW={"500px"}>
                    <Text
                      data-pw="contact-list-view-title"
                      variant={"preLargeBold"}
                      w="100%"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {t(selectedFilter)}
                    </Text>
                  </Flex>
                </MenuButton>
                <MenuList ml={"25px"}>
                  {Object.values(FilterStatus).map(item => (
                    <MenuItem
                      onClick={() => {
                        getRecipientsWithParams(searchContactValue, 1, item);
                        setSelectedFilte(item);
                      }}
                      key={item}
                      color={"black"}
                      data-pw="contact-list-view-menu-item"
                    >
                      {t(item)}
                    </MenuItem>
                  ))}
                </MenuList>
              </>
            )}
          </Menu>
        </Box>
        <InputSearch
          placeholder={t("Search by name") ?? ""}
          setSearchText={setSearchContactValue}
          searchText={searchContactValue}
        />
      </Box>
      <Box ref={contactListRef} onScroll={getAdditionalContactsOnScroll} className={`${s.broadastContactListScroll}`}>
        {recipientList?.items ? (
          recipientList.items.length ? (
            recipientList?.items?.map(contact => (
              <Box key={contact.contactId} className={`${s.broadastContactListFlexView}`}>
                {contact.status === FilterStatus.Sent ? (
                  <Icon
                    data-pw="checkedTrue-icon"
                    className="icon"
                    as={checkedTrue}
                    boxSize="24px"
                    transition="ease-out .2s"
                    mr={"8px"}
                  />
                ) : contact.status === FilterStatus.Skipped ? (
                  <Icon
                    data-pw="skippedRecipientsIcon-icon"
                    className="icon"
                    as={skippedRecipientsIcon}
                    boxSize="24px"
                    transition="ease-out .2s"
                    mr={"8px"}
                  />
                ) : contact.errorMessage === null ? (
                  <Flex alignItems="center" justifyContent="center">
                    <Icon
                      data-pw="checkedFalse-icon"
                      className="icon"
                      as={checkedFalse}
                      boxSize="24px"
                      transition="ease-out .2s"
                      mr={"8px"}
                    />
                  </Flex>
                ) : (
                  <TooltipWithTouch
                    hasArrow
                    arrowSize={16}
                    bg={"#FEF6DC"}
                    placement="top-start"
                    variant={"dominoLight"}
                    key={contact.contactId}
                    label={t(contact.errorMessage)}
                    data-pw="tooltip"
                  >
                    <Flex alignItems="center" justifyContent="center">
                      <Icon
                        data-pw="checkedFalse-icon"
                        className="icon"
                        as={checkedFalse}
                        boxSize="24px"
                        transition="ease-out .2s"
                        mr={"8px"}
                      />
                    </Flex>
                  </TooltipWithTouch>
                )}
                <Text data-pw="contact-list-view-username">
                  {getContactTitle(contact)}
                  {contact.username && <span className={s.contactUserName}>{` @${contact.username}`}</span>}
                </Text>
              </Box>
            ))
          ) : (
            <Flex height={"60px"} alignItems="center" justifyContent="center">
              <Text data-pw="no-result-found" color="darkGrey">
                {t("No result found")}
              </Text>
            </Flex>
          )
        ) : (
          <Flex height={"50px"} justifyContent={"center"} className={`${s.broadastContactListFlexView}`}>
            <Spinner color="mainPurple" className={s.overlaySpinner} />
          </Flex>
        )}
      </Box>
    </Box>
  );
};
