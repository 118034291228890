/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SignupModel } from "./SignUpModel";

const initialState = {};

export const signUpSlice = createSlice({
  name: "signUpSlice",
  initialState,
  reducers: {
    signup: (state, action: PayloadAction<SignupModel>) => {
      return {
        ...state,
      };
    },
  },
});

export const { signup } = signUpSlice.actions;

export default signUpSlice.reducer;
