import { Field, FieldInputProps, FieldProps, FormikErrors, FormikProps } from "formik";
import { BroadcastFormModel } from "../../../../BroadcastModel";
import { Box, Button, Container, Flex, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import s from "../../Broadcast.module.scss";
import { customDateInputIcon, DatePicker, getMinTime } from "../../../../../../UI/atoms/datePicker/DatePicker";
import dayjs from "dayjs";
import React, { forwardRef, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alarm } from "../../../../../../assets/icons/alarmClock.svg";
import { EditBroadcastFormTitleValidation, ValidateNewTitleInput } from "./BroadcastFormTitleValidation";

interface Props {
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  titleInputRef: React.RefObject<HTMLInputElement>;
  setTitleInvalid: React.Dispatch<React.SetStateAction<boolean>>;
  titleInvalid: boolean;
  setFieldValue: (
    field: string,
    value: any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<BroadcastFormModel>>;
  isNew?: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
}

export const BroadcastFormTitle = memo(
  function BroadcastFormTitle(props: Props) {
    const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
    const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

    const CustomInput = forwardRef(customDateInputIcon);

    const [titleFirstInit, setTitleFirstInit] = useState(true);
    const [titleValidationError, setTitleValidationError] = useState("");

    const validateTitle = (value: string) => {
      if (!props.isNew) {
        return EditBroadcastFormTitleValidation(value);
      } else {
        return ValidateNewTitleInput(value);
      }
    };

    const onChangeTitleValidation = () => {
      setTitleFirstInit(false);
      if (props.titleInputRef.current) {
        const result = validateTitle(props.titleInputRef.current.value);
        if (result.isInvalid) {
          props.setTitleInvalid(true);
          setTitleValidationError(t(result.error));
        } else {
          props.setTitleInvalid(false);
        }
      }
    };

    const changeFieldValue = (
      form: FormikProps<BroadcastFormModel>,
      isScheduledField: FieldInputProps<FieldProps>,
      isChecked: boolean,
    ) => {
      form.setFieldTouched("scheduledDate", true, false);
      props.setFieldValue("isScheduled", isChecked);
      if (!isScheduledField.value) {
        props.setFieldValue("scheduledDate", dayjs().add(5, "m").toDate());
      }
    };

    return (
      <Container variant="dominoContentBlock">
        <Field name="name" validate={(value: string) => validateTitle(value).error}>
          {({ field, form }: FieldProps<string, BroadcastFormModel>) => (
            <FormControl
              isInvalid={titleFirstInit ? false : props.titleInvalid}
              className={`${s.broadcastListItemGrid} ${s.broadcastTitleAdaptive}`}
              onChange={onChangeTitleValidation}
            >
              <FormLabel>{t("Broadcast Title")}</FormLabel>
              <Input
                ref={props.titleInputRef}
                onKeyDown={props.handleKeyPress}
                variant="outline"
                data-pw="broadcast-title-input"
                placeholder={t("Broadcast Title placeholder") ?? ""}
                {...field}
              />
              <FormErrorMessage data-pw="broadcast-title-error">{`${titleValidationError}`}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Field name="scheduledDate">
          {({ field, form }: FieldProps) => (
            <FormControl
              isInvalid={form.values.isScheduled && !!form.errors.scheduledDate && !!form.touched.scheduledDate}
              className={`${s.broadcastListItemGrid} ${s.broadcastSheduleAdaptive}`}
            >
              <FormLabel>{t("Broadcast schedule")}</FormLabel>
              <Box className={s.scheduleBoxMobile}>
                <Flex>
                  <Field name="isScheduled">
                    {({ field: isScheduledField }: FieldProps) => (
                      <>
                        <Button
                          width="100%"
                          borderRightRadius="0"
                          fontSize={{ base: "13px", sm: "15px" }}
                          px={{ base: "12px", sm: "24px" }}
                          variant={!isScheduledField.value ? "dominoViolet" : "dominoOutlineViolet"}
                          onClick={() => changeFieldValue(form, isScheduledField, false)}
                          isDisabled={false}
                          data-pw="send-immediately-button"
                        >
                          {t("Send immediately")}
                        </Button>
                        <Button
                          isDisabled={false}
                          ml="0"
                          borderLeftRadius="0"
                          fontSize={{ base: "13px", sm: "15px" }}
                          px={{ base: "12px", sm: "24px" }}
                          width="100%"
                          variant={!isScheduledField.value ? "dominoOutlineViolet" : "dominoViolet"}
                          onClick={() => changeFieldValue(form, isScheduledField, true)}
                          data-pw="schedule-button"
                        >
                          {t("Schedule")}
                        </Button>
                      </>
                    )}
                  </Field>
                </Flex>
              </Box>
              <DatePicker
                className={s.datePickerDesktop}
                wrapperClassName={s.DatePickerWrapper}
                dateFormat="dd.MM.yyyy HH:mm"
                selected={field.value ? new Date(field.value) : null}
                onChange={scheduledDate => {
                  form.setFieldTouched("scheduledDate", true, false);
                  props.setFieldValue("scheduledDate", scheduledDate);
                }}
                placeholderText={ct("placeholderDateTime") ?? ""}
                disabled={!form.values.isScheduled}
                showTimeSelect
                strictParsing
                customInput={<CustomInput icon={Alarm} disableIcon={form.values.isScheduled} colorIcon={"#8592A3"} />}
                minDate={new Date()}
                minTime={getMinTime(form.values.scheduledDate)}
                maxTime={dayjs().endOf("day").toDate()}
                data-pw="broadcast-date-picker"
              />
              <FormErrorMessage data-pw="broadcast-date-picker-error">{`${form.errors?.scheduledDate}`}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </Container>
    );
  },
  (last, next) => {
    //console.log(last, next, Object.is(last, next));
    return Object.is(last, next);
  },
);
