import { EmptyBotView } from "../../../emptyView/EmptyBotView";
import { ReactComponent as EmptyBotImage } from "../../../../assets/images/botImageHappy.svg";
import { useTranslation } from "react-i18next";

export const BroadcastListEmptyPage = () => {
  const { t } = useTranslation();
  return (
    <EmptyBotView
      title={t("broadcastList.No Broadcasts")}
      description={t("broadcastList.Create or connect an existing bot to add your first Broadcast.")}
      Image={EmptyBotImage}
      paddingBottom="60px"
    />
  );
};
