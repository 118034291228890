import { Box, Button, Link, Text, Flex } from "@chakra-ui/react";
import { BotModel } from "../../BotModel";
import s from "./BotInfo.module.scss";
import { useTranslation } from "react-i18next";
import ClipboardText from "../../../../UI/atoms/clipboardText/ClipboardText";

interface Props {
  botInfo: BotModel;
  onOpenBotRemoveModal: () => void;
  onOpenRefreshTokenModal: () => void;
}

export const BotInfo = ({ botInfo, onOpenBotRemoveModal, onOpenRefreshTokenModal }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });

  return (
    <Box className={s.infoSection}>
      <Box className={s.infoRow}>
        <Box>
          <Text className={s.label}>{t("Bot Telegram Id")}</Text>
        </Box>
        <Box className={s.botExternalIdWrp}>
          <ClipboardText
            textIsCopy={botInfo.externalId}
            elemIsCopy={<Text className={s.botExternalIdText}>{botInfo.externalId}</Text>}
            color="#3e5e95"
          />
        </Box>
      </Box>
      <Box className={s.infoRow}>
        <Box>
          <Text className={s.label}>{t("Bot Display Name")}</Text>
        </Box>
        <Box>{botInfo.name}</Box>
        <Box>
          <Text>
            {t("To change bot name you have to open")}{" "}
            <Link href="https://t.me/BotFather" isExternal className={s.link}>
              @BotFather
            </Link>{" "}
            {t("via Telegram and enter the command /setname")}.
          </Text>
        </Box>
      </Box>
      <Box className={s.infoRow}>
        <Box>
          <Text className={s.label}>{t("Bot User Name")}</Text>
        </Box>
        <Box className={s.botUserNameWrp}>
          <ClipboardText
            textIsCopy={`@${botInfo.username}`}
            elemIsCopy={<Text className={s.botUserNameText}>@{botInfo.username}</Text>}
            color="#3e5e95"
          />
        </Box>
      </Box>
      <Box className={s.infoRow}>
        <Box>
          <Text className={s.label}>{t("Access Token")}</Text>
        </Box>
        <Box>{botInfo.accessToken}</Box>
      </Box>
      <Box className={s.infoRow}>
        <Box>
          <Text className={s.label}>{t("Bot URL")}</Text>
        </Box>
        <Flex className={s.rowUrl}>
          <ClipboardText
            textIsCopy={botInfo.botUrl}
            elemIsCopy={
              <Link className={s.rowUrlText} href={botInfo.botUrl} isExternal>
                {botInfo.botUrl}
              </Link>
            }
            color="#3e5e95"
          />
        </Flex>
      </Box>
      <Box className={s.infoRow}>
        <Box>
          <Text className={s.label}>{t("Revoke Bot Token")}</Text>
        </Box>
        <Box>
          <Button
            variant="dominoOutlineViolet"
            size={{ base: "dominoSm", md: "dominoMd" }}
            onClick={onOpenRefreshTokenModal}
            overflow={{ base: "hidden", md: "" }}
            textOverflow={"ellipsis"}
          >
            {" "}
            {t("Refresh Token")}{" "}
          </Button>
        </Box>
        <Box>
          <Text>
            {t("After you revoke the token with")}{" "}
            <Link color="blueLink" href="https://t.me/BotFather" isExternal className={s.link}>
              {t("@BotFather")}
            </Link>{" "}
            {t("your marketing automations will stop. To restart them, refresh the new token right here")}
          </Text>
        </Box>
      </Box>
      <Box className={s.infoRow}>
        <Box>
          <Text className={s.label}>{t("Remove Telegram Bot")}</Text>
        </Box>
        <Box>
          <Button variant="dominoOutlineRed" size={{ base: "dominoSm", md: "dominoMd" }} onClick={onOpenBotRemoveModal}>
            {t("Remove")}
          </Button>
        </Box>
        <Box>
          <Text>{t("You can completely disconnect and remove your Telegram bot.")}</Text>
          <Text color={"#EB5038"}>{t("Warning: all yor Telegram contacts will be deleted")}.</Text>
          <Text>{t("You can connect another bot at any time")}</Text>
        </Box>
      </Box>
    </Box>
  );
};
