import { httpDelete, httpGet, httpPost } from "../../common/BaseApi";

export const getBroadcastListApi = (botId: string, page: number, filter?: string) => {
  return httpGet(`/api/broadcast/bot/${botId}?page=${page}&size=${10}${!!filter ? "&filter=" + encodeURIComponent(filter) : ""}`);
};

export const deleteBroadcastApi = (botId: string, broadcastId: string) => {
  return httpDelete(`/api/broadcast/bot/${botId}/${broadcastId}`);
};

export const cancelBroadcastApi = (botId: string, broadcastId: string) => {
  return httpPost(`/api/broadcast/bot/${botId}/${broadcastId}/cancel`, {});
};
export const copyBroadcastApi = (botId: string, broadcastId: string) => {
  return httpPost(`/api/broadcast/bot/${botId}/${broadcastId}/copy`, {});
};
