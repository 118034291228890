import { httpGet, httpPut } from "../BaseApi";
import { UserData } from "./UserData";

export const getUserProfile = () => {
  return httpGet(`/api/user/profile`);
};

export const changeUserProfile = (userProfile: UserData) => {
  return httpPut(`/api/user/profile`, userProfile);
};
