import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const dominoAvatarVariant = definePartsStyle({
  container: {
    backgroundColor: "mainYellow",
    color: "#FFFFFF",
    marginRight: "12px",
    fontSize: "16px",
    fontWeight: "400",
  },
});

const mdDomino = defineStyle({
  fontSize: "16px",
});

const smDomino = defineStyle({
  fontSize: "14px",
});

const variants = {
  dominoAvatar: dominoAvatarVariant,
};

const sizes = {
  mdDomino: definePartsStyle({ container: mdDomino }),
  smDomino: definePartsStyle({ container: smDomino }),
};

export const avatarTheme = defineMultiStyleConfig({ variants, sizes });
