import { ConversationMessageModel } from "../../ConversationModel";
import { Box, Flex, Text } from "@chakra-ui/react";
import s from "../../Conversation.module.scss";
import { formatMessageDate } from "../../../../common/utils/formatDate";
import { useTranslation } from "react-i18next";

interface Props {
  message: ConversationMessageModel;
}
export default function PaymentMessageItem(props: Props) {
  const paymentInfo = props.message.payment;
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  return (
    <>
      {paymentInfo && (
        <Flex align="flex-start !important" className={s.messageTextWrapper} flexDirection="column">
          <Text variant="medium" fontWeight={600}>
            {`${t("editNodePopup.The contact transferred the bot")} ${paymentInfo.amount} ${paymentInfo.currency}`}
          </Text>
          <Flex color="#3E5E95" gap="4px" flexDirection={"column"}>
            {paymentInfo.orderInfo?.name && (
              <Flex gap="2px">
                <Text variant="medium">{t("editNodePopup.Name")}:</Text>
                <Text variant="medium">
                  {`${paymentInfo.orderInfo?.name}`}
                  {paymentInfo.orderInfo?.email || paymentInfo.orderInfo?.phoneNumber ? "," : ""}
                </Text>
              </Flex>
            )}
            {paymentInfo.orderInfo?.email && (
              <Flex gap="2px">
                <Text variant="medium">{t("editNodePopup.Email")}:</Text>
                <Text variant="medium">
                  {`${paymentInfo.orderInfo?.email}`}
                  {paymentInfo.orderInfo?.phoneNumber ? "," : ""}
                </Text>
              </Flex>
            )}
            {paymentInfo.orderInfo?.phoneNumber && (
              <Flex gap="2px">
                <Text variant="medium">{t("editNodePopup.Phone")}:</Text>
                <Text variant="medium">{`${paymentInfo.orderInfo?.phoneNumber}`}</Text>
              </Flex>
            )}
          </Flex>
          <Box alignSelf="flex-end" className={s.date}>
            {formatMessageDate(props?.message?.date)}
          </Box>
        </Flex>
      )}
    </>
  );
}
