import React, { useMemo } from "react";
import { RecipientFormModel } from "../../BroadcastModel";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../common/state/store";
import { selectContactList, selectInitialRecipients, selectRecipientsList } from "../../BroadcastSlice";

interface Props {
  wasSelectAllChecked: boolean;
  recipients: RecipientFormModel[];
  includedRecipients: number;
  excludedRecipients: number;
  isUnlimited: boolean;
  maxCount: number;
  initialSentForAllFlag: boolean | undefined;
  debouncedSearchText: string;
  broadcastRecipientCount: number | undefined;
  checkedAllFlag: boolean;
}

export const RecipientsCounter = ({
  wasSelectAllChecked,
  recipients,
  isUnlimited,
  maxCount,
  initialSentForAllFlag,
  debouncedSearchText,
  broadcastRecipientCount,
  includedRecipients,
  excludedRecipients,
  checkedAllFlag,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const recipientList = useAppSelector(selectRecipientsList);
  const contactList = useAppSelector(selectContactList);
  const initialRecipients = useAppSelector(selectInitialRecipients);

  function validateByBilling() {
    if (isUnlimited) {
      if (!checkedAllFlag) {
        return contactList?.totalItems;
      }
      return contactList?.totalItems && contactList?.totalItems - excludedRecipients;
    } else {
      const items = contactList?.totalItems ?? 0;
      const val = maxCount > items ? items : maxCount;
      return !checkedAllFlag ? val : val - excludedRecipients;
      // return wasSelectAllChecked && !recipients.length
      //   ? val
      //   : wasSelectAllChecked && recipients.length && initialRecipients && contactList
      //   ? val - excludedRecipients
      //   : val;
    }
  }

  const counter = useMemo(() => {
    if (debouncedSearchText && broadcastRecipientCount) {
      return broadcastRecipientCount + includedRecipients - excludedRecipients;
    }
    if (recipientList?.availableRecipients) {
      return recipientList.availableRecipients;
    } else if (!wasSelectAllChecked && !recipients?.length) {
      return broadcastRecipientCount;
    } else if (
      !wasSelectAllChecked &&
      contactList?.totalItems &&
      initialRecipients &&
      !checkedAllFlag &&
      broadcastRecipientCount
    ) {
      return broadcastRecipientCount + includedRecipients - excludedRecipients;
    } else if (
      !wasSelectAllChecked &&
      contactList?.totalItems &&
      initialRecipients &&
      !checkedAllFlag &&
      broadcastRecipientCount
    ) {
      return broadcastRecipientCount + includedRecipients - excludedRecipients;
    } else if (!wasSelectAllChecked && recipients && !checkedAllFlag && selectRecipientsList.length > 0) {
      return recipients.length;
    } else {
      return validateByBilling();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientList, recipients, wasSelectAllChecked, contactList]);

  return (
    <>
      <Text>
        <span data-pw="selected-contacts-count">{counter ?? 0}</span>
        {` ${t("Selected")}`}
      </Text>
    </>
  );
};
