import { t } from "i18next";
import {
  ComplexFilterFieldTypes,
  ComplexFilterGroupTypes,
  ConditionsByField,
  ConditionsTitles,
  ContactsFilterFields,
} from "./ComplexFilterModel";
import { CustomVariableModel, CustomVariableScope } from "../../common/AppEnums";

export const createFieldsToContactsFilter = (variables?: CustomVariableModel[]) => {
  let contactList = [
    { fieldName: "Id", fieldValue: "id" },
    { fieldName: "Username", fieldValue: "username" },
    { fieldName: "First Name", fieldValue: "firstName" },
    { fieldName: "Last Name", fieldValue: "lastName" },
    { fieldName: "Phone Number", fieldValue: "phoneNumber" },
  ];
  contactList = contactList.map(el => {
    return {
      ...el,
      type: ComplexFilterFieldTypes.Text,
      fieldName: t(`fieldToFilter.${el.fieldName}`),
      groupType: ComplexFilterGroupTypes.ContactFields,
    };
  });

  const groupList = [{ label: t("Contacts"), items: contactList }];
  if (variables && variables?.length) {
    const contactVariableList = variables
      ?.filter(el => ConditionsByField.allowedFieldTypes.includes(el.type) && el.scope === CustomVariableScope.Contact)
      .map(el => {
        return {
          fieldName: el.key,
          fieldValue: el.key,
          type: el.type,
          groupType: ComplexFilterGroupTypes.CustomVariables,
          description: el.description,
          id: el.id,
        };
      });
    groupList.push({ label: t("Custom Contact Fields"), items: contactVariableList });
  }
  return { groups: groupList };
};

export function getTitleByFieldValue(value: string) {
  return ConditionsTitles.titles.find(el => el.value === value)?.title;
}

export function getTitleByFieldTitle(value: string) {
  return ContactsFilterFields.find(el => el.value === value)?.title;
}
