import { CustomVariableType } from "../../../../common/AppEnums";

export const getPlaceholder = (type: CustomVariableType) => {
  if (type === CustomVariableType.Array) {
    return "Enter array like 111,two,THREE";
  }
  if (type === CustomVariableType.Number) {
    return "Enter digits";
  }
  return "Enter text";
};

export const numberValidated = (text: string) => {
  const onlyNumbersRegex = /^\d+$/;
  return onlyNumbersRegex.test(text);
};

export const getItemsCounterForTable = (width: number, height?: number) => {
  if (width <= 390 && height && height <= 703) {
    return "1";
  }
  if (width <= 390) {
    return "2";
  }
  if (width <= 414) {
    return "2";
  }
  if (width <= 480) {
    return "3";
  }
  if (width <= 540) {
    return "2";
  }
  if (width <= 768) {
    return "10";
  }
  if (width <= 1024) {
    return "4";
  }
  if (width <= 1180) {
    return "7";
  }
  if (width <= 1280 && height && height <= 700) {
    return "5";
  }
  if (width <= 1280 && height && height <= 800) {
    return "7";
  }
  if (width <= 1368) {
    return "9";
  }
  if (width <= 1920) {
    return "11";
  }
  return "10";
};
