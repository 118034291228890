import { PaginatorModel } from "../../common/paginator/PaginatorModel";

export interface BroadcastListModel extends PaginatorModel {
  items: BroadcastItemModel[];
}

export interface BroadcastItemModel {
  id: string;
  name: string;
  recipientsCount: number;
  status: BroadcastStatus;
  scheduledDate: string | null;
  startedDate: string | null;
  completedDate: string | null;
}

export enum BroadcastStatus {
  Draft = "Draft",
  Scheduled = "Scheduled",
  Sending = "Sending",
  Finished = "Finished",
  Cancelled = "Cancelled",
  Failed = "Failed",
}
