import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../loading/LoadingStateContainer";
import { RouterContainer } from "../router/RouterContainer";
import { useAppDispatch } from "../state/store";
import { logout } from "./AuthSlice";

export const Invitation = () => {
  const { inviteToken } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout({ inviteToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RouterContainer>
      <Loading />
    </RouterContainer>
  );
};
