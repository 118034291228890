import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { popoverAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(popoverAnatomy.keys);

const dominoPopover = definePartsStyle({
  footer: {
    p: "0",
  },

  body: {
    marginX: "24px",
    marginY: "16px",
    padding: "0",
  },
  content: {
    boxShadow: "0px 4px 8px rgba(35, 43, 57, 0.08)",
  },
});

const tooltipPopover = definePartsStyle({
  body: {
    margin: "0",
    padding: "8px",
    borderRadius: "4px",
    background: "#FEF6DC",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    fontSize: "13px",
    lineHeight: "16px",
  },
  content: {
    maxWidth: "300px",
  },
});

const dominoLinkPopover = definePartsStyle({
  popper: {
    zIndex: 100000000,
  },
});

export const popoverTheme = defineMultiStyleConfig({
  variants: {
    dominoPopover,
    tooltipPopover,
    dominoLinkPopover,
  },
});
