import { call, put, select, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleException } from "../../common/SagaHelper";
import { begin, beginScope, complete, completeScope } from "../../common/loading/LoadingStateActions";
import {
  BroadcastFormModel,
  BroadcastModel,
  BroadcastRecipientModel,
  BroadcatMessageModel,
  ContactListModel,
  FileModel,
  RecipientFiltersModel,
  RecipientsImportModel,
} from "./BroadcastModel";
import { broadcastSlice, selectCurrentFile, selectCustomVariables, selectRecipientsList } from "./BroadcastSlice";
import { BroadcastReplyData, BroadcastRecipientsData, ContactListData, CustomVariableData, FileData } from "./BroadcastData";
import {
  createBroadcastApi,
  getBroadcastApi,
  editBroadcastApi,
  deleteBroadcastApi,
  cancelBroadcastApi,
  postFile,
  executeBroadcastApi,
  getContactCustomVariablesApi,
  getSystemVariablesApi,
  getBroadcastRecipientListApi,
  importRecipientsByFileApi,
  getDraftContactListApi,
  getContactListApi,
} from "./BroadcastApi";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { selectCurrentBotId } from "../sidebar/SidebarSlice";
import { routerSlice } from "../../common/router/RouterSlice";
import { FileUploadType } from "../conversation/ConversationModel";
import { BadRequestError, ValidationError } from "../../common/ErrorModel";
import { mapBroadcastDataToModel, mapBroadcastEditModelToData, mapBroadcastModelToData } from "./BroadcastMapper";
import { t } from "i18next";
import { store } from "../..";
import { broadcastListSlice, BroadcastListState, selectBroadcastListState } from "../broadcastList/BroadcastListSlice";
import { FileValidate, FileValidateModel } from "../../common/validation/fileValidator";

export function* broadcastSaga() {
  yield takeLatest(broadcastSlice.actions.getBroadcast, getBroadcast);
  yield takeLatest(broadcastSlice.actions.getBroadcastRecipientList, getBroadcastRecipientList);
  yield takeLatest(broadcastSlice.actions.editBroadcast, editBroadcast);
  yield takeLatest(broadcastSlice.actions.createBroadcast, createBroadcast);
  yield takeLatest(broadcastSlice.actions.getContactList, getContactList);
  yield takeLatest(broadcastSlice.actions.getDraftContactList, getDraftContactList);
  yield takeLatest(broadcastSlice.actions.deleteBroadcast, deleteBroadcast);
  yield takeLatest(broadcastSlice.actions.cancelBroadcast, cancelBroadcast);
  yield takeLatest(broadcastSlice.actions.executeBroadcast, executeBroadcast);
  yield takeLatest(broadcastSlice.actions.getCustomVariables, getCustomVariables);
  yield takeLatest(broadcastSlice.actions.uploadBroadcastFile, uploadBroadcastFile);
  yield takeLatest(broadcastSlice.actions.importRecipientsByFile, importRecipientsByFile);
}

function* getBroadcast(action: PayloadAction<{ botId: string; broadcastId: string }>) {
  try {
    yield put(begin());
    const data: BroadcastReplyData = yield call(getBroadcastApi, action.payload.botId, action.payload.broadcastId);
    const message: BroadcatMessageModel = {
      text: data.message.text,
      parsedText: data.message?.parsedText,
      entities: data.message.entities,
      buttons: data.message.buttons,
      typeDiscriminator: data.message.typeDiscriminator,
      fileId: data.message.fileId ?? "",
      fileName: data.message.fileName ?? "",
    };
    const broadcastData: BroadcastReplyData = {
      id: data.id,
      botId: data.botId,
      name: data.name,
      recipients: data.recipients,
      status: data.status,
      scheduledDate: data.scheduledDate,
      message: message,
      recipientsCount: data.recipientsCount,
      totalSent: data.totalSent,
      totalNotSent: data.totalNotSent,
      startedDate: data.startedDate,
      completedDate: data.completedDate,
      sentForAll: data.sentForAll,
      recipientFilter: data.recipientFilter,
    };

    const model = mapBroadcastDataToModel(broadcastData);
    yield put(broadcastSlice.actions.getBroadcastCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* getBroadcastRecipientList(
  action: PayloadAction<{ botId?: string; broadcastId?: string; queryFilterParams?: string; isScrolling?: boolean }>,
) {
  try {
    const recipientList = store.getState().app.broadcastState.broadcastRecipientList;
    const broadcastData: BroadcastRecipientsData = yield call(
      getBroadcastRecipientListApi,
      action.payload.botId,
      action.payload.broadcastId,
      action.payload.queryFilterParams,
    );
    const contactListModel: BroadcastRecipientModel =
      recipientList && action.payload.isScrolling
        ? {
            ...recipientList,
            items: [...recipientList.items, ...broadcastData.items],
            currentPage: broadcastData.currentPage,
          }
        : {
            ...broadcastData,
            currentPage: broadcastData.currentPage,
          };
    yield put(broadcastSlice.actions.getBroadcastRecipientListCompleted(contactListModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* editBroadcast(
  action: PayloadAction<{
    botId: string;
    broadcast: BroadcastFormModel;
    execute: boolean;
    recipientFilter: RecipientFiltersModel[] | null;
  }>,
) {
  try {
    yield put(begin());
    const currentFile: FileModel | undefined = yield select(selectCurrentFile);
    const customVariables: CustomVariableData[] = yield select(selectCustomVariables);
    const model = mapBroadcastEditModelToData(action.payload, currentFile, customVariables);

    const broadcast = {
      ...action.payload.broadcast,
      message: model.message,
      scheduledDate: action.payload.broadcast.isScheduled ? action.payload.broadcast.scheduledDate : null,
      recipients: [],
      customVariableIds: model.customVariableIds,
      sentForAll: model.sentForAllFlag,
      excludeAll: model.excludedAllFlag,
      includedRecipients: model.includedRecipients ?? null,
      excludedRecipients: model.excludedRecipients ?? null,
      recipientFilter:
        action.payload.recipientFilter && action.payload.recipientFilter.length ? action.payload.recipientFilter : null,
    };

    yield call(editBroadcastApi, action.payload.botId, broadcast);
    if (action.payload.execute) {
      yield call(executeBroadcastApi, action.payload.botId, action.payload.broadcast.id ?? "");
    }
    yield put(routerSlice.actions.redirect("/broadcasts"));
    yield put(
      notificationSlice.actions.notify({
        message: t("broadcast.Edited successfully!"),
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* executeBroadcast(action: PayloadAction<{ botId: string; broadcastId: string }>) {
  try {
    yield put(begin());
    yield call(executeBroadcastApi, action.payload.botId, action.payload.broadcastId);
    yield put(routerSlice.actions.redirect("/broadcasts"));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* getContactList(
  action: PayloadAction<{
    botId: string;
    queryFilterParams: string;
    isOnScrollPayload?: boolean;
  }>,
) {
  try {
    yield put(beginScope("contactList2"));
    const contactList = store.getState().app.broadcastState.contactList;
    const contactListData: ContactListData = yield call(
      getContactListApi,
      action.payload.botId,
      action.payload?.queryFilterParams,
    );

    const contactListModel: ContactListModel = {
      items:
        contactList?.items && contactList?.items?.length > 0 && contactListData.currentPage !== 1
          ? [...contactList.items.concat(contactListData.items)]
          : contactListData.items,
      currentPage: contactListData.currentPage,
      totalItems: contactListData.totalItems,
      totalPages: contactListData.totalPages,
      filteredItems: undefined,
      selectedRecipientsCount: contactListData.selectedRecipientsCount,
    };
    yield put(broadcastSlice.actions.getContactListCompleted(contactListModel));
    yield put(broadcastSlice.actions.getSelectedListCompleted(contactListData));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("contactList2"));
  }
}

function* getDraftContactList(
  action: PayloadAction<{
    botId: string;
    broadcastId: string;
    queryFilterParams: string;
  }>,
) {
  try {
    yield put(beginScope("contactList2"));
    const contactList = store.getState().app.broadcastState.contactList;
    const contactListData: ContactListData = yield call(
      getDraftContactListApi,
      action.payload.botId,
      action.payload.broadcastId,
      action.payload?.queryFilterParams,
    );

    const contactListModel: ContactListModel = {
      items:
        contactList?.items && contactList?.items?.length > 0 && contactListData.currentPage !== 1
          ? [...contactList.items.concat(contactListData.items)]
          : contactListData.items,
      currentPage: contactListData.currentPage,
      totalItems: contactListData.totalItems,
      totalPages: contactListData.totalPages,
      selectedRecipientsCount: contactListData.selectedRecipientsCount,
      filteredItems: undefined,
    };
    yield put(broadcastSlice.actions.getContactListCompleted(contactListModel));
    yield put(broadcastSlice.actions.getSelectedListCompleted(contactListData));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("contactList2"));
  }
}

function* createBroadcast(
  action: PayloadAction<{
    botId: string;
    broadcast: BroadcastFormModel;
    execute: boolean;
    sentForAll: boolean;
    recipientFilter?: RecipientFiltersModel[] | null;
  }>,
) {
  try {
    yield put(begin());
    const currentFile: FileModel | undefined = yield select(selectCurrentFile);
    const customVariables: CustomVariableData[] = yield select(selectCustomVariables);
    const recipientList: RecipientsImportModel | undefined = yield select(selectRecipientsList);
    const model = mapBroadcastModelToData(action.payload, currentFile, customVariables);
    const data: BroadcastModel = yield call(createBroadcastApi, action.payload.botId, {
      ...action.payload.broadcast,
      name: action.payload.broadcast.name.trim(),
      recipients:
        recipientList?.recipients && action.payload.sentForAll
          ? model.recipients
          : !action.payload.sentForAll
          ? model.recipients
          : undefined,
      scheduledDate: action.payload.broadcast.isScheduled ? action.payload.broadcast.scheduledDate : null,
      message: model.message,
      customVariableIds: model.customVariableIds,
      sentForAll: action.payload.sentForAll,
      recipientFilter:
        action.payload.recipientFilter && action.payload.recipientFilter.length ? action.payload.recipientFilter : null,
    });
    if (action.payload.execute) {
      yield call(executeBroadcastApi, data.botId, data.id);
    }
    yield put(broadcastSlice.actions.clearState());
    yield put(routerSlice.actions.redirect("/broadcasts"));
    yield put(
      notificationSlice.actions.notify({
        message: "Created successfully!",
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* deleteBroadcast(action: PayloadAction<{ broadcastId: string }>) {
  try {
    yield put(begin());
    const botId: string = yield select(selectCurrentBotId) ?? "";
    yield call(deleteBroadcastApi, botId, action.payload.broadcastId);
    yield put(routerSlice.actions.redirect("/broadcasts"));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* cancelBroadcast(action: PayloadAction<{ broadcastId: string }>) {
  try {
    yield put(begin());
    const botId: string = yield select(selectCurrentBotId) ?? "";
    const broadcastListState: BroadcastListState = yield select(selectBroadcastListState);
    const currentPage = broadcastListState.broadcastList?.currentPage ?? 1;
    yield call(cancelBroadcastApi, botId, action.payload.broadcastId);
    yield put(routerSlice.actions.redirect("/broadcasts"));
    yield put(broadcastListSlice.actions.getBroadcastList({ botId, page: currentPage }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* uploadBroadcastFile(action: PayloadAction<{ file: File; type: FileUploadType }>) {
  try {
    validateFile(action.payload.file, action.payload.type);
    yield put(begin());
    const data: FileData = yield call(postFile, action.payload.file);
    const currentFile = {
      file: { fileId: data.id, fileName: data.name },
      type: action.payload.type,
    };
    yield put(broadcastSlice.actions.setCurrentFile(currentFile));
  } catch (e: unknown) {
    if (e instanceof ValidationError) {
      yield put(
        notificationSlice.actions.notify({
          message: e.validationData.errors[0].message,
          type: "error",
        }),
      );
    }
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

export function* getCustomVariables(action: PayloadAction<{ botId: string }>) {
  try {
    const contactVariablesData: CustomVariableData[] = yield call(getContactCustomVariablesApi);
    const systemVariablesData: CustomVariableData[] = yield call(getSystemVariablesApi);
    const variablesModel = systemVariablesData.concat(contactVariablesData);
    yield put(broadcastSlice.actions.getCustomVariablesSucceed(variablesModel));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

const validateFile = (file: File, type: FileUploadType) => {
  const result: FileValidateModel = FileValidate(file, type);
  if (result.isInvalid) {
    throw new BadRequestError(result.error);
  }
};

export function* importRecipientsByFile(action: PayloadAction<{ botId: string | undefined; file: File | null }>) {
  try {
    yield put(begin());
    const response: RecipientsImportModel = yield call(importRecipientsByFileApi, action.payload.botId, action.payload.file);
    yield put(broadcastSlice.actions.setImportResult(response));
  } catch (e: unknown) {
    yield put(broadcastSlice.actions.setImportResult(null));

    yield handleException(e);
  } finally {
    yield put(complete());
  }
}
