import { Button, Flex, FormControl, FormLabel, Heading, Input, ListItem, Image, List, Box, Link, Badge } from "@chakra-ui/react";
import botImage from "../../../../assets/images/botImage.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import s from "../../AddBot.module.scss";

interface Props {
  onConnect: (token: string) => void;
}

export const NewBot = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [token, setToken] = useState<string>("");
  const navigate = useNavigate();

  return (
    <Flex
      w={"100%"}
      h={"auto"}
      overflow={{ base: "auto", "2xl": "hidden" }}
      flexDirection={{ base: "column-reverse", md: "row" }}
      display={{ base: "grid", md: "flex" }}
      className={s.existedBotBlock}
    >
      <Flex
        bg="bgLight"
        justifyContent={"center"}
        p={"32px"}
        w={{ base: "100%", md: "52%", xl: "42%" }}
        fontSize="15px"
        flexDirection="column"
        height={"auto"}
      >
        <Heading mb={"15px"} fontSize={"20px"} fontWeight={"400"} maxW={{ base: "initial", md: "445px" }}>
          {t("createNew.This instruction helps you to create a new")} {t("createNew.Telegram bot")}
        </Heading>
        <List spacing={5} my={"15px"}>
          <ListItem>
            <Flex alignItems={"flex-start"} w={"90%"}>
              <Badge variant="dominoNumberList">1</Badge>
              <Box ml={"15px"}>
                {t("createNew.Open")}{" "}
                <Link
                  color={"#0084FF"}
                  _hover={{ color: "mainPurple", textDecoration: "underline" }}
                  href="https://telegram.me/BotFather"
                  isExternal
                >
                  @BotFather
                </Link>{" "}
                {t("connectExisting.in Telegram and click /start")}
              </Box>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex alignItems={"flex-start"} w={"85%"}>
              <Badge variant="dominoNumberList">2</Badge>
              <Box ml={"15px"}> {t("createNew.Send /newbot and follow the instructions")}</Box>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex alignItems={"flex-start"} w={"85%"}>
              <Badge variant="dominoNumberList">3</Badge>
              <Box ml={"15px"}>
                {t("createNew.Once the bot is created, you will receive a message with the token. Copy token and paste it here")}
              </Box>
            </Flex>
          </ListItem>
        </List>
        <FormControl mt={"10px"}>
          <FormLabel>{t("Telegram bot token")}</FormLabel>
          <Input bg={"white"} value={token} onChange={e => setToken(e.target.value)} placeholder={`${t("Enter token")}`} />
        </FormControl>
        <Flex w="100%" justifyContent="space-between" alignItems={"flex-end"}>
          <Button variant="dominoOutlineViolet" onClick={() => navigate("/addbot")}>
            {ct("Back")}
          </Button>
          <Button onClick={() => props.onConnect(token)} mt={"25px"} variant="dominoViolet" w={"150px"}>
            {t("Connect")}
          </Button>
        </Flex>
      </Flex>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        w={{ base: "100%", md: "48%", xl: "55%" }}
        h={"auto"}
        p={{ base: "32px 32px 0", md: "0 32px" }}
        gridRow={1}
      >
        <Image w={"100%"} maxW={{ base: "240px", md: "460px" }} transform={" scale(-1, 1)"} src={botImage}></Image>
      </Flex>
    </Flex>
  );
};
