export const composeValidators =
  (...validators: Array<(value: unknown) => string | void>) =>
  (value: unknown): string | void =>
    validators.reduce((error: string | void, validator) => error || validator(value), undefined);

export const required =
  (errorMessage = "Required") =>
  (value: unknown): string | void => {
    const isArrayEmpty = Array.isArray(value) && value.length === 0;
    const isEmpty = value === null || value === undefined;
    const haveOnlySpaces = typeof value === "string" && value.trim() === "";

    if (isArrayEmpty || isEmpty || haveOnlySpaces) {
      return errorMessage ?? "";
    }
  };

export const notHaveOnlySpaces =
  (errorMessage = "have only spaces") =>
  (value: unknown): string | void => {
    const haveOnlySpaces = typeof value === "string" && value !== "" && value.trim() === "";

    if (haveOnlySpaces) {
      return errorMessage ?? "";
    }
  };

export const haveSpaces = (errorMessage = "have spaces") => {
  return (value: unknown): string | void => {
    const haveOnlySpaces = typeof value === "string" && value.includes(" ");

    if (haveOnlySpaces) {
      return errorMessage ?? "";
    }
  };
};

export const maxLength =
  (max: number, errorMessage?: string) =>
  (value: unknown): string | void => {
    const errorMessageResult = errorMessage ? errorMessage : `Maximum length is ${max}`;
    return (typeof value === "string" && value.length <= max) ||
      (typeof value === "number" && value.toString().length <= max) ||
      value === null
      ? undefined
      : errorMessageResult;
  };

export const isNumberValue =
  (errorMessage: string) =>
  (value: unknown | string): string | void => {
    const regex = /^[0-9]+$/;
    return value?.toString().match(regex) ? undefined : errorMessage;
  };

export const haveSpecialSymbol = (errorMessage: string) => {
  return (value: unknown | string): string | void => {
    const haveSpecialSymbol = typeof value === "string" && value.includes("@");

    if (haveSpecialSymbol) {
      return errorMessage;
    }
  };
};

export const relatedRequired =
  (relatedTo: unknown, errorMessage = "Required") =>
  (value: unknown): string | void => {
    const isArrayEmpty = Array.isArray(value) && value.length === 0;
    const isEmpty = value === null || value === undefined;
    const haveOnlySpaces = typeof value === "string" && value.trim() === "";

    if ((isArrayEmpty || isEmpty || haveOnlySpaces) && !relatedTo) {
      return errorMessage ?? "";
    }
  };
