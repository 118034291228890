import { Spinner } from "@chakra-ui/react";
import s from "./NodeSpinner.module.scss";

export const NodeSpinner = () => {
  return (
    <div className={s.overlay}>
      <Spinner color="mainPurple" className={s.overlaySpinner} />
    </div>
  );
};
