import { Flex, Button, Menu, MenuButton, MenuItem, MenuList, Icon, Show } from "@chakra-ui/react";
import { ReactComponent as LogoIcon } from "../../../assets/icons/sidebar/logoIcon.svg";
import { ReactComponent as LogoText } from "../../../assets/icons/sidebar/logoText.svg";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/globeIcon.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import s from "./PublicHeaderContainer.module.scss";
import { useTranslation } from "react-i18next";
import { LanguageEnum } from "../../../common/user/UserModel";
import GTM from "../../../common/ga/GAEventTracker";

export const PublicHeaderContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams("");
  const inviteToken = searchParams.get("inviteToken");
  const trackEvent = GTM("Signin");

  return (
    <>
      <Flex flexDirection={"row"} alignItems="center" justifyContent={"space-between"} className={s.headerBlock}>
        <Flex alignItems="center">
          <LogoIcon className={s.logoIcon} />
          <LogoText className={s.logoText} />
        </Flex>
        <Flex alignItems="center">
          <Menu autoSelect={true} size="max" variant="dominoDotsMenu" gutter={4}>
            <MenuButton
              as={Button}
              variant="langButtonTheme"
              mr={{ base: "-8px", sm: "16px", md: "24px" }}
              leftIcon={<Icon as={GlobeIcon} boxSize="18px" />}
              onClick={e => e.stopPropagation()}
              fontSize={{ base: "13px", md: "15px" }}
              size={{ base: "dominoSm", md: "dominoMd" }}
            >
              <Show above="sm">{LanguageEnum[i18n.resolvedLanguage as keyof typeof LanguageEnum]}</Show>
              <Show breakpoint="(max-width: 479px)">
                {LanguageEnum[i18n.resolvedLanguage as keyof typeof LanguageEnum].substring(0, 3)}
              </Show>
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  i18n.changeLanguage("en");
                }}
              >
                English
              </MenuItem>
              <MenuItem
                onClick={() => {
                  i18n.changeLanguage("ru");
                }}
              >
                Русский
              </MenuItem>
            </MenuList>
          </Menu>
          <Show above="sm">
            {(location.pathname === "/auth/signIn" || location.pathname === "/auth/accountNotApproved") && (
              <Button
                size={{ base: "dominoSm", md: "dominoMd" }}
                variant="dominoViolet"
                onClick={() => {
                  trackEvent("Signup");
                  navigate(`/auth/signUp${inviteToken ? `?inviteToken=${inviteToken}` : ""}`);
                }}
              >
                {t("Sign Up")}
              </Button>
            )}
            {location.pathname === "/auth/signUp" && (
              <Button
                size={{ base: "dominoSm", md: "dominoMd" }}
                variant="dominoViolet"
                onClick={() => {
                  trackEvent("Signin");
                  navigate(`/auth/signIn${inviteToken ? `?inviteToken=${inviteToken}` : ""}`);
                }}
              >
                {t("Sign In")}
              </Button>
            )}
          </Show>
        </Flex>
      </Flex>
    </>
  );
};
