import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";

interface Props {
  deleteActionState: boolean;
  onDelete: () => void;
  onClose: () => void;
}

export const DeleteActionConfiramationPopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "flow",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const onDeleteClick = () => {
    props.onDelete();
    props.onClose();
  };

  return (
    <Modal isOpen={props.deleteActionState} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent maxW="460px" borderRadius="12px">
        <ModalHeader></ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <Icon boxSize="20px" as={CrossIcon} />
        </ModalCloseButton>
        <ModalBody paddingTop="0" display="flex" flexDirection="column" gap="8px">
          <Text pr="10px" variant="h1">
            {t("Do you really want to delete the action item")}?
          </Text>
        </ModalBody>
        <ModalFooter paddingBottom="32px" justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={props.onClose}>
            {ct("Cancel")}
          </Button>
          <Button variant="dominoPrimaryRed" mr={3} onClick={onDeleteClick}>
            {ct("Delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
