import { NodeValidation } from "../components/flowBuilder/FlowBuilderModel";

export class ApiError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export class BadRequestError extends Error {
  flowId?: string;
  nodeId?: string;
  constructor(message?: string, flowId?: string, nodeId?: string) {
    super(message);
    Object.setPrototypeOf(this, BadRequestError.prototype);
    this.flowId = flowId;
    this.nodeId = nodeId;
  }
}

export class PaymentRequiredError extends Error {
  maxCount?: number;
  isUnlimited?: boolean;
  constructor(message?: string, maxCount?: number, isUnlimited?: boolean) {
    super(message);
    Object.setPrototypeOf(this, PaymentRequiredError.prototype);
    this.maxCount = maxCount;
    this.isUnlimited = isUnlimited;
  }
}

export class NotFoundRequestError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, NotFoundRequestError.prototype);
  }
}

export class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export class Error403Model extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, Error403Model.prototype);
  }
}

export class ErrorNoContent extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, ErrorNoContent.prototype);
  }
}

export interface Error402Model {
  message: string;
  uiMessage: string;
  validationSource?: {
    maxCount: number;
    isUnlimited: boolean;
  };
}

export interface Error400Model {
  message: string;
  uiMessage: string;
  validationSource?: {
    flowId: string;
    nodeId: string;
  };
}

export interface ValidationModel {
  errors: ValidationInfoError[];
}

export interface ValidationInfoError {
  field: string;
  message: string;
}

export class ValidationError extends Error {
  validationData: ValidationModel;
  flowId?: string;
  nodeId?: string;
  constructor(validationData: ValidationModel, message?: string, flowId?: string, nodeId?: string) {
    super(message);
    Object.setPrototypeOf(this, ValidationError.prototype);
    this.flowId = flowId;
    this.nodeId = nodeId;
    this.validationData = validationData;
  }
}

// TODO: replace it with Validation Error (refactor as separate commit)
export class NodeValidationError extends Error {
  validationData: NodeValidation;
  constructor(validationData: NodeValidation, message?: string) {
    super(message);
    Object.setPrototypeOf(this, NodeValidationError.prototype);
    this.validationData = validationData;
  }
}
