import { useEffect } from "react";
import { Container, Box, Text, FormControl, FormLabel, Flex, Link } from "@chakra-ui/react";
import s from "./ViewBroadcast.module.scss";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectCurrentBotId } from "../../../sidebar/SidebarSlice";
import { clearState, getBroadcast, getBroadcastRecipientList, selectBroadcastState, setCurrentFile } from "../../BroadcastSlice";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ContactListView } from "../ContactList/ContactListView";
import { BroadcastFormModel, BroadcastMessageTypeDescriminator, BroadcastStatus, FileUploadType } from "../../BroadcastModel";
import { ReactComponent as PaperClipIcon } from "../../../../assets/icons/paperClipIcon.svg";
import { ButtonsListView } from "../ButtonsList/ButtonsListView";
import { Formik } from "formik";
import { AppSettings } from "../../../../common/AppSettings";
import { FormattedText } from "../../../../common/formattedText/formattedText";
import { FileNameSplitter } from "../../../../UI/atoms/fileNameSplitter/FileNameSplitter";

declare const appSettings: AppSettings;

export const ViewBroadcast = () => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });

  const dispatch = useAppDispatch();
  const { broadcastId } = useParams();

  const currentBotId = useAppSelector(selectCurrentBotId);
  const { broadcast } = useAppSelector(selectBroadcastState);

  useEffect(() => {
    if (currentBotId && broadcastId) {
      dispatch(getBroadcast({ botId: currentBotId, broadcastId }));
    }
    return () => {
      dispatch(clearState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBotId]);

  useEffect(() => {
    if (broadcast) {
      if (broadcast.message.typeDiscriminator === BroadcastMessageTypeDescriminator.BroadcastDocumentMessage) {
        const { fileName, fileId } = broadcast.message;
        dispatch(
          setCurrentFile({
            file: { fileName, fileId },
            type: FileUploadType.document,
          }),
        );
      }
      if (broadcast.message.typeDiscriminator === BroadcastMessageTypeDescriminator.BroadcastPhotoMessage) {
        const { fileName, fileId } = broadcast.message;
        dispatch(
          setCurrentFile({
            file: { fileName, fileId },
            type: FileUploadType.photo,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcast]);

  const getRecipient = (queryFilterParams?: string, isScrolling?: boolean) => {
    dispatch(getBroadcastRecipientList({ botId: currentBotId, broadcastId, queryFilterParams, isScrolling }));
  };

  if (!broadcast) {
    return <></>;
  }

  const recipients = broadcast.recipients.map(recipient => ({
    ...recipient,
    externalId: recipient.externalContactId,
  }));

  const broadcastData: BroadcastFormModel = {
    ...broadcast,
    isScheduled: !!broadcast.scheduledDate && broadcast.status === BroadcastStatus.Scheduled,
    recipients,
  };

  function getStartedTime() {
    if (broadcastData.isScheduled) {
      return (
        <Flex gap="4px">
          {" "}
          <Text data-pw="date" color={"#3E5E95"}>
            {dayjs(broadcastData.scheduledDate).format("DD.MM.YYYY")}
          </Text>
          <Text data-pw="time" color={"#8592A3"}>
            {dayjs(broadcastData.scheduledDate).format("HH:mm")}
          </Text>
        </Flex>
      );
    } else {
      return (
        <Flex gap="4px">
          {" "}
          <Text data-pw="date" color={"#3E5E95"}>
            {dayjs(broadcastData.startedDate).format("DD.MM.YYYY")}
          </Text>
          <Text data-pw="time" color={"#8592A3"}>
            {dayjs(broadcastData.startedDate).format("HH:mm")}
          </Text>
        </Flex>
      );
    }
  }

  return (
    <>
      <Box className={s.broadcastMainBox}>
        <Formik
          onSubmit={() => {
            return;
          }}
          initialValues={broadcastData}
          enableReinitialize
        >
          <>
            <Box width="100%">
              <Container variant="dominoContentBlock">
                <FormControl className={`${s.broadcastListItemGrid} ${s.broadcastAdaptiveSchedule}`}>
                  <Text data-pw="broadcast-name" fontWeight={600} variant={"preLargeBold"}>
                    {broadcastData.name}
                  </Text>
                  <Flex className={s.broadcastDateFlex} textAlign={"center"} gap={"54px"}>
                    <Flex gap={"8px"}>
                      <Text fontWeight={600} variant={"preLargeBold"}>
                        {" "}
                        {broadcastData.isScheduled ? t("Sheduled") : t("Started")}
                      </Text>
                      <Text mt={"1px"} variant={"medium"}>
                        {broadcastData.startedDate ?? broadcastData.scheduledDate ? getStartedTime() : "N/A"}
                      </Text>
                    </Flex>
                    {!broadcastData.isScheduled && (
                      <Flex gap={"8px"}>
                        <Text fontWeight={600} variant={"preLargeBold"}>
                          {" "}
                          {t("Completed")}
                        </Text>
                        <Text mt={"1px"} variant={"medium"}>
                          {broadcastData.completedDate ? (
                            <Flex gap="4px">
                              <Text data-pw="completed-date" color={"#3E5E95"}>
                                {dayjs(broadcastData.completedDate).format("DD.MM.YYYY")}
                              </Text>
                              <Text data-pw="completed-time" color={"#8592A3"}>
                                {dayjs(broadcastData.completedDate).format("HH:mm")}
                              </Text>
                            </Flex>
                          ) : (
                            "N/A"
                          )}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </FormControl>
              </Container>
              <Container variant="dominoContentBlock">
                <FormControl
                  className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveContactList}`}
                >
                  <Box width={"100%"}>
                    <Box pt={"8px"} pb={"20px"} borderBottom={"1px solid #EFF5FE"}>
                      <Text fontWeight={600}> {t("Contacts list")}</Text>
                    </Box>
                    <Flex
                      className={s.allTotalAdaptive}
                      gap={"8px"}
                      flexDirection={"column"}
                      mt={"8px"}
                      lineHeight={"20px"}
                      fontSize={"15px"}
                    >
                      <Flex gap={"10px"} justifyContent={"space-between"}>
                        <Text data-pw="all-contacts">{t("All")}</Text>
                        <Text data-pw="all-contacts-count">{broadcast.recipientsCount}</Text>
                      </Flex>
                      <Flex gap={"10px"} justifyContent={"space-between"}>
                        <Text data-pw="sent-contacts">{t("Sent")}</Text>
                        <Text data-pw="sent-contacts-count">{broadcast.totalSent}</Text>
                      </Flex>
                      <Flex gap={"10px"} justifyContent={"space-between"}>
                        <Text data-pw="failed-contacts">{t("Failed")}</Text>
                        <Text data-pw="failed-contacts-count">{broadcast.totalNotSent}</Text>
                      </Flex>
                    </Flex>
                  </Box>
                  <ContactListView getRecipients={getRecipient} broadcastFormData={broadcastData} disabled={true} />
                </FormControl>
              </Container>
              <Container variant="dominoContentBlock">
                <FormControl
                  className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveMessage}`}
                >
                  <FormLabel>
                    <Text fontWeight={600}>{t("Broadcast message")}</Text>
                  </FormLabel>
                  <Box width={"100%"}>
                    {broadcast.message.text && (
                      <Box height={"140px"} p={"8px"} resize="none" className={s.broadcastMessage}>
                        <FormattedText
                          text={broadcast.message?.parsedText ?? broadcast.message.text}
                          entities={broadcast.message.entities || []}
                        />
                      </Box>
                    )}

                    <ButtonsListView buttons={broadcastData.message.buttons} />
                    {broadcastData.message &&
                      broadcastData.message.typeDiscriminator === BroadcastMessageTypeDescriminator.BroadcastPhotoMessage && (
                        <Flex>
                          <Link
                            isExternal
                            href={`${appSettings.apiBaseUrl}/file/${broadcastData.message?.fileId}`}
                            className={s.photoPreview}
                            data-pw="photo-preview"
                          >
                            <img
                              alt="addImage"
                              className={s.photo}
                              src={`${appSettings.apiBaseUrl}/file/${broadcastData.message?.fileId}`}
                            />
                          </Link>
                        </Flex>
                      )}
                    {broadcastData.message &&
                      broadcastData.message.typeDiscriminator === BroadcastMessageTypeDescriminator.BroadcastDocumentMessage && (
                        <Box
                          my={!broadcast.message.text && !broadcast.message.buttons.length ? "0px" : "8px"}
                          className={s.documentPreview}
                        >
                          <Link
                            isExternal
                            href={`${appSettings.apiBaseUrl}/file/${broadcastData.message?.fileId}`}
                            className={s.label}
                            data-pw="document-preview"
                          >
                            <PaperClipIcon className={s.clipIcon} />
                            <FileNameSplitter fileName={broadcastData.message?.fileName ?? ""}></FileNameSplitter>
                          </Link>
                        </Box>
                      )}
                  </Box>
                </FormControl>
              </Container>
            </Box>
          </>
        </Formik>
      </Box>
    </>
  );
};
