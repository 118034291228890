import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const dominoContentBlock = defineStyle({
  borderRadius: "12px",
  backgroundColor: "white",
  width: "100%",
  mx: 0,
  maxW: "100%",
  mb: "16px",
  p: 0,
});

export const containerTheme = defineStyleConfig({
  variants: {
    dominoContentBlock,
  },
});
