import { Box } from "@chakra-ui/react";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { SetOrganisation } from "../../../../common/setOrganisation/SetOrganisation";

export const SetOrganizationPage = () => {
  return (
    <Box>
      <Loading />
      <SetOrganisation />
    </Box>
  );
};
