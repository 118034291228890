import { IconButton, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmojiIcon } from "../../../../assets/icons/emojiIcon.svg";

interface Props {
  addEmoji: (emojiData: string) => void;
}

export const EmojiMenu = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "emojiPicker" });

  const onSelectEmoji = (emojiData: EmojiClickData, onClose: () => void) => {
    onClose();
    //Some focus bugs in library, we need this delay to prevent late focus issues
    setTimeout(() => {
      props.addEmoji(emojiData.emoji);
    }, 200);
  };

  return (
    <Menu isLazy lazyBehavior="unmount">
      {({ onClose }) => (
        <>
          <MenuButton
            as={IconButton}
            aria-label="emoji"
            icon={<EmojiIcon />}
            variant="dominoTextareaIcon"
            boxSize={{ base: "32px", lg: "24px" }}
            mr="6px"
            data-pw="emoji-button"
          />
          <MenuList p="0" border="none" zIndex={4} width={{ base: "250px", xs: "350px" }}>
            <EmojiPicker
              width={"100%"}
              searchPlaceHolder={t("Search") ?? ""}
              emojiVersion="5.0"
              lazyLoadEmojis={true}
              emojiStyle={EmojiStyle.NATIVE}
              onEmojiClick={e => {
                onSelectEmoji(e, onClose);
              }}
            />
          </MenuList>
        </>
      )}
    </Menu>
  );
};
