import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import {
  createFlow,
  discardFlow,
  editNode,
  getFlow,
  selectDraftFlowId,
  selectFlowBuilderState,
  selectNodeEditorState,
  selectNodeEvent,
  selectNodeValidationState,
  setEdges,
  setNodeEvent,
  setNodes,
} from "./FlowBuilderSlice";
// you need these styles for React Flow to work properly
import "reactflow/dist/style.css";
// additionally you can load the default theme
import "./components/CustomEdge/CustomEdge.css";
import s from "./FlowBuilder.module.scss";
import { ReactComponent as StarPlan } from "../../assets/icons/starPlan.svg";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { AddNodePopup } from "./components/AddNodePopup/AddNodePopup";
import {
  DeleteConfirmationPopupModel,
  FlowModel,
  NodeEventType,
  NodeModel,
  NodeType,
  TriggerNodeModel,
  TriggerTypeDiscriminator,
} from "./FlowBuilderModel";
import { NodeEditPopup } from "./components/NodeEditor/NodeEditPopup";
import { DeleteConfirmationPopup } from "./components/DeleteConfirmationPopup/DeleteConfirmationPopup";
import { selectCurrentBot } from "../sidebar/SidebarSlice";
import { FlowBuilder } from "./components/FlowBuilder";
import { applyEdgeChanges, ReactFlowProvider } from "reactflow";
import { AddVariablePopupModel } from "../modals/addVariable/AddVariableModel";
import { AddVariablePopup } from "../modals/addVariable/addVariableModal/AddVariableModal";
import { useTranslation } from "react-i18next";
import { AddNewTriggerPopup } from "./components/AddNewTriggerPopup/AddNewTriggerPopup";
import { LeaveNewFlowConfirmationPopup } from "./components/LeaveNewFlowConfirmationPopup/LeaveNewFlowConfirmationPopup";
import { getTariffPlanAccessability } from "../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../common/AppEnums";
import { TariffPlanRedirectButton } from "../../common/tariffPlan/TariffPlanRedirectButton";
import { getRandomId } from "../../common/utils/randomId";
import { ReactComponent as GoHomeIcon } from "../../assets/icons/GoHomeIcon.svg";
import { useGAWithCustomParameters } from "../../common/ga/GAEventTracker";

interface Props {
  flow: FlowModel | undefined;
  onNodeAccordionDelete: () => void;
  connectingNodeId: MutableRefObject<{
    current: string;
    handleId?: string | undefined;
  } | null>;
}

export function FlowBuilderContainer({ flow, connectingNodeId, onNodeAccordionDelete }: Props) {
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const state = useAppSelector(selectFlowBuilderState);
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(selectCurrentBot);
  const { id } = useParams();
  const [showAddNodePopup, setShowAddNodePopup] = useState(false);
  const [nodeCountTooltip, setNodeCountTooltip] = useState(false);
  const [showPopoverHome, setShowPopoverHome] = useState(false);
  const [goStartNode, setGoStartNode] = useState(false);
  const nodeEditorState = useAppSelector(selectNodeEditorState);
  const nodeValidationState = useAppSelector(selectNodeValidationState);
  const nodeEvent = useAppSelector(selectNodeEvent);
  const nodePopupState = useDisclosure();
  const draftFlowId = useAppSelector(selectDraftFlowId);

  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.NodeCountPerFlow);
  const { MaxCount, IsUnlimited } = accessability;
  const countNodes = flow?.nodes;

  const flowBuilderRef = useRef<HTMLDivElement | null>(null);
  const trackEvent = useGAWithCustomParameters("Flow");

  useEffect(() => {
    if (nodeEvent) {
      nodePopupState.onOpen();
    }
  }, [nodeEvent, nodePopupState]);

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    switch (id) {
      case "new":
        dispatch(createFlow({ botId: currentBot.id, flowId: id, newFlowId: getRandomId() }));
        break;
      default:
        if (id) {
          dispatch(getFlow(id));
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentBot?.id]);

  const addNode = (newNode: NodeModel) => {
    if (newNode && newNode.position && state.flow) {
      const newNodes = [
        ...state.flow.nodes,
        {
          id: newNode.id,
          type: newNode.type,
          position: newNode.position,
          data: newNode.flowAction,
        },
      ];
      dispatch(setNodes(newNodes));
      dispatch(editNode(newNode));
      setShowAddNodePopup(false);
      trackEvent("FlowAddNode", "", { target: "Menu" });
    }
  };

  const onDiscardChanges = (confirmNavigation: () => void) => {
    dispatch(discardFlow("new"));
    confirmNavigation();
  };
  const onDiscardChangesImport = (confirmNavigation: () => void) => {
    dispatch(discardFlow("import"));
    confirmNavigation();
  };

  const onNodeClick = (node: NodeModel | TriggerNodeModel) => {
    dispatch(editNode(node));
  };

  if (!state.flow) {
    return <></>;
  }

  const deleteEdge = (id?: string) => {
    if (flow && id) {
      if (flow.edges.find(el => el.target === "creation_menu")) {
        const updatedNodes = flow.nodes.filter(el => el.id !== "creation_menu");
        dispatch(setNodes(updatedNodes));
      }
      dispatch(setEdges(applyEdgeChanges([{ id, type: "remove" }], flow.edges)));
    }
  };

  const getNodePopup = () => {
    if (!nodeEvent) {
      return null;
    }

    const onClose = () => {
      nodePopupState.onClose();
      dispatch(setNodeEvent(null));
    };

    const onCreateTrigger = (type: TriggerTypeDiscriminator) => {
      if (flow?.nodes) {
        const node = flow.nodes.find(el => el.type === NodeType.Trigger) as NodeModel;
        onNodeClick({
          ...node,
          newTriggerType: type,
          isTriggerPopupOpen: !!nodeEditorState,
        });
        onClose();
      }
    };

    switch (nodeEvent.eventType) {
      case NodeEventType.deleteConfirmationPopup: {
        const { title, itemTitle, onDelete } = nodeEvent.eventData as DeleteConfirmationPopupModel;
        return (
          <DeleteConfirmationPopup
            isOpen={nodePopupState.isOpen}
            onClose={onClose}
            title={title}
            itemTitle={itemTitle}
            onDelete={onDelete}
          />
        );
      }
      case NodeEventType.addVariablePopup: {
        const { flowId, onCreate, scope } = nodeEvent.eventData as AddVariablePopupModel;
        return (
          <AddVariablePopup isOpen={nodePopupState.isOpen} onClose={onClose} flowId={flowId} onCreate={onCreate} scope={scope} />
        );
      }
      case NodeEventType.addNewTriggerPopup: {
        return (
          <AddNewTriggerPopup
            onClose={onClose}
            onCreateTrigger={onCreateTrigger}
            disabledTypes={flow?.triggers.map(el => el.typeDiscriminator) ?? []}
          />
        );
      }
      default: {
        return null;
      }
    }
  };
  const onDrop = (node: NodeModel) => {
    addNode(node);
  };

  return (
    <>
      {flow && flow.id === id && (
        <ReactFlowProvider>
          <FlowBuilder
            ref={flowBuilderRef}
            flow={flow}
            editNode={onNodeClick}
            validation={nodeValidationState}
            deleteEdge={deleteEdge}
            onDrop={onDrop}
            goStart={goStartNode}
            setGoStartNode={setGoStartNode}
            addNode={addNode}
            onNodeAccordionDelete={onNodeAccordionDelete}
            connectingNodeId={connectingNodeId}
          />
          <Flex className={s.add}>
            {!IsUnlimited && countNodes && countNodes.length >= MaxCount + 1 ? (
              <Popover trigger={"hover"}>
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <IconButton
                        onClick={() => onNodeAccordionDelete()}
                        isRound={true}
                        variant="dominoViolet"
                        aria-label="Call Sage"
                        size={"lg"}
                        icon={<StarPlan fill="white" />}
                      />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                        <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                        <Flex alignItems="center" justifyContent="center" direction="column">
                          <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                            {tp("Advanced feature")}
                          </PopoverHeader>
                          <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                            {tp("Please upgrade your plan to add more nodes in the Flow")}
                          </PopoverBody>
                          <TariffPlanRedirectButton onClose={onClose} />
                        </Flex>
                      </PopoverContent>
                    </Portal>
                  </>
                )}
              </Popover>
            ) : (
              <Popover isOpen={showAddNodePopup} onClose={() => setShowAddNodePopup(false)} placement="right-end">
                <PopoverTrigger>
                  <Box position="relative">
                    <IconButton
                      onMouseEnter={() => setNodeCountTooltip(true)}
                      onMouseLeave={() => setNodeCountTooltip(false)}
                      isRound={true}
                      variant="dominoViolet"
                      aria-label="Call Sage"
                      size={"lg"}
                      icon={showAddNodePopup ? <CloseIcon /> : <AddIcon />}
                      onClick={() => {
                        setShowAddNodePopup(true);
                        setNodeCountTooltip(false);
                        onNodeAccordionDelete();
                      }}
                      data-pw="add-node"
                    />
                    {!IsUnlimited ? (
                      <Box top="50%" className={`${s.tooltip} ${nodeCountTooltip && s.tooltipAnim}`} data-pw="node-count-tooltip">
                        <Box className={s.arrow} />
                        {`${tp("Nodes")}: ${countNodes?.length && countNodes?.length - 1}/${MaxCount}`}
                      </Box>
                    ) : (
                      <Box
                        top="50%"
                        className={`${s.tooltipUn} ${nodeCountTooltip && s.tooltipAnim}`}
                        data-pw="node-count-tooltip"
                      >
                        <Box className={s.arrow} />
                        {`${tp("Nodes")}: ${countNodes?.length && countNodes?.length - 1}`}
                      </Box>
                    )}
                  </Box>
                </PopoverTrigger>
                <AddNodePopup
                  onClose={() => setShowAddNodePopup(false)}
                  flowBuilderWidth={flowBuilderRef.current?.clientWidth ?? 0}
                  flowBuilderHeight={flowBuilderRef.current?.clientHeight ?? 0}
                  addNode={addNode}
                />
              </Popover>
            )}
            <Popover isOpen={showAddNodePopup} onClose={() => setShowPopoverHome(false)} placement="right-end">
              <PopoverTrigger>
                <Box position="relative">
                  <Icon
                    onMouseEnter={() => setShowPopoverHome(true)}
                    onMouseLeave={() => setShowPopoverHome(false)}
                    cursor="pointer"
                    _hover={{ color: "#089850" }}
                    color="#4EAA4A"
                    boxSize="32px"
                    as={GoHomeIcon}
                    onClick={() => {
                      trackEvent("FlowGoToStartClick");
                      setGoStartNode(true);
                      onNodeAccordionDelete();
                    }}
                    data-pw="go-to-start-button"
                  />
                  <Box top="40%" className={`${s.tooltipUn} ${showPopoverHome && s.tooltipAnim}`}>
                    {t("Go to Starting Step")}
                  </Box>
                </Box>
              </PopoverTrigger>
            </Popover>
          </Flex>
        </ReactFlowProvider>
      )}
      {flow?.id === "new" && (
        <LeaveNewFlowConfirmationPopup dirty={draftFlowId === "flowData-new"} onDiscardChanges={onDiscardChanges} />
      )}
      {flow?.id === "import" && (
        <LeaveNewFlowConfirmationPopup dirty={draftFlowId === "flowData-import"} onDiscardChanges={onDiscardChangesImport} />
      )}
      {getNodePopup()}
      {nodeEditorState && flow && currentBot && (
        <NodeEditPopup
          node={nodeEditorState}
          flow={flow}
          validation={nodeValidationState}
          botId={currentBot.id}
          botUserName={currentBot.username}
          deleteEdge={deleteEdge}
        />
      )}
    </>
  );
}
