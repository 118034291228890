import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, Routes } from "react-router-dom";
import s from "./App.module.scss";
import { Login } from "./components/login/LoginContainer";
import { NotificationContainer } from "./common/notifications/NotificationContainer";
import { SignUp } from "./components/signUp/SignUpContainer";
import { AccountNotApprovedPage } from "./components/pages/accountNotApprovedPage/AccountNotApprovedPage";
import { ProtectedLayout } from "./components/layout/ProtectedLayout";
import { PublicLayout } from "./components/layout/PublicLayout";
import { ContactPage } from "./components/contact/ContactPage";
import ReactGA from "react-ga4";
import { AppSettings } from "./common/AppSettings";
import { Invitation } from "./common/auth/Invitation";
import HelmetContainer from "./common/HelmetContainer";
import { ContactsPage } from "./components/layout/BuildMainApp/Contacts/ContactsPage";
import { BotsPage } from "./components/layout/BuildMainApp/Bots/BotsPage";
import { BroadcastListPage } from "./components/layout/BuildMainApp/Broadcast/BroadcastListPage";
import { NewBroadcastPage } from "./components/layout/BuildMainApp/Broadcast/pages/NewBroadcastPage";
import { ViewBroadcastPage } from "./components/layout/BuildMainApp/Broadcast/pages/ViewBroadcastPage";
import { EditBroadcastPage } from "./components/layout/BuildMainApp/Broadcast/pages/EditBroadcastPage";
import { BotPage } from "./components/layout/BuildMainApp/Bots/pages/BotPage";
import { MyProfilePage } from "./components/layout/BuildMainApp/MyProfile/MyProfilePage";
import { UserManagementPage } from "./components/layout/BuildMainApp/UserManagment/UserManagementPage";
import { DashboardPage } from "./components/layout/BuildMainApp/Dashboard/DashboardPage";
import { AddBotPage } from "./components/layout/BuildMainApp/Bots/pages/AddBotPage";
import { SetOrganizationPage } from "./components/layout/BuildMainApp/Organisation/SetOrganizationPage";
import { SetBotPage } from "./components/layout/BuildMainApp/Bots/pages/SetBotPage";
import { OperatorsPage } from "./components/organisation/components/OperatorList/OperatorsPage";
import { TeamsPage } from "./components/organisation/components/TeamList/TeamsPage";
import { OrganisationPage } from "./components/layout/BuildMainApp/Organisation/OrganisationPage";
import { FlowBuilderPage } from "./components/layout/BuildMainApp/Automation/pages/FlowBuilder/FlowBuilderPage";
import { TriggersPage } from "./components/layout/BuildMainApp/Automation/pages/TriggersPage";
import { FlowPage } from "./components/layout/BuildMainApp/Automation/pages/FlowPage";
import { AutomationPage } from "./components/layout/BuildMainApp/Automation/AutomationPage";
import { ConversationPage } from "./components/layout/BuildMainApp/LiveChat/pages/ConversationPage";
import { IFrameContainer } from "./components/iFrame/iFrameContainer";
import { PluginPage } from "./components/layout/BuildMainApp/Plugin/PluginPage";
import { PluginAuthPage } from "./components/layout/BuildMainApp/Plugin/PluginAuthPage";
import { GeneralOnboardingTour } from "./components/onboardingTour/GeneralOnboardingTourContainer";

declare const appSettings: AppSettings;

function App() {
  if (appSettings.GAIds?.length) {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }

  return (
    <div className={s.App}>
      <GeneralOnboardingTour />
      <RouterProvider router={router} />
      <NotificationContainer />
      <HelmetContainer />
    </div>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/plugin/auth/:pluginName" element={<PluginAuthPage />} />
      <Route path="/auth/signIn" element={PublicLayout(<Login />, "Welcome to Domino!")} />
      <Route path="/auth/signUp" element={PublicLayout(<SignUp />, "Sign Up to Domino", "Sign Up to create an account.")} />
      <Route
        path="/auth/accountNotApproved"
        element={PublicLayout(<AccountNotApprovedPage />, "Thank you for registering with Domino CRM!")}
      />
      <Route path="/invitation/:inviteToken" element={<Invitation />} />
      <Route path="/checkauth" element={<IFrameContainer />} />
      <Route
        path="*"
        element={
          <ProtectedLayout>
            <Routes>
              <Route path="*" element={<Navigate to="/automation/flows" />} />
              <Route element={<AutomationPage />}>
                <Route path="/automation" element={<Navigate to="/automation/flows" />} />
                <Route path="/automation/flows" element={<FlowPage />} />
                <Route path="/automation/triggers" element={<TriggersPage />} />
              </Route>
              <Route path="/automation/flows/:id" element={<FlowBuilderPage />} />

              <Route element={<OrganisationPage />}>
                <Route path="/organisation" element={<Navigate to="/organisation/operators" />} />
                <Route path="/organisation/operators" element={<OperatorsPage />} />
                <Route path="/organisation/teams" element={<TeamsPage />} />
              </Route>
              <Route path="/setOrganisation/:organisationId" element={<SetOrganizationPage />} />

              <Route path="/broadcasts">
                <Route path="" element={<BroadcastListPage />} />
                <Route path="new" element={<NewBroadcastPage />} />
                <Route path=":broadcastId/view" element={<ViewBroadcastPage />} />
                <Route path=":broadcastId/edit" element={<EditBroadcastPage />} />
              </Route>

              <Route path="/contacts">
                <Route path="" element={<ContactsPage />} />
                <Route path="*" element={<ContactPage />} />
              </Route>

              <Route path="/bots" element={<BotsPage />} />
              <Route path="/bots/:botId" element={<BotPage />} />
              <Route path="/setBot/:botId" element={<SetBotPage />} />
              <Route path="/addbot">
                <Route path="" element={<AddBotPage />} />
                <Route path="*" element={<AddBotPage />} />
              </Route>

              <Route path="/chats" element={<ConversationPage />} />
              <Route path="/chats/:conversationId" element={<ConversationPage />} />

              <Route path="/dashboard" element={<DashboardPage />} />

              <Route path="/management" element={<UserManagementPage />} />

              <Route path="/profile" element={<MyProfilePage />} />

              <Route path="/plugin/:pluginName" element={<PluginPage />} />
            </Routes>
          </ProtectedLayout>
        }
      />
    </>,
  ),
);

export default App;
