import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "../../../common/hooks/useDocumentTitle";
import { UserPlanManageButton } from "./UserPlanManageButton";
import h from "../../../components/headers/HeaderStyles.module.scss";

export const UserProfileHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "userProfile" });
  useDocumentTitle(t("My Profile"), "My Profile");

  return (
    <Flex flexDir={"row"} w={"100%"} py="12px" alignItems="center">
      <Text variant="h2" color="midDeepBlue" className={h.title}>
        {t("My Profile")}
      </Text>
      <UserPlanManageButton />
    </Flex>
  );
};
