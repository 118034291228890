import s from "./ImageNodePopup/ImageNodePopup.module.scss";
import { Dispatch, DragEvent, SetStateAction } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { notify } from "../../../../common/notifications/NotificationSlice";
import { postFile } from "../../FlowBuilderSlice";
import { AnyAction } from "redux";
import { NodeModel, TriggerNodeModel } from "../../FlowBuilderModel";

interface HandleDataChangeParams {
  file?: File | undefined;
  dispatch: Dispatch<AnyAction>;
  currentNode: NodeModel | TriggerNodeModel;
  fieldTypeExt: string;
  fileSizeB: number;
}

interface Props {
  // handleDataChange: (value: File | undefined) => void;
  setDragging: Dispatch<SetStateAction<boolean>>;
  handleDataChange: HandleDataChangeParams;
}

export const handleDrag = function (
  e: DragEvent<HTMLDivElement>,
  setDragging: { (value: SetStateAction<boolean>): void; (arg0: boolean): void },
) {
  e.preventDefault();
  e.stopPropagation();

  if (e.type === "dragenter" || e.type === "dragover") {
    setDragging(true);
  } else if (e.type === "dragleave" || e.type === "dragend") {
    setDragging(false);
  }
};

export const handleDataChange = ({ file, dispatch, currentNode, fieldTypeExt, fileSizeB }: HandleDataChangeParams) => {
  const extentsion = file?.name.split(".").pop();

  if (file?.size && file.size > fileSizeB) {
    dispatch(notify({ message: "File is too big", type: "error" }));
    return;
  }

  if (!fieldTypeExt.includes(extentsion ?? "")) {
    dispatch(notify({ message: "This file format isn’t supported", type: "error" }));
    return;
  }

  if (file) {
    dispatch(postFile({ file, nodeId: currentNode.id }));
    return;
  }
};

export const DragAndDropContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    props.setDragging(false);
    const file = event.dataTransfer?.files[0];
    handleDataChange({ ...props.handleDataChange, file });
  };

  const onDragFile = (e: DragEvent<HTMLDivElement>) => handleDrag(e, props.setDragging);

  return (
    <>
      <Box
        className={s.dragFileElement}
        onDragEnter={onDragFile}
        onDragLeave={onDragFile}
        onDragOver={onDragFile}
        onDrop={onDropFile}
      />
      <Box className={s.dragFileContainer}>
        <Box className={s.dragFileContent}>
          <Text maxW="195px" variant="largeBold" color="white">
            {t("Drag and drop")}
          </Text>
        </Box>
      </Box>
    </>
  );
};
