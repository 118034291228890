import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tooltip,
} from "@chakra-ui/react";

import { ReactComponent as boldIcon } from "../../../../assets/icons/bold.svg";
import { ReactComponent as italicIcon } from "../../../../assets/icons/italic.svg";
import { ReactComponent as monospaceIcon } from "../../../../assets/icons/monospace.svg";
import { ReactComponent as urlIcon } from "../../../../assets/icons/addlinkIcon.svg";
import { FormatAction } from "../../../../common/utils/escapeMarkdown";
import { useTranslation } from "react-i18next";
import { AddLinkModal } from "./components/AddLinkModal";

export interface Props {
  onFormattingClick: (type: FormatAction) => void;
}

export const FormattingMenu = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });

  return (
    <Box style={{ paddingBottom: "1px" }}>
      <Tooltip variant="dominoLight" placement="top" label={t("Bold")}>
        <Button
          aria-label={"Bold"}
          onClick={() => props.onFormattingClick({ type: "bold" })}
          variant="dominoTextareaIcon"
          boxSize={{ base: "32px", lg: "24px" }}
          mr="3px"
          data-pw="bold-button"
        >
          <Icon as={boldIcon}></Icon>
        </Button>
      </Tooltip>
      <Tooltip variant="dominoLight" placement="top" label={t("Italics")}>
        <Button
          aria-label={"Italics"}
          onClick={() => props.onFormattingClick({ type: "italic" })}
          variant="dominoTextareaIcon"
          boxSize={{ base: "32px", lg: "24px" }}
          style={{ font: "Arial" }}
          mr="3px"
          data-pw="italics-button"
        >
          <Icon as={italicIcon}></Icon>
        </Button>
      </Tooltip>
      <Tooltip variant="dominoLight" placement="top" label={t("Monospace")}>
        <Button
          aria-label={"Monospace"}
          onClick={() => props.onFormattingClick({ type: "code" })}
          variant="dominoTextareaIcon"
          boxSize={{ base: "32px", lg: "24px" }}
          style={{ font: "Arial" }}
          mr="3px"
          data-pw="monospace-button"
        >
          <Icon as={monospaceIcon}></Icon>
        </Button>
      </Tooltip>
      <Popover variant="dominoLinkPopover">
        <Tooltip variant="dominoLight" placement="top" label={t("Add link")}>
          <Box display="inline-block">
            <PopoverTrigger>
              <Button
                aria-label={"Add link"}
                variant="dominoTextareaIcon"
                boxSize={{ base: "32px", lg: "24px" }}
                style={{ font: "Arial" }}
                mr="3px"
                data-pw="add-link-button"
              >
                <Icon as={urlIcon}></Icon>
              </Button>
            </PopoverTrigger>
          </Box>
        </Tooltip>
        <Portal>
          <PopoverContent data-pw="add-link-modal">
            <PopoverArrow />
            <PopoverHeader>{t("Add link")}</PopoverHeader>
            <PopoverCloseButton data-pw="add-link-close-button" />
            <PopoverBody>
              <AddLinkModal
                onSubmit={(text, url) => {
                  props.onFormattingClick({
                    type: "link",
                    text,
                    url,
                  });
                }}
              />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Box>
  );
};
