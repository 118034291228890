import { SetURLSearchParams, createSearchParams } from "react-router-dom";

export const constructSearchParams = (
  search: string,
  debouncedSearchText: string | undefined,
  setSearchParams: SetURLSearchParams,
) => {
  if (search !== debouncedSearchText) {
    const newParams: { search?: string } = {};
    if (debouncedSearchText !== "") {
      newParams.search = debouncedSearchText;
    }
    setSearchParams(createSearchParams(newParams));
  }
};
