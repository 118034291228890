import { PaginatorModel } from "../../common/paginator/PaginatorModel";

export interface BotListModel extends PaginatorModel {
  items: BotModel[];
}

export interface BotModel {
  id: string;
  externalId?: string;
  username?: string;
  name: string;
  accessToken?: string;
  flowCount?: number;
}

export interface SelectedBotModel {
  id: string;
  name: string;
}

export interface DashboardDataModel {
  from: string;
  to: string;
  botId: string;
}

export interface DashboardLocalStorageModel {
  date: {
    from: string;
    to: string;
    type: EnumTypeDateRange;
  };
  bot: BotModel;
}

export interface DataChartModel {
  from: string;
  to: string;
  total: number;
}

export interface MessagesModel extends DataChartModel {
  totalBot: number;
  totalBroadcasts: number;
  totalOperators: number;
  data: DataMessagesModel[];
}

export interface MessagesStatisticModel extends DataChartModel {
  totalSent: number;
  totalReceived: number;
  data: DataMessagesStatisticModel[];
}

export interface DataMessagesModel {
  name: string;
  bot: number;
  broadcasts: number;
  operators: number;
  total: number;
}

export interface DataMessagesStatisticModel {
  name: string | Date;
  sent: number;
  received: number;
  total: number;
}

export interface DashboardFilterCheckboxTypeModel {
  label: string;
  checked?: boolean;
  total?: number;
}

export interface DashboardDateModel {
  from: Date;
  to: Date;
  typeDate: EnumTypeDateRange;
}

export enum EnumTypeDateRange {
  Week = "Week",
  Month = "Month",
  ThreeMonth = "ThreeMonth",
  SixMonth = "SixMonth",
  CustomRange = "CustomRange",
}
