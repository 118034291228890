import React, { useMemo } from "react";
import { QuestionOutlineIcon, CloseIcon } from "@chakra-ui/icons";
import { Flex, Tooltip, Input, Text, Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariablesModel, TakeItemFromArrayModel } from "../../../FlowBuilderModel";
import { createCustomVariable, selectCustomVariables } from "../../../FlowBuilderSlice";
import s from "./SystemActionNodePopup.module.scss";
interface Props {
  flowAction: TakeItemFromArrayModel;
  onDataChange: (flowAction: TakeItemFromArrayModel) => void;
}

export const TakeItemFromArrayEditor = ({ flowAction, onDataChange }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const variables = useAppSelector(selectCustomVariables);
  const targetCustomVariables = flowAction.targetCustomVariables;
  const dispatch = useAppDispatch();

  const setSource = (variableId: string) => {
    onDataChange({
      ...flowAction,
      sourceCustomVariableId: variableId,
    });
  };

  const selectSource = (variable: CustomVariablesModel) => {
    if (variable.id) setSource(variable.id);
  };

  const sourceVariables = useMemo(() => {
    return variables?.filter(variable => variable.type === CustomVariableType.Array);
  }, [variables]);

  const chosenSource = variables?.find(el => {
    const sourceCustomVariableId = flowAction.sourceCustomVariableId;
    return el.id === sourceCustomVariableId ? true : false;
  });

  const setTargetCustomVariables = (targetCustomVariables: { contentPath: string; targetCustomVariableId: string | null }[]) => {
    onDataChange({
      ...flowAction,
      targetCustomVariables,
    });
  };

  const chooseCustomVariable = (id: string, targetIndex: number) => {
    if (targetCustomVariables) {
      setTargetCustomVariables(
        targetCustomVariables.map((el, index) => (index === targetIndex ? { ...el, targetCustomVariableId: id } : el)),
      );
    }
  };

  const setContentPathText = (text: string, targetIndex: number) => {
    if (targetCustomVariables) {
      setTargetCustomVariables(
        targetCustomVariables.map((el, index) => (index === targetIndex ? { ...el, contentPath: text } : el)),
      );
    }
  };

  const addNewTargetCustomVariable = () => {
    if (targetCustomVariables) {
      setTargetCustomVariables([...targetCustomVariables, { contentPath: "", targetCustomVariableId: null }]);
    }
  };

  const deleteTargetCustomVariable = (targetIndex: number) => {
    if (targetCustomVariables) {
      setTargetCustomVariables(targetCustomVariables.filter((el, index) => index !== targetIndex));
    }
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  return (
    <div>
      <>
        <Text variant="large">{t("Source array")}</Text>
        <CustomVariableContextMenu
          type={CustomVariableContextMenuType.Dropdown}
          onlyVariableType={CustomVariableType.Array}
          selectCustomVariable={selectSource}
          addVariable={onCreateVariable()}
          variables={sourceVariables?.filter(v => v.type !== CustomVariableType.Order) ?? []}
          chosenVariable={chosenSource}
          mt="16px"
          clearVariable={() => setSource("")}
        />
        <Flex mt="24px" direction="column" gap="16px">
          {targetCustomVariables?.map((targetCustomVariable, index) => (
            <Box className={`${s.targetCustomVariableContainer}`} key={index}>
              <Flex gap="8px" align="center">
                <Text variant="large">{t("JSON path")}</Text>
                <Tooltip
                  variant="dominoLight"
                  placement="right"
                  label={t(
                    "You can take only the first item from array. If JSON path field is empty, the first item will be entirely saved in the variable.",
                  )}
                  fontSize="md"
                >
                  <QuestionOutlineIcon boxSize={"16px"} color="blueLink" />
                </Tooltip>
              </Flex>
              <Input
                pl="8px"
                m="8px 0 16px"
                value={targetCustomVariable.contentPath}
                onChange={e => setContentPathText(e.target.value, index)}
                placeholder={t("Enter the path") ?? ""}
              />
              <Text variant="large">{t("Custom field")}</Text>
              <CustomVariableContextMenu
                mt="8px"
                type={CustomVariableContextMenuType.Dropdown}
                selectCustomVariable={variable => chooseCustomVariable(variable?.id ?? "", index)}
                addVariable={onCreateVariable(index)}
                variables={
                  variables?.filter(
                    variable =>
                      !targetCustomVariables.some(el => el.targetCustomVariableId === variable.id) &&
                      variable.type !== CustomVariableType.Array &&
                      variable.type !== CustomVariableType.Order,
                  ) ?? []
                }
                buttonProps={{ pl: "8px" }}
                chosenVariable={variables?.find(el => el.id === targetCustomVariable.targetCustomVariableId)}
                clearVariable={() =>
                  setTargetCustomVariables([{ contentPath: targetCustomVariables[0].contentPath, targetCustomVariableId: null }])
                }
                excludeVariableType={CustomVariableType.Order}
              />
              <Box position="relative">
                {targetCustomVariables.length > 1 && (
                  <Box
                    className={s.closeIconWrp}
                    onClick={() => {
                      deleteTargetCustomVariable(index);
                    }}
                  >
                    <CloseIcon color="white" boxSize="9px" onMouseDown={e => e.preventDefault()} />
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Flex>
        <Button variant="dominoDashedViolet" onClick={addNewTargetCustomVariable}>
          + {t("Field")}
        </Button>
      </>
    </div>
  );
};
