import { createAction } from "typesafe-actions";

const begin = createAction("loadingState/BEGIN")();
const beginScope = createAction("loadingState/BEGIN_SCOPE")<string>();
const setMessage = createAction("loadingState/SET_MESSAGE")<string>();
const complete = createAction("loadingState/COMPLETE")();
const completeScope = createAction("loadingState/COMPLETE_SCOPE")<string>();
const reset = createAction("loadingState/RESET")();
const resetScope = createAction("loadingState/RESET_SCOPE")<string>();

export { begin, beginScope, setMessage, complete, completeScope, reset, resetScope };
