import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const dominoListTile = definePartsStyle({
  container: {
    backgroundColor: "#FFFFFF",
    border: "1px solid var(--chakra-colors-defaultGrey)",
    transition: "ease-out .2s",
    cursor: "pointer",
    padding: 0,
    borderRadius: "12px",
    mb: "8px",

    _hover: {
      borderColor: "darkPurple",
    },
  },
  body: {
    padding: "18px 24px",
  },
});

const dominoListTileTriggers = definePartsStyle({
  container: {
    backgroundColor: "#FFFFFF",
    border: "1px solid var(--chakra-colors-defaultGrey)",
    transition: "ease-out .2s",
    padding: 0,
    borderRadius: "12px",
    mb: "8px",
  },
  body: {
    padding: "18px 24px",
  },
});

export const cardTheme = defineMultiStyleConfig({
  variants: {
    dominoListTile,
    dominoListTileTriggers,
  },
});
