import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "../../../common/hooks/useDocumentTitle";

export const UserManagementHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "userManagement" });
  useDocumentTitle(t("User Management"), "User Management");

  return (
    <>
      <Text variant="h2" color="midDeepBlue" py="16px">
        {t("User Management")}
      </Text>
      <Flex justifyContent="flex-end"></Flex>
    </>
  );
};
