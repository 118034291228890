import { Alert, AlertDescription, AlertIcon, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  validationMessage: string;
}

export const NodeEditorFooter = ({ validationMessage }: Props) => {
  const { t } = useTranslation("validation");

  return (
    <Alert status="warning" data-pw="validation-message">
      <AlertIcon />
      <Box>
        <AlertDescription>{t(validationMessage)}</AlertDescription>
      </Box>
    </Alert>
  );
};
