export function firstCharToUpperCase(this: string): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

export function toDate(this: string): Date {
  return new Date(this);
}

declare global {
  interface String {
    firstCharToUpperCase(): string;

    empty: string;

    toDate(this: string): Date;
  }
}

String.prototype.firstCharToUpperCase = firstCharToUpperCase;
String.prototype.empty = "";
String.prototype.toDate = toDate;
