import { call, put, select, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleException } from "../../common/SagaHelper";
import { broadcastListSlice, BroadcastListState, selectBroadcastListState } from "./BroadcastListSlice";
import { cancelBroadcastApi, copyBroadcastApi, deleteBroadcastApi, getBroadcastListApi } from "./BroadcastListApi";
import { BroadcastListModel } from "./BroadcastListModel";
import { begin, complete } from "../../common/loading/LoadingStateActions";
import { selectCurrentBotId } from "../sidebar/SidebarSlice";
import { isChangePage } from "../../common/utils/pagination";
import { BroadcastReplyData } from "../broadcast/BroadcastData";
import { routerSlice } from "../../common/router/RouterSlice";

export function* broadcastListSaga() {
  yield takeLatest(broadcastListSlice.actions.getBroadcastList, getBroadcastList);
  yield takeLatest(broadcastListSlice.actions.deleteBroadcast, deleteBroadcast);
  yield takeLatest(broadcastListSlice.actions.cancelBroadcast, cancelBroadcast);
  yield takeLatest(broadcastListSlice.actions.copyBroadcast, copyBroadcast);
}

function* getBroadcastList(action: PayloadAction<{ botId: string; page: number; filter?: string }>) {
  try {
    yield put(begin());
    const broadcastList: BroadcastListModel = yield call(
      getBroadcastListApi,
      action.payload.botId,
      action.payload.page,
      action.payload.filter,
    );
    yield put(broadcastListSlice.actions.getBroadcastListCompleted(broadcastList));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* deleteBroadcast(action: PayloadAction<{ broadcastId: string }>) {
  try {
    yield put(begin());
    const botId: string = yield select(selectCurrentBotId) ?? "";

    const broadcastListState: BroadcastListState = yield select(selectBroadcastListState);
    const { broadcastList } = broadcastListState;
    let page = broadcastListState.broadcastList?.currentPage;
    if (broadcastList && page && isChangePage(broadcastList)) {
      page -= 1;
    }

    yield call(deleteBroadcastApi, botId, action.payload.broadcastId);

    yield put(broadcastListSlice.actions.getBroadcastList({ botId, page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* cancelBroadcast(action: PayloadAction<{ broadcastId: string }>) {
  try {
    yield put(begin());
    const botId: string = yield select(selectCurrentBotId) ?? "";

    const broadcastListState: BroadcastListState = yield select(selectBroadcastListState);
    const page = broadcastListState.broadcastList?.currentPage;

    yield call(cancelBroadcastApi, botId, action.payload.broadcastId);

    yield put(broadcastListSlice.actions.getBroadcastList({ botId, page: page ?? 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}
function* copyBroadcast(action: PayloadAction<{ broadcastId: string }>) {
  try {
    yield put(begin());
    const botId: string = yield select(selectCurrentBotId) ?? "";

    const broadcastData: BroadcastReplyData = yield call(copyBroadcastApi, botId, action.payload.broadcastId);
    yield put(routerSlice.actions.redirect(`/broadcasts/${broadcastData.id}/edit`));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}
