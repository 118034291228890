import s from "./AddNodePopup.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { ReactComponent as TextIcon } from "../../../../assets/icons/textIcon.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/icons/imageChatIcon.svg";
import { ReactComponent as FileIcon } from "../../../../assets/icons/fileIcon.svg";
import { ReactComponent as FlashIcon } from "../../../../assets/icons/globeIcon.svg";
import { ReactComponent as VideoIcon } from "../../../../assets/icons/videoIcon.svg";
import { ReactComponent as AudioIcon } from "../../../../assets/icons/audioIcon.svg";
import { ReactComponent as ConditionIcon } from "../../../../assets/icons/conditionIcon.svg";
import { ReactComponent as ThunderIcon } from "../../../../assets/icons/thunderIcon.svg";
import { ReactComponent as ClockIcon } from "../../../../assets/icons/clockIcon.svg";
import { ReactComponent as QuestionNodeIcon } from "../../../../assets/icons/questionNodeIcon.svg";
import { ReactComponent as AssignIcon } from "../../../../assets/icons/assignIcon.svg";
import { ReactComponent as StartFlowIcon } from "../../../../assets/icons/startFlowIcon.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/CalendarNodeIcon.svg";
import { ReactComponent as PaymentIcon } from "../../../../assets/icons/PaymentNodeIcon.svg";
import { ReactComponent as CatalogIcon } from "../../../../assets/icons/CatalogNodeIcon.svg";
import { ReactComponent as StarPlan } from "../../../../assets/icons/starPlan.svg";
import { FlowActionType, NodeModel } from "../../FlowBuilderModel";
import {
  Box,
  Flex,
  Text,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  SimpleGrid,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { NodeItem } from "./NodeItem";

interface Props {
  addNode: (newNode: NodeModel) => void;
  onClose: () => void;
  flowBuilderWidth: number;
  flowBuilderHeight: number;
}

export const AddNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;

  const accessabilityHTTP = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.NodeHttpRequest);
  const accessabilityDelay = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.NodeDelay);
  const accessabilityStartFlow = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.NodeStartFlow);

  return (
    <PopoverContent width={["300px", "auto", "auto"]} padding={"16px"}>
      <Text variant="largeBold" paddingBottom="12px">
        {t("Telegram")}
      </Text>
      <PopoverCloseButton data-pw="close-button" onClick={props.onClose} className={s.closeIcon} />
      <SimpleGrid columns={[1, 2, 2]} spacing={[5, 5, 5]} gap="8px">
        <NodeItem
          title="Text"
          actionType={FlowActionType.SendTextFlowAction}
          iconType={TextIcon}
          addNode={props.addNode}
          flowBuilderWidth={props.flowBuilderWidth}
          flowBuilderHeight={props.flowBuilderHeight}
        />
        <NodeItem
          title="Question"
          actionType={FlowActionType.QuestionFlowAction}
          iconType={QuestionNodeIcon}
          addNode={props.addNode}
          flowBuilderWidth={props.flowBuilderWidth}
          flowBuilderHeight={props.flowBuilderHeight}
        />
        <NodeItem
          title="Image"
          actionType={FlowActionType.SendPhotoFlowAction}
          iconType={ImageIcon}
          addNode={props.addNode}
          flowBuilderWidth={props.flowBuilderWidth}
          flowBuilderHeight={props.flowBuilderHeight}
        />
        <NodeItem
          title="Document"
          actionType={FlowActionType.SendDocumentFlowAction}
          iconType={FileIcon}
          addNode={props.addNode}
          flowBuilderWidth={props.flowBuilderWidth}
          flowBuilderHeight={props.flowBuilderHeight}
        />
        <NodeItem
          title="Video"
          actionType={FlowActionType.SendVideoFlowAction}
          iconType={VideoIcon}
          addNode={props.addNode}
          flowBuilderWidth={props.flowBuilderWidth}
          flowBuilderHeight={props.flowBuilderHeight}
        />
        <NodeItem
          title="Audio"
          actionType={FlowActionType.SendAudioFlowAction}
          iconType={AudioIcon}
          addNode={props.addNode}
          flowBuilderWidth={props.flowBuilderWidth}
          flowBuilderHeight={props.flowBuilderHeight}
        />
      </SimpleGrid>
      <Box className={s.extras}>
        <Text variant="largeBold" paddingBottom="12px">
          {t("Extras")}
        </Text>
        <SimpleGrid columns={[1, 2, 2]} spacing={[5, 5, 5]} gap="8px">
          {!accessabilityHTTP["IsAvailable"] ? (
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Flex className={s.starPlanItem}>
                      <Flex gap="8px">
                        <Icon className={s.svg} as={FlashIcon} />
                        <Box className={s.typeName}>{t("HTTP request")}</Box>
                      </Flex>
                      <Box className={`${sp.starPlanHover} ${s.starPlan}`}>
                        <Icon boxSize="14px" as={StarPlan} />
                      </Box>
                    </Flex>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to invite more Operators")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : (
            <NodeItem
              title="HTTP request"
              actionType={FlowActionType.CurlFlowAction}
              iconType={FlashIcon}
              addNode={props.addNode}
              flowBuilderWidth={props.flowBuilderWidth}
              flowBuilderHeight={props.flowBuilderHeight}
            />
          )}
          <NodeItem
            title="Condition"
            actionType={FlowActionType.ConditionFlowAction}
            iconType={ConditionIcon}
            addNode={props.addNode}
            flowBuilderWidth={props.flowBuilderWidth}
            flowBuilderHeight={props.flowBuilderHeight}
          />
          <NodeItem
            title="System Action"
            actionType={FlowActionType.SystemActionFlowAction}
            iconType={ThunderIcon}
            addNode={props.addNode}
            flowBuilderWidth={props.flowBuilderWidth}
            flowBuilderHeight={props.flowBuilderHeight}
          />
          <NodeItem
            title="Calendar"
            actionType={FlowActionType.SendAppointmentFlowAction}
            iconType={CalendarIcon}
            addNode={props.addNode}
            flowBuilderWidth={props.flowBuilderWidth}
            flowBuilderHeight={props.flowBuilderHeight}
          />
          <NodeItem
            title="Payment"
            actionType={FlowActionType.SendPaymentFlowAction}
            iconType={PaymentIcon}
            addNode={props.addNode}
            flowBuilderWidth={props.flowBuilderWidth}
            flowBuilderHeight={props.flowBuilderHeight}
          />
          <NodeItem
            title="Catalog"
            actionType={FlowActionType.ShoppingCartFlowAction}
            iconType={CatalogIcon}
            addNode={props.addNode}
            flowBuilderWidth={props.flowBuilderWidth}
            flowBuilderHeight={props.flowBuilderHeight}
          />
          {!accessabilityDelay["IsAvailable"] ? (
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Flex className={s.typeItem} justify="space-between">
                      <Flex gap="8px">
                        <Icon className={s.svg} as={ClockIcon} boxSize="19px !important" color={"midDeepBlue"} />
                        <Box className={s.typeName}>{t("Delay")}</Box>
                      </Flex>
                      <Box className={`${sp.starPlanHover} ${s.starPlan}`}>
                        <Icon boxSize="14px" as={StarPlan} />
                      </Box>
                    </Flex>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to invite more Operators")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : (
            <NodeItem
              title="Delay"
              actionType={FlowActionType.DelayFlowAction}
              iconType={ClockIcon}
              addNode={props.addNode}
              flowBuilderWidth={props.flowBuilderWidth}
              flowBuilderHeight={props.flowBuilderHeight}
            />
          )}
          <NodeItem
            title="Assign"
            actionType={FlowActionType.AssignFlowAction}
            iconType={AssignIcon}
            addNode={props.addNode}
            flowBuilderWidth={props.flowBuilderWidth}
            flowBuilderHeight={props.flowBuilderHeight}
          />
          {!accessabilityStartFlow["IsAvailable"] ? (
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Flex className={s.typeItem} justify="space-between">
                      <Flex align="center" gap="8px">
                        <Icon className={s.svg} as={StartFlowIcon} boxSize="19px !important" color={"midDeepBlue"} />
                        <Box maxW="110px" className={s.typeName}>
                          {t("Start Flow")}
                        </Box>
                      </Flex>
                      <Box className={`${sp.starPlanHover} ${s.starPlan}`}>
                        <Icon boxSize="14px" as={StarPlan} />
                      </Box>
                    </Flex>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to invite more Operators")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : (
            <NodeItem
              title="Start Flow"
              actionType={FlowActionType.StartSubFlowAction}
              iconType={StartFlowIcon}
              addNode={props.addNode}
              flowBuilderWidth={props.flowBuilderWidth}
              flowBuilderHeight={props.flowBuilderHeight}
            />
          )}
        </SimpleGrid>
      </Box>
    </PopoverContent>
  );
};
