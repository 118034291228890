import { Box, Button, Flex, Text } from "@chakra-ui/react";
import s from "../../Organisation.module.scss";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../common/state/store";
import { selectBillingPlan, selectLanguage } from "../../../../common/user/UserSlice";
import { AppSettings } from "../../../../common/AppSettings";

declare const appSettings: AppSettings;

export const CurrentPlanInfo = () => {
  const billingPlan = useAppSelector(selectBillingPlan);
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const link = appSettings.billingPortalUrl;
  const locale = useAppSelector(selectLanguage);

  function cleanTime(time: string) {
    const date = new Date(time.substring(0, 19));
    const month = t("months." + date.toLocaleString("en", { month: "long" }));
    if (locale === "ru") {
      return date.getDate() + " " + month + ", " + date.getFullYear();
    }
    return month + " " + date.getDate() + ", " + date.getFullYear();
  }

  return (
    <Box className={s.currentPlanInfo}>
      <Flex className={s.mainFlexContainer}>
        <Box borderBottom={"1px solid"} borderColor="bgMiddle" py="16px" className={`${s.userListItemGrid}`}>
          <Flex className={s.currentPlanFlex}>
            <Flex className={s.tariffBlock}>
              <Text style={{ textWrap: "nowrap" }} variant="h2">
                {t("Your Plan:")}
              </Text>
              <Text fontWeight={700} variant="h2" noOfLines={1}>
                {billingPlan?.billingPlanName}
              </Text>
            </Flex>
            <Flex gap="4px">
              <Text style={{ textWrap: "nowrap" }} variant="medium">
                {!billingPlan?.endDate ? t("Unlimited") : t("Active until:")}
              </Text>
              <Text fontWeight={700} variant="medium" noOfLines={1}>
                {billingPlan?.endDate ? cleanTime(billingPlan?.endDate) : ""}
              </Text>
            </Flex>
          </Flex>
          <Box className={s.button} mr="24px">
            <Button size="sm" variant="dominoOutlineViolet" onClick={() => window.open(link, "_blank")}>
              {t("Manage your plan")}
            </Button>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
