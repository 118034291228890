import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Grid, IconButton, Input, InputGroup, InputLeftElement, Switch, Text } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as removeTimeIcon } from "../../../../../assets/icons/TrashGrey.svg";
import { ReactComponent as DoubleArrowIcon } from "../../../../../assets/icons/automationDoubleArrowIcon.svg";
import { useAppDispatch } from "../../../../../common/state/store";
import { MenuCommandModel } from "../../../BotModel";
import { deleteBotMenuCommand, editBotMenuCommand } from "../../../BotSlice";
import s from "./MenuCommandsItem.module.scss";

interface Props {
  botId: string;
  commandItem: MenuCommandModel;
}

export default function MenuCommandsItem({ commandItem, botId }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const tm = useTranslation("translation", { keyPrefix: "bot.menuCommands" }).t;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: commandItem.id });
  const dispatch = useAppDispatch();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [editItem, setEditItem] = useState<MenuCommandModel | null>(null);
  useEffect(() => {
    setEditItem(commandItem);
  }, [commandItem]);

  useEffect(() => {
    onSaveClick(editItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItem?.isActive]);

  function validateCommand(editItem: MenuCommandModel, editItemCommand: string, editItemDescription: string) {
    const validCommandNameRegex = /^([a-z-0-9_]*)$/;
    if (
      editItemCommand.length < 1 ||
      editItemCommand.length > 256 ||
      (editItemCommand.length > 0 && !editItemCommand.match(validCommandNameRegex))
    ) {
      setEditItem({ ...editItem, command: commandItem.command ?? "" });
    }

    if (editItemDescription.length < 2 || editItemDescription.length > 256 || !editItemDescription.length) {
      setEditItem({ ...editItem, description: commandItem.description ?? "" });
    }
  }

  const onSaveClick = (editItem: MenuCommandModel | null) => {
    if (!editItem) return;
    if (
      editItem?.description === commandItem.description &&
      editItem.command === commandItem.command &&
      editItem.isActive === commandItem.isActive
    )
      return;
    validateCommand(editItem, editItem.command, editItem.description);

    dispatch(editBotMenuCommand({ botId, data: editItem }));
  };

  return (
    <>
      {editItem !== null && (
        <Flex
          className={`${s.wrapper} ${commandItem.commandType === "System" ? s.activeSystem : s.active}`}
          position="relative"
          ref={setNodeRef}
          style={style}
          direction={"row"}
        >
          <Box {...attributes} {...listeners} className={s.dragArea} data-pw="duble-arrow">
            <DoubleArrowIcon className={`${s.dragIcon}`} />
          </Box>
          <Grid
            flex={1}
            gap="24px"
            border={
              commandItem.commandType === "System"
                ? "1px solid rgba(133, 146, 163, 0.20)"
                : "1px solid var(--chakra-colors-newGrey)"
            }
            borderRadius="8px"
            background={`${commandItem.commandType === "System" && "var(--chakra-colors-newGrey) !important"}`}
            className={s.item}
          >
            <InputGroup>
              <InputLeftElement mr="4px">
                <Text
                  color={`${!commandItem.isActive && "gray"}`}
                  borderLeftRadius="8px"
                  background="var(--chakra-colors-newGrey)"
                  p="5px 13.5px"
                >
                  /
                </Text>
              </InputLeftElement>
              <Input
                className={`${commandItem.commandType === "System" ? s.systemInput : s.inputField}`}
                isDisabled={commandItem.commandType === "System"}
                pl="40px"
                _disabled={{ color: "black" }}
                borderColor="transparent"
                backgroundColor={commandItem.commandType === "System" ? "none" : "white"}
                value={editItem?.command}
                onChange={e => {
                  setEditItem({ ...editItem, command: e.target.value });
                }}
                onBlur={() => onSaveClick(editItem)}
              />
            </InputGroup>
            <Input
              className={`${commandItem.commandType === "System" ? s.systemInput : s.inputField} ${
                commandItem.isActive ? s.inputActive : s.inputInactive
              }`}
              color={"#232B39"}
              isDisabled={commandItem.commandType === "System"}
              borderColor="transparent"
              backgroundColor={commandItem.commandType === "System" ? "none" : "white"}
              p="8px"
              placeholder={t("Add description")}
              value={commandItem.commandType === "System" ? tm(editItem?.description) : editItem?.description}
              onChange={e => {
                setEditItem({ ...editItem, description: e.target.value });
              }}
              onBlur={() => onSaveClick(editItem)}
            />
            <Switch
              isChecked={editItem?.isActive}
              justifySelf="center"
              variant="dominoDefaultGreen"
              onChange={() => {
                setEditItem({ ...editItem, isActive: !editItem.isActive });
              }}
            />
            {editItem.commandType === "System" ? (
              <Text background="#DCE7FB" p="2px 4px" borderRadius="4px">
                System
              </Text>
            ) : (
              <Flex align="center" justify="center">
                <IconButton
                  aria-label="RemoveCommand"
                  p="8px"
                  backgroundColor="transparent"
                  color="#8592A3"
                  _hover={{ color: "#EB5038", bg: "#DCE7FB", borderRadius: "4px" }}
                  cursor="pointer"
                  as={removeTimeIcon}
                  boxSize="36px"
                  onClick={() => {
                    dispatch(deleteBotMenuCommand({ botId, commandId: editItem.id }));
                  }}
                />
              </Flex>
            )}
          </Grid>
        </Flex>
      )}
    </>
  );
}
