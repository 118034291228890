import {
  httpGet,
  httpGetShoppingCart,
  httpPost,
  httpPostForm,
  httpPostFormShoppingCart,
  httpPostShoppingCart,
  httpPut,
} from "../../common/BaseApi";
import { CustomVariableDefinitionData, FlowActionData, FlowData } from "./FlowBuilderData";
import { PostFilePayload } from "./FlowBuilderModel";

export const nodeValidate = (data: FlowActionData, botId: string, flowId: string) => {
  return httpPost(`/api/flow/validate/flowaction/${botId}/${flowId}`, data);
};

export const createFlow = (data: FlowData) => {
  return httpPost("/api/flow", data);
};

export const loadYamlFile = (file: File | null) => {
  const formData = new FormData();
  if (file) {
    formData.append("file", file, file.name);
  }
  return httpPostFormShoppingCart("/api/Yaml/loadFile", formData);
};

export const getDraftYamlFile = () => {
  return httpGetShoppingCart(`/api/store/productsDraft `);
};

export const publishYamlFile = () => {
  return httpPostShoppingCart("/api/store/publishingProducts", {});
};

export const editFlow = (data: FlowData) => {
  return httpPut(`/api/flow/${data.id}`, data);
};

export const postFile = (payload: PostFilePayload) => {
  const formData = new FormData();
  formData.append("file", payload.file, payload.file.name);
  return httpPostForm("/api/file", formData);
};

export const getFile = (fileId: string) => {
  return httpGet(`/api/file/${fileId}`);
};

export const getImage = (fileId: string) => {
  return httpGet(`/file/${fileId}`);
};

export const getFlow = (flowId: string) => {
  return httpGet(`/api/flow/${flowId}`);
};

export const getFlowTitle = (flowId: string) => {
  return httpGet(`/api/flow/${flowId}/lookup`);
};

export const getTeamTitle = (teamId: string | null) => {
  return httpGet(`/api/team/${teamId}`);
};

export const getOperatorTitle = (userId: string | null) => {
  return httpGet(`/api/organization/user/${userId}`);
};

export const getFlowList = (botId: string, page: number, filter?: string) => {
  return httpGet(`/api/flow/bot/${botId}?Filter=${filter ?? ""}&page=${page}&size=${10}`);
};

export const getOperators = (page: number, filter?: string) => {
  return httpGet(`/api/organization/user?page=${page}&size=10${filter ? `&filter=${filter}` : ""}`);
};

export const getTeams = (page: number, filter?: string) => {
  return httpGet(`/api/team?page=${page}&size=10${filter ? `&filter=${filter}` : ""}`);
};

export const getFlowCustomVariables = (flowId: string) => {
  return httpGet(`/api/customvariable/definitions/flow/${flowId}`);
};

export const getBotCustomVariables = (botId: string) => {
  return httpGet(`/api/customvariable/definitions/bot/${botId}`);
};

export const getContactCustomVariables = () => {
  return httpGet(`/api/customvariable/definitions/contact`);
};

export const getSystemVariables = () => {
  return httpGet(`/api/customvariable/definitions/system`);
};

export const createCustomVariable = (info: CustomVariableDefinitionData) => {
  return httpPost(`/api/customvariable/definition`, info);
};
