import s from "../MessageListSpinner/MessageListSpinner.module.scss";
import { Center } from "@chakra-ui/react";

interface Props {
  isBlock?: boolean;
}

export const MessageListSpinner = ({ isBlock }: Props) => {
  return (
    <div className={`${isBlock ? s.overlayBlock : s.overlay}`}>
      <Center height={"15vh"}>
        <svg className={s.spinner} viewBox="0 0 50 50">
          <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
      </Center>
    </div>
  );
};
