import { PaginatorModel } from "../../common/paginator/PaginatorModel";

export interface OverLimitConversationsModel {
  conversationId: string;
}

//TODO:It's must be isolate
export interface PlanWasUpdatedModel {
  isUpdated: boolean;
}

export interface ChatListModel {
  items: ChatListItemModel[];
  lastId: string;
  totalItems: number;
}

export interface TeamsModel extends PaginatorModel {
  items: { id: string; name: string }[];
}

export interface LastMessageModel {
  text: string | null;
  date: string | null;
  isPhoto: boolean;
  isDocument: boolean;
  status?: string;
  errorCode?: string;
  isContact: boolean;
}

export interface ChatListItemModel {
  id: string;
  botId: string;
  contact:
    | {
        id: string;
        username: string;
        firstName: string;
        lastName: string;
        avatars: AvatarModel[][];
        fullName?: string;
      }
    | undefined;
  name: string;
  status: string;
  lastMessage: LastMessageModel;
  unreadMessageCount: string;
  flowExecutionId: string;
  isNew?: boolean;
  assignedTeam?: AssignedItemModel;
  assignedOperator?: AssignedItemModel;
  errorCode?: string;
  createDate: string;
}

export interface AssignedItemModel {
  id: string;
  name: string;
}

export interface AvatarModel {
  fileId: string;
  fileSize: number;
  width: number;
  height: number;
}

export interface OperatorJoinModel {
  operatorId: string;
  operatorName: string;
  conversationId: string;
}

export interface OperatorLeftModel {
  operatorName: string;
}

export interface ActiveOperatorsViewChatModel {
  operators: OperatorJoinModel[];
}

export enum ChatListSortEnum {
  newest = "Desc",
  oldest = "Asc",
}

export type ChatListFilterTypes = "open" | "closed" | "all" | "assignedToMe" | "assignedToMyTeams" | "assignedToOneOfMyTeams";

export const CHAT_LIST_FILTER_VARIANTS = {
  all: { field: "", condition: "", value: "" },
  open: { field: "status", condition: "Equal", value: "Open" },
  closed: { field: "status", condition: "Equal", value: "Closed" },
  assignedToMe: { field: "assignedToMe", condition: "Equal", value: "" },
  assignedToMyTeams: { field: "assignedToMyTeams", condition: "Equal", value: "" },
  assignedToOneOfMyTeams: { field: "assignedTeamId", condition: "Equal", value: "teamId" },
} as const;
