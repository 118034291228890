import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  titleText: string;
  progressStep: string;
}

export const TooltipTitleContainer = ({ titleText, progressStep }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "sidebar" });

  return (
    <Box>
      <Text as="span">{t(titleText)}</Text>
      <Text as="span" fontSize="14px" color="#C1B9FF">
        {progressStep}
      </Text>
    </Box>
  );
};
