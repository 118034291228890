import { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Loading } from "../loading/LoadingStateContainer";
import { useAppDispatch } from "../state/store";
import { getBots, selectBotCompleted } from "../../components/sidebar/SidebarSlice";
import { getOrganisation } from "../../components/organisation/OrganisationSlice";
import { authSlice } from "../auth/AuthSlice";
import { getTokenFromStorage } from "../auth/AuthRepo";
import { AppSettings } from "../AppSettings";
import { getBillingPlan, getProfile } from "../user/UserSlice";

declare const appSettings: AppSettings;

export function SetOrganisation() {
  const { organisationId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { location: string; forceRedirect: boolean } | undefined;

  const clearBotInfo = () => {
    dispatch(selectBotCompleted(undefined));
    localStorage.setItem("CurrentBot", "");
  };

  useEffect(() => {
    const sessionOrgId = sessionStorage.getItem("usersOrganizationId");
    if (organisationId && sessionOrgId) {
      const newOrganisationId: { [key: string]: string } = JSON.parse(sessionOrgId);
      const username = localStorage.getItem("username") || sessionStorage.getItem("username") || "";
      newOrganisationId[username] = organisationId;
      localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      dispatch(getOrganisation());
      dispatch(getBots({ page: 1, filter: undefined }));
      dispatch(getProfile());
      dispatch(getBillingPlan());
      clearBotInfo();
      const token = getTokenFromStorage();
      if (token) {
        dispatch(
          authSlice.actions.refreshToken({
            token: token.accessToken,
            apiName: appSettings.apiBaseUrl,
            refresh: token.refresh,
            expiration: token.expiration,
          }),
        );
      }
    }

    const isForceRedirect = locationState?.forceRedirect ?? false;
    const to = locationState?.location
      ? `${isForceRedirect ? locationState.location : locationState.location.split("/")[1]}`
      : "/";

    navigate(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Loading />;
}
