import { ImpersonateTokenModel, TokenModel } from "./AuthModel";
import { getOrganisationIdFromStorage } from "../BaseApi";

export function setToken(token: TokenModel) {
  setCookie("token", JSON.stringify(token));
  setCookie("organizationId", getOrganisationIdFromStorage());
}

export function getTokenFromStorage() {
  const tokenString: string | null = getCookieString("token");

  if (!tokenString) {
    return undefined;
  }

  const jsonModel = JSON.parse(tokenString);
  const model: TokenModel = {
    ...jsonModel,
    expiration: new Date(jsonModel.expiration),
  };

  return model;
}

export function setCookie(name: string, value: string) {
  deleteCookie(name);

  name = `${window.document.location.host}:${name}`;

  const expires = new Date();
  expires.setDate(new Date().getDate() + 1);
  const domain = document.location.hostname.split(".").slice(-2).join(".");
  const path = "/";

  document.cookie =
    name +
    "=" +
    value +
    "; expires = " +
    expires.toUTCString() +
    "; domain = " +
    domain +
    "; path=" +
    path +
    "; SameSite=None; Secure;";
}

export function getCookie(name: string) {
  const strValue = getCookieString(name);
  if (strValue) {
    return JSON.parse(strValue);
  }
  return null;
}

export function getCookieString(name: string) {
  name = `${window.document.location.host}:${name}`;
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      const cookieValue = cookie.substring(nameEQ.length, cookie.length);
      return cookieValue;
    }
  }
  return null;
}

export function deleteCookie(name: string) {
  name = `${window.document.location.host}:${name}`;
  const domain = document.location.hostname.split(".").slice(-2).join(".");
  const path = "/";
  document.cookie =
    name + "=; expires = Thu, 01 Jan 1970 00:00:01 GMT; domain = " + domain + "; path=" + path + "; SameSite=None; Secure;";
}

export function getSuperAdminToken(): TokenModel | undefined {
  const token = getTokenFromStorage();

  if (!token) {
    return undefined;
  }

  const accessTokenData = JSON.parse(atob(token.accessToken.split(".")[1]));
  const superAdminTokenString: string | undefined = accessTokenData?.ImpersonateToken;
  if (superAdminTokenString) {
    const superAdminToken: ImpersonateTokenModel = JSON.parse(superAdminTokenString);
    const superAdminTokenModel: TokenModel = {
      accessToken: superAdminToken.Token,
      refresh: superAdminToken.RefreshToken,
      expiration: superAdminToken.Expiration,
    };
    return superAdminTokenModel;
  }
  return undefined;
}

export const removeBotFromStorage = () => {
  sessionStorage.removeItem("CurrentBot");
  localStorage.removeItem("CurrentBot");
};

export const removeLngFromStorage = () => {
  localStorage.removeItem("language");
};
