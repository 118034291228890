import { MouseEvent, useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../common/state/store";
import {
  FlowActionModel,
  FlowModel,
  NodeModel,
  NodeValidationProp,
  SystemActionNames,
  SystemActionFlowActionModel,
  FlowActionType,
  SetVariableModel,
  PutItemIntoArrayModel,
} from "../../../FlowBuilderModel";
import { saveNode } from "../../../FlowBuilderSlice";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import { PutItemIntoArrayEditor } from "./PutItemIntoArrayEditor";
import { SetVariableEditor } from "./SetVariableEditor";
import { TakeItemFromArrayEditor } from "./TakeItemFromArrayEditor";
import { DeleteActionConfiramationPopup } from "./DeleteActionConfiramationPopup";

interface Props {
  flow: FlowModel;
  node: NodeModel;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
}

const getSourceType = (flowAction: SetVariableModel | PutItemIntoArrayModel) => {
  if (flowAction?.sourceType) {
    return flowAction.sourceType;
  } else if (flowAction.sourceCustomVariableId) {
    return "variable";
  } else if (flowAction.value) {
    return "value";
  }
  return "variable";
};

export const SystemActionNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const flowAction = props.node.flowAction as SystemActionFlowActionModel;
  const dispatch = useAppDispatch();
  const [deletePopupState, setDeletePopupState] = useState<boolean>(false);

  const onDelelePopupClose = () => setDeletePopupState(false);
  const deleteAction = () => {
    props.onDataChange({
      typeDiscriminator: FlowActionType.SystemActionFlowAction,
    } as SystemActionFlowActionModel);
  };
  const onCloseValidate = () => {
    props.validate(flowAction);
    dispatch(saveNode(props));
  };

  const getSystemActionEditor = () => {
    switch (flowAction.typeDiscriminator) {
      case FlowActionType.SetVariableValueFlowAction:
        return <SetVariableEditor flowAction={flowAction} onDataChange={props.onDataChange} validate={props.validate} />;
      case FlowActionType.PutItemIntoArrayFlowAction:
        return <PutItemIntoArrayEditor flowAction={flowAction} onDataChange={props.onDataChange} validate={props.validate} />;
      case FlowActionType.TakeItemFromArrayFlowAction:
        return <TakeItemFromArrayEditor flowAction={flowAction} onDataChange={props.onDataChange} />;
      default:
        return <></>;
    }
  };

  const systemActions = [
    {
      actionName: t(SystemActionNames.SetVariableValueFlowAction),
      onClick: (event: MouseEvent) => {
        const setVariableFlowAction = flowAction as SetVariableModel;
        event.stopPropagation();
        props.onDataChange({
          ...flowAction,
          typeDiscriminator: FlowActionType.SetVariableValueFlowAction,
          sourceType: getSourceType(setVariableFlowAction),
        } as SystemActionFlowActionModel);
      },
    },
    {
      actionName: t(SystemActionNames.TakeItemFromArrayFlowAction),
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        props.onDataChange({
          ...flowAction,
          typeDiscriminator: FlowActionType.TakeItemFromArrayFlowAction,
          sourceCustomVariableId: "",
          targetCustomVariables: [{ contentPath: "", targetCustomVariableId: null }],
        } as SystemActionFlowActionModel);
      },
    },
    {
      actionName: t(SystemActionNames.PutItemIntoArrayFlowAction),
      onClick: (event: MouseEvent) => {
        const putItemIntoArrayFlowAction = flowAction as PutItemIntoArrayModel;
        event.stopPropagation();
        props.onDataChange({
          ...flowAction,
          typeDiscriminator: FlowActionType.PutItemIntoArrayFlowAction,
          sourceType: getSourceType(putItemIntoArrayFlowAction),
        } as SystemActionFlowActionModel);
      },
    },
  ];

  const isActionChosen = flowAction.typeDiscriminator !== FlowActionType.SystemActionFlowAction;

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoDrawer">
        <DrawerContent>
          <DrawerHeader>{isActionChosen ? t(SystemActionNames[flowAction.typeDiscriminator]) : t("System Action")}</DrawerHeader>
          <DrawerBody>
            {!isActionChosen && (
              <Menu placement="bottom" autoSelect={false} size="max">
                <MenuButton onClick={e => e.stopPropagation()} as={Button} variant="dominoDashedViolet">
                  + {t("Add Action")}
                </MenuButton>
                <MenuList>
                  {systemActions.map(action => (
                    <MenuItem key={action.actionName} onClick={e => action.onClick(e)}>
                      {action.actionName}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
            {isActionChosen && getSystemActionEditor()}
          </DrawerBody>
          <DrawerFooter justifyContent="center" flexDirection="column" gap="8px" pb="8px">
            {isActionChosen && (
              <Button variant="dominoOutlineRed" onClick={() => setDeletePopupState(true)}>
                {t("Delete the action item")}
              </Button>
            )}
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
          <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => onCloseValidate()} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
        <DeleteActionConfiramationPopup
          deleteActionState={deletePopupState}
          onDelete={deleteAction}
          onClose={onDelelePopupClose}
        />
      </Drawer>
    </>
  );
};
