import { Box, Image, Hide } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import s from "./AuthSidebar.module.scss";
import botImage from "../../assets/images/botImageStart.svg";
interface Props {
  title: string;
  description?: string;
}
export const AuthSidebar = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  return (
    <Hide breakpoint="(max-width: 768px)">
      <Box className={s.authLeftSide}>
        <Box minH="300px">
          <Image mx={"32px"} mb={"16px"} className={s.authLeftImage} src={botImage}></Image>
          <Box px={"32px"}>
            <p className={s.authLeftTitle}>{t(props.title)}</p>
            {!!props.description && <p className={s.authLeftText}>{t(props.description)}</p>}
          </Box>
        </Box>
      </Box>
    </Hide>
  );
};
