import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { begin, complete } from "../../common/loading/LoadingStateActions";
import { handleException } from "../../common/SagaHelper";
import { postBot, setBotWebHook } from "./AddBotApi";
import { BotInfoData } from "./AddBotData";
import { addBotSlice } from "./AddBotSlice";
import { AppSettings } from "../../common/AppSettings";
import { CreatedBotModel } from "./AddBotModel";
import { sidebarSlice } from "../sidebar/SidebarSlice";

declare const appSettings: AppSettings;

export function* addBotSaga() {
  yield takeLatest(addBotSlice.actions.connectBot, connectBot);
}

function* connectBot(action: PayloadAction<string>) {
  try {
    yield put(begin());
    const botData: BotInfoData = yield call(postBot, action.payload);
    const webHookUrl = `${appSettings.apiBaseUrl}/api/telegramBot/webhook`;
    const botModel: CreatedBotModel = { ...botData };
    yield call(setBotWebHook, botData.id, webHookUrl);
    yield put(addBotSlice.actions.connectBotCompleted(botModel));
    yield put(sidebarSlice.actions.selectBot(botModel));
    yield put(sidebarSlice.actions.getBots({ page: 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}
