import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createStore } from "./common/state/store";
import { ChakraProvider } from "@chakra-ui/react";
import { createRoot } from "react-dom/client";
import theme from "./common/chakra/theme";
import "./common/i18next/i18n.ts";
import { AppSettings } from "./common/AppSettings";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";

declare const appSettings: AppSettings;

if (appSettings.GAIds?.length) {
  ReactGA.initialize(
    appSettings.GAIds.map(x => {
      return {
        trackingId: x,
        gaOptions: {
          allowAdPersonalizationSignals: false,
          allowAdFeatures: false,
        },
      };
    }),
  );
}
process.env.REACT_APP_SENTRY_MODE !== "development" &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

export const store = createStore();

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ChakraProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
