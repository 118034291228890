export type FormatActionType = "bold" | "italic" | "code" | "link";

export type FormatAction = {
  type: FormatActionType;
  text?: string;
  url?: string;
};

export const defaultFormatting = {
  bold: "**",
  italic: "__",
  code: "``",
  url: (text: string, url: string) => {
    return `[${text}](${url})`;
  },
};
