import { Flex, IconButton, ResponsiveValue, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as LeftPaginationArrow } from "../../assets/images/pagination/leftPaginationArrow.svg";
import { ReactComponent as RightPaginationArrow } from "../../assets/images/pagination/rightPaginationArrow.svg";
import { isNextPage, isPrevPage } from "../utils/pagination";
import { PaginatorModel } from "./PaginatorModel";
import { PaginatorNumber } from "./components/PaginatorNumbers";
import s from "./components/Paginator.module.scss";

interface Props {
  data: PaginatorModel;
  getPage: (page: number) => void;
  typeOfPage: string;
  paddingRight?:
    | ResponsiveValue<number | string | "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset">
    | undefined;
  paddingLeft?: string;
}

export const Paginator = ({ getPage, data, typeOfPage, paddingRight, paddingLeft }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "paginator" });

  useEffect(() => {
    if (!data.items.length && data.currentPage > 1) {
      getPage(data.currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.items]);

  function calculateFirstItemInList() {
    switch (data.currentPage) {
      case 1:
        return 1;
      case data.totalPages:
        return data.totalItems - data.items.length + 1;
      default:
        return data.items.length * data.currentPage - data.items.length + 1;
    }
  }

  function calculateLastItemInList() {
    switch (data.currentPage) {
      case data.totalPages:
        return data.totalItems;
      default:
        return data.items.length * data.currentPage;
    }
  }

  return isNextPage(data) || isPrevPage(data) ? (
    <div className={s.paginator}>
      <Flex
        w="100%"
        align="center"
        justify="space-between"
        color="darkGray"
        paddingRight={paddingRight}
        paddingLeft={paddingLeft}
      >
        <Text className={s.counterOfItems}>
          {t(typeOfPage)}&nbsp;
          {calculateFirstItemInList()}&nbsp;-&nbsp;{calculateLastItemInList()}&nbsp;
          {t("of")}
          &nbsp;{data.totalItems}&nbsp;&nbsp;&nbsp;&nbsp;
        </Text>
        <Flex align="center">
          <IconButton
            isDisabled={!isPrevPage(data)}
            aria-label="left arrow"
            onClick={() => getPage(data.currentPage - 1)}
            cursor={"pointer"}
            boxSize={"32px"}
            fill={!isPrevPage(data) ? "lightDeepBlue !important" : "darkGrey"}
            background={!isPrevPage(data) ? "none !important" : "initial"}
            opacity={"1 !important"}
            _hover={
              isPrevPage(data)
                ? {
                    fill: "darkGrey",
                    bg: "white",
                    border: "1px solid var(--chakra-colors-line)",
                  }
                : undefined
            }
            _focus={
              isPrevPage(data)
                ? {
                    fill: "newMiddleDeepBlue",
                    bg: "lightDeepBlue",
                  }
                : undefined
            }
            minWidth={"24px"}
            marginRight={"8px"}
            icon={<LeftPaginationArrow width="24px" height="24px" />}
            data-pw="paginator-left-arrow"
          />
          <PaginatorNumber
            lastPage={data.totalPages}
            getPage={getPage}
            totalItems={data.totalItems}
            currentPage={data.currentPage}
            totalPages={data.totalPages}
          />
          <IconButton
            isDisabled={!isNextPage(data)}
            onClick={() => getPage(data.currentPage + 1)}
            cursor={"pointer"}
            boxSize={"32px"}
            fill={!isNextPage(data) ? "lightDeepBlue !important" : "darkGrey"}
            background={!isNextPage(data) ? "none !important" : "initial"}
            opacity={"1 !important"}
            _hover={
              isNextPage(data)
                ? {
                    fill: "darkGrey",
                    bg: "white",
                    border: "1px solid var(--chakra-colors-line)",
                  }
                : undefined
            }
            _focus={
              isNextPage(data)
                ? {
                    fill: "newMiddleDeepBlue",
                    bg: "lightDeepBlue",
                  }
                : undefined
            }
            aria-label="right arrow"
            minWidth={"24px"}
            marginLeft={"8px"}
            icon={<RightPaginationArrow width="24px" height="24px" />}
            data-pw="paginator-right-arrow"
          />
        </Flex>
      </Flex>
    </div>
  ) : null;
};
