import { useMemo } from "react";
import { Flex, Button, Text, Input } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { CustomVariablesModel, FlowActionModel, PutItemIntoArrayModel } from "../../../FlowBuilderModel";
import { createCustomVariable, selectCustomVariables } from "../../../FlowBuilderSlice";
import { useTranslation } from "react-i18next";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";

interface Props {
  flowAction: PutItemIntoArrayModel;
  onDataChange: (flowAction: PutItemIntoArrayModel) => void;
  validate: (flowAction: FlowActionModel) => void;
}

export const PutItemIntoArrayEditor = ({ flowAction, onDataChange, validate }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const variables = useAppSelector(selectCustomVariables);
  const dispatch = useAppDispatch();

  const changeSourceType = (sourceType: "variable" | "value") => () => {
    onDataChange({
      ...flowAction,
      sourceType,
    });
  };

  const setSource = (variableId: string) => {
    onDataChange({
      ...flowAction,
      sourceCustomVariableId: variableId,
    });
  };

  const setSourceValue = (value: string) => {
    onDataChange({
      ...flowAction,
      value: value,
    });
  };
  const selectSource = (variable: CustomVariablesModel) => {
    if (variable.id) setSource(variable.id);
  };

  const chosenSource = variables?.find(el => {
    const sourceCustomVariableId = flowAction.sourceCustomVariableId;
    return el.id === sourceCustomVariableId ? true : false;
  });

  const targetVariables = useMemo(() => {
    return variables?.filter(el => el?.type && el?.type === CustomVariableType.Array && el.id !== chosenSource?.id);
  }, [chosenSource?.id, variables]);

  const setTarget = (variableId: string) => {
    onDataChange({
      ...flowAction,
      targetCustomVariableId: variableId,
    });
  };

  const selectTarget = (variable: CustomVariablesModel) => {
    if (variable.id) setTarget(variable.id);
  };

  const chosenTarget = targetVariables?.find(el => {
    const targetCustomVariableId = flowAction.targetCustomVariableId;
    return el.id === targetCustomVariableId ? true : false;
  });

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  return (
    <div>
      <>
        <Text variant="large">{t("Source")}</Text>
        <Flex mt="16px" w={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Button
            width="100%"
            borderRightRadius="0"
            variant={flowAction.sourceType === "variable" ? "dominoViolet" : "dominoOutlineViolet"}
            onClick={changeSourceType("variable")}
          >
            {t("Variable")}
          </Button>
          <Button
            ml="0"
            borderLeftRadius="0"
            width="100%"
            variant={flowAction.sourceType === "variable" ? "dominoOutlineViolet" : "dominoViolet"}
            onClick={changeSourceType("value")}
          >
            {t("Value")}
          </Button>
        </Flex>
        {flowAction.sourceType === "variable" ? (
          <CustomVariableContextMenu
            type={CustomVariableContextMenuType.Dropdown}
            selectCustomVariable={selectSource}
            addVariable={onCreateVariable({ sourceOrTarget: "sourceCustomVariableId" })}
            variables={variables?.filter(v => v.type !== CustomVariableType.Order) ?? []}
            chosenVariable={chosenSource}
            mt="16px"
            clearVariable={() => setSource("")}
          />
        ) : (
          <Input
            mt="16px"
            value={flowAction.value ?? ""}
            placeholder={t("Enter array like 111,two,THREE") ?? ""}
            onChange={e => setSourceValue(e.target.value)}
            onBlur={() => validate(flowAction)}
          />
        )}

        <Flex mt="20px" gap="4px">
          <Text variant="large">{t("Put into")}</Text>
        </Flex>
        <CustomVariableContextMenu
          onlyVariableType={CustomVariableType.Array}
          type={CustomVariableContextMenuType.Dropdown}
          selectCustomVariable={selectTarget}
          addVariable={onCreateVariable({ sourceOrTarget: "targetCustomVariableId", variableType: CustomVariableType.Array })}
          variables={targetVariables ?? []}
          chosenVariable={chosenTarget}
          mt="16px"
          clearVariable={() => setTarget("")}
        />
      </>
    </div>
  );
};
