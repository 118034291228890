import React from "react";
import { TakeItemFromArrayModel } from "../../../../FlowBuilderModel";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../common/state/store";
import { selectCustomVariables } from "../../../../FlowBuilderSlice";
interface Props {
  flowAction?: TakeItemFromArrayModel;
}

export const TakeItemFromArrayAction = ({ flowAction }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const variables = useAppSelector(selectCustomVariables);

  const chosenSource = variables?.find(el => {
    const sourceCustomVariableId = flowAction?.sourceCustomVariableId;
    return el.id === sourceCustomVariableId ? true : false;
  });

  const getChosenTarget = (targetCustomVariableId: string | null) => {
    return variables?.find(el => {
      return el.id === targetCustomVariableId ? true : false;
    });
  };

  return (
    <Flex direction="column" width="100%" mt="16px">
      <Flex gap="8px">
        <Text as={Box} noOfLines={1} variant="largeBold" color="darkGrey">
          {t("Source")}
        </Text>
        <Text as={Box} noOfLines={1} variant="largeBold" color="midDeepBlue">
          {chosenSource?.key ?? t("Not set")}
        </Text>
      </Flex>
      <Flex direction="column">
        {flowAction?.targetCustomVariables?.map((variable, index) => (
          <Box key={index} mt="12px" width="100%" p="16px" bg="bgMiddle" borderRadius="8px">
            <Flex gap="8px">
              <Flex gap="8px" direction="column">
                <Text as={Box} variant="medium" color="darkGrey">
                  {t("JSON")}
                </Text>
                <Text as={Box} variant="medium" color="darkGrey">
                  {t("to")}
                </Text>
              </Flex>
              <Flex gap="8px" direction="column">
                <Text width="100%" maxW="260px" as={Box} noOfLines={1} variant="medium" color="midDeepBlue">
                  {variable.contentPath ? variable.contentPath : t("Not set")}
                </Text>
                <Text width="100%" maxW="260px" as={Box} variant="medium" color="midDeepBlue">
                  {getChosenTarget(variable.targetCustomVariableId)?.key ?? t("Not set")}
                </Text>
              </Flex>
            </Flex>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};
