/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { ConversationInfoModel } from "../conversation/ConversationModel";
import {
  ChatListFilterTypes,
  ChatListItemModel,
  ChatListModel,
  ChatListSortEnum,
  OperatorJoinModel,
  OperatorLeftModel,
  OverLimitConversationsModel,
  PlanWasUpdatedModel,
  TeamsModel,
} from "./ChatListModel";
import { getFilterFromStorage } from "./ChatListSaga";

export interface ChatListState {
  chatList?: ChatListModel;
  botId?: string;
  myTeams?: TeamsModel;
  overLimitedConversations?: OverLimitConversationsModel[];
  searchText: string;
  sort: ChatListSortEnum;
  filter: { type: ChatListFilterTypes; teamId?: string; teamName?: string };
  isPlanUpdated?: boolean;
  operatorChatViewers?: OperatorJoinModel[];
  canUploadConversations?: boolean;
}

const initialState: ChatListState = {
  searchText: "",
  sort: ChatListSortEnum.newest,
  filter: { type: "open" },
  canUploadConversations: true,
};

export const chatListSlice = createSlice({
  name: "chatListSlice",
  initialState,
  reducers: {
    getChatList: (
      state: ChatListState,
      action: PayloadAction<{
        botId: string;
        search: string;
        sort: ChatListSortEnum;
        filter: { type: ChatListFilterTypes; teamId?: string };
        lastId: string;
      }>,
    ) => {
      return {
        ...state,
        botId: action.payload.botId,
      };
    },
    getChatListCount: (
      state: ChatListState,
      action: PayloadAction<{
        botId: string;
        search: string;
        sort: ChatListSortEnum;
        filter: { type: ChatListFilterTypes; teamId?: string };
        lastId: string;
      }>,
    ) => {
      return {
        ...state,
        botId: action.payload.botId,
        chatList: state.chatList && { ...state.chatList, totalItems: -1 },
      };
    },
    getChatListCountSucceed: (
      state: ChatListState,
      action: PayloadAction<{
        totalItems: number;
      }>,
    ) => {
      return {
        ...state,
        chatList: state.chatList
          ? { ...state.chatList, totalItems: action.payload.totalItems }
          : { items: [], lastId: "", totalItems: action.payload.totalItems },
      };
    },
    updateConversationsUploading: (state: ChatListState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        canUploadConversations: action.payload,
      };
    },
    getChatListSucceed: (state: ChatListState, action: PayloadAction<{ chatList: ChatListModel }>) => {
      return {
        ...state,
        chatList: {
          items: action.payload.chatList.items,
          lastId: action.payload.chatList.lastId,
          totalItems: state.chatList?.totalItems || 0,
        },
      };
    },
    getMyTeams: (state: ChatListState, action: PayloadAction<{ page: number }>) => {
      return {
        ...state,
      };
    },
    getMyTeemsSucceed: (state: ChatListState, action: PayloadAction<TeamsModel>) => {
      return {
        ...state,
        myTeams: action.payload,
      };
    },
    addChat: (state: ChatListState, action: PayloadAction<ChatListItemModel>) => {
      const chatList = state?.chatList;
      return {
        ...state,
        chatList: chatList
          ? {
              ...chatList,
              items: [action.payload, ...chatList.items],
              totalItems: chatList.totalItems + 1,
            }
          : undefined,
      };
    },
    updateChatInfo: (state: ChatListState, action: PayloadAction<ConversationInfoModel>) => {
      return {
        ...state,
        chatList: state?.chatList
          ? {
              ...state?.chatList,
              items: state?.chatList?.items?.map(item => (item.id === action.payload.id ? { ...item, ...action.payload } : item)),
            }
          : undefined,
      };
    },
    removeChat: (state: ChatListState, action: PayloadAction<ChatListItemModel>) => {
      const chatList = state?.chatList;
      return {
        ...state,
        chatList: chatList
          ? {
              ...chatList,
              items: [...chatList.items?.filter(item => item.id !== action.payload.id)],
              totalItems: chatList.totalItems > 1 ? chatList.totalItems - 1 : 0,
            }
          : undefined,
      };
    },
    addNewConversation: (state: ChatListState, action: PayloadAction<ChatListItemModel>) => {
      return {
        ...state,
      };
    },
    setChatList: (state: ChatListState, action: PayloadAction<ChatListModel>) => {
      return {
        ...state,
        chatList: action.payload,
      };
    },
    setFilter: (
      state: ChatListState,
      action: PayloadAction<{ type: ChatListFilterTypes; teamId?: string; teamName?: string }>,
    ) => {
      return {
        ...state,
        filter: action.payload,
      };
    },
    setSearchText: (state: ChatListState, action: PayloadAction<string>) => {
      return {
        ...state,
        searchText: action.payload,
      };
    },
    setSort: (state: ChatListState, action: PayloadAction<ChatListSortEnum>) => {
      return {
        ...state,
        sort: action.payload,
      };
    },
    clearChatListState: (state: ChatListState) => {
      return {
        ...initialState,
        filter: state.filter,
        sort: state.sort,
      };
    },
    getOverLimitConversations: (state: ChatListState) => {
      return {
        ...state,
      };
    },
    getOverLimitConversationsCompleted: (state: ChatListState, action: PayloadAction<OverLimitConversationsModel[]>) => {
      return {
        ...state,
        overLimitedConversations: action.payload,
      };
    },
    updateChatListAfterPlanUpgrade: (state: ChatListState, action: PayloadAction<PlanWasUpdatedModel>) => {
      return {
        ...state,
        isPlanUpdated: action.payload.isUpdated,
      };
    },
    updateChatListAfterPlanUpgradeCompleted: (state: ChatListState) => {
      return {
        ...state,
        isPlanUpdated: undefined,
      };
    },
    operatorViewConversation: (state: ChatListState, action: PayloadAction<OperatorJoinModel>) => {
      return {
        ...state,
      };
    },
    operatorViewConversationCompleted: (state: ChatListState, action: PayloadAction<OperatorJoinModel[]>) => {
      return {
        ...state,
        operatorChatViewers: action.payload,
      };
    },
    operatorLeftConversation: (state: ChatListState, action: PayloadAction<OperatorLeftModel>) => {
      return {
        ...state,
      };
    },
    operatorLeftConversationCompleted: (state: ChatListState, action: PayloadAction<OperatorJoinModel[]>) => {
      return {
        ...state,
        operatorChatViewers: action.payload,
      };
    },
    currentOperatorViewersInConversation: (state: ChatListState) => {
      return {
        ...state,
      };
    },
  },
});

export const {
  getChatList,
  getChatListCount,
  getMyTeams,
  clearChatListState,
  setFilter,
  setSearchText,
  setSort,
  getOverLimitConversations,
  operatorViewConversation,
  operatorLeftConversation,
} = chatListSlice.actions;

export const selectConversationUploading = (state: RootState) => state.app.chatListState.canUploadConversations;
export const selectChatList = (state: RootState) => state.app.chatListState.chatList;
export const selectMyTeams = (state: RootState) => state.app.chatListState.myTeams;
export const selectFilter = (state: RootState) => state.app.chatListState.filter;
export const selectSearchText = (state: RootState) => state.app.chatListState.searchText;
export const selectSort = (state: RootState) => state.app.chatListState.sort;
export const selectOverLimitedConversations = (state: RootState) => state.app.chatListState.overLimitedConversations;
export const selectIsPlanUpdated = (state: RootState) => state.app.chatListState.isPlanUpdated;
export const selectOperatorViewersInChats = (state: RootState) => state.app.chatListState.operatorChatViewers;
export default chatListSlice.reducer;
