import { validateWarnings } from "./ScheduleTriggerPopupValidationRules";
import { ScheduleTriggerTypeDiscriminator } from "../../../../FlowBuilderModel";

export interface validationDependencies {
  typeDiscriminator?: ScheduleTriggerTypeDiscriminator;
  onceValue?: string;
  endTime?: string | null;
  startTime?: string | null;
}

export function scheduleTriggerPopupValidator(rule: string, values: validationDependencies) {
  switch (rule) {
    case "warnings":
      if (!values.typeDiscriminator || !values.onceValue) return [];
      return validateWarnings(values.typeDiscriminator, values.onceValue, values.endTime ?? null, values.startTime ?? null);
  }
  return [];
}
