import { defaultFormatting, FormatAction } from "../utils/escapeMarkdown";
import { insertStringIntoText } from "../utils/insertStringIntoText";

export const addFormatting = (
  action: FormatAction,
  fieldValue: string,
  inputMaxLength: number,
  textarea: HTMLTextAreaElement,
  setFieldValue: (value: string) => void,
): string | undefined => {
  const start = textarea.selectionStart;
  const end = textarea.selectionEnd;

  if (start === undefined || end === undefined) {
    return;
  }

  if (action.type === "link") {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const toInsert = defaultFormatting.url(action.text!, action.url!);
    const newText = insertStringIntoText(fieldValue, toInsert, start);
    if (newText.length < inputMaxLength) setFieldValue(newText);
    textarea.focus();
    return newText;
  }

  let newText = insertStringIntoText(fieldValue, defaultFormatting[action.type], start);
  newText = insertStringIntoText(newText, defaultFormatting[action.type], end + defaultFormatting[action.type].length);
  if (newText.length < inputMaxLength) {
    setFieldValue(newText);
    textarea.focus();
    return newText;
  }
  textarea.focus();
  return fieldValue;
};
