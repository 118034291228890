import { BotInfoData } from "../addBot/AddBotData";
import { BotFlowData, BotMenuCommandData, BotMenuCreateData } from "./BotData";
import { BotFlowModel, BotMenuCreateModel, BotModel, MenuCommandModel } from "./BotModel";

export const mapBotDataToBotModel = (data: BotInfoData): BotModel => {
  const model = {
    ...data,
    botUrl: `https://t.me/${data.username}`,
  };
  return model;
};

export const mapDataBotFlowModel = (data: BotFlowData): BotFlowModel[] => {
  const useKeys = Object.keys(data);
  return useKeys.map(k => {
    return {
      id: k,
      name: data[k],
    } as BotFlowModel;
  });
};

export const mapMenuCommandDataToModel = (data: BotMenuCommandData[]): MenuCommandModel[] => {
  const model = data.map(el => {
    return {
      command: el.command,
      id: el.id,
      description: el.description,
      isActive: el.isActive,
      commandType: el.commandType,
      order: el.order,
    };
  });
  return model;
};

export const mapBotMenuCreateModelToData = (model: BotMenuCreateModel): BotMenuCreateData => {
  const data = {
    command: model.command,
    description: model.description,
    isActive: model.isActive,
  };
  return data;
};
