import { FunctionComponent } from "react";
import { Button, Flex, Grid, GridItem, Icon, Text, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BotImageStart } from "../../assets/images/botImageStart.svg";
import { ReactComponent as BotIcon } from "../../assets/icons/sidebar/botIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIconTile.svg";
import { useTranslation } from "react-i18next";
import useGA from "../../common/ga/GAEventTracker";
import { useMobileView } from "../layout/LayoutHelper/ResolutionHooks";
import { useAppSelector } from "../../common/state/store";
import { selectIsOrganizationTrial } from "../organisation/OrganisationSlice";

interface Props {
  mainTitle?: string;
  title?: string;
  description: string;
  Image?: FunctionComponent;
  paddingBottom?: string;
}

export const EmptyBotView = ({ mainTitle, title, description, Image, paddingBottom = "0px" }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });
  const navigate = useNavigate();
  const trackEvent = useGA("BotList");

  const createBotClick = () => {
    trackEvent("CreateBot");
    navigate("/addbot/new");
  };

  const isMobile = useMobileView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  return (
    <Grid
      w="100%"
      h={"100%"}
      templateColumns={{ base: "1fr", md: "45% 55%", xl: "40% 60%" }}
      overflow={{ base: "auto", md: "hidden" }}
      alignItems="center"
      display={{ base: "flex", md: "grid" }}
      flexDirection={"column-reverse"}
      justifyContent={"center"}
      pb={isMobile && isTrial ? paddingBottom : 0}
    >
      <GridItem display="flex" alignItems="center" justifyContent="center" p={"0 32px"}>
        <Flex direction="column" maxW="380px" textAlign={{ base: "center", md: "left" }}>
          {mainTitle && (
            <Text mb={"0"} variant="h2">
              {mainTitle}
            </Text>
          )}

          <Text mb={"15px"} variant="h2">
            {title}
          </Text>
          <Text variant="medium" color="darkGrey">
            {description}
          </Text>
          <Flex flexDirection={["column", "column", "column", "column", "row"]} mt={"24px"} gap="24px">
            <Button variant="dominoViolet" onClick={createBotClick}>
              <Flex gap={"8px"} align={"center"}>
                <PlusIcon />
                {t("Create New Bot")}
              </Flex>
            </Button>
            <Button onClick={() => navigate("/addbot/existed")} variant="connectBot">
              <Icon as={BotIcon} boxSize="24px" mr="8px" />
              <Text variant="medium">{t("Connect Existing Bot")}</Text>
            </Button>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem
        h={{ md: "100%" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={{ base: "initial", md: "white" }}
        p={"0 32px"}
      >
        {Image ? (
          <Box as={Image} mb={{ base: "16px", md: "0" }} maxW={{ base: "240px", md: "100%" }} height={"auto"}></Box>
        ) : (
          <Box as={BotImageStart} maxW={{ base: "240px", md: "100%" }} mb={{ base: "16px", md: "0" }} height={"auto"}></Box>
        )}
      </GridItem>
    </Grid>
  );
};
