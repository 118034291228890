import { Button, Flex, Input, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import s from "../ScheduleTriggerPopup.module.scss";
import { TimeUnits } from "../../../../FlowBuilderModel";
import { customDateInputIcon, DatePicker } from "../../../../../../UI/atoms/datePicker/DatePicker";
import { formatDateTimeToString } from "../../../../../../common/utils/formatDate";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Dispatch, forwardRef, SetStateAction } from "react";
import { ReactComponent as Clock } from "../../../../../../assets/icons/clockIcon.svg";

export interface Props {
  recurValue: { startTime: string | null; endTime: string | null; unit: TimeUnits; value: string; scheduledTime: string | null };
  onChangeRecurIntervalValue: (unit: TimeUnits, value?: string) => void;
  validateRepeatIntervalValue: (unit: TimeUnits, value?: string) => boolean;
  getScheduleMinEndTime: (dateString: string | null, offset?: number) => Date;
  getScheduleMinStartTime: (dateString: string | null) => Date;
  getMinDate: (dateString: string | null, offset?: number) => Date;
  setRecurValue: Dispatch<
    SetStateAction<{
      startTime: string | null;
      endTime: string | null;
      unit: TimeUnits;
      value: string;
      scheduledTime: string | null;
    }>
  >;
}
export const RecurringTriggerPopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const CustomInput = forwardRef(customDateInputIcon);
  return (
    <Flex flexDir="column">
      <Text>{t("editTriggerPopup.Enter the repeat interval")}</Text>
      <Flex my="16px">
        <Input
          value={props.recurValue.value}
          onChange={e => props.onChangeRecurIntervalValue(props.recurValue.unit, e.target.value)}
          mr="10px"
          w="85%"
          placeholder={t("editTriggerPopup.Value")}
        />
        <Menu autoSelect={false} matchWidth offset={[0, 0]}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            w="55%"
            variant="dominoDefaultMenuBtn"
            _active={{
              borderColor: "blueLink",
              borderBottomColor: "line",
              backgroundColor: "white",
              borderBottomRadius: "0",
            }}
          >
            {t(`timeUnits.${props.recurValue.unit}`)}
          </MenuButton>
          <MenuList className={s.dropDownList}>
            {Object.keys(TimeUnits)
              .slice(1)
              .map((el, index) => {
                return (
                  <MenuItem
                    onClick={e =>
                      props.setRecurValue(prevState => {
                        return {
                          ...prevState,
                          unit: TimeUnits[el as keyof typeof TimeUnits],
                        };
                      })
                    }
                    key={index}
                    isDisabled={
                      !props.validateRepeatIntervalValue(TimeUnits[el as keyof typeof TimeUnits], props.recurValue.value)
                    }
                  >
                    {t(`timeUnits.${el}`)}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Menu>
      </Flex>
      <Text mb="16px">{t("editTriggerPopup.Start time")}</Text>
      <DatePicker
        wrapperClassName={s.DatePickerWrapper}
        dateFormat="dd.MM.yyyy HH:mm"
        selected={props.recurValue?.startTime ? new Date(props.recurValue.startTime) : null}
        onChange={scheduledDate => {
          props.setRecurValue(prevState => {
            return { ...prevState, startTime: formatDateTimeToString(scheduledDate) };
          });
        }}
        placeholderText={ct("placeholderDateTime") ?? ""}
        showTimeSelect
        timeIntervals={15}
        strictParsing
        customInput={<CustomInput icon={Clock} colorIcon="black" />}
        minDate={new Date()}
        minTime={props.getScheduleMinStartTime(props.recurValue.startTime)}
        maxTime={dayjs().endOf("day").toDate()}
      />

      <Text my="16px">{t("editTriggerPopup.End time")}</Text>
      <DatePicker
        wrapperClassName={s.DatePickerWrapper}
        dateFormat="dd.MM.yyyy HH:mm"
        selected={props.recurValue?.endTime ? new Date(props.recurValue.endTime) : null}
        onChange={scheduledDate => {
          props.setRecurValue(prevState => {
            return { ...prevState, endTime: formatDateTimeToString(scheduledDate) };
          });
        }}
        placeholderText={ct("placeholderDateTime") ?? ""}
        showTimeSelect
        strictParsing
        customInput={<CustomInput icon={Clock} colorIcon="black" />}
        timeIntervals={15}
        minDate={props.getMinDate(props.recurValue.startTime)}
        minTime={props.getScheduleMinEndTime(props.recurValue.endTime, 15)}
        maxTime={dayjs().endOf("day").toDate()}
      />
    </Flex>
  );
};
