import React, { useEffect, useState } from "react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { ContactListHeader } from "../../../contactList/components/ContactListHeader";
import { Box, Flex } from "@chakra-ui/react";
import { ContactListContainer } from "../../../contactList/ContactListContainer";
import { useBrowserFitContent, useExtendedSubHeader, useTabletView } from "../../LayoutHelper/ResolutionHooks";
import { useAppSelector } from "../../../../common/state/store";
import { selectContactList } from "../../../contactList/ContactListSlice";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";
import * as vars from "../../../../components/layout/LayoutHelper/ResolutionConst";

export const ContactsPage = () => {
  const browserFill = useBrowserFitContent();
  const isTablet = useTabletView();
  const [mobileView, isMobileView] = useState(isTablet);

  const contactList = useAppSelector(selectContactList);
  const isContactListExists = !!(contactList && contactList.items.length);
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  const containerHeight = useExtendedSubHeader(isContactListExists) - vars.SUB_HEADER - (isTrial ? 60 : 0);

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  return (
    <>
      <Flex flexDirection={"column"} height={"100%"}>
        <ProtectedHeaderContainer>
          <ContactListHeader />
        </ProtectedHeaderContainer>
        <Box bg="bgLight" height={mobileView ? containerHeight : browserFill}>
          <ContactListContainer />
        </Box>
      </Flex>
    </>
  );
};
