import { EdgeProps, getBezierPath } from "reactflow";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
}: EdgeProps<unknown>) {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const style = { zIndex: "3000 !important" };

  return (
    <>
      <path id={id} style={style} d={edgePath} markerEnd={markerEnd} />
    </>
  );
}
