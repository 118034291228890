import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  ModalFooter,
  Button,
  ModalHeader,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../common/state/store";
import { addBotMenuCommand } from "../../../BotSlice";
import { BotMenuCreateModel } from "../../../BotModel";
import { validateCommandDescription, validateCommandName } from "../../../../../common/validation/botCommandsValidation";
import { t } from "i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  botId: string;
}

export default function AddCommandPopup({ botId, ...props }: Props) {
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [commandNameValidationError, setCommandNameValidationError] = useState("");
  const [commandDescriptionValidationError, setCommandDescriptionValidationError] = useState("");
  const dispatch = useAppDispatch();
  const handleInputDescription = (e: { target: HTMLInputElement }) => {
    setDescription(e.target.value);
  };

  const handleInputName = (e: { target: HTMLInputElement }) => {
    setName(e.target.value);
  };

  const handleCancelCreateNewCommand = () => {
    setName("");
    setDescription("");
    setCommandNameValidationError("");
    setCommandDescriptionValidationError("");
    props.onClose();
  };

  const onCreateCommand = () => {
    if (!name) {
      handleInputNameBlur();
      return;
    }
    if (!description) {
      handleInputDescriptionBlur();
      return;
    }
    if (commandNameValidationError) {
      handleInputNameBlur();
      return;
    }
    if (commandDescriptionValidationError) {
      handleInputDescriptionBlur();
      return;
    }
    const data: BotMenuCreateModel = {
      command: name,
      description: description,
      isActive: true,
    };
    props.onClose();
    dispatch(addBotMenuCommand({ botId, data }));
  };

  const handleInputNameBlur = () => {
    const error = validateCommandName(name, t);
    setCommandNameValidationError(error || "");
  };

  const handleInputDescriptionBlur = () => {
    const error = validateCommandDescription(description, t);
    setCommandDescriptionValidationError(error || "");
  };

  useEffect(() => {
    if (!props.isOpen) {
      setName("");
      setDescription("");
      setCommandNameValidationError("");
      setCommandDescriptionValidationError("");
    }
  }, [props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent minW={"300px"} p={{ base: "32px 16px", md: "32px" }} w={"95%"} maxW={"460px"}>
        <ModalCloseButton />
        <ModalHeader p={0} mb="12px">
          {t("bot.Create New Command")}
        </ModalHeader>
        <ModalBody fontSize="15px" p="0">
          <Text mb="24px" color="#8592A3">
            {t("bot.menuCommands.Create Command description")}
          </Text>
          <Flex gap="12px" flexDir="column">
            <Text>{t("bot.Command Name")}</Text>
            <Flex gap={1} flexDir={"column"}>
              <InputGroup>
                <InputLeftElement>
                  <Text borderLeftRadius="8px" background="var(--chakra-colors-newGrey)" p="5px 13.5px">
                    /
                  </Text>
                </InputLeftElement>
                <Input
                  pl="42px"
                  placeholder={t("bot.Enter text")}
                  value={name}
                  onChange={handleInputName}
                  onBlur={handleInputNameBlur}
                  isInvalid={!!commandNameValidationError}
                  onFocus={() => {
                    setCommandNameValidationError("");
                  }}
                  maxLength={32}
                />
              </InputGroup>
              {!!commandNameValidationError && (
                <Text color={"red"} fontSize={"12px"}>
                  {commandNameValidationError}
                </Text>
              )}
            </Flex>
          </Flex>

          <Flex gap="12px" mt="16px" flexDir="column">
            <Text>{t("bot.Description")}</Text>
            <Flex gap={1} flexDir={"column"}>
              <Input
                placeholder={t("bot.Enter text")}
                value={description}
                onChange={handleInputDescription}
                onBlur={handleInputDescriptionBlur}
                isInvalid={!!commandDescriptionValidationError}
                onFocus={() => {
                  setCommandDescriptionValidationError("");
                }}
                type="text"
                maxLength={256}
                minLength={2}
              />
              {!!commandDescriptionValidationError && (
                <Text color={"red"} fontSize={"12px"}>
                  {commandDescriptionValidationError}
                </Text>
              )}
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter p="32px 0 0 0">
          <Flex w="100%" justifyContent="space-between" alignItems={"flex-end"}>
            <Button variant="dominoOutlineViolet" onClick={handleCancelCreateNewCommand}>
              {t("bot.menuCommands.Cancel")}
            </Button>
            <Button variant="dominoViolet" mr={3} onClick={onCreateCommand}>
              {t("bot.menuCommands.Create")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
