import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./RootReducer";
import { rootSaga } from "./RootSaga";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import signalRMiddleware from "../../middlewares/signalRMiddleware";

export function createStore() {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [signalRMiddleware, sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

export type AppDispatch = ReturnType<typeof createStore>["dispatch"];
export type RootState = ReturnType<typeof rootReducer>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
