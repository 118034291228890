import { ContactDocumentsData, ContactMediaFilesData } from "./ContactInfoData";
import { ContactVariableModel } from "./ContactInfoModel";

export function mapVariableValuesToObject(contactVariables: ContactVariableModel[] | undefined) {
  return contactVariables?.reduce(
    (variablesObject, currentVariable) => ({
      ...variablesObject,
      [currentVariable.id]: (() => {
        return currentVariable.value ?? "";
      })(),
    }),
    {},
  );
}

export const mapMediaFilesListDataToModel = (newMediaFilesList: ContactMediaFilesData, data?: ContactMediaFilesData) => {
  if (!data || newMediaFilesList.currentPage === 1) {
    return newMediaFilesList;
  }

  if (newMediaFilesList && data.currentPage !== newMediaFilesList.currentPage) {
    const model: ContactMediaFilesData = {
      ...data,
      items: data.items.concat(newMediaFilesList.items),
    };

    return model;
  }

  const model: ContactMediaFilesData = {
    ...data,
  };

  return model;
};

export const mapDocumentsDataToModel = (newDocumentsList: ContactDocumentsData, data?: ContactDocumentsData) => {
  if (!data || newDocumentsList.currentPage === 1) {
    return newDocumentsList;
  }

  if (newDocumentsList && data.currentPage !== newDocumentsList.currentPage) {
    const model: ContactDocumentsData = {
      ...data,
      items: data.items.concat(newDocumentsList.items),
    };

    return model;
  }

  const model: ContactDocumentsData = {
    ...data,
  };

  return model;
};
