import { ChatListHeader } from "../../../../chatList/components/ChatListHeader";
import { Box, Flex } from "@chakra-ui/react";
import { ChatPage } from "../../../../pages/chatListPage/ChatListPage";
import { useEffect, useState } from "react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import * as vars from "../../../../../components/layout/LayoutHelper/ResolutionConst";
import { useNowWidthView } from "../../../LayoutHelper/ResolutionHooks";
import { useAppSelector } from "../../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../../organisation/OrganisationSlice";

export const ConversationPage = () => {
  const nowWidth = useNowWidthView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  const [containerHeight, setContainerHeight] = useState(
    nowWidth > 769 ? window.innerHeight - vars.HEADER : window.innerHeight - vars.HEADER - vars.HEADER - (isTrial ? 60 : 0),
  );

  useEffect(() => {
    handleWindowResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowWidth]);

  const handleWindowResize = () => {
    if (nowWidth <= 769) {
      setContainerHeight(window.innerHeight - vars.HEADER - vars.SUB_HEADER - (isTrial ? 60 : 0));
      return;
    }
    setContainerHeight(window.innerHeight - vars.HEADER);
  };

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <ChatListHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={containerHeight}>
        <ChatPage />
      </Box>
    </Flex>
  );
};
