import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const dominoSubtleVariant = definePartsStyle({
  container: {
    maxWidth: "fit-content",
    padding: "2px 4px",
  },
  label: {
    fontSize: "15px",
    lineHeight: "20px",
  },
});

const dominoSubtleHeaderVariant = definePartsStyle({
  container: {
    maxWidth: "fit-content",
    padding: "4px 8px",
  },
  label: {
    fontSize: "15px",
    lineHeight: "20px",
  },
});

const variants = {
  dominoSubtle: dominoSubtleVariant,
  dominoSubtleHeader: dominoSubtleHeaderVariant,
};

export const tagTheme = defineMultiStyleConfig({ variants });
