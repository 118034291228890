import { httpDelete, httpGet, httpPost, httpPostForm, httpPut } from "../../common/BaseApi";
import { BroadcastCreateData } from "./BroadcastData";
import { BroadcastFormModel } from "./BroadcastModel";

export const getBroadcastApi = (botId: string, broadcastId: string) => {
  return httpGet(`/api/broadcast/bot/${botId}/${broadcastId}`);
};

export const getBroadcastRecipientListApi = (botId?: string, broadcastId?: string, queryFilterParams?: string) => {
  return httpGet(`/api/broadcast/bot/${botId}/${broadcastId}/recipients?${queryFilterParams}`);
};

export const importRecipientsByFileApi = (botId: string | undefined, file: File | null) => {
  const formData = new FormData();
  if (file) {
    formData.append("file", file, file.name);
  }
  return httpPostForm(`/api/broadcast/recipientByFile/${botId}?excludeUnavailable=true&limit=1000`, formData); // предупредить пользователя про лимит
};

export const editBroadcastApi = (botId: string, broadcast: BroadcastFormModel) => {
  return httpPut(`/api/broadcast/bot/${botId}/${broadcast.id}`, broadcast);
};

export const createBroadcastApi = (botId: string, broadcast: BroadcastCreateData) => {
  return httpPost(`/api/broadcast/bot/${botId}`, { ...broadcast });
};

export const getContactListApi = (botId: string, queryFilterParams: string | undefined) => {
  return httpGet(`/api/broadcast/bot/recipients/${botId}?${queryFilterParams ? `&${queryFilterParams}` : ""}`);
};

export const getDraftContactListApi = (botId: string, broadcastId: string, queryFilterParams: string | undefined) => {
  return httpGet(
    `/api/broadcast/bot/recipients/${botId}?broadcastId=${broadcastId}${queryFilterParams ? `&${queryFilterParams}` : ""}`,
  );
};

export const deleteBroadcastApi = (botId: string, broadcastId: string) => {
  return httpDelete(`/api/broadcast/bot/${botId}/${broadcastId}`);
};

export const cancelBroadcastApi = (botId: string, broadcastId: string) => {
  return httpPost(`/api/broadcast/bot/${botId}/${broadcastId}/cancel`, {});
};

export const executeBroadcastApi = (botId: string, broadcastId: string) => {
  return httpPost(`/api/broadcast/bot/${botId}/${broadcastId}/execute`, {});
};

export const getContactCustomVariablesApi = () => {
  return httpGet(`/api/customvariable/definitions/contact`);
};

export const getSystemVariablesApi = () => {
  return httpGet(`/api/customvariable/definitions/system`);
};

export const postFile = (file: File) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return httpPostForm("/api/file", formData);
};
