import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const outlineVariant = definePartsStyle({
  field: {
    height: "36px",
    lineHeight: "36px",
    borderRadius: "8px",
    borderColor: "line",
    boxShadow: "none !important",
    width: "100%",
    _hover: {
      borderColor: "darkPurple",
    },
    _disabled: {
      borderColor: "line !important",
    },
    _focusVisible: {
      borderColor: "blueLink",
      boxShadow: "none",
    },
  },
  element: {
    border: "1px solid var(--chakra-color-line)",
    borderRadius: "8px",
    borderRightRadius: "none",
  },
});

const authVariant = definePartsStyle({
  field: {
    height: "36px",
    borderRadius: "none",
    border: "none",
    borderBottom: "1px solid",
    borderColor: "line",
    bg: "transparent",
    p: 0,
    _placeholder: {
      color: "darkGrey",
      fontSze: "15px",
    },
    _hover: {
      borderColor: "darkPurple",
    },
    _focusVisible: {
      borderColor: "blueLink",
      boxShadow: "none",
    },
    _invalid: {
      borderColor: "red",
    },
  },
});

const inlineVariant = definePartsStyle({
  field: {
    color: "midDeepBlue",
    backgroundColor: "transparent",
    height: "36px",
    border: "1px solid transparent",
    padding: "4px 8px",
    minWidth: "100px",
    maxWidth: "300px",
    _hover: {
      borderColor: "darkPurple",
      fill: "darkPurple",
    },
    _focusVisible: {
      backgroundColor: "white",
      borderColor: "blueLink",
    },
  },
});
const dateRangeVariant = definePartsStyle({
  field: {
    height: "36px",
    lineHeight: "20px",
    width: "266px",
    fontSize: "15px",
    cursor: "pointer",
    backgroundColor: "transparent",
    zIndex: 0,
    _focusVisible: {
      fontSize: "15px",
    },
  },
  element: {
    border: "1px solid var(--chakra-color-line)",
    borderRadius: "8px",
    borderRightRadius: "none",
  },
});

const variants = {
  outline: outlineVariant,
  auth: authVariant,
  inline: inlineVariant,
  dateRange: dateRangeVariant,
};

export const inputTheme = defineMultiStyleConfig({ variants });
