import { Helmet } from "react-helmet";

function HelmetContainer() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      </Helmet>
    </>
  );
}

export default HelmetContainer;
