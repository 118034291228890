import { call, put, takeLatest } from "redux-saga/effects";
import { userManagementSlice } from "./UserManagementSlice";
import { getAllBillingPlansApi, getAllOrganisation, impersonateApi, setSubscriptionApi } from "./UserManagementApi";
import { handleException } from "../../common/SagaHelper";
import { PayloadAction } from "@reduxjs/toolkit";
import { TokenModel } from "../../common/auth/AuthModel";
import { userSlice } from "../../common/user/UserSlice";
import { routerSlice } from "../../common/router/RouterSlice";
import { getSuperAdminToken, getTokenFromStorage, setToken } from "../../common/auth/AuthRepo";
import { GetTokenResponseData } from "../login/LoginData";
import { sidebarSlice } from "../sidebar/SidebarSlice";
import { organisationSlice } from "../organisation/OrganisationSlice";
import { OrganisationUserModel } from "./UserManagementModel";
import { TariffPlanItemsModel } from "../../common/tariffPlan/TariffPlanModel";
import { authSlice } from "../../common/auth/AuthSlice";
import { AppSettings } from "../../common/AppSettings";

declare const appSettings: AppSettings;

export function* userManagementSaga() {
  yield takeLatest(userManagementSlice.actions.impersonate, impersonate);
  yield takeLatest(userManagementSlice.actions.stopImpersonation, stopImpersonation);
  yield takeLatest(userManagementSlice.actions.getAllOrganisation, getOrganisationsUser);
  yield takeLatest(userManagementSlice.actions.getAllBillingPlans, getAllBillingPlans);
  yield takeLatest(userManagementSlice.actions.setSubscription, setSubscription);
}

function* impersonate(action: PayloadAction<{ username: string }>) {
  try {
    const refreshToken = getTokenFromStorage()?.refresh;
    const impersonateToken: GetTokenResponseData = yield call(impersonateApi, action.payload.username, refreshToken);

    const token: TokenModel = {
      accessToken: impersonateToken.token,
      refresh: impersonateToken.refreshToken,
      expiration: impersonateToken.expiration,
    };
    const organizationId = JSON.parse(atob(impersonateToken.token.split(".")[1])).Organization;
    const sessionOrgId = sessionStorage.getItem("usersOrganizationId");
    const newOrganisationId: { [key: string]: string } = JSON.parse(sessionOrgId || "");
    newOrganisationId[action.payload.username] = organizationId;
    localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
    sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));

    localStorage.clear();
    localStorage.setItem("username", action.payload.username);
    setToken(token);
    yield put(userSlice.actions.getProfile());
    yield put(organisationSlice.actions.getOrganisation());
    yield put(sidebarSlice.actions.getAllOrganisation());
    yield put(sidebarSlice.actions.getBots({ page: 1, filter: undefined }));
    yield put(sidebarSlice.actions.removeSelectedBot());
    yield put(routerSlice.actions.redirect("/profile"));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* stopImpersonation() {
  try {
    const superAdminToken = getSuperAdminToken();
    if (superAdminToken) {
      const organizationId = JSON.parse(atob(superAdminToken.accessToken.split(".")[1])).Organization;
      const sessionOrgId = sessionStorage.getItem("usersOrganizationId");
      const newOrganisationId: { [key: string]: string } = JSON.parse(sessionOrgId || "");
      const username = localStorage.getItem("username") || sessionStorage.getItem("username") || "";
      newOrganisationId[username] = organizationId;
      localStorage.clear();
      localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      yield setToken(superAdminToken);
      yield put(userSlice.actions.getProfile());
      yield put(organisationSlice.actions.getOrganisation());
      yield put(sidebarSlice.actions.getAllOrganisation());
      yield put(sidebarSlice.actions.getBots({ page: 1 }));
      yield put(
        authSlice.actions.refreshToken({
          token: superAdminToken.accessToken,
          apiName: appSettings.apiBaseUrl,
          refresh: superAdminToken.refresh,
          expiration: superAdminToken.expiration,
        }),
      );
      yield put(routerSlice.actions.redirect("/management"));
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* getOrganisationsUser(action: PayloadAction<string>) {
  try {
    if (!action.payload) {
      yield put(userManagementSlice.actions.getAllOrganisationCompleted(undefined));
      return;
    }
    const userOrganisations: OrganisationUserModel[] = yield call(getAllOrganisation, action.payload);
    yield put(userManagementSlice.actions.getAllOrganisationCompleted(userOrganisations));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* getAllBillingPlans() {
  try {
    const billingPlans: TariffPlanItemsModel = yield call(getAllBillingPlansApi);
    yield put(userManagementSlice.actions.getAllBillingPlansCompleted(billingPlans));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* setSubscription(action: PayloadAction<{ organizationId: string; BillingPlanId: string; EndDate: Date }>) {
  try {
    yield call(setSubscriptionApi, action.payload.organizationId, action.payload.BillingPlanId, action.payload.EndDate);
  } catch (e: unknown) {
    yield handleException(e);
  }
}
