import { useBrowserFitContent, useTabletView } from "../../../LayoutHelper/ResolutionHooks";
import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { BotContainer } from "../../../../bot/BotContainer";
import { BotHeader } from "../../../../bot/components/BotHeader/BotHeader";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { HEADER } from "../../../../layout/LayoutHelper/ResolutionConst";

export const BotPage = () => {
  const browserFill = useBrowserFitContent();
  const isTablet = useTabletView();
  const [mobileView, isMobileView] = useState(isTablet);

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <BotHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={mobileView ? window.innerHeight - HEADER - HEADER : browserFill}>
        <Loading />
        <BotContainer />
      </Box>
    </Flex>
  );
};
