/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { BroadcastListModel } from "./BroadcastListModel";

export interface BroadcastListState {
  broadcastList?: BroadcastListModel;
}

const initialState: BroadcastListState = {};

export const broadcastListSlice = createSlice({
  name: "broadcastListSlice",
  initialState,
  reducers: {
    getBroadcastList: (state, action: PayloadAction<{ botId: string; page: number; filter?: string }>) => {
      return {
        ...state,
      };
    },
    getBroadcastListCompleted: (state, action: PayloadAction<BroadcastListModel>) => {
      return {
        ...state,
        broadcastList: action.payload,
      };
    },
    deleteBroadcast: (state, action: PayloadAction<{ broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    cancelBroadcast: (state, action: PayloadAction<{ broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    copyBroadcast: (state, action: PayloadAction<{ broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    clearBroadcastListState: state => {
      return initialState;
    },
  },
});

export const {
  getBroadcastList,
  getBroadcastListCompleted,
  deleteBroadcast,
  cancelBroadcast,
  copyBroadcast,
  clearBroadcastListState,
} = broadcastListSlice.actions;

export const selectBroadcastListState = (state: RootState) => state.app.broadcastListState;

export default broadcastListSlice.reducer;
