import { Box, Text } from "@chakra-ui/react";
import sm from "../../headers/publicHeaderContainer/PublicHeaderContainer.module.scss";
import { useTranslation } from "react-i18next";

export const AccountNotApprovedPage = () => {
  const email = "support@domino-crm.com";
  const { t } = useTranslation("translation", { keyPrefix: "auth" });

  return (
    <Box className={`${sm.authBlock} ${sm.authBlockSingUp}`}>
      <div className={sm.authForm}>
        <h1 className={sm.loginTitle}>{t("Registration")}</h1>
        <Text className={sm.authLeftText}>
          {t("To complete your registration, please reach out to us at")}
          &nbsp;&nbsp;
          <a className={sm.authTextLink} href={"mailto:" + email}>
            {email}
          </a>
        </Text>
      </div>
    </Box>
  );
};
