import React, { useEffect } from "react";
import { Box, useDisclosure, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import {
  clearContactListState,
  getContactList,
  selectContactList,
  getCustomVariables,
  selectImportResult,
  selectSearchParams,
} from "./ContactListSlice";
import { ContactList } from "./components/ContactList";
import { ImportContactsModal } from "../importContacts/ImportContactsModal";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { NewContactModal } from "./components/NewContactModal/NewContacModal";
import s from "./ContactList.module.scss";
import sc from "../contactList/ContactListContainer.module.scss";
import { ContactListEmptyPage } from "./components/ContactListEmptyPage/ContactListEmptyPage";
import { PaginatorWithSearchParams } from "../../common/paginator/components/PaginatorWithSearchParams";
import * as vars from "../layout/LayoutHelper/ResolutionConst";
import { CONTACTS_PAGE_SIZE } from "../../common/paginator/paginatorSizes";
import { Loading } from "../../common/loading/LoadingStateContainer";

export const ContactListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "contactList" });
  const dispatch = useAppDispatch();
  const contactList = useAppSelector(selectContactList);
  const loadingContactList = useGetLoadingState("getContactList");
  const [searchParams] = useSearchParams();
  const { isOpen: isImportContactsOpen, onOpen: onImportContactsOpen, onClose: onImportContactsClose } = useDisclosure();
  const { isOpen: isNewContactModalOpen, onOpen: onNewContact, onClose: onNewContactModalClose } = useDisclosure();

  const search = searchParams.get("search") ?? "";
  const filters = searchParams.get("filters") ?? "";
  const currentPage = Number(searchParams.get("page") ?? 1);
  const isNoSearchResults = contactList && contactList.items.length === 0 && (search !== "" || filters !== "");
  const importResult = useAppSelector(selectImportResult);
  const searchStateParams = useAppSelector(selectSearchParams);

  useEffect(() => {
    dispatch(getCustomVariables());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const size = CONTACTS_PAGE_SIZE;
    dispatch(getContactList({ filters: filters, search: search, page: currentPage, size }));
  }, [filters, search, currentPage, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearContactListState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (importResult) {
      const search = searchStateParams?.search ?? "";
      const filters = searchStateParams?.filters ?? "";
      const size = CONTACTS_PAGE_SIZE;
      dispatch(getContactList({ filters: filters, search: search, page: currentPage, size }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importResult, dispatch]);

  return (
    <>
      {!contactList && <Loading scope="getContactList" />}
      {contactList && (
        <Box className={sc.contactListContainer}>
          {!loadingContactList && contactList && contactList.items.length === 0 && search === "" && filters === "" ? (
            <ContactListEmptyPage onImportContactsOpen={onImportContactsOpen} onNewContact={onNewContact} />
          ) : (
            <Box className={s.contactListContent}>
              {window.innerWidth >= vars.TABLET_VIEW && (
                <Box className={s.contactListTitle} position={isNoSearchResults ? "relative" : "absolute"}>
                  <div />
                  <Text noOfLines={1} variant="medium">
                    {t("Avatar")}
                  </Text>
                  <Text noOfLines={1} variant="medium">
                    {t("First Name")}
                  </Text>
                  <Text noOfLines={1} variant="medium">
                    {t("Last Name")}
                  </Text>
                  <Text noOfLines={1} variant="medium">
                    {t("Username")}
                  </Text>
                  <Text noOfLines={1} variant="medium">
                    {t("Created")}
                  </Text>
                  <div />
                </Box>
              )}
              {contactList && <Loading scope="getContactList" />}
              <ContactList
                contactList={contactList}
                onImportContactsOpen={onImportContactsOpen}
                onNewContact={onNewContact}
                loadingContactList={loadingContactList}
                filters={filters}
              />
              {contactList.items.length > 0 && window.innerWidth >= vars.TABLET_VIEW ? (
                <PaginatorWithSearchParams data={contactList} typeOfPage={"ContactList"} />
              ) : contactList.items.length > 0 && window.innerWidth < vars.TABLET_VIEW ? (
                <PaginatorWithSearchParams data={contactList} typeOfPage={"ContactList"} paddingLeft="5px" />
              ) : null}
            </Box>
          )}
        </Box>
      )}
      <NewContactModal newContactState={isNewContactModalOpen} onClose={onNewContactModalClose} />
      <ImportContactsModal isOpen={isImportContactsOpen} onClose={onImportContactsClose} />
    </>
  );
};
