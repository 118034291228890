/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import {
  NewTeamModel,
  OrganisationModel,
  OrganisationTeamItemModel,
  OrganisationTeamsModel,
  OrganisationUsersModel,
} from "./OrganisationModel";
import { TrialPlanModel } from "./components/TrialPlan/TrialPlanModel";

export interface OrganisationState {
  organisation?: OrganisationModel;
  organisationUsers?: OrganisationUsersModel;
  organisationsTeams?: OrganisationTeamsModel;
  organisationUserUsage?: { id: string; name: string }[];
  organisationTeamUsage?: { id: string; name: string }[];
  inviteToken?: string;
  organizationTrial?: TrialPlanModel | null;
  isOrganizationTrial: boolean;
  isInitialTrialLoading: boolean;
}

const initialState: OrganisationState = {
  isOrganizationTrial: false,
  isInitialTrialLoading: false,
};

export const organisationSlice = createSlice({
  name: "organisationSlice",
  initialState,
  reducers: {
    getOrganisation: state => {
      return {
        ...state,
      };
    },
    getOrganisationCompleted: (state, action: PayloadAction<OrganisationModel>) => {
      return {
        ...state,
        organisation: action.payload,
      };
    },
    createOrganisation: (state, action: PayloadAction<OrganisationModel>) => {
      return {
        ...state,
      };
    },
    getOrganisationUsers: (state, action: PayloadAction<{ page: number; filter?: string }>) => {
      return {
        ...state,
      };
    },
    getOrganisationUsersCompleted: (state, action: PayloadAction<OrganisationUsersModel>) => {
      return {
        ...state,
        organisationUsers: action.payload,
      };
    },
    getOrganisationTeams: (state, action: PayloadAction<{ page: number }>) => {
      return {
        ...state,
      };
    },
    getOrganisationTeamsCompleted: (state, action: PayloadAction<OrganisationTeamsModel>) => {
      return {
        ...state,
        organisationsTeams: action.payload,
      };
    },
    generateInviteToken: state => {
      return {
        ...state,
      };
    },
    setInviteToken: (state, action: PayloadAction<{ inviteToken: string }>) => {
      return {
        ...state,
        inviteToken: action.payload.inviteToken,
      };
    },
    clearInviteToken: state => {
      return {
        ...state,
        inviteToken: undefined,
      };
    },
    updateOrganisation: (state, action: PayloadAction<OrganisationModel>) => {
      return {
        ...state,
      };
    },
    deleteUserFromOrganisation: (state, action: PayloadAction<{ userId: string }>) => {
      return {
        ...state,
      };
    },
    clearOrganisationState: state => {
      return {
        ...initialState,
      };
    },
    createOrganisationTeam: (state, action: PayloadAction<NewTeamModel>) => {
      return {
        ...state,
      };
    },
    editOrganisationTeam: (state, action: PayloadAction<OrganisationTeamItemModel>) => {
      return {
        ...state,
      };
    },
    deleteOrganisationTeam: (state, action: PayloadAction<{ teamId: string }>) => {
      return {
        ...state,
      };
    },
    deleteTeamOperator: (state, action: PayloadAction<{ teamId: string; operatorIds: string[] }>) => {
      return {
        ...state,
      };
    },
    addOperatorsToTeam: (state, action: PayloadAction<{ teamId: string; operatorIds: string[] }>) => {
      return {
        ...state,
      };
    },
    checkIsTeamInUse: (state, action: PayloadAction<{ teamId: string }>) => {
      return {
        ...state,
      };
    },
    checkIsTeamInUseSucceed: (state, action: PayloadAction<{ id: string; name: string }[]>) => {
      return {
        ...state,
        organisationTeamUsage: action.payload,
      };
    },
    checkIsOperatorInUse: (state, action: PayloadAction<{ operatordId: string }>) => {
      return {
        ...state,
      };
    },
    checkIsOperatorInUseSucceed: (state, action: PayloadAction<{ id: string; name: string }[]>) => {
      return {
        ...state,
        organisationUserUsage: action.payload,
      };
    },
    setOrganization: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getTrialPlan: state => {
      return {
        ...state,
      };
    },
    getTrialPlanCompleted: (state, action: PayloadAction<TrialPlanModel>) => {
      return {
        ...state,
        organizationTrial: action.payload,
        isOrganizationTrial: action.payload.isAvailable,
      };
    },
    activateTrialPlan: (state, _action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    setTrialPlanState: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isOrganizationTrial: action.payload,
      };
    },
    setisInitialTrialLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isInitialTrialLoading: action.payload,
      };
    },
  },
});

export const {
  getOrganisation,
  getOrganisationCompleted,
  createOrganisation,
  getOrganisationUsers,
  getOrganisationUsersCompleted,
  getOrganisationTeams,
  getOrganisationTeamsCompleted,
  generateInviteToken,
  updateOrganisation,
  deleteUserFromOrganisation,
  setInviteToken,
  clearInviteToken,
  clearOrganisationState,
  createOrganisationTeam,
  editOrganisationTeam,
  deleteOrganisationTeam,
  deleteTeamOperator,
  addOperatorsToTeam,
  checkIsOperatorInUse,
  checkIsOperatorInUseSucceed,
  checkIsTeamInUse,
  checkIsTeamInUseSucceed,
  setOrganization,
  getTrialPlan,
  getTrialPlanCompleted,
  activateTrialPlan,
  setTrialPlanState,
  setisInitialTrialLoading,
} = organisationSlice.actions;

export const selectNewOrganisation = (state: RootState) => state.app.organisationState.organisation;
export const selectOrganisation = (state: RootState) => state.app.organisationState.organisation;
export const selectOrganisationUsers = (state: RootState) => state.app.organisationState.organisationUsers;
export const selectOrganisationTeams = (state: RootState) => state.app.organisationState.organisationsTeams;
export const selectInviteToken = (state: RootState) => state.app.organisationState.inviteToken;
export const selectOrganisationUserUsage = (state: RootState) => state.app.organisationState.organisationUserUsage;
export const selectOrganisationTeamUsage = (state: RootState) => state.app.organisationState.organisationTeamUsage;
export const selectTrialPlan = (state: RootState) => state.app.organisationState.organizationTrial;
export const selectIsOrganizationTrial = (state: RootState) => state.app.organisationState.isOrganizationTrial;
export const selectIsInitialTrialLoading = (state: RootState) => state.app.organisationState.isInitialTrialLoading;

export default organisationSlice.reducer;
