import { useEffect } from "react";
import { AppSettings } from "../AppSettings";
import ReactGA from "react-ga4";

declare const appSettings: AppSettings;

export const useDocumentTitle = (title: string, trackingTitle?: string) => {
  useEffect(() => {
    document.title = "Domino | " + title;

    if (appSettings.GAIds?.length && trackingTitle) {
      ReactGA.set({ title: trackingTitle });
    }
  }, [title, trackingTitle]);
};
