import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../state/store";
import { selectRouter } from "./RouterSlice";

interface RouterContainerProps {
  children: JSX.Element;
}

export function RouterContainer({ children }: RouterContainerProps) {
  const routerState = useAppSelector(selectRouter);
  const navigate = useNavigate();
  useEffect(() => {
    if (routerState.path) {
      navigate(routerState.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerState]);
  return children;
}
