import { Box, Flex, Link } from "@chakra-ui/react";
import s from "../../Conversation.module.scss";
import { ConversationMessageModel, MessageStatusEnum } from "../../ConversationModel";
import { ReactComponent as FollowLinkIcon } from "../../../../assets/icons/paperClipIcon.svg";
import { formatBytes } from "../../../../common/utils/formatBytes";
import { AppSettings } from "../../../../common/AppSettings";
import { ErrorMessageItem } from "./ErrorMessageItem";
import { FileNameSplitter } from "../../../../UI/atoms/fileNameSplitter/FileNameSplitter";

declare const appSettings: AppSettings;

interface Props {
  message: ConversationMessageModel;
}

export const DocumentMessageItem = (props: Props) => {
  const timeString = new Date(props?.message?.date).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  });

  const messageDocumentLength = props.message.documents.length;
  const messageStatus = props.message.status;
  const messageTextLength = props.message.text?.length;

  return (
    <Box>
      {messageDocumentLength && messageStatus === MessageStatusEnum.Failed ? (
        <Box
          className={`${s.messageTextWrapper} ${
            (messageTextLength || props.message.replyMarkup !== null) && s.documentWithText
          } clearfix 
          ${props.message.replyToMessage !== null ? s.textWithReply : ""}`}
        >
          <Box className={s.messageText}>
            {props.message.documents.map(doc => (
              <Box key={doc.fileId} className={s.document}>
                <Link
                  key={doc.fileId}
                  className={s.documentLink}
                  href={`${appSettings.apiBaseUrl}/file/${doc.fileId}`}
                  isExternal
                >
                  <FollowLinkIcon className={s.documentLinkIcon}></FollowLinkIcon>
                  <Box className={s.documentLabel}>{doc.fileName}</Box>
                </Link>
                <p className={s.documentSize}>{formatBytes(doc.fileSize)}</p>
              </Box>
            ))}
          </Box>
          {!messageTextLength && <Box className={s.date}>{timeString}</Box>}
          {!messageTextLength && (
            <Box ml={2} mb={-1.5}>
              <ErrorMessageItem errorCode={props.message.errorCode} />
            </Box>
          )}
        </Box>
      ) : !!messageDocumentLength ? (
        <Box
          className={`${s.messageTextWrapper} ${
            (messageTextLength > 0 || props.message.replyMarkup !== null) && s.documentWithText
          } clearfix
          ${props.message.replyToMessage !== null ? s.textWithReply : ""}`}
        >
          <Box className={s.messageText}>
            {props.message.documents.map(doc => (
              <Box key={doc.fileId} className={s.document}>
                <Link
                  key={doc.fileId}
                  className={s.documentLink}
                  href={`${appSettings.apiBaseUrl}/file/${doc.fileId}`}
                  isExternal
                >
                  <FollowLinkIcon className={s.documentLinkIcon}></FollowLinkIcon>
                  <Box className={s.documentLabel}>
                    <FileNameSplitter fileName={doc.fileName} variant="normal" />
                  </Box>
                </Link>
                <Flex>
                  <p className={s.documentSize}>{formatBytes(doc.fileSize)}</p>
                </Flex>
              </Box>
            ))}
          </Box>
          <Box className={s.date}>{timeString}</Box>
        </Box>
      ) : null}
    </Box>
  );
};
