import { Flex, Text, Image, Skeleton } from "@chakra-ui/react";
import s from "../FlowTemplatesModal.module.scss";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { createImportFlowTemplate } from "../../../../flowBuilder/FlowBuilderSlice";
import { selectCurrentBotId } from "../../../../sidebar/SidebarSlice";

interface Props {
  icon: string;
  label: string;
  description: string;
  id: string;
}

export default function FlowTemplateItem({ icon, label, description, id }: Props) {
  const dispatch = useAppDispatch();
  const selectBotId = useAppSelector(selectCurrentBotId);
  const [imageLoaded, setImageLoaded] = useState(false);

  const importFlowTemplate = (flowTemplateId: string) => {
    if (selectBotId) {
      dispatch(createImportFlowTemplate({ botId: selectBotId, flowTemplateId: flowTemplateId }));
    }
  };

  return (
    <Flex data-pw={label} onClick={() => importFlowTemplate(id)} className={s.flowTemplateItem}>
      <Skeleton
        isLoaded={imageLoaded}
        speed={0.5}
        startColor="line"
        endColor="bgLight"
        height="100%"
        width="100%"
        borderColor="line"
        borderRadius="8px"
      >
        <Flex className={s.flexIconTemplate}>
          <Image src={icon} className={s.iconTemplate} h={"80px"} onLoad={() => setImageLoaded(true)} />
        </Flex>
      </Skeleton>

      <Flex gap={"8px"} direction={"column"}>
        <Text variant={"preLargeBold"}>{label}</Text>
        <Text noOfLines={2} variant={"small"} color={"darkGray"} className={s.descriptionTemplate}>
          {description}
        </Text>
      </Flex>
    </Flex>
  );
}
