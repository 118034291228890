import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../state/store";
import { clearNotify, selectNotification } from "./NotificationSlice";
import React from "react";
import PaymentError from "./CustomNotification/PaymentError";

export const NotificationContainer = () => {
  const { t } = useTranslation("validation");
  const notification = useAppSelector(selectNotification);
  const dispatch = useAppDispatch();
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toastIdRef: any = React.useRef();

  useEffect(() => {
    if (notification) {
      const duration = notification.duration === undefined ? 3000 : notification.duration;
      dispatch(clearNotify());
      if (notification.type === "paymentError") {
        toastIdRef.current = toast({
          title: `${t(notification.message, notification.messageVariables)}`,
          status: "error",
          isClosable: duration > 0,
          position: "bottom-left",
          duration: duration === 0 ? Number.MAX_VALUE : duration,
          render: () => <PaymentError idToast={toastIdRef.current} message={t(notification.message)} toast={toast} />,
        });
      } else {
        toast({
          title: `${t(notification.message, notification.messageVariables)}`,
          status: notification.type,
          isClosable: duration > 0,
          position: "bottom-left",
          duration: duration === 0 ? Number.MAX_VALUE : duration,
        });
      }
    }
  }, [dispatch, notification, t, toast]);

  return <></>;
};
