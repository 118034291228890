import { useTabletView } from "../../LayoutHelper/ResolutionHooks";
import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { useAppSelector } from "../../../../common/state/store";
import { selectOrganisation } from "../../../organisation/OrganisationSlice";
import { useParams } from "react-router-dom";
import { getOrganisationIdFromStorage } from "../../../../common/BaseApi";

export const PluginPage = () => {
  const isTablet = useTabletView();
  const [mobileView, isMobileView] = useState(isTablet);
  const organisation = useAppSelector(selectOrganisation);
  const { pluginName } = useParams();

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  const getFrameUrl = () => {
    if (!organisation) {
      return "";
    }

    const plugin = organisation.plugins?.find(x => x.name == pluginName);
    return plugin?.url?.replace("{:orgId}", getOrganisationIdFromStorage());
  };

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <iframe src={getFrameUrl()} style={{ height: "100%" }} />
    </Flex>
  );
};
