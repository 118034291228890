import { call, put, takeLatest } from "redux-saga/effects";
import { begin, complete } from "../loading/LoadingStateActions";
import { tariffPlanSlice } from "./TariffPlanSlice";
import { handleException } from "../SagaHelper";
import { TariffPlanData } from "./TariffPlanData";
import { getTariffPlan } from "./TariffPlanApi";
import { mapTariffPlan } from "./TariffPlanMapper";

export function* tariffPlanSaga() {
  yield takeLatest(tariffPlanSlice.actions.getPlan, getPlan);
}

export function* getPlan() {
  try {
    yield put(begin());
    const data: TariffPlanData = yield call(getTariffPlan);
    const model = mapTariffPlan(data);
    yield put(tariffPlanSlice.actions.getPlanCompleted(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}
