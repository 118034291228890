/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { ChangeUserPasswordSendData } from "./UserProfileData";
import {
  NotificationBotsModel,
  NotificationSettingsModel,
  UserProfileModel,
  UserProfileNotificationsModel,
} from "./UserProfileModel";

export interface UserProfileState {
  data?: UserProfileModel;
  notifications?: NotificationSettingsModel[];
  bots?: NotificationBotsModel[];
}

const initialState: UserProfileState = {};

export const userProfileSlice = createSlice({
  name: "userProfileSlice",
  initialState,
  reducers: {
    changePassword: (state, action: PayloadAction<ChangeUserPasswordSendData>) => {
      return {
        ...state,
      };
    },
    getUserProfile: state => {
      return {
        ...state,
      };
    },
    getUserProfileSucceed: (state, action: PayloadAction<UserProfileModel>) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    setUserProfile: (state, action: PayloadAction<UserProfileModel>) => {
      return {
        ...state,
      };
    },
    getUserProfileNotifications: state => {
      return {
        ...state,
      };
    },
    getUserProfileNotificationsSucceed: (state, action: PayloadAction<UserProfileNotificationsModel>) => {
      return {
        ...state,
        notifications: action.payload.notifications,
        bots: action.payload.telegramBots,
      };
    },
    setUserProfileNotifications: (state, action: PayloadAction<UserProfileNotificationsModel>) => {
      return {
        ...state,
      };
    },
  },
});

export const { changePassword, getUserProfileNotifications, setUserProfileNotifications, setUserProfile, getUserProfile } =
  userProfileSlice.actions;
export const selectNotificationSettingsInUserProfile = (state: RootState) => state.app.userProfileState.notifications;
export const selectNotificationBotsInUserProfile = (state: RootState) => state.app.userProfileState.bots;
export const selectOrganizationList = (state: RootState) => state.app.sidebarState.organisationList;
export const selectCurrentOrganization = (state: RootState) => state.app.organisationState.organisation?.name;
export const selectUserProfileName = (state: RootState) => state.userState;
export default userProfileSlice.reducer;
