import { useBrowserFitContent } from "../../../LayoutHelper/ResolutionHooks";
import React from "react";
import { useAppSelector } from "../../../../../common/state/store";
import { selectFlowList } from "../../../../automation/AutomationSlice";
import { useSearchParams } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
//import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { TriggerListContainer } from "../../../../automation/components/TriggerListContainer";
import { TABLET_VIEW } from "../../../LayoutHelper/ResolutionConst";

export const TriggersPage = () => {
  const browserFill = useBrowserFitContent();

  const flowList = useAppSelector(selectFlowList);
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";

  if (!flowList && search === "") {
    return (
      <Flex flexDirection={"column"} height={"100%"}>
        <Box bg="bgLight" height={"100%"}>
          <TriggerListContainer />
        </Box>
      </Flex>
    );
  }

  if (window.innerWidth <= TABLET_VIEW) {
    return (
      <Flex flexDirection={"column"} height={"100%"}>
        <Box bg="bgLight" height={window.innerHeight - 60 - 120}>
          <TriggerListContainer />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <Box bg="bgLight" height={browserFill}>
        <TriggerListContainer />
      </Box>
    </Flex>
  );
};
