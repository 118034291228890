import { ButtonModel } from "../../../../common/AppButtonsModel";
import { BroadcastFormModel } from "../../BroadcastModel";

export const addButton =
  (values: BroadcastFormModel, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) =>
  (newButton: ButtonModel) => {
    setFieldValue("message.buttons", [...values.message.buttons, newButton]);
  };

export const editButton =
  (values: BroadcastFormModel, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) =>
  (button: ButtonModel) => {
    setFieldValue(
      "message.buttons",
      values.message.buttons.map(buttonItem => (buttonItem.id === button.id ? button : buttonItem)),
    );
  };

export const deleteButton =
  (values: BroadcastFormModel, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) =>
  (buttonId: string) => {
    setFieldValue(
      "message.buttons",
      values.message.buttons.filter(button => button.id !== buttonId),
    );
  };
export const setButtons =
  (setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void) => (buttons: ButtonModel[]) => {
    setFieldValue("message.buttons", buttons);
  };
