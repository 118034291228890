import s from "./ActionNode.module.scss";
import { v1 as uuidv1 } from "uuid";
import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import {
  Connection,
  Handle,
  NodeProps,
  Position,
  ReactFlowState,
  useReactFlow,
  useStore,
  useUpdateNodeInternals,
  Viewport,
} from "reactflow";
import {
  ButtonsContainerFlowAction,
  CalendarContainerFlowAction,
  FlowActionModel,
  FlowActionType,
  NodeDefaultSize,
  NodeModel,
  NodeType,
  PaymentFlowActionModel,
  SendAudioFlowActionModel,
  SendTextFlowActionModel,
} from "../../FlowBuilderModel";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import {
  editNode,
  selectFlow,
  selectNodeEditorState,
  selectNodeValidationState,
  setEdges,
  setNodeEvent,
  setNodes,
} from "../../FlowBuilderSlice";
import SendMessageNode from "./SendMessageNode/SendMessageNode";
import QuestionMessageNode from "./QuestionMessageNode/QuestionMessageNode";
import ImageMessageNode from "./ImageMessageNode/ImageMessageNode";
import DocumentMessageNode from "./DocumentMessageNode/DocumentMessageNode";
import CurlMessageNode from "./CurlMessageNode/CurlMessageNode";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copyIconMenu.svg";
import { ActionNodeProps } from "./ActionNodeProps";
import { VideoMessageNode } from "./VideoMessageNode/VideoMessageNode";
import { AudioMessageNode } from "./AudioMessageNode/AudioMessageNode";
import { useTranslation } from "react-i18next";
import { getRandomRange } from "../../../../common/utils";
import { ConditionNode } from "./ConditionNode/ConditionNode";
import { SystemActionNode } from "./SystemActionNode/SystemActionNode";
import { DelayActionNode } from "./DelayActionNode/DelayActionNode";
import { FlowMarkupTypeDiscriminator, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { InlineKeyboardMarkupModel, ReplyKeyboardMarkupModel } from "../../../../common/AppButtonsModel";
import { AssignNode } from "./AssignNode/AssignNode";
import { StartFlowNode } from "./StartFlowNode/StartFlowNode";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { getButtonsWithExactType } from "../../utils";
import { AudioPlayer } from "../../../../UI/molecules/audioPlayer/AudioPlayer";
import { AppSettings } from "../../../../common/AppSettings";
import CreationMenuNode from "../CreationMenuNode/CreationMenuNode";
import { CalendarNode } from "./CalendarNode/CalendarNode";
import { PaymentNode } from "./PaymentNode/PaymentNode";
import CartNode from "./CartNode/CartNode";

const NodeTypeName = {
  SendTextFlowAction: "Send Message",
  QuestionFlowAction: "Question Message",
  SendPhotoFlowAction: "Image Message",
  SendDocumentFlowAction: "Document Message",
  CurlFlowAction: "Http Message",
  ConditionFlowAction: "Condition",
  SendVideoFlowAction: "Video Message",
  SendAudioFlowAction: "Audio Message",
  DelayFlowAction: "Delay",
  SystemActionFlowAction: "System Action",
  SetVariableValueFlowAction: "Set variable value",
  PutItemIntoArrayFlowAction: "Put item into array",
  TakeItemFromArrayFlowAction: "Take item from array",
  AssignFlowAction: "Assign",
  StartSubFlowAction: "Start Flow",
  CreationMenuAction: "Menu",
  SendAppointmentFlowAction: "Calendar",
  SendPaymentFlowAction: "Payment",
  ShoppingCartFlowAction: "Catalog",
} as const;

type NodeCopy = NodeModel & {
  data: FlowActionModel;
};

declare const appSettings: AppSettings;

const connectionNodeIdSelector = (state: ReactFlowState) => state.connectionNodeId;
const zoomSelector = (s: ReactFlowState) => s.transform[2];
const multipleSourceHandleNodeTypes = [FlowActionType.CurlFlowAction, FlowActionType.ConditionFlowAction];
const noSourceHandleNodeTypes = [FlowActionType.StartSubFlowAction];

function ActionNode(props: NodeProps<FlowActionModel>) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const { id, data } = props;
  const { getViewport } = useReactFlow();
  const [isButtonBarActive, setButtonBarActive] = useState<boolean>(false);
  const zoom = useStore(zoomSelector);
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectFlow);
  const nodeValidationState = useAppSelector(selectNodeValidationState);
  const nodeId = useAppSelector(selectNodeEditorState)?.id;
  const countNodes = flow?.nodes;

  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.NodeCountPerFlow);
  const { MaxCount, IsUnlimited } = accessability;
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== id;
  const targetHandleStyle = { zIndex: isTarget ? 3 : -1 };
  const updateNodeInternals = useUpdateNodeInternals();
  const [blockClick, setBlockClick] = useState(false);

  function getTypeDiscriminator(reply: InlineKeyboardMarkupModel | ReplyKeyboardMarkupModel) {
    if (reply.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup) {
      return FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup;
    } else {
      return FlowMarkupTypeDiscriminator.InlineKeyboardMarkup;
    }
  }

  useEffect(() => {
    const flowAction = data as ButtonsContainerFlowAction;
    if (flowAction?.replyMarkup) {
      updateNodeInternals(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!flow) {
    return <></>;
  }

  const nodes = flow.nodes;
  const edges = flow.edges;
  const nodeItem = flow.nodes.find(x => x.id === id);

  if (!nodeItem) {
    return <></>;
  }

  const node: NodeModel = {
    id: nodeItem.id,
    type: NodeType.Action,
    flowAction: nodeItem.data,
    position: nodeItem.position,
  };

  const onNodeDelete = () => {
    const updatedNodes = nodes.filter(el => el.id !== id);
    const updatedEdges = edges.filter(el => el.target !== id && el.source !== id);
    if (edges.find(el => el.source === id)?.target === "creation_menu") {
      const RemoveMenuNodes = updatedNodes.filter(el => el.id !== "creation_menu");
      dispatch(setNodes(RemoveMenuNodes));
      dispatch(setEdges(updatedEdges));
    } else {
      dispatch(setNodes(updatedNodes));
      dispatch(setEdges(updatedEdges));
    }
  };

  const onNodeCopy = () => {
    const desiredNode = nodes.find(el => el.id === id);
    const viewport = getViewport();

    if (!desiredNode?.data) return;
    if (!(desiredNode?.data as ButtonsContainerFlowAction).replyMarkup) {
      const updatedNode = {
        ...desiredNode,
        id: uuidv1(),
        position: getClonedNodePosition(viewport),
        selected: false,
        flowAction: desiredNode?.data as SendTextFlowActionModel,
        data: desiredNode?.data,
      };

      const updatedNodes = [...nodes];
      updatedNodes.push(updatedNode);
      dispatch(setNodes(updatedNodes));
      dispatch(editNode(updatedNode as NodeModel));
      return;
    }
    const action = (desiredNode as NodeCopy).data as ButtonsContainerFlowAction;
    const replyMarkup =
      action.replyMarkup?.typeDiscriminator === FlowMarkupTypeDiscriminator.InlineKeyboardMarkup
        ? (action.replyMarkup as InlineKeyboardMarkupModel)
        : (action.replyMarkup as ReplyKeyboardMarkupModel);
    const replyMarkupButtons = getButtonsWithExactType(replyMarkup, action);
    const newReplyMarkupButtons = replyMarkupButtons?.map(el => {
      return el.map(elem => {
        return {
          ...elem,
          id: uuidv1(),
        };
      });
    });
    const flowActionWithNewButtons = {
      ...((desiredNode as NodeCopy).data as SendTextFlowActionModel),
      text: ((desiredNode as NodeCopy).data as SendTextFlowActionModel).text ?? "",
      customVariableIds: ((desiredNode as NodeCopy).data as SendTextFlowActionModel).customVariableIds ?? undefined,
      replyMarkup: {
        buttons: newReplyMarkupButtons,
        typeDiscriminator: getTypeDiscriminator(replyMarkup),
      },
    };

    const updatedNode = {
      ...desiredNode,
      id: uuidv1(),
      position: getClonedNodePosition(viewport),
      selected: false,
      flowAction: flowActionWithNewButtons,
      data: flowActionWithNewButtons,
    };

    const updatedNodes = [...nodes];
    updatedNodes.push(updatedNode);
    dispatch(setNodes(updatedNodes));
    dispatch(editNode(updatedNode as NodeModel));
  };

  const isValidButtonConnection = (connection: Connection) => {
    return !edges.some(x => x.sourceHandle === connection.sourceHandle);
  };

  const isValidConnection = (connection: Connection) => {
    return !edges.some(x => x.source === connection.source && (!x.sourceHandle || x.sourceHandle === "common-source"));
  };

  const isEdgeInButtonHandle = (handleId: string) => {
    return !!flow?.edges.find(el => el.sourceHandle === handleId);
  };

  const isEdgeInHandle = (nodeId: string, sourceHandle?: string) => {
    if (flow?.edges.find(el => el.source === nodeId && el.sourceHandle === (sourceHandle ?? "common-source"))) {
      return true;
    }
    return false;
  };

  const hasButtons = () => {
    const replyMarkup = (actionData.node.flowAction as ButtonsContainerFlowAction).replyMarkup;
    if (!replyMarkup) {
      return false;
    }
    const checkButtons = (replyMarkup as InlineKeyboardMarkupModel).buttons;
    const filterNotValidButtons = checkButtons.filter(buttons => !buttons.length);
    if (!filterNotValidButtons) {
      return false;
    }

    return !!checkButtons;
  };

  const actionData: ActionNodeProps = {
    node,
    isValidButtonConnection,
    isValidConnection,
    isEdgeInButtonHandle,
    isEdgeInHandle,
  };

  const makeNodeComponent = (typeDiscriminator: FlowActionType | undefined) => {
    switch (typeDiscriminator) {
      case FlowActionType.SendTextFlowAction:
        return <SendMessageNode actionData={actionData} />;
      case FlowActionType.QuestionFlowAction:
        return <QuestionMessageNode actionData={actionData} />;
      case FlowActionType.SendPhotoFlowAction:
        return <ImageMessageNode actionData={actionData} />;
      case FlowActionType.SendDocumentFlowAction:
        return <DocumentMessageNode actionData={actionData} />;
      case FlowActionType.CurlFlowAction:
        return <CurlMessageNode actionData={actionData} />;
      case FlowActionType.SendVideoFlowAction:
        return <VideoMessageNode actionData={actionData} />;
      case FlowActionType.SendAudioFlowAction:
        return (
          <AudioMessageNode
            actionData={actionData}
            audioPlayer={
              <AudioPlayer
                link={appSettings.FileEndpoint + (data as SendAudioFlowActionModel)?.fileInfo?.id}
                name={(data as SendAudioFlowActionModel)?.fileInfo?.name}
                blockClick={blockClick}
                setBlockClick={setBlockClick}
              />
            }
          />
        );
      case FlowActionType.ConditionFlowAction:
        return <ConditionNode actionData={actionData} />;
      case FlowActionType.SystemActionFlowAction:
      case FlowActionType.SetVariableValueFlowAction:
      case FlowActionType.PutItemIntoArrayFlowAction:
      case FlowActionType.TakeItemFromArrayFlowAction:
        return <SystemActionNode actionData={actionData} />;
      case FlowActionType.DelayFlowAction:
        return <DelayActionNode actionData={actionData} />;
      case FlowActionType.AssignFlowAction:
        return <AssignNode actionData={actionData} />;
      case FlowActionType.StartSubFlowAction:
        return <StartFlowNode actionData={actionData} />;
      case FlowActionType.SendAppointmentFlowAction:
        return <CalendarNode actionData={actionData} />;
      case FlowActionType.SendPaymentFlowAction:
        return <PaymentNode actionData={actionData} />;
      case FlowActionType.ShoppingCartFlowAction:
        return <CartNode actionData={actionData} />;
      default:
        return <>ActionNode: Unhandled node time</>;
    }
  };

  const typeDiscriminator = data ? data.typeDiscriminator : undefined;
  const Component = makeNodeComponent(typeDiscriminator);
  const isMultipleSourceHandleType = typeDiscriminator && multipleSourceHandleNodeTypes.includes(typeDiscriminator);
  const isNoSourceHandleType = typeDiscriminator && noSourceHandleNodeTypes.includes(typeDiscriminator);

  const deleteNode = () => {
    if (typeDiscriminator) {
      dispatch(
        setNodeEvent({
          eventType: "deleteConfirmationPopup",
          eventData: {
            title: "Node",
            itemTitle: NodeTypeName[typeDiscriminator],
            onDelete: onNodeDelete,
          },
        }),
      );
    }
  };

  const getClonedNodePosition = (viewport: Viewport) => {
    const marginLeft = (1000 - NodeDefaultSize.width * viewport.zoom) / 2;
    const marginTop = (500 - NodeDefaultSize.height * viewport.zoom) / 2;

    const convertedX = (-viewport.x + marginLeft + getRandomRange(20)) / viewport.zoom;
    const convertedY = (-viewport.y + marginTop + getRandomRange(20)) / viewport.zoom;
    return { x: convertedX, y: convertedY };
  };

  const getScaleNodeButtons = () => {
    if (zoom > 0.6) {
      return 1;
    }
    const zoomMaxVars = {
      "0": 3,
      "0.5": 2,
    };
    const roundZoomString = getRoundZoom(zoom).toString();
    return zoomMaxVars[roundZoomString as keyof typeof zoomMaxVars] - zoom;
  };

  const getRoundZoom = (zoom: number) => {
    const zoomFractionValue = zoom % 1;
    if (zoomFractionValue > 0.5) {
      zoom = Math.ceil(zoom);
      return zoom;
    }
    return Math.round(zoom * 2) / 2;
  };

  const getButtonBarMargin = () => {
    const zoomValue = zoom;
    if (zoomValue > 0 && zoom < 0.26) {
      return "-40px";
    }
    if (zoomValue < 0.5) {
      return "-10px";
    }
    return "0px";
  };

  if (typeDiscriminator === FlowActionType.CreationMenuAction) {
    return <CreationMenuNode />;
  }
  return (
    <div onMouseLeave={() => setButtonBarActive(false)} onClick={e => e.stopPropagation()} className={s.wrapper}>
      {isButtonBarActive && (
        <Box
          onClick={e => e.stopPropagation()}
          className={s.buttonBar}
          w="77px"
          h="37px"
          mt={`${getButtonBarMargin()}`}
          transform={`scale(${getScaleNodeButtons()})`}
        >
          {!IsUnlimited && countNodes && countNodes.length >= MaxCount + 1 ? (
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Box className={s.iconBlock}>
                      <Icon as={CopyIcon} color="black" boxSize="28px" />
                    </Box>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to add more nodes in the Flow")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : (
            <Box className={s.iconBlock}>
              <Icon as={CopyIcon} onClick={() => onNodeCopy()} boxSize="28px" color="black" />
            </Box>
          )}
          <Box className={s.iconBlock}>
            <Icon as={TrashIcon} onClick={deleteNode} boxSize="28px" color="red" />
          </Box>
        </Box>
      )}

      <Flex
        direction="column"
        justify="flex-end"
        alignItems="center"
        onClick={() => {
          if (!blockClick) {
            dispatch(editNode(actionData.node));
          }
        }}
        className={`${s.nodeHoverWrapper} ${nodeId === id ? s.activeNode : ""}`}
      >
        <Box
          onMouseEnter={() => setButtonBarActive(true)}
          className={`${s.node} ${props.id === nodeValidationState.nodeId && nodeValidationState.flowId && s.error}
          ${isNoSourceHandleType && s.noSourceHandleNode}  ${
            (actionData.node.flowAction?.typeDiscriminator === FlowActionType.SendPaymentFlowAction ||
              actionData.node.flowAction?.typeDiscriminator === FlowActionType.ShoppingCartFlowAction) &&
            s.paymentNode
          }`}
        >
          {Component}
          {hasButtons() && (actionData.node.flowAction as ButtonsContainerFlowAction)?.isFallback && (
            <>
              <Box mt="16px" display="flex" color="black" justifyContent="space-between">
                <Text as={Box}>{t("If the user reply is invalid")}</Text>
                <Flex>
                  <Text as={Box} color="darkGrey" marginRight="4px">
                    {t("editNodePopup.Retry")}
                  </Text>
                  <Text as={Box}>
                    {(actionData.node.flowAction as ButtonsContainerFlowAction)?.retryCount} {t("editNodePopup.time(s)")}
                  </Text>
                </Flex>
              </Box>
              {(actionData.node.flowAction as ButtonsContainerFlowAction)?.fallbackMessage && (
                <Box p="12px" paddingInline="16px" mt="12px" borderRadius="12px" bg="defaultGrey" whiteSpace="pre-wrap">
                  {(actionData.node.flowAction as ButtonsContainerFlowAction)?.fallbackMessage}
                </Box>
              )}
            </>
          )}
          {actionData.node.flowAction?.typeDiscriminator === FlowActionType.SendAppointmentFlowAction &&
            (actionData.node.flowAction as CalendarContainerFlowAction)?.isFallback && (
              <>
                <Box mt="16px" display="flex" color="black" justifyContent="space-between">
                  <Text as={Box}>{t("If the user reply is invalid")}</Text>
                  <Flex>
                    <Text as={Box} color="darkGrey" marginRight="4px">
                      {t("editNodePopup.Retry")}
                    </Text>
                    <Text as={Box}>
                      {(actionData.node.flowAction as ButtonsContainerFlowAction)?.retryCount} {t("editNodePopup.time(s)")}
                    </Text>
                  </Flex>
                </Box>
                {(actionData.node.flowAction as ButtonsContainerFlowAction)?.fallbackMessage && (
                  <Box p="12px" paddingInline="16px" mt="12px" borderRadius="12px" bg="defaultGrey" whiteSpace="pre-wrap">
                    {(actionData.node.flowAction as ButtonsContainerFlowAction)?.fallbackMessage}
                  </Box>
                )}
              </>
            )}
          {actionData.node.flowAction?.typeDiscriminator === FlowActionType.SendPaymentFlowAction &&
            (actionData.node.flowAction as PaymentFlowActionModel)?.isFallback && (
              <>
                <Box mt="16px" display="flex" color="black" justifyContent="space-between">
                  <Text as={Box}>{t("If the user reply is invalid")}</Text>
                  <Flex>
                    <Text as={Box} color="darkGrey" marginRight="4px">
                      {t("editNodePopup.Retry")}
                    </Text>
                    <Text as={Box}>
                      {(actionData.node.flowAction as ButtonsContainerFlowAction)?.retryCount} {t("editNodePopup.time(s)")}
                    </Text>
                  </Flex>
                </Box>
                {(actionData.node.flowAction as ButtonsContainerFlowAction)?.fallbackMessage && (
                  <Box p="12px" paddingInline="16px" mt="12px" borderRadius="12px" bg="defaultGrey" whiteSpace="pre-wrap">
                    {(actionData.node.flowAction as ButtonsContainerFlowAction)?.fallbackMessage}
                  </Box>
                )}
              </>
            )}
        </Box>

        {!isNoSourceHandleType && !isMultipleSourceHandleType && (
          <>
            <div
              className={`${s.nodeFooter} ${
                actionData.node.flowAction?.typeDiscriminator === FlowActionType.SendPaymentFlowAction && s.footerPayment
              }`}
            >
              {(actionData.node.flowAction?.typeDiscriminator === FlowActionType.SendPaymentFlowAction ||
                actionData.node.flowAction?.typeDiscriminator === FlowActionType.ShoppingCartFlowAction) && (
                <Flex>
                  <Handle
                    className={`${s.handleStyle} ${s.paymentHandle} ${
                      isEdgeInHandle(actionData.node.id, "success-source") ? s.active : ""
                    }`}
                    type="source"
                    position={Position.Right}
                    id="success-source"
                    isValidConnection={() => !isEdgeInHandle(actionData.node.id, "success-source")}
                    isConnectable={!isEdgeInHandle(actionData.node.id, "success-source")}
                    data-pw="handle"
                  />
                  <Text>
                    {actionData.node.flowAction?.typeDiscriminator === FlowActionType.SendPaymentFlowAction
                      ? t("Success")
                      : t("Submit Order")}
                  </Text>
                </Flex>
              )}
              {actionData.node.flowAction?.typeDiscriminator !== FlowActionType.ShoppingCartFlowAction && (
                <Flex>
                  <Handle
                    className={`${s.handleStyle} ${isEdgeInHandle(actionData.node.id) ? s.active : ""}`}
                    type="source"
                    position={Position.Right}
                    id="common-source"
                    isValidConnection={actionData.isValidConnection}
                    isConnectable={!isEdgeInHandle(actionData.node.id)}
                    data-pw="handle"
                  />
                  <Text color="blue">
                    {hasButtons() || actionData.node.flowAction?.typeDiscriminator === FlowActionType.SendPaymentFlowAction
                      ? t("Default")
                      : t("Next step")}
                  </Text>
                </Flex>
              )}
            </div>
          </>
        )}
        <Handle
          className={s.nodeConnector}
          style={targetHandleStyle}
          type="target"
          position={Position.Left}
          id="common-target"
          data-pw="handle"
        />
      </Flex>
    </div>
  );
}

export default ActionNode;
