import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DeleteConfirmationPopupModel } from "../../FlowBuilderModel";

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & DeleteConfirmationPopupModel;

export const DeleteConfirmationPopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const onDeleteClick = () => {
    props?.onDelete?.();
    props.onClose();
  };

  return (
    <Modal isOpen={!!props?.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {ct("Delete")} {props?.title && t(`deletePopup.${props.title}`)}
        </ModalHeader>
        <ModalCloseButton data-pw="close-button" />
        <ModalBody paddingTop={"0"}>
          <Text variant="large">
            {t("deletePopup.You are going to delete")} <b>{props?.itemTitle && t(props.itemTitle)}</b>
          </Text>
        </ModalBody>
        <ModalFooter paddingBottom="32px" justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={props.onClose} data-pw="cancel-button">
            {ct("Cancel")}
          </Button>
          <Button variant="dominoPrimaryRed" mr={3} onClick={onDeleteClick} data-pw="delete-button">
            {ct("Delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
