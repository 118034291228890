import { Flex, Icon, Link, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ButtonType, ButtonTypeDiscriminator, InlineKeyboardTypeDiscriminatorList } from "../../../../common/AppEnums";
import { ButtonModel, StaticButtonModel } from "../../../../common/AppButtonsModel";
import { ReactComponent as DynamicIcon } from "../../../../assets/icons/dynamicIcon.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/linkIcon.svg";
import { ReactComponent as payloadIcon } from "../../../../assets/icons/circleAlert.svg";
import s from "./ButtonsList.module.scss";
import { ExternalLinkIcon } from "@chakra-ui/icons";

interface Props {
  buttons: ButtonModel[];
}

export const ButtonsListView = ({ buttons }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });

  return (
    <>
      {buttons?.map(button => {
        if (InlineKeyboardTypeDiscriminatorList.includes(button.typeDiscriminator) || !button.typeDiscriminator) {
          return (
            <Popover trigger="hover" placement="auto" key={button.id}>
              <PopoverTrigger key={button.id}>
                <div key={button.id} className={`${s.button} ${s.disabledView}`}>
                  <div data-pw="label" className={s.buttonName}>
                    {button.label}
                  </div>
                  {button.type === ButtonType.CallbackButton ? (
                    <Icon data-pw="payload" className={s.icon} pos="absolute" right="15px" boxSize="20px" as={payloadIcon} />
                  ) : (
                    <Icon data-pw="link" className={s.icon} pos="absolute" right="15px" boxSize="20px" as={LinkIcon} />
                  )}
                </div>
              </PopoverTrigger>
              <PopoverContent width={{ base: "250px", sm: "320px" }}>
                <PopoverArrow />
                <PopoverBody>
                  {button.type === ButtonType.CallbackButton && (
                    <Flex data-pw="payload-popover" gap="4px">
                      <Text color={"#8592A3"}>{t("Payload")}:</Text>
                      <Text data-pw="payload-text" noOfLines={1}>
                        {button.payload}
                      </Text>
                    </Flex>
                  )}
                  {button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageUrlButton ||
                    (button.type === ButtonType.UrlButton && (
                      <Flex>
                        <Link
                          data-pw="link-popover"
                          isExternal
                          noOfLines={2}
                          color={"#325EE6"}
                          zIndex={15}
                          href={(button as StaticButtonModel).url}
                        >
                          {(button as StaticButtonModel).url}
                          <ExternalLinkIcon data-pw="link-icon" mb={"3px"} mx="2px" />
                        </Link>
                      </Flex>
                    ))}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          );
        } else if (button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageDynamicButton) {
          return (
            <div key={button.id} className={`${s.button} ${s.disabled}`}>
              <Flex data-pw="dynamic-button" width="100%" justify="center" align="center" color="inherit" fill="inherit">
                <Icon data-pw="dynamic-icon" color="inherit" fill="inherit" boxSize="20px" mr="4px" as={DynamicIcon} />
                <Text data-pw="dynamic-text" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {t("Dynamic buttons")}
                </Text>
              </Flex>
            </div>
          );
        }
        return <></>;
      })}
    </>
  );
};
