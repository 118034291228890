import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useCallbackPrompt } from "../../../../common/hooks/useCallbackPrompt";
import s from "./LeaveNewFlowConfirmationPopup.module.scss";

type Props = {
  dirty: boolean;
  onDiscardChanges: (confirmNavigation: () => void) => void;
};

export const LeaveNewFlowConfirmationPopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(props.dirty);

  return (
    <Modal isOpen={showPrompt} onClose={cancelNavigation} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent className={s.modalContent}>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        />
        <ModalBody paddingTop={"0"} data-pw="leave-modal">
          <Text variant="h1" textAlign="center">
            {t("Do you really want to Exit without saving?")}
          </Text>
        </ModalBody>
        <ModalFooter className={s.modalFooter}>
          <Button variant="dominoOutlineViolet" mr={3} onClick={cancelNavigation} data-pw="cancel-button">
            {ct("Cancel")}
          </Button>
          <Button variant="dominoPrimaryRed" onClick={() => props.onDiscardChanges(confirmNavigation)} data-pw="discard-button">
            {t("Discard changes")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
