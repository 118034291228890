import { useEffect, useLayoutEffect, useState } from "react";
import { MOBILE_VIEW, TABLET_VIEW } from "./ResolutionConst";
import { isChrome, isFirefox } from "react-device-detect";

export function useMobileView() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      setIsMobileView(window.innerWidth < MOBILE_VIEW);
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth < MOBILE_VIEW) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  return isMobileView;
}

export function useTabletView() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      setIsMobileView(window.innerWidth <= TABLET_VIEW);
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= TABLET_VIEW) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  return isMobileView;
}

export function useNowWidthView() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nowWidth, setNowWidth] = useState(0);

  useEffect(() => {
    if (typeof window !== undefined) {
      setNowWidth(window.innerWidth);
    }
  }, []);

  const handleResize = () => {
    setNowWidth(window.innerWidth);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  return nowWidth;
}

export function useNowHeightView() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nowHeight, setNowHeight] = useState(0);

  useEffect(() => {
    if (typeof window !== undefined) {
      setNowHeight(window.innerHeight);
    }
  }, []);

  const handleResize = () => {
    setNowHeight(window.innerHeight);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  return nowHeight;
}

export function useBrowserFitContent() {
  if (isFirefox) {
    return "-moz-available";
  }
  if (isChrome) {
    return "-webkit-fill-available";
  }
  return "fill-available";
}

export function useExtendedSubHeader(exists: boolean) {
  if (exists) {
    return window.innerHeight - 60 - 120;
  } else {
    return window.innerHeight - 60;
  }
}

export const handleScroll = () => {
  if (window.document.documentElement.scrollHeight > 0 && window.innerWidth <= TABLET_VIEW) {
    window.document.documentElement.scrollTo(0, 1);
  }
};

export const useHandleScroll = () => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
};
