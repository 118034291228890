import React, { useEffect } from "react";
import { getOrganisationIdFromStorage } from "../../common/BaseApi";
import { AppSettings } from "../../common/AppSettings";
import { getTokenFromStorage, getCookie, getCookieString } from "../../common/auth/AuthRepo";

import { TokenModel } from "../../common/auth/AuthModel";

declare const appSettings: AppSettings;

export const IFrameContainer = () => {
  const organizationIdInParams = new URLSearchParams(window.location.search).get("organizationId");

  interface MessageModel {
    type: string;
    message: {
      token: TokenModel | null;
      organizationId: string | null;
      language: string | undefined;
    } | null;
  }
  const sendMessage = () => {
    const token = getTokenFromStorage();
    const tokenCookie = getCookie("token");
    const language = localStorage.getItem("i18nextLng");
    const organizationId = !!organizationIdInParams ? organizationIdInParams : getOrganisationIdFromStorage();
    const organizationCookie = getCookieString("organizationId");
    const languageMessage = language?.substring(0, 2);
    const tokenMessage: TokenModel | null = token?.accessToken ? token : null;
    const organisationIdMessage: string | null = !!organizationId ? organizationId : organizationCookie;
    let sentMessage: MessageModel = {
      type: "token",
      message: null,
    };
    if (token && (organizationId || organizationCookie)) {
      sentMessage = {
        type: "token",
        message: { token: tokenMessage, organizationId: organisationIdMessage, language: languageMessage },
      };
      window.parent.postMessage(sentMessage, appSettings.billingPortalUrl);
      return;
    } else if (!token && tokenCookie) {
      sentMessage = {
        type: "token",
        message: { token: tokenCookie, organizationId: organizationCookie, language: languageMessage },
      };
      window.parent.postMessage(sentMessage, appSettings.billingPortalUrl);
      return;
    }
    window.parent.postMessage(sentMessage, appSettings.billingPortalUrl);
  };

  useEffect(() => {
    sendMessage();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
