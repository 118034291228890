import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import { getTriggerList, selectTriggerList } from "../AutomationSlice";
import { Box } from "@chakra-ui/react";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../../sidebar/SidebarSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EmptyBotView } from "../../emptyView/EmptyBotView";
import { EmptyListView } from "../../emptyView/EmptyListView";
import { TriggerList } from "./TriggerList";
import React, { useEffect } from "react";
import { TABLET_VIEW } from "../../layout/LayoutHelper/ResolutionConst";
import { PaginatorWithSearchParams } from "../../../common/paginator/components/PaginatorWithSearchParams";

export const TriggerListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const triggerList = useAppSelector(selectTriggerList);

  const [searchParams] = useSearchParams("");
  const currentPage = Number(searchParams.get("page") ?? 1);

  useEffect(() => {
    if (currentBot) {
      dispatch(getTriggerList({ botId: currentBot.id, page: currentPage }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentBot]);

  if (!currentBot && !isCurrentBotLoading) {
    return <EmptyBotView title={t("No flows")} description={t("Create or connect an existing bot to add your first Flow.")} />;
  }

  if (!triggerList) {
    return <></>;
  }

  if (triggerList?.totalItems === 0) {
    return (
      <EmptyListView
        title={t("Create your first Flow")}
        description={t("Automate communication with your contacts by creation of chatbot Flows.")}
        createButtonText={t("Create New Flow")}
        createButtonOnClick={() => navigate("new")}
      />
    );
  }

  return (
    <>
      <Box padding="0px 16px 32px 16px" pb={"12px"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
        {triggerList && currentBot && (
          <>
            <TriggerList currentPage={currentPage} triggerList={triggerList} currentBot={currentBot} />
            {window.innerWidth >= TABLET_VIEW ? (
              <PaginatorWithSearchParams data={triggerList} typeOfPage={"TriggerList"} />
            ) : (
              <PaginatorWithSearchParams data={triggerList} typeOfPage={"TriggerList"} />
            )}
          </>
        )}
      </Box>
    </>
  );
};
