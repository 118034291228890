import { AppSettings } from "../AppSettings";
import { RefreshTokenModel } from "./AuthModel";
import { getOrganisationIdFromStorage } from "../BaseApi";

declare const appSettings: AppSettings;

export const updateToken = async (data: RefreshTokenModel) => {
  const organizationId = getOrganisationIdFromStorage();

  const res = await fetch(`${appSettings.apiIdentityProvider}/refreshtoken`, {
    method: "POST",
    headers: {
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": "application/vnd.domino.v1+json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "X-Domino-Organization": organizationId ?? "",
    },
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
  });

  if (res.status === 200) {
    return res.json();
  }
  return {
    responseStatus: res.status,
  };
};
