import { Box } from "@chakra-ui/react";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { SetBot } from "../../../../../common/setBot/SetBot";

export const SetBotPage = () => {
  return (
    <Box>
      <Loading />
      <SetBot />
    </Box>
  );
};
