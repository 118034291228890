import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  ListItem,
  Image,
  List,
  Box,
  Alert,
  AlertIcon,
  Link,
  Badge,
} from "@chakra-ui/react";
import botImage from "../../../../assets/images/botImage.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import s from "../../AddBot.module.scss";
import { useAppSelector } from "../../../../common/state/store";
import { selectIsConnectionCompleted } from "../../AddBotSlice";
import { selectTrialPlan } from "../../../organisation/OrganisationSlice";
import { TrialModal } from "../../../organisation/components/TrialPlan/TrilalModal/TrialModal";

interface Props {
  onConnect: (token: string) => void;
}

export const ExistedBot = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [token, setToken] = useState<string>("");
  const navigate = useNavigate();
  const isConnectionCompleted = useAppSelector(selectIsConnectionCompleted);
  const trialPlan = useAppSelector(selectTrialPlan);
  const [isOpenModal, setIsOpenModal] = useState(true);

  return (
    <Flex
      display={{ base: "grid", md: "flex" }}
      w={"100%"}
      h={"auto"}
      overflow={{ base: "auto", md: "hidden" }}
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <Flex
        bg="bgLight"
        justifyContent={"center"}
        p={"32px"}
        w={{ base: "100%", md: "52%", xl: "42%" }}
        fontSize="15px"
        flexDirection="column"
        overflow={{ base: "visible", md: "auto" }}
        className={s.existedBotBlock}
      >
        <Heading mb={{ base: "8px", md: "15px" }} fontSize={"20px"} fontWeight={"400"}>
          {t("connectExisting.Connect existing Telegram bot")}
        </Heading>
        <Alert my={"12px"} p={"20px"} status="warning" flexShrink={0}>
          <AlertIcon />
          {t("connectExisting.Please don’t use the same token for different services")}.
        </Alert>
        {t("connectExisting.This instruction helps you to connect the Telegram bot")}.
        <List spacing={5} my={"15px"}>
          <ListItem>
            <Flex alignItems={"flex-start"} w={"90%"}>
              <Badge variant="dominoNumberList">1</Badge>
              <Box ml={"15px"}>
                {t("connectExisting.Open")}{" "}
                <Link
                  color={"#0084FF"}
                  _hover={{ color: "mainPurple", textDecoration: "underline" }}
                  href="https://telegram.me/BotFather"
                  isExternal
                >
                  @BotFather
                </Link>{" "}
                {t("connectExisting.in Telegram and click /start")}
              </Box>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex alignItems={"flex-start"} w={"85%"}>
              <Badge variant="dominoNumberList">2</Badge>
              <Box ml={"15px"}>{t("connectExisting.Send /mybots and choose the bot you want to connect from the list")}</Box>
            </Flex>
          </ListItem>
          <ListItem>
            <Flex alignItems={"flex-start"} w={"85%"}>
              <Badge variant="dominoNumberList">3</Badge>
              <Box ml={"15px"}>{t("connectExisting.Copy its API token and paste it here")}</Box>
            </Flex>
          </ListItem>
        </List>
        <FormControl mt={"10px"}>
          <FormLabel>{t("Telegram bot token")}</FormLabel>
          <Input value={token} bg={"white"} onChange={e => setToken(e.target.value)} placeholder={`${t("Enter token")}`} />
        </FormControl>
        <Flex w="100%" justifyContent="space-between" alignItems={"flex-end"}>
          <Button variant="dominoOutlineViolet" onClick={() => navigate("/addbot")}>
            {ct("Back")}
          </Button>
          <Button onClick={() => props.onConnect(token)} mt={"25px"} variant="dominoViolet" w={"150px"}>
            {t("Connect")}
          </Button>
        </Flex>
      </Flex>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        w={{ base: "100%", md: "48%", xl: "55%" }}
        h={{ base: "initial", md: "100%" }}
        p={"0 32px"}
        gridRow={1}
      >
        <Image w={"100%"} maxW={{ base: "240px", md: "460px" }} src={botImage} transform={" scale(-1, 1)"}></Image>
      </Flex>
      {isConnectionCompleted && !!trialPlan && trialPlan.isAvailable && (
        <TrialModal trialPlan={trialPlan} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
      )}
    </Flex>
  );
};
