import { formatDateTimeToView } from "../../../common/utils/formatDate";
import { fromNow } from "../../../common/utils/fromNow";
import { Text, Tooltip } from "@chakra-ui/react";

interface Props {
  lng: string;
  lastModifiedDate: string;
}

export const FormattedDateTooltip = (props: Props) => {
  return (
    <Tooltip placement="bottom-start" variant={"dominoLight"} label={formatDateTimeToView("Date", props.lastModifiedDate)}>
      <Text marginLeft="6px" variant="medium" noOfLines={1} data-pw="date">
        {fromNow(props.lastModifiedDate, props.lng)}
      </Text>
    </Tooltip>
  );
};
