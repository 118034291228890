import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import s from "./AddOperatorModal.module.scss";
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalBody,
  Box,
  Flex,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { getOrganisationUsers, selectOrganisationUsers } from "../../OrganisationSlice";
import { useState } from "react";
import { TeamMemberModel } from "../../OrganisationModel";
import { MultiSelectMenu } from "../../../multiSelectMenu/MultiSelectMenu";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  modalState: { includedOperators: TeamMemberModel[]; teamId: string };
  onApply: (assignedOperatorIds: string[], teamId: string) => void;
  onOperatorSearchChange: (value: string) => void;
}

export const AddOperatorModal = ({ isOpen, onClose, modalState, onApply, onOperatorSearchChange }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation.newOrganisationModal" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const operators = useAppSelector(selectOrganisationUsers);
  const multiSelectMenuOptions = operators?.items
    .filter(el => !modalState.includedOperators.find(elem => elem.userId === el.userId))
    .map(el => {
      return { name: el.username, id: el.userId };
    });
  const [assignedOperatorIds, setAssignedOperatorIds] = useState<string[]>([]);

  const handleClose = () => {
    onClose();
  };

  const getAdditionalOperators = () => {
    if (operators) {
      dispatch(getOrganisationUsers({ page: operators.currentPage + 1 }));
    }
  };

  const onOperatorListChange = (operatorIds: string[]) => {
    setAssignedOperatorIds(operatorIds);
  };

  const handleOnApply = () => {
    onApply(assignedOperatorIds, modalState.teamId);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: "330px", sm: "460px" }}>
          <ModalHeader p="32px">
            <Text variant="h1">{t("Add Operators")}</Text>
          </ModalHeader>
          <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
            <CrossIcon />
          </ModalCloseButton>
          <ModalBody px="32px" py="0" className={s.modalBody}>
            <Box>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text variant="medium" mb={"8px"}>
                  {t("Select Operators from the list")}
                </Text>
              </Flex>
              <MultiSelectMenu
                isLastPage={true}
                options={multiSelectMenuOptions ?? []}
                getAdditionalItems={getAdditionalOperators}
                onChange={onOperatorListChange}
                searchProps={{ searchItemName: "Operators", onSearchTextChange: onOperatorSearchChange }}
              />
            </Box>
          </ModalBody>

          <ModalFooter p="32px">
            <Flex width="100%" justifyContent="space-between">
              <Button variant="dominoOutlineViolet" onClick={handleClose}>
                {ct("Cancel")}
              </Button>
              <Button
                onClick={handleOnApply}
                variant="dominoViolet"
                isDisabled={!assignedOperatorIds.length}
                data-pw="apply-button"
              >
                {ct("Apply")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
