/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiTokenModel, AuthModelMap } from "./AuthModel";
import { RootState } from "../state/store";

const initialState: AuthModelMap = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    tokenReceived: (state, action: PayloadAction<ApiTokenModel>) => {
      return {
        ...state,
        [action.payload.apiName]: {
          accessToken: action.payload.token,
          refresh: action.payload.refresh,
          isExpired: false,
          expiration: action.payload.expiration,
        },
      };
    },
    //commented for testing
    // tokenRejected: (state, action: PayloadAction<{ apiName: string; message: string }>) => {
    //   const newState = { ...state, [action.payload.apiName]: undefined };
    //   console.log(newState);

    //   return newState;
    // },
    // tokenRejected: (state, action: PayloadAction<{ apiName: string; message: string }>) => {
    //   const newState = { ...state, [action.payload.apiName]: undefined };
    //   console.log(newState);

    //   return newState;
    // },
    tokenRejected: (state, action: PayloadAction<{ apiName: string; message: string }>) => {
      const newState = { ...state };
      delete state[action.payload.apiName];
      return newState;
    },

    refreshToken: (state, action: PayloadAction<ApiTokenModel>) => {
      return {
        ...state,
      };
    },
    logout: (state, action: PayloadAction<{ inviteToken?: string }>) => {
      return {};
    },
  },
});
export const selectAuth = (state: RootState) => state.authState;

export const { getToken, tokenReceived, tokenRejected, logout, refreshToken } = authSlice.actions;

export default authSlice.reducer;
