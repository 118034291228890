import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import { Checkbox, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { DashboardFilterCheckboxTypeModel, DataMessagesStatisticModel, DataMessagesModel } from "../../DashboardModel";
import DashboardEmptyDataChart from "./DashboardEmptyDataChart/DashboardEmptyDataChart";
import DashboardZeroTotalChart from "./DashboardZeroTotalChart/DashboardZeroTotalChart";
import DashboardCustomTooltipChart from "./DashboardCustomTooltipChart/DashboardCustomTooltipChart";
import s from "../../DashboardContainer.module.scss";

interface Props {
  dataChart?: DataMessagesModel[] | DataMessagesStatisticModel[];
  label: string;
  total: number | undefined;
  labelCheckbox: string;
  origins: Array<DashboardFilterCheckboxTypeModel>;
}

export default function DashboardItem({ dataChart, label, total, labelCheckbox, origins }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<DashboardFilterCheckboxTypeModel[]>(origins);

  const updateTotalInOrigins = (origins: Array<DashboardFilterCheckboxTypeModel>) => {
    const updatedOrigins = [];
    for (let index = 0; index < origins.length; index++) {
      updatedOrigins.push({ ...selectedCheckboxes[index], total: origins[index].total });
    }
    return updatedOrigins;
  };

  useEffect(() => {
    setSelectedCheckboxes(updateTotalInOrigins(origins));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origins]);

  const variantLine = (n: number) => {
    switch (n) {
      case 0: {
        return { variant: "dominoBlue", color: "#0084FF" };
      }
      case 1: {
        return { variant: "dominoGreen", color: "#4EAA4A" };
      }
      case 2: {
        return { variant: "dominoOrange", color: "#FFC300" };
      }
      default: {
        return { variant: "dominoRed", color: "#FF0000" };
      }
    }
  };

  const changeSelectedCheckBox = (label: DashboardFilterCheckboxTypeModel, isAdd: boolean) => {
    let filtered: Array<DashboardFilterCheckboxTypeModel> = selectedCheckboxes;
    filtered = selectedCheckboxes.map(checkbox => {
      if (checkbox.label === label.label) {
        checkbox.checked = isAdd;
        return checkbox;
      }
      return checkbox;
    });
    setSelectedCheckboxes(filtered);
  };

  return (
    <Box mb={"24px"}>
      <Flex justifyContent={"space-between"} mb={"16px"}>
        <Text variant={"largeBold"} flex={0.905}>
          {t(label)}
        </Text>
        <Text textAlign={{ base: "right", md: "left" }} width={"182px"} variant={"largeBold"}>
          {t("Total")} {total}
        </Text>
      </Flex>
      <Flex className={s.flexContainerDashboard}>
        {dataChart ? (
          <>
            {total ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart className={s.dashboardItem} data={dataChart} margin={{ top: 38, right: 56, left: 20, bottom: 12 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tickMargin={8} dataKey="name" />
                  <YAxis type="number" domain={[0, 4]} />
                  <Tooltip content={<DashboardCustomTooltipChart active={false} dataChart={dataChart} />} />
                  {selectedCheckboxes.map((item, index) => {
                    if (item.checked) {
                      return (
                        <Line
                          dot={{ r: 0 }}
                          key={item.label}
                          type="monotone"
                          dataKey={item.label}
                          stroke={variantLine(index)?.color}
                        />
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <DashboardZeroTotalChart origins={selectedCheckboxes} dataChart={dataChart} />
            )}
          </>
        ) : (
          <DashboardEmptyDataChart />
        )}

        <Box className={s.CheckBoxContainer}>
          <Box>
            <Flex className={s.flexCheckBoxContainer} gap="16px" flexDirection="column">
              <Box>
                <Text variant={"preLargeBold"}> {t(labelCheckbox)}:</Text>
              </Box>
              <Stack className={s.stackCheckBox} spacing="10px" direction="column">
                {selectedCheckboxes.map((item, index) => (
                  <>
                    {dataChart ? (
                      <Checkbox
                        isChecked={item.checked}
                        isDisabled={!total}
                        onChange={e => changeSelectedCheckBox(item, e.target.checked)}
                        key={index}
                        variant={variantLine(index)?.variant}
                      >
                        {t(item.label)} {item.total}
                      </Checkbox>
                    ) : (
                      <Skeleton
                        key={index}
                        speed={0.5}
                        startColor="line"
                        endColor="bgLight"
                        borderRadius="4px"
                        height="24px"
                        width="80%"
                        border="1px solid"
                        borderColor="line"
                      />
                    )}
                  </>
                ))}
              </Stack>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
