import {
  Text,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Alert,
  Checkbox,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AlertIcon } from "../../../../assets/icons/alertIcon.svg";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";

interface Props {
  deleteBotPopupState: {
    id: string;
    title: string;
  };
  onClose: () => void;
  onDelete: () => void;
  isOpen: boolean;
}

export const BotRemovePopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "bot.botDeletePopup",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [checkboxState, setCheckBoxState] = useState(false);
  const trackEvent = useGAWithCustomParameters("BotDetails");

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent minW={"300px"} p={{ base: "32px 16px", md: "32px" }} w={"95%"} maxW={"460px"}>
        <ModalCloseButton />
        <ModalBody fontSize="15px" p="0">
          <Flex flexDir="column" justifyContent="space-between">
            <Text fontSize={{ base: "20px", md: "24px" }} lineHeight={{ base: "26px", md: "32px" }}>
              {t("Do you really want to remove")} <q>{props.deleteBotPopupState.title}</q> ?
            </Text>
            <Alert variant="dominoWarningAlertVariant" status="warning" alignItems="flex-start" mt="16px">
              <Icon as={AlertIcon} boxSize="24px" mr="21px" />
              <Text>
                {t("If you remove telegrom bot")}, <b> {t("all your conversations will be deleted")}.</b>{" "}
                {t("Your flows will be stopped. You can connect another Telegram bot later")}
              </Text>
            </Alert>
            <Checkbox mt="26px" colorScheme="green" isChecked={checkboxState} onChange={e => setCheckBoxState(e.target.checked)}>
              {t("I understand, that all conversations will be removed")}
            </Checkbox>
          </Flex>
        </ModalBody>

        <ModalFooter p="32px 0 0 0">
          <Flex w="100%" justifyContent="space-between" alignItems={"flex-end"}>
            <Button variant="outline" onClick={props.onClose}>
              {ct("Cancel")}
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              isDisabled={!checkboxState}
              onClick={() => {
                trackEvent("BotDelete");
                props.onDelete();
              }}
            >
              {t("Remove Bot")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
