import {
  Text,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { OperatorViewItem } from "./OperatorViewItem";
import { OperatorJoinModel } from "../../../chatList/ChatListModel";
import s from "../OperatorViewBox/OperatorViewBox.module.scss";
import { OperatorViewItemsList } from "./components/OperatorViewItemsList/OperatorViewItemsList";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  operators: OperatorJoinModel[];
}

export const OperatorViewBox = (props: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation("translation", { keyPrefix: "conversation" });
  const elemCounter = window.innerWidth <= 1920 ? 2 : 5;
  const extendedCounter = props.operators && elemCounter < props.operators.length ? props.operators.length - elemCounter : 0;

  return (
    <Flex className={s.operatorView}>
      <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} placement={"top"}>
        <PopoverTrigger>
          <Button
            onClick={onOpen}
            className={isOpen ? s.operatorViewMessageActive : s.operatorViewMessage}
            colorScheme="teal"
            size="xs"
          >
            {props.text}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>{t("Watching the chat room")}</PopoverHeader>
          <PopoverBody>
            <OperatorViewItemsList operators={props.operators} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Flex className={isOpen ? s.operatorViewItemsHidden : s.operatorViewItems}>
        {props.operators.slice(0, elemCounter).map(operators => {
          return <OperatorViewItem key={operators.operatorName} operatorName={operators.operatorName} />;
        })}
        {extendedCounter !== 0 && <Text className={s.operatorViewCounter}>+{extendedCounter}</Text>}
      </Flex>
    </Flex>
  );
};
