/* eslint-disable @typescript-eslint/no-unused-vars */

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TariffPlanItemsModel } from "../../common/tariffPlan/TariffPlanModel";
import { OrganisationUserModel } from "./UserManagementModel";
import { RootState } from "../../common/state/store";

export interface UserManagementState {
  impersonateError?: string;
  billingPlans?: TariffPlanItemsModel;
  userOrganisations?: OrganisationUserModel[];
}

const initialState: UserManagementState = {};

export const userManagementSlice = createSlice({
  name: "userProfileSlice",
  initialState,
  reducers: {
    impersonate: (state, action: PayloadAction<{ username: string }>) => {
      return {
        ...state,
      };
    },
    stopImpersonation: state => {
      return {
        ...state,
      };
    },
    getAllOrganisation: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getAllOrganisationCompleted: (state, action: PayloadAction<OrganisationUserModel[] | undefined>) => {
      return {
        ...state,
        userOrganisations: action.payload,
      };
    },
    getAllBillingPlans: state => {
      return {
        ...state,
      };
    },
    getAllBillingPlansCompleted: (state, action: PayloadAction<TariffPlanItemsModel>) => {
      return {
        ...state,
        billingPlans: action.payload,
      };
    },
    setSubscription: (state, action: PayloadAction<{ organizationId: string; BillingPlanId: string; EndDate: Date }>) => {
      return {
        ...state,
      };
    },
  },
});

export const { impersonate, stopImpersonation, getAllOrganisation, getAllBillingPlans, setSubscription } =
  userManagementSlice.actions;

export const selectUserOrganisations = (state: RootState) => state.app.userManagementState.userOrganisations;
export const selectBillingPlans = (state: RootState) => state.app.userManagementState.billingPlans;

export default userManagementSlice.reducer;
