import { Paginator } from "../Paginator";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { Box, ResponsiveValue } from "@chakra-ui/react";
import { OrganisationTeamsModel, OrganisationUsersModel } from "../../../components/organisation/OrganisationModel";
import { ContactListModel } from "../../../components/contactList/ContactListModel";
import { BotListModel } from "../../../components/sidebar/SidebarModel";
import { BroadcastListModel } from "../../../components/broadcastList/BroadcastListModel";
import { FlowListModel } from "../../../components/flowBuilder/FlowBuilderModel";
import { TriggerListModel } from "../../../components/automation/AutomationModel";
import { ContactVariableListModel } from "../../../components/contact/ContactModel";

interface Props {
  data:
    | OrganisationUsersModel
    | OrganisationTeamsModel
    | ContactListModel
    | BotListModel
    | BroadcastListModel
    | FlowListModel
    | TriggerListModel
    | ContactVariableListModel;
  typeOfPage: string;
  paddingRight?:
    | ResponsiveValue<number | string | "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset">
    | undefined;
  paddingLeft?: string;
}

export const PaginatorWithSearchParams = (props: Props) => {
  const { data, typeOfPage } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const filters = searchParams.get("filters") ?? "";
  const getPage = (page: number) => {
    const newParams: { page?: string; search?: string; filters?: string } = {};
    if (page > 1) {
      newParams.page = page.toString();
    }
    if (search !== "") {
      newParams.search = search;
    }
    if (filters !== "") {
      newParams.filters = filters;
    }
    setSearchParams(createSearchParams(newParams));
  };
  return (
    <Box position="sticky" bottom={0} width="100%">
      {data && (
        <Paginator
          getPage={getPage}
          data={data}
          typeOfPage={typeOfPage}
          paddingRight={props.paddingRight}
          paddingLeft={props.paddingLeft}
        />
      )}
    </Box>
  );
};
