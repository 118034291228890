import { Box, Flex, Text, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import s from "./ChatListPage.module.scss";
import { ChatListContainer } from "../../chatList/ChatListContainer";
import { ConversationContainer } from "../../conversation/ConversationContainer";
import { ContactInfoContainer } from "../../contactInfo/ContactInfoContainer";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ReactComponent as EmptyChatIcon } from "../../../assets/icons/emptyChat.svg";
import { useAppSelector } from "../../../common/state/store";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../../sidebar/SidebarSlice";
import { EmptyBotView } from "../../emptyView/EmptyBotView";
import { ReactComponent as EmptyBotImage } from "../../../assets/images/botImageHappy.svg";
import { selectChatList } from "../../chatList/ChatListSlice";
import { selectConversationInfo } from "../../conversation/ConversationSlice";

export const ChatPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "chatList" });
  const params = useParams();
  const currentBot = useAppSelector(selectCurrentBot);
  const conversation = useAppSelector(selectConversationInfo);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const chatList = useAppSelector(selectChatList);
  const [isDesktop] = useMediaQuery("(min-width: 1470px)");
  const [isTablet] = useMediaQuery("(min-width: 768px) and (max-width: 1470px)");
  const contactInfo = useDisclosure();
  const id = params["conversationId"];

  if (!currentBot && !isCurrentBotLoading) {
    return (
      <EmptyBotView
        title={t("No conversations")}
        description={t("Create or connect an existing bot to start messaging with your contacts.")}
        Image={EmptyBotImage}
      />
    );
  }

  return (
    <Box
      className={
        isDesktop
          ? `${s.chatPageLayout} ${!conversation?.contact?.id ? s.twoColumns : ""}`
          : `${isTablet ? s.chatPageLayout : s.chatPageLayoutMob}`
      }
    >
      <Box h={isDesktop ? "auto" : "100%"}>
        <ChatListContainer />
      </Box>

      {chatList && (
        <>
          {id ? (
            isDesktop ? (
              <>
                <Box className={s.conversationContainer}>
                  <ConversationContainer />
                </Box>
                {conversation?.contact?.id && (
                  <Box>
                    <ContactInfoContainer conversationId={id} />
                  </Box>
                )}
              </>
            ) : contactInfo.isOpen ? (
              <Box className={`${isTablet ? s.conversationContainer : s.conversationContainerMob}`} bg={"white !important"}>
                <ContactInfoContainer conversationId={id} onClose={contactInfo.onClose} />
              </Box>
            ) : (
              <Box className={`${isTablet ? s.conversationContainer : s.conversationContainerMob}`}>
                <ConversationContainer onContactInfoOpen={contactInfo.onOpen} />
              </Box>
            )
          ) : (
            <Flex className={s.startMessaging}>
              <EmptyChatIcon />
              <Text variant="medium" color="darkGrey">
                {t("Select a conversation to start messaging")}
              </Text>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
};
