import { TrialPlanData } from "./TrialPlanData";
import { TrialPlanModel } from "./TrialPlanModel";

export function mapTrialDataToModel(data: TrialPlanData) {
  const model: TrialPlanModel = {
    isAvailable: data.isAvailable,
    isAlmostAvailable: data.isAlmostAvailable,
    availableDays: data.availableDays,
    botsCount: data.botsCount,
    conversationCount: data.conversationCount,
    operatorsCount: data.operatorsCount,
    trialDays: data.trialDays,
  };
  return model;
}
