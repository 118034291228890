import { httpDelete, httpGet, httpPost, httpPostForm } from "../../common/BaseApi";
import { NewContactFormModel } from "./ContactListModel";

export const importContactsApi = (file: File | null, botIds: string[]) => {
  const formData = new FormData();
  if (file) {
    formData.append("file", file, file.name);
    for (let i = 0; i < botIds.length; i++) {
      formData.append(`botIds[${i}]`, botIds[i]);
    }
  }
  return httpPostForm(`/api/contact/import`, formData);
};

export const getContactListByOrganization = (
  filters: string,
  filter: string,
  page: number,
  size = 12,
  orderDirection = "Desc",
  orderBy = "CreateDate",
) => {
  return httpGet(
    `/api/contact?${filters}&filter=${filter}&page=${page}&size=${size}&orderDirection=${orderDirection}&orderBy=${orderBy}`,
  );
};

export const deleteContactApi = (contactId: string) => {
  return httpDelete(`/api/contact/${contactId}`);
};
export const createContact = (contact: NewContactFormModel) => {
  return httpPost(`/api/contact`, contact);
};

export const getContactCustomVariables = () => {
  return httpGet(`/api/customvariable/definitions/contact`);
};
