import dayjs from "dayjs";
import { BotVariableModel } from "../../components/bot/BotModel";
import { ContactVariableModel } from "../../components/contactInfo/ContactInfoModel";
import { CustomVariableType } from "../AppEnums";

export const formatDateTimeVariableToView = (variable: ContactVariableModel | BotVariableModel) => {
  const variableView = { ...variable, id: variable.id ?? "" };
  if (!variableView.value) {
    return variableView;
  }
  if (variable.type === CustomVariableType.DateTime) {
    const variableValueView = dayjs(variable.value as string).format("YYYY-MM-DD HH:mm:ss");
    variableView.value = variableValueView;
    return variableView;
  }
  if (variable.type === CustomVariableType.Date) {
    const variableValueView = dayjs(variable.value as string).format("YYYY-MM-DD");
    variableView.value = variableValueView;
    return variableView;
  }
  return variableView;
};

export const formatVariableToData = (variable: ContactVariableModel | BotVariableModel) => {
  const formattedContactVariable = { ...variable, id: variable.id ?? "", value: variable.value ?? "" };
  if (formattedContactVariable.type === CustomVariableType.DateTime && formattedContactVariable.value) {
    formattedContactVariable.value = dayjs(formattedContactVariable.value as string).format("YYYY-MM-DDTHH:mm:ssZ");
    return formattedContactVariable;
  }
  if (formattedContactVariable.type === CustomVariableType.Array && formattedContactVariable.value) {
    // eslint-disable-next-line no-useless-escape
    formattedContactVariable.value = formattedContactVariable.value.replace(/[\[\]\"']+/g, "");
    return formattedContactVariable;
  }
  return formattedContactVariable;
};
