import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { useEffect, useState } from "react";
import { useBrowserFitContent, useTabletView } from "../../../LayoutHelper/ResolutionHooks";
import { NewBroadcastHeader } from "../../../../broadcast/components/BroadcastHeader/NewBroadcastHeader";
import { Broadcast } from "../../../../broadcast/components/Broadcast/Broadcast";
import * as vars from "../../../../layout/LayoutHelper/ResolutionConst";

export const NewBroadcastPage = () => {
  const browserFill = useBrowserFitContent();
  const isTablet = useTabletView();
  const [mobileView, isMobileView] = useState(isTablet);

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  if (mobileView) {
    return (
      <Flex flexDirection={"column"} height={"100%"}>
        <ProtectedHeaderContainer>
          <NewBroadcastHeader />
        </ProtectedHeaderContainer>
        <Box bg="bgLight" height={window.innerHeight - vars.HEADER}>
          <Loading />
          <Broadcast isNew={true} />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <NewBroadcastHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={browserFill}>
        <Loading />
        <Broadcast isNew={true} />
      </Box>
    </Flex>
  );
};
