import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import {
  BotVariablesInFlowModel,
  FlowItemModel,
  FlowListModel,
  FlowTemplateModel,
  ImportResultsFlowModel,
  TriggerListModel,
  UsedFlowModel,
} from "./AutomationModel";
import { AutomationState } from "./AutomationState";

const initialState: AutomationState = { importResults: null, canAddNewFlow: true, canImportNewFlow: true };

export const automationSlice = createSlice({
  name: "automationSlice",
  initialState,
  reducers: {
    addNewFlow: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    setFlowList: (state, action: PayloadAction<FlowItemModel[]>) => {
      return {
        ...state,
        items: action.payload,
      };
    },
    getFlowList: (state, action: PayloadAction<{ botId: string; page: number; search: string }>) => {
      return {
        ...state,
      };
    },
    getFlowTemplates: state => {
      return {
        ...state,
      };
    },
    getFlowTemplatesSucceed: (state, action: PayloadAction<FlowTemplateModel[] | undefined>) => {
      return {
        ...state,
        flowTemplates: action.payload,
      };
    },
    getExportFlow: (state, action: PayloadAction<{ flowId: string; title: string }>) => {
      return {
        ...state,
      };
    },
    getFlowListSucceed: (state, action: PayloadAction<FlowListModel>) => {
      return {
        ...state,
        flowList: action.payload,
      };
    },
    setAccessToAddNewFlow: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        canAddNewFlow: action.payload,
      };
    },
    setAccessToImportNewFlow: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        canImportNewFlow: action.payload,
      };
    },
    setImportResultsFlow: (state, action: PayloadAction<{ importResults: ImportResultsFlowModel | null }>) => {
      return {
        ...state,
        importResults: action.payload.importResults,
      };
    },
    getTriggerList: (state, action: PayloadAction<{ botId: string; page: number }>) => {
      return {
        ...state,
      };
    },
    getTriggerListSucceed: (state, action: PayloadAction<TriggerListModel>) => {
      return {
        ...state,
        triggerList: action.payload,
      };
    },
    switchTriggerStatus: (
      state,
      action: PayloadAction<{ flowId: string | undefined; triggerId: string | undefined; isEnabled: boolean }>,
    ) => {
      return {
        ...state,
      };
    },
    reorderFlow: (
      state: AutomationState,
      action: PayloadAction<{
        botId: string;
        sourceFlowId: string;
        destinationFlowId: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    deleteFlow: (state: AutomationState, action: PayloadAction<{ flowId: string; forceDelete: boolean }>) => {
      return {
        ...state,
      };
    },
    copyFlow: (state: AutomationState, action: PayloadAction<{ flowId: string; botId: string; locale: string }>) => {
      return {
        ...state,
      };
    },
    getBotVariablesInFlow: (state: AutomationState, action: PayloadAction<{ flowId: string; targetBotId: string }>) => {
      return {
        ...state,
      };
    },
    getBotVariablesInFlowSucceed: (
      state: AutomationState,
      action: PayloadAction<{ botVariablesInFlow: BotVariablesInFlowModel }>,
    ) => {
      return {
        ...state,
        botVariablesInFlow: { ...action.payload.botVariablesInFlow },
      };
    },
    clearBotVariablesInFlowState: (state: AutomationState) => {
      return {
        ...state,
        botVariablesInFlow: undefined,
      };
    },
    getUsersInFlow: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getUsersInFlowSucceed: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        usersInFlowNumber: action.payload,
      };
    },
    getUsedFlowsInFlow: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    getUsedFlowInFlowSucceed: (state, action: PayloadAction<UsedFlowModel[]>) => {
      return {
        ...state,
        usedFlowsInFlow: action.payload,
      };
    },
    resetUsersInFlow: (state: AutomationState) => {
      return {
        ...state,
        usersInFlowNumber: undefined,
      };
    },
    clearAutomationState: (state: AutomationState) => {
      return { ...initialState, importResults: state.importResults, flowTemplates: state.flowTemplates };
    },
    renameFlow: (state, action: PayloadAction<{ flowId: string; newName: string }>) => {
      return {
        ...state,
      };
    },
    renameFlowCompleted: (state: AutomationState, action: PayloadAction<{ flowId: string; newName: string }>) => {
      const newState = {
        ...state,
        flowlist: state.flowList,
        ...state.flowList?.items,
      };

      if (newState.flowlist !== undefined && newState.flowList?.items !== undefined) {
        const renamedItemIdx = newState.flowList.items.findIndex(item => item.id === action.payload.flowId);

        if (renamedItemIdx !== undefined && renamedItemIdx !== null) {
          newState.flowList.items[renamedItemIdx].title = action.payload.newName;
        }
      }
    },
  },
});

export const {
  addNewFlow,
  setFlowList,
  getFlowList,
  getFlowListSucceed,
  setAccessToAddNewFlow,
  setAccessToImportNewFlow,
  getExportFlow,
  getTriggerList,
  getTriggerListSucceed,
  switchTriggerStatus,
  reorderFlow,
  deleteFlow,
  copyFlow,
  getBotVariablesInFlow,
  getBotVariablesInFlowSucceed,
  clearBotVariablesInFlowState,
  setImportResultsFlow,
  getUsersInFlow,
  getUsedFlowsInFlow,
  getFlowTemplates,
  getFlowTemplatesSucceed,
  resetUsersInFlow,
  getUsersInFlowSucceed,
  getUsedFlowInFlowSucceed,
  clearAutomationState,
  renameFlow,
} = automationSlice.actions;

export const selectAutomationState = (state: RootState) => state.app.automationState;
export const selectFlowList = (state: RootState) => state.app.automationState.flowList;
export const selectFlowTemplates = (state: RootState) => state.app.automationState.flowTemplates;
export const selectImportResultsFlow = (state: RootState) => state.app.automationState.importResults;
export const selectTriggerList = (state: RootState) => state.app.automationState.triggerList;
export const selectUsersInFlowNumber = (state: RootState) => state.app.automationState.usersInFlowNumber;
export const selectFlowsInFlowNumber = (state: RootState) => state.app.automationState.usedFlowsInFlow;
export const selectBotVariablesInFlow = (state: RootState) => state.app.automationState.botVariablesInFlow;
export const selectCanAddNewFlow = (state: RootState) => state.app.automationState.canAddNewFlow;
export const selectCanImportNewFlow = (state: RootState) => state.app.automationState.canImportNewFlow;
export default automationSlice.reducer;
