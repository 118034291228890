import { takeLatest, put, call } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { PluginTokenData } from "./PluginAuthData";
import * as PluginAuthApi from "./PluginAuthApi";
import { pluginAuthSlice } from "./PluginAuthSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { PluginTokenModel } from "./PluginAuthModel";

export function* pluginAuthSaga() {
  yield takeLatest(pluginAuthSlice.actions.getPluginToken, getPluginToken);
}

function* getPluginToken(action: PayloadAction<{ pluginName: string }>) {
  try {
    const data: PluginTokenData = yield call(PluginAuthApi.getPluginToken, action.payload.pluginName);
    const model: PluginTokenModel = { ...data };
    yield put(pluginAuthSlice.actions.getPluginTokenCompleted({ token: model }));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
