/* eslint-disable @typescript-eslint/no-explicit-any */

import { CustomVariableModel } from "../../../common/AppEnums";

export interface AddVariablePopupModel {
  botId?: string;
  flowId?: string;
  onCreate?: (newVariable: CustomVariableModel) => void;
  scope?: CustomVariableScope.Flow | CustomVariableScope.Contact | CustomVariableScope.Bot;
}

export enum CustomVariableType {
  "Text" = "Text",
  "Number" = "Number",
  "Date" = "Date",
  "DateTime" = "DateTime",
  "Boolean" = "Boolean",
  "Array" = "Array",
  "Order" = "Order",
}

export enum CustomVariableScope {
  "Contact" = "Contact",
  "System" = "System",
  "Bot" = "Bot",
  "Flow" = "Flow",
}
