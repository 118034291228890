import s from "../AddNewTriggerPopup.module.scss";
import { TriggerTypeDiscriminator } from "../../../FlowBuilderModel";
import { Icon, Text, Tooltip } from "@chakra-ui/react";

interface Props {
  isDisabled: boolean;
  icon: React.FC;
  label: string;
  type: TriggerTypeDiscriminator;
  tooltip: string;
  onClick: (type: TriggerTypeDiscriminator) => void;
}

export const AddButton = (props: Props) => {
  return (
    <Tooltip variant="dominoLight" label={props.tooltip} whiteSpace={"pre-wrap"} mt={"-8px"} maxWidth={"220px"}>
      <div
        onClick={() => !props.isDisabled && props.onClick(props.type)}
        className={`${s.triggerItem} ${props.isDisabled ? s.disabled : ""}`}
      >
        <Icon as={props.icon} boxSize="24px" mr="8px" />
        <Text variant="medium">{props.label}</Text>
      </div>
    </Tooltip>
  );
};
