import { TelegramAuthModel } from "./TelegramAuthModel";
import { AppSettings } from "../AppSettings";
import { BadRequestError, Error400Model, Error403Model } from "../ErrorModel";
import { getFingerprint } from "react-fingerprint";
import i18next from "i18next";

declare const appSettings: AppSettings;

export const telegramAuth = async (data: TelegramAuthModel) => {
  const fingerprint = await getFingerprint();

  data = { ...data, fingerprint: fingerprint };

  const res = await fetch(`${appSettings.apiIdentityProvider}/auth/telegram`, {
    method: "POST",
    headers: {
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": "application/vnd.domino.v1+json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-lang": i18next.language,
    },
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
  });

  if (res?.status === 400) {
    const data: Error400Model = await res.json();
    throw new BadRequestError(data?.uiMessage ?? data.message);
  }

  if (res?.status === 403) {
    const error = { message: "notApproved" };
    throw new Error403Model(error.message);
  }

  if (res.status === 200) {
    return await res.json();
  }
};
