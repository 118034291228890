import { useCallback, useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectCurrentBotId } from "../../../sidebar/SidebarSlice";
import {
  clearState,
  deleteBroadcast,
  editBroadcast,
  executeBroadcast,
  getBroadcast,
  getContactList,
  getCustomVariables,
  selectBroadcastState,
  setCurrentFile,
  createBroadcast,
  selectCurrentFile,
  selectCustomVariables,
  //selectContactList,
  getDraftContactList,
  //setSelectAllState,
  selectButtonSelectAllState,
} from "../../BroadcastSlice";
import { BroadcastFormModel, BroadcastMessageTypeDescriminator, FileUploadType } from "../../BroadcastModel";
import { DeleteBroadcastPopup } from "../DeleteBroadcastPopup/DeleteBroadcastPopup";
import { Formik, FormikErrors } from "formik";
import s from "./Broadcast.module.scss";
import { BroadcastForm } from "../Broadcast/BroadcastForm";
import { INITIAL_BROADCAST_FORM_DATA } from "../../BroadcastValidation";
import { validateNewTextField } from "./BroadcastValidation";
import { useTranslation } from "react-i18next";
import { ComplexFilterFieldTypes, ComplexFilterGroupTypes, FilterParams } from "../../../complexFilter/ComplexFilterModel";
import { formatTelegramTextToEdit } from "../../../../common/utils/formatTelegramText";
import { Entity } from "../../../../common/formattedText/entity";
import { mapRecipientsFiltersToData } from "../../BroadcastMapper";
import { getTitleByFieldTitle } from "../../../complexFilter/CreateFilterFields";
import {
  parseRecipientFilterConditionTitle,
  parseRecipientFilterConditionValue,
} from "../../../complexFilter/ComplexFilterMapper";

const BROADCAST_VIEW_STATUSES = ["Scheduled", "Sending", "Finished", "Cancelled", "Failed"];

interface Props {
  isNew: boolean;
}

export const Broadcast = ({ isNew }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { broadcastId } = useParams();
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const bt = useTranslation("translation", { keyPrefix: "broadcast.fieldToFilter" }).t;
  const ccf = useTranslation("translation", {
    keyPrefix: "complexFilter.conditionsByType",
  }).t;

  const currentBotId = useAppSelector(selectCurrentBotId);
  const broadcastState = useAppSelector(selectBroadcastState);
  const currentFile = useAppSelector(selectCurrentFile);
  const variables = useAppSelector(selectCustomVariables);
  const recipientList = useAppSelector((state: RootState) => state.app.broadcastState.recipientList);

  const [initialFormData, setInitialFormData] = useState<BroadcastFormModel>(INITIAL_BROADCAST_FORM_DATA);
  const [deletePopupState, setDeletePopupState] = useState<{ id: string; itemName: string } | null>(null);
  const [executeState, setExecuteState] = useState({ execute: false, dirty: false });
  const [isExecute, setIsExecute] = useState(false);
  const [prevQueryString, setPrevQueryString] = useState<{ filter: string | undefined; params: string | undefined }>({
    filter: undefined,
    params: undefined,
  });
  const status = broadcastState.broadcast?.status;
  const broadcast = useAppSelector(selectBroadcastState).broadcast;
  const [broadcastFilter, setBroadcastFilter] = useState<FilterParams[]>([{}]);
  //const contactList = useAppSelector(selectContactList);
  const sentForAllFlag = useAppSelector(selectButtonSelectAllState);
  const [wasSelectAllChecked, setWasSelectAllChecked] = useState(sentForAllFlag ?? false);
  // const [wasSelectAllCheckedNew, setWasSelectAllCheckedNew] = useState(false);
  // const wasSelectAllChecked = !isNew && sentForAllFlag ? sentForAllFlag : wasSelectAllCheckedNew;
  // const setWasSelectAllCheckedDraft = (checked: boolean) => {
  //   setWasSelectAllChecked(checked);
  // };
  // const setWasSelectAllChecked = !isNew ? setWasSelectAllCheckedDraft : setWasSelectAllCheckedNew;

  useEffect(() => {
    if (broadcast?.recipientFilter && broadcast.recipientFilter.length && variables && variables.length > 0) {
      const filters = broadcast.recipientFilter.flatMap(filter => {
        return filter.members.map(member => {
          const filterParam: FilterParams = {
            field: {
              title:
                member?.targetCustomVariable && variables
                  ? variables.find(el => el.id === member.targetCustomVariable)?.key ?? ""
                  : bt(`${getTitleByFieldTitle(member.field ?? "")}`) ?? "",
              value:
                member?.targetCustomVariable && variables
                  ? variables.find(el => el.id === member.targetCustomVariable)?.key ?? ""
                  : member.field ?? "",
              type: member?.valueType
                ? ComplexFilterFieldTypes[member.valueType as keyof typeof ComplexFilterFieldTypes]
                : undefined,
              id: member?.targetCustomVariable ?? null,
              groupType: !!member.targetCustomVariable
                ? ComplexFilterGroupTypes.CustomVariables
                : ComplexFilterGroupTypes.ContactFields,
            },
            condition: {
              value: parseRecipientFilterConditionValue(member),
              title: ccf(parseRecipientFilterConditionTitle(member)),
            },
            conditionValue: {
              title: member?.value ?? "",
              value: member.valueType === "DateTime" ? member?.value : member.value ?? "",
            },
            id: member.filterId,
          };
          return filterParam;
        });
      });
      //const isEmpty = filters?.[0] && Object.keys(filters[0]).length === 0;

      setBroadcastFilter(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcast?.recipientFilter, variables]);

  useEffect(() => {
    if (currentBotId) {
      dispatch(getCustomVariables({ botId: currentBotId }));
      if (!isNew && broadcastId) {
        dispatch(getBroadcast({ botId: currentBotId, broadcastId }));
      }
      if (isNew && !broadcast) {
        dispatch(getContactList({ botId: currentBotId, queryFilterParams: "size=20&page=1" }));
      }
      if (!isNew && broadcastId && !broadcast?.recipientFilter) {
        dispatch(getDraftContactList({ botId: currentBotId, broadcastId, queryFilterParams: "size=20&page=1" }));
      }
    }
    return () => {
      dispatch(clearState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastId, currentBotId, isNew]);

  useEffect(() => {
    if (!isNew && broadcastState.broadcast) {
      setWasSelectAllChecked(broadcastState.broadcast?.sentForAll ?? false);
      const recipients = broadcastState.broadcast.recipients.map(recipient => ({
        ...recipient,
        externalId: recipient.externalContactId,
      }));
      const initialData = {
        ...broadcastState.broadcast,
        // sentForAll: broadcastState.broadcast.sentForAll,
        recipients,
        isScheduled: !!broadcastState.broadcast.scheduledDate,
      };
      setInitialFormData(initialData);
      if (broadcastState.broadcast.message.typeDiscriminator === BroadcastMessageTypeDescriminator.BroadcastDocumentMessage) {
        const { fileName, fileId } = broadcastState.broadcast.message;
        dispatch(setCurrentFile({ file: { fileName, fileId }, type: FileUploadType.document }));
      }
      if (broadcastState.broadcast.message.typeDiscriminator === BroadcastMessageTypeDescriminator.BroadcastPhotoMessage) {
        const { fileName, fileId } = broadcastState.broadcast.message;
        dispatch(setCurrentFile({ file: { fileName, fileId }, type: FileUploadType.photo }));
      }
      // }
    } else {
      setInitialFormData(INITIAL_BROADCAST_FORM_DATA);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastState.broadcast]);

  useEffect(() => {
    if (status && BROADCAST_VIEW_STATUSES.includes(status)) {
      navigate(-1);
    }
  }, [navigate, status]);

  useEffect(() => {
    setBroadcastFilter([{}]);
  }, [recipientList?.availableRecipients]);

  const mapBroadcastFiltersToData = (filters: FilterParams[]) => {
    if (filters && filters[0].condition) {
      const result = mapRecipientsFiltersToData(filters, variables);
      return result;
    }
    return null;
  };

  const onSave = (values: BroadcastFormModel) => {
    values.sentForAll = wasSelectAllChecked;
    const commonPayload = {
      botId: currentBotId ?? "",
      broadcast: values,
      execute: isExecute,
      recipientFilter: mapBroadcastFiltersToData(broadcastFilter),
    };
    if (isNew) {
      dispatch(
        createBroadcast({
          ...commonPayload,
          sentForAll: recipientList?.availableRecipients ? false : wasSelectAllChecked,
        }),
      );
    } else {
      if (!executeState.execute) {
        dispatch(
          editBroadcast({
            ...commonPayload,
            broadcast: {
              ...values,
              sentForAll: recipientList?.availableRecipients ? false : wasSelectAllChecked,
            },
            execute: false,
          }),
        );
      } else if (executeState.dirty) {
        dispatch(
          editBroadcast({
            ...commonPayload,
            broadcast: {
              ...values,
              sentForAll: recipientList?.availableRecipients ? false : wasSelectAllChecked,
            },
            execute: true,
          }),
        );
      } else {
        dispatch(
          executeBroadcast({
            botId: currentBotId ?? "",
            broadcastId: broadcastState.broadcast?.id ?? "",
          }),
        );
      }
    }
  };

  const onBroadcastDeleteClick = (id: string, itemName: string) => {
    setDeletePopupState({ id, itemName });
  };

  const onDeletePopupClose = () => setDeletePopupState(null);
  const deleteBroadcastDispatch = (broadcastId: string) => {
    dispatch(deleteBroadcast({ broadcastId }));
  };

  const validateFields = (values: BroadcastFormModel) => {
    const errors: FormikErrors<BroadcastFormModel> = validateNewTextField(values, currentFile, isExecute, t);
    return errors;
  };

  const getContacts = useCallback(
    (
      queryFilterString: string | undefined,
      queryParamsString: string | undefined,
      isFilterActive: boolean,
      isOnScrollPayload?: boolean,
    ) => {
      if (currentBotId) {
        if (isFilterActive && prevQueryString.params?.startsWith("size=20&page=")) {
          prevQueryString.params = "";
        }
        let queryFilterFinalString = queryFilterString;
        let queryParamsFinalString = queryParamsString;
        if (queryFilterString === undefined) {
          queryFilterFinalString = prevQueryString.filter;
        }
        if (queryParamsString === undefined) {
          queryParamsFinalString = prevQueryString.params;
        }
        const queryString = (queryFilterFinalString ?? "") + (queryParamsFinalString ?? "");

        setPrevQueryString({ filter: queryFilterFinalString, params: queryParamsFinalString });
        if (broadcastId) {
          dispatch(getDraftContactList({ botId: currentBotId, queryFilterParams: queryString ?? "size=20&page=1", broadcastId }));
        } else {
          dispatch(
            getContactList({
              botId: currentBotId,
              ...(queryString ? { queryFilterParams: queryString } : { queryFilterParams: "size=20&page=1" }),
              isOnScrollPayload,
            }),
          );
        }
      }
    },
    [currentBotId, dispatch, prevQueryString],
  );

  return (
    <>
      <Box px={{ base: "12px", sm: "32px" }} py={{ base: "12px", sm: "32px" }} className={s.broadcastContainer}>
        <Formik
          validate={validateFields}
          onSubmit={onSave}
          initialValues={
            isNew
              ? initialFormData
              : {
                  ...initialFormData,
                  message: {
                    ...initialFormData.message,
                    text: formatTelegramTextToEdit(initialFormData.message.text, initialFormData.message.entities as Entity[]),
                  },
                }
          }
          enableReinitialize
        >
          <BroadcastForm
            getContacts={getContacts}
            setExecuteState={setExecuteState}
            onBroadcastDeleteClick={onBroadcastDeleteClick}
            wasSelectAllChecked={wasSelectAllChecked}
            setWasSelectAllChecked={setWasSelectAllChecked}
            broadcastState={broadcastState}
            setIsExecute={setIsExecute}
            isNew={isNew}
            broadcastRecipientsFilters={broadcastFilter}
            setBroadcastFilter={setBroadcastFilter}
          />
        </Formik>
      </Box>
      <DeleteBroadcastPopup
        deleteBroadcastState={deletePopupState}
        onDelete={deleteBroadcastDispatch}
        onClose={onDeletePopupClose}
      />
    </>
  );
};
