/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TelegramAuthData } from "./TelegramAuthData";

const initialState = {};

export const telegramAuthSlice = createSlice({
  name: "telegramAuthSlice",
  initialState,
  reducers: {
    telegramAuthenticate: (state, action: PayloadAction<{ telegramData: TelegramAuthData; redirectUrlLogin?: string }>) => {
      return {
        ...state,
      };
    },
  },
});

export const { telegramAuthenticate } = telegramAuthSlice.actions;

export default telegramAuthSlice.reducer;
