import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";

export const SideBarBotLoading = () => {
  return (
    <Flex width="100%" height="60px" gap="12px" alignItems="center" p="12px">
      <SkeletonCircle size="8" startColor="line" endColor="bgLight" />

      <Flex gap="6px" direction="column" w="75%">
        <Skeleton
          speed={0.5}
          startColor="line"
          endColor="bgLight"
          borderRadius="4px"
          height="20px"
          width="80%"
          border="1px solid"
          borderColor="line"
          fadeDuration={1}
          ml="5px"
        />

        <Skeleton
          speed={0.5}
          startColor="line"
          endColor="bgLight"
          borderRadius="4px"
          height="10px"
          width="70%"
          border="1px solid"
          borderColor="line"
          fadeDuration={1}
          ml="5px"
        />
      </Flex>
    </Flex>
  );
};
