import { ButtonModel, StaticButtonModel } from "../../common/AppButtonsModel";
import { BroadcastReplyData, ContactData, CustomVariableData } from "./BroadcastData";
import {
  BaseBroadcatMessageModel,
  BroadcastFormModel,
  BroadcastMessageTypeDescriminator,
  BroadcatMessageModel,
  ContactModel,
  FileModel,
  FileUploadType,
  MemberModel,
  RecipientFiltersModel,
  RecipientFormModel,
  RecipientModel,
} from "./BroadcastModel";
import { v1 as uuidv1 } from "uuid";
import { ComplexFilterFieldTypes, FilterParams } from "../complexFilter/ComplexFilterModel";
import { CustomVariableModel } from "../../common/AppEnums";
import "../../common/utils/stringUtils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { store } from "../..";

dayjs.extend(utc);

export function getMessage(message: BaseBroadcatMessageModel, currentFile?: FileModel): BroadcatMessageModel {
  const text = message.text.trim();
  const buttons = message.buttons;
  if (currentFile?.type === FileUploadType.photo) {
    return {
      text,
      buttons,
      typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastPhotoMessage,
      ...currentFile.file,
    };
  } else if (currentFile?.type === FileUploadType.document) {
    return {
      text,
      buttons,
      typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastDocumentMessage,
      ...currentFile.file,
    };
  }
  return {
    text,
    buttons,
    typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastTextMessage,
  };
}

export const getNodeVariableIds = (text: string, vars: CustomVariableData[]): string[] => {
  const regex = /@{([\wа-яА-ЯёЁ-]+):([\wа-яА-ЯёЁ-]+)}/g;
  const variables = text.match(regex);
  if (!variables) {
    return [];
  }

  const variableIds = variables
    .map(el => {
      const scopeAndKey = el.replace(regex, "$1:$2");
      const [scope, key] = scopeAndKey.split(":");
      const customVariable = vars.find(el => key && el.key === key && el.scope === scope);
      return customVariable?.id ?? "";
    })
    .filter(x => x.length > 0);
  return variableIds;
};

export const mapCustomVariableIds = (text: string | undefined, customVariables: CustomVariableData[]): string[] => {
  if (!text) {
    return [];
  }
  const customVariableIds = getNodeVariableIds(text, customVariables);
  return customVariableIds;
};

export const mapBroadcastModelToData = (
  action: { botId: string; broadcast: BroadcastFormModel; execute: boolean },
  currentFile: FileModel | undefined,
  customVariables: CustomVariableData[],
) => {
  const message = getMessage(action.broadcast.message, currentFile);
  message.buttons = mapBroadcastUrlButtonToPayload(message.buttons);
  const recipients = action.broadcast.recipients.map(recipient => ({
    ...recipient,
    externalContactId: recipient.externalId,
    externalConversationId: recipient.externalId,
    typeDiscriminator: "TelegramBroadcastRecipient",
  }));
  const customVariableIds = mapCustomVariableIds(message.text, customVariables);
  return { message, recipients, customVariableIds };
};

export const mapBroadcastFormModelToRecipientModel = (model: RecipientFormModel) => {
  return {
    ...model,
    externalContactId: model.externalId,
    externalConversationId: model.conversationId,
    typeDiscriminator: "TelegramBroadcastRecipient",
  };
};

export const mapBroadcastEditModelToData = (
  action: { botId: string; broadcast: BroadcastFormModel; execute: boolean },
  currentFile: FileModel | undefined,
  customVariables: CustomVariableData[],
) => {
  const contactList = store.getState().app.broadcastState.contactList;
  const initialRecipients = store.getState().app.broadcastState.initialRecipientsList;

  const message = getMessage(action.broadcast.message, currentFile);
  message.buttons = mapBroadcastUrlButtonToPayload(message.buttons);

  const sentForAllFlag = action.broadcast.sentForAll || action.broadcast.checkedFlag ? true : false;
  const excludedAllFlag =
    (action.broadcast.initialSentForAllFlag === true && !sentForAllFlag) || action.broadcast.uncheckedFlag ? true : false;

  const includedRecipients = excludedAllFlag
    ? action.broadcast.recipients.map(rec => mapBroadcastFormModelToRecipientModel(rec))
    : !excludedAllFlag && sentForAllFlag && action.broadcast.recipients.length
    ? []
    : action.broadcast.recipients
        .filter(rec => contactList?.items.find(elem => elem.id === rec.contactId && !elem.isSelected))
        .map(rec => ({
          ...rec,
          externalContactId: rec.externalId,
          externalConversationId: rec.conversationId,
          typeDiscriminator: "TelegramBroadcastRecipient",
        }));
  const excludedRecipients = excludedAllFlag
    ? []
    : !excludedAllFlag &&
      action.broadcast.checkedFlag &&
      !action.broadcast.sentForAll &&
      action.broadcast.recipients.length > 0 &&
      action.broadcast.excludedRecipients &&
      action.broadcast.excludedRecipients?.length > 0
    ? action.broadcast.excludedRecipients.map(rec => ({
        ...rec,
        typeDiscriminator: "TelegramBroadcastRecipient",
      }))
    : !excludedAllFlag && action.broadcast.sentForAll
    ? []
    : initialRecipients
        ?.filter(item => !action.broadcast.recipients.some(item2 => item2.contactId === item.contactId))
        .map(rec => ({
          ...rec,
          externalContactId: rec.externalContactId,
          externalConversationId: rec.conversationId,
          typeDiscriminator: "TelegramBroadcastRecipient",
        }));

  const customVariableIds = mapCustomVariableIds(message.text, customVariables);
  return { message, includedRecipients, excludedRecipients, customVariableIds, excludedAllFlag, sentForAllFlag };
};

export const mapContactListToRecipientsList = (data: ContactModel) => {
  const model: RecipientFormModel = {
    contactId: data.id,
    conversationId: data.conversationId,
    externalId: data.externalId,
  };
  return model;
};

export function mapRecipientsFiltersToData(filters: FilterParams[], variables: CustomVariableModel[] | undefined) {
  const findTargetCustomVar = (filter: FilterParams) => {
    const condition = filter.field?.groupType === "CustomVariables" && variables && variables.length && filter.field?.value;
    return condition ? variables.find(el => el.key === filter.field?.value)?.id : null;
  };

  const identifyKey = (filter: FilterParams) => {
    return filter.field?.groupType === "CustomVariables" && variables ? filter.field?.value : null;
  };

  const valueByType = (filter: FilterParams) => {
    switch (filter.field?.type) {
      case ComplexFilterFieldTypes.Boolean:
        const booleanValue = filter.condition?.value;
        if (booleanValue === "True" || booleanValue === "False") {
          return (booleanValue.toLowerCase() === "true").toString();
        }
        return "";
      case ComplexFilterFieldTypes.DateTime:
        if (!filter.conditionValue?.value) return "";
        const dateTimeValue = filter.conditionValue.value.toDate();
        const jsDate = dayjs.utc(dateTimeValue).format("YYYY-MM-DDTHH:mm:ss");
        return jsDate.toString();
      default:
        return filter.conditionValue?.value ?? "";
    }
  };

  const conditionByType = (filter: FilterParams) => {
    switch (filter.field?.type) {
      case "Boolean":
        const condition = filter.condition?.value;
        if (!(condition === "HasValue" || condition === "HasNoValue")) {
          return "equal";
        }
        return filter.condition?.value ?? "";
      default:
        return filter.condition?.value ?? "";
    }
  };

  const members: MemberModel[] = filters.map(filter => {
    return {
      typeDiscriminator: "BroadcastRecipientFilter",
      targetCustomVariableKey: identifyKey(filter),
      targetCustomVariable: findTargetCustomVar(filter),
      field: filter.field?.groupType === "CustomVariables" ? null : filter.field?.value ?? "",
      valueType: filter.field?.type ?? "",
      value: valueByType(filter),
      condition: conditionByType(filter),
    };
  });
  const actionFilter: RecipientFiltersModel = {
    typeDiscriminator: "BroadcastRecipientGroupFilter",
    members: members,
  };

  return [actionFilter];
}

export const mapBroadcastDataToModel = (broadcastData: BroadcastReplyData) => {
  let buttons = broadcastData.message.buttons.map(button => ({
    ...button,
    id: uuidv1(),
  }));
  buttons = mapBroadcastPayloadToUrlButton(buttons);
  const broadcast = {
    ...broadcastData,
    message: { ...broadcastData.message, buttons },
  };
  return broadcast;
};

const mapBroadcastUrlButtonToPayload = (buttons: ButtonModel[]) => {
  let item = buttons as StaticButtonModel[];
  item = item.map(btn => {
    if (btn.type === "UrlButton") {
      return { ...btn, payload: btn.url };
    } else {
      return { ...btn };
    }
  });
  return item;
};

const mapBroadcastPayloadToUrlButton = (buttons: ButtonModel[]) => {
  let item = buttons as StaticButtonModel[];
  item = item.map(btn => {
    if (btn.type === "UrlButton") {
      return { ...btn, url: btn.payload };
    } else {
      return { ...btn };
    }
  });
  return item;
};

export const mapContactListDataToModel = (data: ContactData) => {
  const model: ContactModel = {
    id: data.id,
    conversationId: data.conversationId,
    externalId: data.externalId,
    username: data.username,
    phoneNumber: data.phoneNumber,
    firstName: data.firstName,
    lastName: data.lastName,
    isSelected: data.isSelected ? data?.isSelected : false,
    isSelectedInitial: data.isSelected ? data?.isSelected : false,
  };
  return model;
};

export const mapContactItemDataToModel = (data: ContactData) => {
  const model: ContactModel = {
    id: data.id,
    conversationId: data.conversationId,
    externalId: data.externalId,
    username: data.username,
    phoneNumber: data.phoneNumber,
    firstName: data.firstName,
    lastName: data.lastName,
    isSelected: data.isSelected,
    isSelectedInitial: data.isSelected,
  };
  return model;
};

export const mapRecipientModalToRecipientFormModal = (data: RecipientModel) => {
  const model: RecipientFormModel = {
    contactId: data.contactId,
    conversationId: data.conversationId,
    externalId: data.externalContactId,
  };
  return model;
};

export const mapContactItemDataToRecipientFormModel = (data: ContactModel) => {
  const model: RecipientFormModel = {
    contactId: data.id,
    conversationId: data.conversationId,
    externalId: data.externalId,
  };
  return model;
};
