import { httpGet, httpPost } from "../../common/BaseApi";
import { DashboardDataModel } from "./DashboardModel";

export const getBots = (page: number) => {
  return httpGet(`/api/telegrambot?page=${page}&size=${10}`);
};

export const getSentMessages = (data: DashboardDataModel) => {
  return httpPost(`/api/dashboard/getSentMessages`, data);
};

export const getSentReceivedMessages = (data: DashboardDataModel) => {
  return httpPost(`/api/dashboard/getSentReceivedMessages`, data);
};
