import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox,
  Text,
  Flex,
  Link,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import {
  deleteFlow,
  getUsersInFlow,
  resetUsersInFlow,
  selectUsersInFlowNumber,
  selectFlowsInFlowNumber,
  getUsedFlowsInFlow,
} from "../../AutomationSlice";

interface Props {
  deletePopupState: { id: string; title: string };
  onClose: () => void;
}

export const DeleteFlowPopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "flowList.deleteFlow",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const usersInFlowNumber = useAppSelector(selectUsersInFlowNumber);
  const flowsInFlow = useAppSelector(selectFlowsInFlowNumber);
  const [forceDelete, setForceDelete] = useState<boolean>(true);

  useEffect(() => {
    dispatch(getUsersInFlow(props.deletePopupState.id));
    dispatch(getUsedFlowsInFlow(props.deletePopupState.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    dispatch(resetUsersInFlow());
    props.onClose();
  };

  const toggleCheckBox = () => {
    setForceDelete(state => {
      return !state;
    });
  };

  const onFlowDelete = () => {
    dispatch(deleteFlow({ flowId: props.deletePopupState.id, forceDelete }));
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Delete Flow")}</ModalHeader>
        <ModalCloseButton
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
          data-pw="close-button"
        />
        <ModalBody paddingTop={"0"}>
          <Loading scope={"deleteFlow"} />
          {flowsInFlow?.length ? (
            <Flex direction="column">
              <Text variant="large">
                <b>{props.deletePopupState.title}</b> {t("Used in flow")}:
              </Text>
              {flowsInFlow.map(flow => (
                <Text key={flow.id} variant="large">
                  <Link href={`/automation/flows/${flow.id}`} isExternal={true}>
                    <b>{flow.title}</b>
                  </Link>
                </Text>
              ))}
              <Text variant="large">{t("Remove it from the flow(s)")}.</Text>
            </Flex>
          ) : (
            <>
              {usersInFlowNumber ? (
                <>
                  <Flex direction="column">
                    <Text variant="large">
                      {t("You have")} {usersInFlowNumber} {t("user(s) in")} <b>{props.deletePopupState.title}</b>.
                    </Text>
                    <Flex mt="12px">
                      <Checkbox
                        size="lg"
                        style={{ marginRight: "10px" }}
                        type="checkbox"
                        isChecked={forceDelete}
                        checked={forceDelete}
                        onChange={toggleCheckBox}
                      />
                      <Text variant="large">{t("Kick all users before removing the flow")}</Text>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <Text variant="large">
                  {t("You are going to delete")} <b>{props.deletePopupState.title}</b>
                </Text>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter paddingBottom="32px" justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={onClose} data-pw="cancel-button">
            {ct("Cancel")}
          </Button>
          <Button
            isDisabled={!!flowsInFlow?.length}
            variant="dominoPrimaryRed"
            mr={3}
            onClick={onFlowDelete}
            data-pw="delete-button"
          >
            {ct("Delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
