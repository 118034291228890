import { SetStateAction } from "react";
import { Flex, Show } from "@chakra-ui/react";
import { ReactComponent as LogoIcon } from "../../../../assets/icons/sidebar/logoIcon.svg";
import { ReactComponent as LogoText } from "../../../../assets/icons/sidebar/logoText.svg";
import { ReactComponent as SidebarCrossIcon } from "../../../../assets/icons/sidebar/sidebarCrossIcon.svg";
import s from "../../Sidebar.module.scss";

interface Props {
  setMobileOpenMenu: (value: SetStateAction<boolean>) => void;
}
export const SideBarMenuPanelMobile = ({ setMobileOpenMenu }: Props) => {
  return (
    <Flex flexDirection={"row"} alignItems="center" className={s.logoBlock}>
      <Show breakpoint="(max-width: 768px)">
        <SidebarCrossIcon className={s.sidebarCrossIcon} onClick={() => setMobileOpenMenu(false)} />
      </Show>
      <Show breakpoint="(min-width: 769px)">
        <LogoIcon className={s.logoIcon} />
        <LogoText className={s.logoText} />
      </Show>
      <Show breakpoint="(max-width: 768px)">
        <LogoText className={s.logoText} />
      </Show>
    </Flex>
  );
};
