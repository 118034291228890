import { InlineKeyboardMarkupModel, ReplyKeyboardMarkupModel, ReplyMarkupModel } from "../../common/AppButtonsModel";
import { FlowMarkupTypeDiscriminator } from "../../common/AppEnums";
import { ButtonsContainerFlowAction, FlowActionModel } from "./FlowBuilderModel";

export function getButtonsWithExactType(reply: ReplyMarkupModel | undefined, flowAction: FlowActionModel | undefined) {
  if (!reply) {
    return;
  }
  const action = flowAction as ButtonsContainerFlowAction;
  if (reply.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup) {
    return (action.replyMarkup as ReplyKeyboardMarkupModel).buttons;
  } else {
    return (action.replyMarkup as InlineKeyboardMarkupModel).buttons;
  }
}
