import { PaginatorData } from "../paginator/PaginatorData";

export const isNextPage = (data: PaginatorData) => {
  if (data.currentPage === data.totalPages) {
    return false;
  }
  return true;
};

export const isPrevPage = (data: PaginatorData) => {
  if (data.currentPage === 1) {
    return false;
  }
  return true;
};

const isLastItem = (data: PaginatorData) => {
  if (data.items.length > 1) {
    return false;
  }
  return true;
};

export const isChangePage = (data: PaginatorData) => {
  if (isLastItem(data) && isPrevPage(data)) {
    return true;
  }
  return false;
};
