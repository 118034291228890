import { Box, Text } from "@chakra-ui/react";
import s from "./BroadcastHeader.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../common/state/store";
import { selectBroadcastState } from "../../BroadcastSlice";
import { BackButton } from "../../../backButton/BackButton";
import { StatusTag } from "../../../broadcastList/components/BroadcastList/components/StatusTag/StatusTag";

export const ViewBroadcastHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const navigate = useNavigate();
  const { broadcast } = useAppSelector(selectBroadcastState);

  return (
    <Box className={s.headerContainer}>
      <Box className={s.main}>
        <BackButton onButtonClick={() => navigate(-1)} />
        <Text className={s.title}>{t("Broadcast view")}</Text>
        <StatusTag variant="dominoSubtleHeader" broadcastStatus={broadcast?.status} />
      </Box>
    </Box>
  );
};
