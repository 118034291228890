import { CustomVariableData } from "../conversation/ConversationData";
import { FlowData } from "../flowBuilder/FlowBuilderData";

export interface FlowListData {
  items: FlowAutomationData[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
}

export interface FlowAutomationData {
  id: string;
  botId: string;
  title: string;
  runCount: number;
  nodesCount: number;
  lastModifiedDate: string;
}

export interface UsedFlowsInFlowData {
  [item: string]: UsedFlowData;
}

export interface UsedFlowData {
  id: string;
  botId: string;
  title: string;
}

export interface BotVariablesInFlowData {
  flowId: string;
  botVariables: CustomVariableData[];
}

export interface ImportResultsFlowData {
  status: string;
  errors: ErrorResultFlowData[];
  flow?: FlowData;
  warnings: WarningResultFlowData[];
  variableWarnings: WarningScopeResultFlowData[];
}

export interface WarningScopeResultFlowData {
  data: ErrorResultFlowData[];
  key: string;
}

export interface ErrorResultFlowData {
  key: string;
  data: ErrorResultData;
}

export interface WarningResultFlowData {
  key: string;
  data: WarningResultData;
}

interface ErrorResultData {
  containerId: string;
  description: string;
  id: string;
  key: string;
  scope: string;
  type: string;
}

interface WarningResultData {
  operatorId: string;
  operatorName: string;
  teamId: string;
  teamName: string;
  typeDiscriminator: string;
  flowId: string;
}

export interface FlowTemplateData {
  id: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  flow?: string;
}

export enum importStatusResultEnum {
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}
