import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Text,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Input,
  Flex,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  DelayFlowActionModel,
  FlowActionModel,
  FlowModel,
  NodeModel,
  NodeValidationProp,
  TimeUnits,
} from "../../../FlowBuilderModel";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import s from "./DelayNodePopup.module.scss";

interface Props {
  flow: FlowModel;
  node: NodeModel;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  onClose: () => void;
}

export const DelayNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const flowAction = props.node.flowAction as DelayFlowActionModel;
  const maxValues = [
    { unit: TimeUnits.Seconds, maxValue: 31536000 },
    { unit: TimeUnits.Minutes, maxValue: 525600 },
    { unit: TimeUnits.Hours, maxValue: 8760 },
    { unit: TimeUnits.Days, maxValue: 365 },
  ];

  const validateValue = (unit: TimeUnits, value?: string) => {
    const allowedSymbols = /^[0-9]*$/;
    if (!value?.length) {
      return true;
    }
    if (!value.match(allowedSymbols)) {
      return false;
    }
    if (parseInt(value) === 0) {
      return false;
    }
    return !!maxValues.find(el => el.unit === unit && parseInt(value) < el.maxValue);
  };

  const onChange = (unit: TimeUnits, value?: string) => {
    const isValid = validateValue(unit, value);
    if (isValid) {
      props.onDataChange({ ...flowAction, unit, value } as DelayFlowActionModel);
    }
  };

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={props.onClose} variant="dominoDrawer">
        <DrawerContent>
          <DrawerHeader>{t("Delay")}</DrawerHeader>
          <DrawerBody>
            <Text color="grey">{t("editNodePopup.Delay duration")}</Text>
            <Flex mt="10px">
              <Input
                placeholder={t("editNodePopup.Value")}
                value={flowAction.value}
                onChange={e => onChange(flowAction.unit, e.target.value)}
                onBlur={() => props.validate(flowAction)}
                mr="10px"
                w="85%"
              />
              <Menu autoSelect={false} matchWidth offset={[0, 0]}>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  w="55%"
                  variant="dominoDefaultMenuBtn"
                  _active={{
                    borderColor: "blueLink",
                    borderBottomColor: "line",
                    backgroundColor: "white",
                    borderBottomRadius: "0",
                  }}
                >
                  {t(`timeUnits.${flowAction.unit ?? TimeUnits.Minutes}`)}
                </MenuButton>
                <MenuList className={s.dropDownList}>
                  {Object.keys(TimeUnits).map((el, index) => {
                    return (
                      <MenuItem
                        onClick={() => onChange(TimeUnits[el as keyof typeof TimeUnits], flowAction.value)}
                        key={index}
                        isDisabled={!validateValue(TimeUnits[el as keyof typeof TimeUnits], flowAction.value)}
                      >
                        {t(`timeUnits.${el}`)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Flex>
          </DrawerBody>
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>

          <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={() => props.onClose()} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  );
};
