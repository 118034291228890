import { Text } from "@chakra-ui/react";
import s from "../../Conversation.module.scss";
import { SystemMessageModel } from "../../ConversationModel";

interface Props {
  message: SystemMessageModel;
}

export const SystemMessageItem = (props: Props) => {
  return (
    <div className={s.systemMessageItem}>
      <Text className={s.systemMessageText}>{props.message.text}</Text>
    </div>
  );
};
