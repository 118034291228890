import { CustomVariableModel } from "../../common/AppEnums";
import { httpDelete, httpGet, httpPost, httpPut } from "../../common/BaseApi";
import { ContactVariableModel } from "./ContactModel";

export const getContactById = (contactId: string) => {
  return httpGet(`/api/contact/${contactId}`);
};

export const getContactVariables = (contactId: string, size: string, page: string) => {
  return httpGet(`/api/customVariable/values/contact/${contactId}?size=${size}&page=${page}`);
};

export const changeContactVariable = (contactId: string, contactVariable: ContactVariableModel) => {
  return httpPut(`/api/customVariable/values/contact/${contactId}/variable/${contactVariable.id}`, contactVariable);
};

export const createCustomVariable = (variable: CustomVariableModel) => {
  return httpPost(`/api/customvariable/definition`, variable);
};

export const deleteVariable = (variableId: string) => {
  return httpDelete(`/api/customVariable/definition/${variableId}`);
};

export const getIsVariableInUse = (variableId: string) => {
  return httpGet(`/api/customvariable/checkUsed/contact/${variableId}`);
};

export const editVariableDefenition = (contactId: string, contactVariable: ContactVariableModel) => {
  return httpPut(`/api/customvariable/definition/${contactId}`, contactVariable);
};

export const getContactBotConversationId = (contactId?: string, botId?: string) => {
  return httpGet(`/api/contact/${contactId}/bot/${botId}/conversation`);
};
