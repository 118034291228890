import ReactGA from "react-ga4";
import { AppSettings } from "../AppSettings";

declare const appSettings: AppSettings;

export const useGA = (category: string) => {
  const trackEvent = (action: string, label?: string) => {
    if (appSettings.GAIds) {
      ReactGA.event({ category, action, label });
    }
  };
  return trackEvent;
};

export const useGAWithCustomParameters = (category: string) => {
  const trackEvent = (action: string, label?: string, data?: unknown) => {
    if (appSettings.GAIds) {
      if (data != null) {
        const event_params = {
          category,
          label,
          ...data,
        };

        ReactGA.event(action, event_params);
      } else {
        ReactGA.event({ category, action, label });
      }
    }
  };
  return trackEvent;
};

export const setUserId = (userId: string) => {
  ReactGA.set({ userId: userId });
};

export const setUserProperty = (data: unknown) => {
  ReactGA.gtag("set", "user_properties", data);
};

export default useGA;
