import s from "../../UserProfile.module.scss";
import { Flex, Text, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";
import { UserState } from "../../../../common/user/UserState";
import { BotListModel } from "../../../dashboard/DashboardModel";
import { UserProfilePushItem } from "./components/UserProfilePushItem";
import { useAppSelector } from "../../../../common/state/store";
import { selectOrganizationList } from "../../UserProfileSlice";
import { NotificationBotsModel, NotificationSettingsModel, OrganisationProfileModel } from "../../UserProfileModel";
import { UserProfilePushTitle } from "./components/UserProfilePushTitle";

interface Props {
  changeDominoPush: (notifications: NotificationSettingsModel[]) => void;
  userProfile: UserState;
  bots: BotListModel | undefined;
  telegramBots: NotificationBotsModel[] | undefined;
  getPage: (page: number) => void;
  notifications?: NotificationSettingsModel[];
  currentOrganization: string;
}

export const UserProfilePushNotifications = (props: Props) => {
  const organizationList: OrganisationProfileModel[] | undefined = useAppSelector(selectOrganizationList);

  function getNotification(id: string) {
    return props.notifications?.find(notification => notification.organizationId === id);
  }

  function setBot(notification?: NotificationSettingsModel) {
    const pushes = props.notifications?.map(existingNotification => {
      if (existingNotification.organizationId === notification?.organizationId) {
        return notification;
      } else {
        return existingNotification;
      }
    });
    if (pushes) {
      props.changeDominoPush(pushes as NotificationSettingsModel[]);
    }
  }

  function setNewBot(notification: NotificationSettingsModel) {
    let pushes: NotificationSettingsModel[];
    if (props.notifications) {
      pushes = [...props.notifications, notification];
    } else {
      pushes = [notification];
    }
    props.changeDominoPush(pushes as NotificationSettingsModel[]);
  }

  function disableBot(disabledNotification: NotificationSettingsModel) {
    let pushes = props.notifications?.filter(notification => notification.organizationId !== disabledNotification.organizationId);
    if (pushes) {
      pushes = [...pushes, disabledNotification];
      props.changeDominoPush(pushes as NotificationSettingsModel[]);
    }
  }

  return (
    <div>
      <UserProfilePushTitle />
      <Accordion allowMultiple variant={"baseAccordion"}>
        {organizationList?.map(organization => {
          return (
            <AccordionItem key={organization.id}>
              <AccordionButton mb="0" className={`${s.pushItemGrid}`} _expanded={{ borderBottomRadius: "0" }}>
                <AccordionIcon />
                <Flex className={s.push} gap="8px" align="center">
                  <Text textAlign="end" pr={{ base: "0px", sm: "30px" }}>
                    {organization.name}
                  </Text>
                </Flex>
              </AccordionButton>
              <AccordionPanel>
                <UserProfilePushItem
                  userProfile={props.userProfile}
                  bots={props.bots}
                  telegramBots={props.telegramBots}
                  getPage={props.getPage}
                  notification={getNotification(organization.id)}
                  setBot={setBot}
                  setNewBot={setNewBot}
                  disableBot={disableBot}
                  organizationId={organization.id}
                />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};
