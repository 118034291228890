import s from "../../../UserProfile.module.scss";
import { Button, Menu, MenuButton, MenuItem, MenuList, Switch, Text } from "@chakra-ui/react";
import { UserNotificationsProvider } from "../../../../../common/user/UserModel";
import React from "react";
import { UserState } from "../../../../../common/user/UserState";
import { BotListModel } from "../../../../dashboard/DashboardModel";
import { useTranslation } from "react-i18next";
import { NotificationBotsModel, NotificationSettingsModel } from "../../../UserProfileModel";

interface Props {
  userProfile: UserState;
  bots: BotListModel | undefined;
  telegramBots: NotificationBotsModel[] | undefined;
  setBot: (notification?: NotificationSettingsModel) => void;
  setNewBot: (notification: NotificationSettingsModel) => void;
  disableBot: (disabledNotification: NotificationSettingsModel) => void;
  getPage: (page: number) => void;
  notification?: NotificationSettingsModel;
  organizationId: string;
}

export const UserProfilePushItem = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "userProfile" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const getBotName = (botId: string) => {
    return props.telegramBots?.find(x => x.id === botId)?.name;
  };

  const IsBotsChecked = (provider: UserNotificationsProvider, expectedProvider: UserNotificationsProvider) => {
    switch (expectedProvider) {
      case UserNotificationsProvider.dominoBot:
        return provider === expectedProvider;
      case UserNotificationsProvider.customBot:
        return provider === expectedProvider;
      default:
        return false;
    }
  };

  function setDominoBot(isChecked: boolean) {
    if (props.notification) {
      if (!isChecked) {
        const notification: NotificationSettingsModel = {
          ...props.notification,
          provider: UserNotificationsProvider.dominoBot,
        };
        props.setBot(notification);
      } else {
        disableNotification(props.notification.customBotId ?? "");
      }
    }
  }

  function setCustomBot(botId: string, isChecked: boolean) {
    if (props.notification) {
      if (!isChecked) {
        const notification: NotificationSettingsModel = {
          ...props.notification,
          provider: UserNotificationsProvider.customBot,
          customBotId: botId,
        };
        props.setBot(notification);
      } else {
        disableNotification(props.notification.customBotId ?? "");
      }
    }
  }

  function addNewNotification(botId: string, provider: UserNotificationsProvider) {
    const notification: NotificationSettingsModel = {
      provider: provider,
      customBotId: botId,
      organizationId: props.organizationId,
    };
    props.setNewBot(notification);
  }

  function disableNotification(botId: string) {
    const notification: NotificationSettingsModel = {
      provider: UserNotificationsProvider.none,
      customBotId: botId,
      organizationId: props.organizationId,
    };
    props.disableBot(notification);
  }

  return (
    <div>
      <div className={s.contentItem}>
        <Text width="100%" maxWidth="300px" variant="large">
          {t("Domino Bot")}
        </Text>
        <Switch
          onChange={() =>
            props.notification
              ? setDominoBot(IsBotsChecked(props.notification.provider, UserNotificationsProvider.dominoBot))
              : addNewNotification("", UserNotificationsProvider.dominoBot)
          }
          isChecked={props.notification?.provider === UserNotificationsProvider.dominoBot}
          justifySelf="center"
          variant="dominoDefaultGreen"
        />
      </div>
      {props.telegramBots && props.telegramBots.length > 0 && (
        <div className={s.contentItem}>
          <Text width="100%" maxWidth="300px" variant="large">
            {t("Custom Bot")}
          </Text>
          <Switch
            onChange={() =>
              props.notification
                ? setCustomBot(
                    props.notification?.customBotId ? props.notification.customBotId : "",
                    IsBotsChecked(props.notification.provider, UserNotificationsProvider.customBot),
                  )
                : addNewNotification("", UserNotificationsProvider.customBot)
            }
            isChecked={props.notification?.provider === UserNotificationsProvider.customBot}
            justifySelf="center"
            variant="dominoDefaultGreen"
          />
          {props.notification?.provider === UserNotificationsProvider.customBot && (
            <Menu variant="dominoGroupedMenu" gutter={0} matchWidth={true} preventOverflow={false}>
              <MenuButton
                as={Button}
                variant="dominoDefaultMenuBtn"
                _active={{ borderColor: "mainPurple", borderBottom: "mainPurple", borderRadius: "8px" }}
                ml={"18px"}
                maxW={"329px"}
              >
                {props.notification?.customBotId ? (
                  getBotName(props.notification?.customBotId) ?? <>{ct("Select")}</>
                ) : (
                  <>{ct("Select")}</>
                )}
              </MenuButton>
              <MenuList w="100%" maxH={"320px"} overflow={"auto"}>
                {props.telegramBots.length
                  ? // eslint-disable-next-line array-callback-return
                    props.telegramBots.map(bot => {
                      if (props.notification?.organizationId === bot.organizationId) {
                        return (
                          <MenuItem
                            color={"mainPurple"}
                            key={`bot-item-${bot.id}`}
                            as="button"
                            value={bot.name}
                            onClick={() => setCustomBot(bot.id, false)}
                          >
                            <Text color={"black"}>{bot.name}</Text>
                          </MenuItem>
                        );
                      }
                    })
                  : t("(No bots")}
              </MenuList>
            </Menu>
          )}
        </div>
      )}
    </div>
  );
};
