import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Link,
  Mark,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import s from "../EditContactInfo/EditContactInfo.module.scss";
import dayjs from "dayjs";
import TelegramIcon from "../../../../../assets/images/telegramImage.svg";
import { ContactModel } from "../../../ContactModel";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../common/state/store";
import { selectChatBot } from "../../../ContactSlice";
import { BotModel } from "../../../../sidebar/SidebarModel";
import { useNowWidthView } from "../../../../layout/LayoutHelper/ResolutionHooks";
import ClipboardText from "../../../../../UI/atoms/clipboardText/ClipboardText";
import { OverflowEllipsesText } from "../../../../../UI/atoms/textOverflow/OverflowEllipsesText";

interface Props {
  contact: ContactModel | undefined;
  apiBaseUrl: string;
}

export const EditContactInfo = (props: Props) => {
  const t = useTranslation("translation", { keyPrefix: "contact" }).t;
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const nowWidth = useNowWidthView();

  const getContactBotNames = (isSplit?: boolean) => {
    const contactBotNames = props.contact?.bots.map((el, index, arr) => {
      let name = el.name;
      if (index !== arr.length - 1) {
        name += ", ";
      }
      return name;
    });
    return contactBotNames;
  };

  const redirectBotChat = (bot: BotModel) => {
    if (props.contact) {
      dispatch(selectChatBot({ bot: bot, contactId: props.contact.id }));
    }
  };

  return (
    <Grid className={s.gridContactEditInfo}>
      <Container variant="dominoContentBlock" className={s.contactEditUserInfo}>
        <Box className={s.avatar}>
          <Avatar
            boxSize={nowWidth >= 1001 ? "100px" : "84px"}
            name={props.contact?.lastName ? `${props.contact?.firstName} ${props.contact?.lastName}` : props.contact?.firstName}
            src={props.contact?.avatars?.[0] ? `${props.apiBaseUrl}/file/${props.contact?.avatars?.[0]?.[0].fileId}` : ""}
            bg="midDeepBlue"
            color={"white"}
            border="1px solid"
            borderColor="mainPurple"
          />
        </Box>
        <div className={s.userInfo}>
          <OverflowEllipsesText
            text={`${props.contact?.firstName ?? ""} ${props.contact?.lastName ?? ""}`}
            content={
              <Text variant={"largeBold"} className={s.contactInfoName}>
                {`${props.contact?.firstName ?? ""} ${props.contact?.lastName ?? ""}`}
              </Text>
            }
          />
          <Box className={s.contactInfoLineTelegram}>
            <Text variant="text14" color={"darkGrey"}>
              {t("Telegram id")}
            </Text>
            <Flex className={s.contactInfoTelegramIdWrp}>
              <ClipboardText
                textIsCopy={props.contact?.externalId || ""}
                elemIsCopy={
                  <OverflowEllipsesText
                    text={props.contact?.externalId}
                    content={
                      <Text variant="text14" align={"left"} className={s.contactInfoTelegramId}>
                        {props.contact?.externalId}
                      </Text>
                    }
                  />
                }
                color="#3e5e95"
              />
            </Flex>
          </Box>
          <Box className={s.contactInfoLineCreated}>
            <Text variant="text14" color={"darkGrey"}>
              {t("Created")}
            </Text>
            <Text display={"inline-grid"} variant="text14" align={"left"} className={s.contactInfoTelegramDateCreated}>
              <span>{dayjs(props.contact?.createDate).format("DD.MM.YYYY HH:mm")}</span>
            </Text>
          </Box>
        </div>
      </Container>
      <Container variant="dominoContentBlock" className={s.contactInfo}>
        <Box w="100%" className={s.boxContactInfo}>
          <Flex direction="column" justify="space-between">
            <Flex justify="space-between" align="center">
              <Flex align="center">
                <Box pb={"2px"}>{props.contact?.username && <Image src={TelegramIcon} boxSize={"24px"} />}</Box>
                <Link href={`https://t.me/${props.contact?.username}`} ml={"4px"} mb={"4px"} color={"blueLink"} target="_blank">
                  <Text className={s.userName}>{props.contact?.username}</Text>
                </Link>
              </Flex>
              {props.contact?.bots.length === 1 ? (
                <Button
                  minW="115px"
                  onClick={() => {
                    if (props.contact?.bots[0] !== undefined) {
                      redirectBotChat(props.contact?.bots[0]);
                    }
                  }}
                  variant="dominoViolet"
                >
                  {t("Start Chat")}
                </Button>
              ) : (
                <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} placement={"left-start"}>
                  <PopoverTrigger>
                    <Button minW="115px" onClick={onOpen} variant="dominoViolet">
                      {t("Start Chat")}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent maxH="200px" maxW="250px">
                    <PopoverArrow />
                    <PopoverHeader>{t("Please, choose bot")}</PopoverHeader>
                    <PopoverBody px="0px" overflow="auto">
                      {props.contact?.bots.map(el => (
                        <Text
                          px="8px"
                          _hover={{ bgColor: "var(--chakra-colors-bgMiddle)" }}
                          py="5px"
                          cursor="pointer"
                          noOfLines={1}
                          key={el.id}
                          onClick={() => redirectBotChat(el)}
                        >
                          {el.name}
                        </Text>
                      ))}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </Flex>
          </Flex>
          <Box>
            <Flex>
              <Mark color="darkGrey" mr={"8px"} fontSize={"14px"}>
                {t("Bot list")}
              </Mark>
              {props.contact && <Box className={s.editContactBotList}>{getContactBotNames()}</Box>}
            </Flex>
          </Box>
        </Box>
      </Container>
    </Grid>
  );
};
