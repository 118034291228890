import { Input, InputGroup, InputGroupProps, InputLeftElement, InputProps, InputRightElement } from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/searchIcon.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { notHaveOnlySpaces } from "../../../common/validation/defaultValidators";
import s from "./InputSearch.module.scss";
import { ChangeEvent, RefObject } from "react";

interface Props extends InputProps {
  searchText?: string | undefined;
  inputGroupStyles?: InputGroupProps;
  ref?: RefObject<HTMLInputElement>;
  setSearchText: (value: string) => void;
  classprop?: string;
}

export const InputSearch = ({ inputGroupStyles, placeholder, setSearchText, searchText, ref, ...props }: Props) => {
  const onChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    const errors = notHaveOnlySpaces()(event.target.value);
    if (errors) return;
    setSearchText(event.target.value);
  };

  const onDeleteSearchText = () => {
    setSearchText("");
  };

  return (
    <InputGroup {...inputGroupStyles} className={`${s.inputContainer} ${props.classprop}`}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon />
      </InputLeftElement>
      <Input
        className={s.inputBlock}
        isInvalid={false}
        placeholder={placeholder}
        value={searchText}
        ref={ref}
        onChange={onChangeSearchText}
        {...props}
        data-pw="input-search"
      />
      <InputRightElement>
        {searchText && <CrossIcon cursor={"pointer"} className={s.crossIcon} onClick={onDeleteSearchText} />}
      </InputRightElement>
    </InputGroup>
  );
};
