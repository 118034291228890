import s from "./ImageNodePopup.module.scss";
import sm from "../NodeEditPopup.module.scss";
import { ReactComponent as ImageIconGrey } from "../../../../../assets/icons/imageIcon.svg";
import { CloseIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  CustomVariablesModel,
  SendPhotoFlowActionModel,
  FlowModel,
  NodeModel,
  NodeValidationProp,
  FlowActionModel,
} from "../../../FlowBuilderModel";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { editNode, saveNode, selectCustomVariables, selectNodeEditorState } from "../../../FlowBuilderSlice";
import { useEffect, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  FormControl,
  DrawerFooter,
  Box,
  Icon,
  Button,
  Text,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import { ButtonsList } from "../ButtonsList";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";
import { useTranslation } from "react-i18next";
import { ButtonModel, ReplyKeyboardButtonModel } from "../../../../../common/AppButtonsModel";
import { Fallback } from "../Fallback";
import { getButtonsWithExactType } from "../../../utils";
import { UrlTextEditor } from "../UrlTextEditor/UrlTextEditor";
import { AppSettings } from "../../../../../common/AppSettings";
import { store } from "../../../../..";
import { DragAndDropContainer, handleDataChange, handleDrag } from "../DragAndDropContainer";

declare const appSettings: AppSettings;

interface Props {
  addButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  setButtons: (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => void;
  deleteButton: (buttonId: string) => void;
  addCustomVariableText: (variable: CustomVariablesModel, variableMaxLength: number) => void;
  addCustomVariableUrl: (variable: CustomVariablesModel, variableMaxLength: number) => void;
  editButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  dataChange: (flowAction: FlowActionModel) => void;
  flow: FlowModel;
  node: NodeModel;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
}

export const ImageNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const dispatch = useAppDispatch();
  const variables = useAppSelector(selectCustomVariables);
  const currentNode = useAppSelector(selectNodeEditorState);
  const flowAction = props.node.flowAction as SendPhotoFlowActionModel;
  const [imageUrlPopupState, setImageUrlPopupState] = useState(false);
  const [imageUrlState, setImageUrlState] = useState(flowAction.fileUrl ?? "");

  const [isDragging, setDragging] = useState(false);
  const [showBtnDone, setshowBtnDone] = useState(false);
  const [isChangeUrl, setChangeUrl] = useState(false);
  const [customVariables, setCustomVariables] = useState<{
    text: CustomVariablesModel;
    maxLength: number;
    cursorPosition: number;
  } | null>(null);
  const fieldTypeExt = ".jpeg,.jpg,.png,.tiff,.gif";
  const fileSizeMB = 10;
  const fileSizeB = fileSizeMB * 1024 * 1024;

  useEffect(() => {
    props.validate(flowAction);
    // eslint-disable-next-line
  }, [isChangeUrl]);

  if (!currentNode) {
    return <></>;
  }

  const onDataChange = (text: string, file?: File) => {
    handleDataChange({ file, dispatch, currentNode, fieldTypeExt, fileSizeB });
    const newData: SendPhotoFlowActionModel = {
      ...flowAction,
      text,
    };
    props.dataChange(newData);
    dispatch(
      editNode({
        ...currentNode,
        flowAction: newData,
      }),
    );
  };

  const onDataUrlChange = (text: string) => {
    setImageUrlState(text);
    setChangeUrl(true);
  };

  const onCloseValidate = () => {
    dispatch(
      saveNode({
        flow: props.flow,
        node: props.node,
      }),
    );
  };

  const addImageUrl = (imageUrl: string) => {
    const updatedData: SendPhotoFlowActionModel = {
      ...flowAction,
      file: undefined,
      fileUrl: imageUrl.length ? imageUrl : undefined,
    };
    if (customVariables?.text) {
      props.addCustomVariableUrl(customVariables.text, customVariables?.maxLength);
    }
    dispatch(
      editNode({
        ...currentNode,
        flowAction: updatedData,
      }),
    );
  };

  const setImageUrl = () => {
    if (validateUrl(imageUrlState).boolean) {
      addImageUrl(imageUrlState);
      setChangeUrl(false);
    }
  };

  const deleteImageUrl = () => {
    const updatedData: SendPhotoFlowActionModel = {
      ...flowAction,
      file: undefined,
      fileUrl: undefined,
    };

    dispatch(
      editNode({
        ...currentNode,
        flowAction: updatedData,
      }),
    );

    setImageUrlPopupState(false);
    setImageUrlState("");
  };

  const deleteImageFile = () => {
    setDragging(false);
    const updatedData: SendPhotoFlowActionModel = {
      ...flowAction,
      fileId: undefined,
    };

    dispatch(
      editNode({
        ...currentNode,
        flowAction: updatedData,
      }),
    );
  };

  const handleFallbackChange = (text: string) => {
    const newFlowAction = { ...flowAction, fallbackMessage: text };
    dispatch(
      editNode({
        ...currentNode,
        flowAction: newFlowAction,
      }),
    );
  };

  const flowActionDataChange = (flowAction: SendPhotoFlowActionModel) => {
    dispatch(
      editNode({
        ...currentNode,
        flowAction: flowAction,
      }),
    );
  };

  const validateUrl = (url: string) => {
    const regex = /@{([\wа-яА-ЯёЁ-]+):([\wа-яА-ЯёЁ-]+)}/g;
    const findVariables = url.match(regex);

    const customVariables = store.getState().app.flowBuilderState.customVariables;
    const notExist = findVariables?.find(el => {
      const scopeAndKey = el.replace(regex, "$1:$2");
      const [scope, key] = scopeAndKey.split(":");
      const isVariableExist = customVariables?.find(el => key && el.key === key && el.scope === scope);
      return isVariableExist === undefined;
    });

    if (variables && findVariables) {
      let isCorrectVariable = true;

      findVariables?.forEach(findVar => {
        const isFind = variables.find(el => `@{${el.scope}:${el.key}}` === findVar);
        isCorrectVariable = !!isFind;
      });

      if (findVariables.length > 0) {
        return { boolean: isCorrectVariable, error: t("variableDoesNotExist", { variable: notExist }) };
      }
    }
    if (url.length) {
      /* eslint-disable-next-line no-useless-escape */
      const urlRegex =
        // eslint-disable-next-line no-useless-escape
        /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_,\+.~#?&\/=]*)$/;

      return { boolean: urlRegex.test(url), error: "Website address is required" };
    }

    return { boolean: true, error: "" };
  };

  return (
    <Drawer isOpen={true} placement="right" onClose={onCloseValidate} variant="dominoDrawer">
      <DrawerContent>
        <Loading scope="nodeValidate" />
        <DrawerHeader>{t("Image Message")}</DrawerHeader>
        <DrawerBody paddingInline="7px">
          <div className={s.popupWrapper}>
            <div className={s.popup}>
              <div className={s.wrapper}>
                <div onDragEnter={e => handleDrag(e, setDragging)} className={s.popupItem}>
                  <FormControl
                    className={`${sm.itemFileWrp} ${(flowAction.file || flowAction.fileId) && sm.itemFileWrpImg} ${
                      (flowAction.fileUrl || imageUrlPopupState) && sm.itemFileWrpUrl
                    } ${sm.itemImage}`}
                  >
                    <div
                      className={`${s.popupItem} ${s.imageBlock} ${
                        (flowAction.fileUrl || imageUrlPopupState) && s.imageBlockUrl
                      }`}
                    >
                      <Loading scope={`postFileEditor${props.node.id}`} />
                      {(flowAction.fileUrl || imageUrlPopupState) && (
                        <>
                          <Flex justifyContent={"space-between"}>
                            <Flex>
                              <p className={s.imgBlockUrlLabel}>{t("editNodePopup.URL")}</p>
                              <Tooltip
                                variant="dominoLight"
                                placement="right"
                                whiteSpace={"pre-wrap"}
                                label={t("editNodePopup.Add Custom Variable To Img Url Text")}
                              >
                                <QuestionOutlineIcon
                                  ml={"4px"}
                                  boxSize={"15px"}
                                  color="darkGrey"
                                  _hover={{ color: "darkPurple" }}
                                />
                              </Tooltip>
                            </Flex>
                            <div
                              onClick={() => deleteImageUrl()}
                              className={`${sm.deleteIcon} ${sm.deleteIconFileUrl}`}
                              data-pw="closeIcon"
                            >
                              <CloseIcon boxSize="10px" cursor="pointer" stroke={"#8592A3"} color={"#8592A3"} />
                            </div>
                          </Flex>
                          <UrlTextEditor
                            autoFocus={true}
                            isInvalid={!validateUrl(imageUrlState).boolean}
                            variables={variables || []}
                            addCustomVariableUrl={setCustomVariables}
                            text={imageUrlState}
                            onChange={t => onDataUrlChange(t)}
                            onFocus={() => setshowBtnDone(true)}
                            onBlur={() => setshowBtnDone(false)}
                            maxLength={500}
                            placeholder="https://example.com/640x360.jpeg"
                            variant={"dominoOutline"}
                            flowAction={flowAction}
                            maxHeightWithoutScroll={10}
                          />
                          {!validateUrl(imageUrlState).boolean && (
                            <Box color="mainRed" fontSize={"12px"}>
                              <Text data-pw="errorText">{validateUrl(imageUrlState).error}</Text>
                            </Box>
                          )}
                          {(showBtnDone || isChangeUrl) && (
                            <div onClick={setImageUrl}>
                              <Button variant="dominoViolet" mt="16px" w="100%" data-pw="doneButton">
                                {t("editNodePopup.Done")}
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                      {flowAction.fileId ? (
                        <div className={`${sm.imageFilteWrp} ${sm.imageFilteWrpAddImg}`}>
                          <div
                            onClick={() => deleteImageFile()}
                            className={`${sm.deleteIcon} ${sm.deleteIconFile}`}
                            data-pw="closeIcon"
                          >
                            <CloseIcon boxSize="10px" cursor="pointer" />
                          </div>
                          <label className={s.imageInput}>
                            <img
                              className={s.addedImage}
                              src={`${appSettings.apiBaseUrl}/file/${flowAction.fileId}`}
                              alt=""
                              data-pw="imageInput"
                            />
                            <input
                              onChange={e => {
                                if (e.target.files) {
                                  onDataChange(flowAction.text ?? "", e.target.files[0]);
                                }
                              }}
                              type="file"
                              accept={fieldTypeExt}
                            />
                          </label>
                        </div>
                      ) : (
                        !flowAction.fileUrl &&
                        !imageUrlPopupState && (
                          <Box display="flex" flexDirection="column" alignItems="center">
                            <Icon as={ImageIconGrey} boxSize="28px" mb="8px" />
                            <Box textAlign="center">
                              <label className={s.input}>
                                <div className={s.uploadImage} data-pw="uploadImage">
                                  {t("editNodePopup.Upload Image")}
                                </div>
                                <input
                                  onChange={e => {
                                    if (e.target.files) {
                                      onDataChange(flowAction.text ?? "", e.target.files[0]);
                                    }
                                  }}
                                  type="file"
                                  accept={fieldTypeExt}
                                />
                              </label>
                              <span className={s.imageUrlWrapper}>
                                &#160;{t("editNodePopup.or")}&#160;
                                <span
                                  onClick={() => setImageUrlPopupState(true)}
                                  className={s.uploadImage}
                                  data-pw="uploadImageUrl"
                                >
                                  {t("editNodePopup.Add Image URL")}
                                </span>
                              </span>
                            </Box>
                            <Text textAlign="center" variant="small">
                              {t("editNodePopup.jpeg, jpg, png, gif up to 10 mb")}
                            </Text>
                          </Box>
                        )
                      )}
                      {isDragging && !flowAction.fileId && (
                        <DragAndDropContainer
                          setDragging={setDragging}
                          handleDataChange={{ dispatch, currentNode, fieldTypeExt, fileSizeB }}
                        />
                      )}
                    </div>
                  </FormControl>
                </div>
                <MessageTextEditor
                  variables={variables ?? []}
                  text={flowAction.text ?? ""}
                  maxLength={500}
                  onChange={t => onDataChange(t, flowAction.file)}
                  validate={props.validate}
                  flowAction={flowAction}
                  addCustomVariableText={props.addCustomVariableText}
                />

                <ButtonsList
                  node={props.node}
                  buttons={
                    (getButtonsWithExactType(flowAction?.replyMarkup, flowAction) as
                      | ButtonModel[][]
                      | ReplyKeyboardButtonModel[][]) ?? []
                  }
                  flowAction={flowAction}
                  setButtons={props.setButtons}
                  addButton={props.addButton}
                  deleteButton={props.deleteButton}
                  editButton={props.editButton}
                />
                <Fallback
                  validate={props.validate}
                  flowAction={flowAction}
                  dataChange={flowActionDataChange}
                  maxLength={500}
                  text={""}
                  onChange={handleFallbackChange}
                />
              </div>
            </div>
          </div>
        </DrawerBody>
        {props.validationInfo.isError && (
          <DrawerFooter margin={"8px 8px 0 8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
        )}
        <Button
          variant="dominoViolet"
          w={"auto"}
          margin={"24px 16px 16px 16px"}
          onClick={() => onCloseValidate()}
          data-pw="apply-button"
        >
          {t("Apply")}
        </Button>
      </DrawerContent>
    </Drawer>
  );
};
