import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";

export default function DashboardHeader() {
  const { t } = useTranslation("translation", {
    keyPrefix: "dashboard.dashboardHeader",
  });

  useDocumentTitle(t("Dashboard"), "Dashboard");

  return (
    <Flex alignItems={"center"} width={"60%"} position={"relative"}>
      <Text variant="h2" color="midDeepBlue" py="16px">
        {t("Dashboard")}
      </Text>
    </Flex>
  );
}
