import { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ImportContactsModal } from "../../importContacts/ImportContactsModal";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import { selectContactList, setStateSearchParams, selectCustomVariables } from "../ContactListSlice";
import { NewContactModal } from "./NewContactModal/NewContacModal";
import { useGetLoadingState } from "../../../common/loading/hooks/useGetLoadingState";
import { useDocumentTitle } from "../../../common/hooks/useDocumentTitle";
import sh from "../components/ContactListHeader.module.scss";
import h from "../../../components/headers/HeaderStyles.module.scss";
import { InputSearch } from "../../../UI/molecules/inputSearch/InputSearch";
import useDebounce from "../../../common/hooks/useDebounce";
import { ComplexFilterFieldTypes, ComplexFilterGroupTypes, ConditionsByField } from "../../complexFilter/ComplexFilterModel";
import { ComplexFilter } from "../../complexFilter/ComplexFilter";
import { selectCurrentBotId } from "../../sidebar/SidebarSlice";
import { getCustomVariables } from "../../conversation/ConversationSlice";
import { CONTACTS_PAGE_SIZE } from "../../../common/paginator/paginatorSizes";

export const ContactListHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "contactList" });
  const cfs = useTranslation("translation", {
    keyPrefix: "complexFilter.systemFields",
  }).t;
  const cf = useTranslation("translation", {
    keyPrefix: "complexFilter",
  }).t;

  useDocumentTitle(t("Contacts"), "Contacts");
  const dispatch = useAppDispatch();
  const importTransl = useTranslation("translation", { keyPrefix: "importContacts" }).t;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isNewContactModalOpen, onOpen: onNewContact, onClose: onNewContactModalClose } = useDisclosure();
  const contactList = useAppSelector(selectContactList);
  const contactListLoading = useGetLoadingState("getContactList");
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const page = searchParams.get("page") ?? "";
  const [searchText, setSearchText] = useState(search);
  const debouncedSearchText = useDebounce(searchText, 500);
  const [contactsListQueryString, setContactListQueryString] = useState<string>("");
  const currentBotId = useAppSelector(selectCurrentBotId);
  const variables = useAppSelector(selectCustomVariables);
  const [cashedFilters, setCaсhedFilters] = useState("");
  const [cashedSearchString, setCashedSearchString] = useState("");
  const [pageParam, setPageParam] = useState(page);

  const constructSearchAndFilterParams = (search: string | undefined, debouncedSearch: string, filters: string, page: string) => {
    const newParameters = new URLSearchParams();
    debouncedSearch && newParameters.set("search", debouncedSearch);
    if (filters) {
      newParameters.set("filters", filters);
    }
    if (page) {
      if (cashedFilters !== contactsListQueryString || debouncedSearch !== cashedSearchString) {
        setPageParam(String(1));
        newParameters.set("page", String(1));
      } else {
        newParameters.set("page", page);
      }
    }
    dispatch(setStateSearchParams({ filters: filters, search: debouncedSearch }));

    return newParameters;
  };

  const newQueryParams = useMemo(
    () => constructSearchAndFilterParams(search, debouncedSearchText, contactsListQueryString, page),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchText, contactsListQueryString, page],
  );

  useEffect(() => {
    setCaсhedFilters(contactsListQueryString);
    setCashedSearchString(searchText);
    setSearchParams(newQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newQueryParams, setSearchParams]);

  useEffect(() => {
    if (currentBotId) {
      dispatch(getCustomVariables({ botId: currentBotId }));
    }
  }, [currentBotId, dispatch]);

  const getContacts = useCallback((queryFilterString?: string, queryParamsString?: string) => {
    const finalFilterString = queryFilterString ?? "";
    const finalParamsString = queryParamsString ?? "";

    const queryString = `${finalFilterString}&${finalParamsString}`;
    return queryString;
  }, []);

  const createFieldsToContactsFilter = () => {
    let contactList = [
      { fieldName: "Id", fieldValue: "id" },
      { fieldName: "Username", fieldValue: "username" },
      { fieldName: "First Name", fieldValue: "firstName" },
      { fieldName: "Last Name", fieldValue: "lastName" },
      { fieldName: "Phone Number", fieldValue: "phoneNumber" },
    ];
    contactList = contactList.map(el => {
      return {
        ...el,
        type: ComplexFilterFieldTypes.Text,
        fieldName: cfs(`${el.fieldName}`),
        groupType: ComplexFilterGroupTypes.ContactFields,
      };
    });

    const groupList = [{ label: t("Contacts"), items: contactList }];

    if (variables?.length) {
      const contactVariableList = variables
        ?.filter(el => ConditionsByField.allowedFieldTypes.includes(el.type))
        .map(el => {
          return {
            fieldName: el.key,
            fieldValue: el.key,
            type: el.type,
            groupType: ComplexFilterGroupTypes.CustomVariables,
            description: el.description,
            id: el.id,
          };
        });
      groupList.push({ label: cf("Contact Fields"), items: contactVariableList });
    }

    return { groups: groupList };
  };

  return (
    <div className={`${sh.headerContent}`}>
      <Flex gap="16px" className={`${h.titleBlock} ${sh.titleBlock}`}>
        <Text variant="h2" color="newMiddleDeepBlue" py={{ base: 0, md: "16px" }}>
          {t("Contacts")}
        </Text>
      </Flex>
      <NewContactModal newContactState={isNewContactModalOpen} onClose={onNewContactModalClose} />
      {contactList &&
        !(
          contactList &&
          !contactListLoading &&
          contactList.items.length === 0 &&
          search === "" &&
          contactsListQueryString === ""
        ) && (
          <>
            <Flex className={`${h.InputSearchBlock} ${sh.InputSearchBlock}`} justifyContent={"space-between"}>
              <Flex className={sh.InputFilterSearchBlock}>
                <InputSearch
                  classprop={sh.inptuSearch}
                  placeholder={`${t("Search by contacts")}`}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  position={"relative"}
                />
                {variables && (
                  <ComplexFilter
                    componentClassName={"ContactsFilter"}
                    filterFields={createFieldsToContactsFilter()}
                    getItemsWithFilter={getContacts}
                    isActive={false}
                    componentSlice="ContactList"
                    contactListQueryString={contactsListQueryString}
                    setContactListQueryString={setContactListQueryString}
                    size={CONTACTS_PAGE_SIZE}
                    page={pageParam}
                  />
                )}
              </Flex>
              <div className={sh.buttonContainer}>
                <Button variant="dominoOutlineViolet" onClick={() => onNewContact()}>
                  + {t("New Contact")}
                </Button>
                <Button variant="dominoViolet" onClick={onOpen} data-pw="import-contacts-button">
                  {importTransl("Import Contacts")}
                </Button>
              </div>
            </Flex>
          </>
        )}
      <ImportContactsModal isOpen={isOpen} onClose={onClose} />
    </div>
  );
};
