import { t } from "i18next";

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) {
    return "0";
  } else {
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      t(`conversation.fileSize.bytes`),
      t(`conversation.fileSize.KB`),
      t(`conversation.fileSize.MB`),
      t(`conversation.fileSize.GB`),
      t(`conversation.fileSize.TB`),
    ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
