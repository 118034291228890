import { ButtonModel } from "../../common/AppButtonsModel";
import { BroadcastMessageTypeDescriminator, BroadcastFormModel, FileModel } from "./BroadcastModel";

export const INITIAL_BROADCAST_FORM_DATA: BroadcastFormModel = {
  name: "",
  isScheduled: false,
  scheduledDate: null,
  message: {
    text: "",
    buttons: [] as ButtonModel[],
    typeDiscriminator: BroadcastMessageTypeDescriminator.BroadcastTextMessage,
  },
  recipients: [],
  initialRecipients: [],
  includedRecipients: null,
  excludedRecipients: null,
  recipientCounter: 0,
  checkedFlag: false,
  uncheckedFlag: true,
};

export interface BroadcastFormValidationFields extends BroadcastFormModel {
  currentFile?: FileModel | null;
}
