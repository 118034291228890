import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/ru";

dayjs.extend(relative);

dayjs.extend(updateLocale);

// following two functions needed for default ru locale because we update locale in fromNowChat.ts and we can't simply set locale to default
function plural(word: string, num: number) {
  const forms = word.split("_");
  return num % 10 === 1 && num % 100 !== 11
    ? forms[0]
    : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
    ? forms[1]
    : forms[2]; // eslint-disable-line
}

function relativeTimeWithPlural(number: number, withoutSuffix: boolean, key: "mm" | "hh" | "dd" | "MM" | "yy" | "m") {
  const format = {
    mm: withoutSuffix ? "минута_минуты_минут" : "минуту_минуты_минут",
    hh: "час_часа_часов",
    dd: "день_дня_дней",
    MM: "месяц_месяца_месяцев",
    yy: "год_года_лет",
  };
  if (key === "m") {
    return withoutSuffix ? "минута" : "минуту";
  }

  return `${number} ${plural(format[key], +number)}`;
}

export function fromNow(
  date?: string | number | dayjs.Dayjs | Date | null | undefined,
  locale = "en",
  short?: boolean,
  withoutSuffix?: boolean | undefined,
) {
  dayjs.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });
  dayjs.updateLocale("ru", {
    relativeTime: {
      future: "через %s",
      past: "%s назад",
      s: "несколько секунд",
      m: relativeTimeWithPlural,
      mm: relativeTimeWithPlural,
      h: "час",
      hh: relativeTimeWithPlural,
      d: "день",
      dd: relativeTimeWithPlural,
      M: "месяц",
      MM: relativeTimeWithPlural,
      y: "год",
      yy: relativeTimeWithPlural,
    },
  });
  if (locale === "ru") {
    dayjs.locale("ru");
  } else {
    dayjs.locale("en");
  }
  return dayjs(date).fromNow(withoutSuffix);
}
