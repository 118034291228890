import { CSSProperties, RefObject } from "react";
import { CustomVariableModel } from "../../common/AppEnums";
import { CustomVariableContextMenuType } from "../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { FormatAction } from "../../common/utils/escapeMarkdown";

export enum InputType {
  "Input" = "Input",
  "TextArea" = "TextArea",
}

export interface InputWrapperPropsModel {
  type: InputType;
  cursorPosition?: number;
  children: React.ReactNode;
  maxLength: number;
  charactersNumber: number;
  variablesProps?: VariablesPropsModel;
  emojiProps?: EmojiPropsModel;
  buttonBlockStyle?: CSSProperties;
  customButton?: JSX.Element;
  addFormatting?: (action: FormatAction) => void;
  childRef?: RefObject<HTMLTextAreaElement> | RefObject<HTMLInputElement>;
}

export interface VariablesPropsModel {
  variables: CustomVariableModel[];
  addCustomVariableText: (variable: CustomVariableModel) => void;
  ContextModelType?: CustomVariableContextMenuType;
}

export interface EmojiPropsModel {
  addEmoji: (emojiData: string) => void;
}
