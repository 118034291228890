export const endingHours = (ruText: string, enText: string, time: string) => {
  const lng = localStorage.getItem("i18nextLng");
  if (lng === "ru") {
    const lastNumber = time.slice(-1);
    if (lastNumber === "1" && (Number(time) < 10 || Number(time) > 20)) {
      return ruText;
    } else if (Number(lastNumber) > 1 && Number(lastNumber) < 5 && (Number(time) < 10 || Number(time) > 20)) {
      return ruText + "а";
    } else {
      return ruText + "ов";
    }
  } else {
    if (time === "1") {
      return enText;
    } else {
      return enText + "s";
    }
  }
};

export const endingMinutes = (ruText: string, enText: string, time: string) => {
  const lng = localStorage.getItem("i18nextLng");
  if (lng === "ru") {
    const lastNumber = time.slice(-1);
    if (lastNumber === "1") {
      return ruText + "а";
    } else if (Number(lastNumber) > 1 && Number(lastNumber) < 5) {
      return ruText + "ы";
    } else {
      return ruText;
    }
  } else {
    if (time === "1") {
      return enText;
    } else {
      return enText + "s";
    }
  }
};
