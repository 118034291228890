import React from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Input, Text, Box } from "@chakra-ui/react";
import { CustomVariableContextMenu } from "../CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableModel, CustomVariableType } from "../../../common/AppEnums";
import { DynamicButtonModel } from "../../../common/AppButtonsModel";
import { composeValidators, relatedRequired, required } from "../../../common/validation/defaultValidators";
import { createCustomVariable } from "../../flowBuilder/FlowBuilderSlice";
import { useAppDispatch } from "../../../common/state/store";

interface Props {
  variables: CustomVariableModel[];
}

export const DynamicButtonFormFields = ({ variables }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const dispatch = useAppDispatch();
  const { values, setFieldValue, setFieldTouched } = useFormikContext<DynamicButtonModel>();

  const clearSourceArray = () => {
    setFieldValue("sourceCustomVariableId", null);
  };

  const clearPayload = () => {
    setFieldValue("targetCustomVariableId", null);
  };

  const selectSource = (variable: CustomVariableModel) => {
    if (variable.id) {
      setFieldValue("sourceCustomVariableId", variable.id);
    }
  };

  const chosenSource = variables?.find(el => {
    const sourceCustomVariableId = values.sourceCustomVariableId;
    return el.id === sourceCustomVariableId ? true : false;
  });

  const selectTarget = (variable: CustomVariableModel) => {
    if (variable.id) setFieldValue("targetCustomVariableId", variable.id);
  };

  const chosenTarget = variables?.find(el => {
    const targetCustomVariableId = values.targetCustomVariableId;
    return el.id === targetCustomVariableId ? true : false;
  });

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  return (
    <>
      <Text mt="20px" variant="large">
        {t("Source Array")}
      </Text>
      <Field name="sourceCustomVariableId" validate={composeValidators(required())}>
        {({ field, form }: FieldProps) => (
          <CustomVariableContextMenu
            type={CustomVariableContextMenuType.Dropdown}
            onlyVariableType={CustomVariableType.Array}
            selectCustomVariable={selectSource}
            variables={variables.filter(variable => variable.type === "Array") ?? []}
            isInvalid={!!form.errors.sourceCustomVariableId && !!form.touched.sourceCustomVariableId}
            chosenVariable={chosenSource}
            onOpenMenu={() => setFieldTouched("sourceCustomVariableId", true)}
            addVariable={onCreateVariable({
              sourceOrTarget: "sourceCustomVariableId",
              setButtonField: (sourceOrTarget: string, createdCustomVariable: CustomVariableModel) =>
                createdCustomVariable.type === "Array" && setFieldValue(sourceOrTarget, createdCustomVariable.id),
            })}
            clearVariable={clearSourceArray}
            preventOverflow={false}
            flip={false}
            mt="12px"
            data-pw="source-variable"
          />
        )}
      </Field>
      <Text mt="20px" variant="large">
        {t("JSON path for button title")}
      </Text>
      <Field name="labelPath" validate={composeValidators(required())}>
        {({ field, form }: FieldProps) => (
          <Box mt="12px">
            <Input
              isInvalid={!!form.errors.labelPath && !!form.touched.labelPath}
              variant="outline"
              maxLength={32}
              paddingRight="52px"
              placeholder={t("Enter the path") ?? ""}
              {...field}
              data-pw="label-path"
            />
          </Box>
        )}
      </Field>
      <Text mt="20px" variant="large">
        {t("Static payload")}
      </Text>
      <Field name="staticPayload" validate={composeValidators(relatedRequired(values.payloadPath))}>
        {({ field, form }: FieldProps) => (
          <Box mt="12px">
            <Input
              isInvalid={!!form.errors.payloadPath && !!form.touched.payloadPath}
              variant="outline"
              maxLength={20}
              paddingRight="52px"
              placeholder={t("Enter static payload") ?? ""}
              {...field}
              data-pw="static-payload"
            />
          </Box>
        )}
      </Field>
      <Text mt="20px" variant="large">
        {t("JSON path for button payload")}
      </Text>
      <Field name="payloadPath" validate={composeValidators(relatedRequired(values.staticPayload))}>
        {({ field, form }: FieldProps) => (
          <Box mt="12px">
            <Input
              isInvalid={!!form.errors.payloadPath && !!form.touched.payloadPath}
              variant="outline"
              maxLength={32}
              paddingRight="52px"
              placeholder={t("Enter the path") ?? ""}
              {...field}
              data-pw="payload-path"
            />
          </Box>
        )}
      </Field>
      <Text mt="20px" variant="large">
        {t("Save Payload to a Custom Field")}
      </Text>
      <Field name="targetCustomVariableId">
        {({ field, form }: FieldProps) => (
          <Box mb="20px" mt="12px">
            <CustomVariableContextMenu
              type={CustomVariableContextMenuType.Dropdown}
              selectCustomVariable={selectTarget}
              variables={variables.filter(variable => variable.id !== chosenSource?.id)}
              chosenVariable={chosenTarget}
              mt="0px"
              onOpenMenu={() => setFieldTouched("targetCustomVariableId", true)}
              isInvalid={!!form.errors.targetCustomVariableId && !!form.touched.targetCustomVariableId}
              addVariable={onCreateVariable({
                sourceOrTarget: "targetCustomVariableId",
                setButtonField: (sourceOrTarget: string, createdCustomVariable: CustomVariableModel) =>
                  setFieldValue(sourceOrTarget, createdCustomVariable.id),
              })}
              clearVariable={clearPayload}
              preventOverflow={false}
              flip={false}
            />
          </Box>
        )}
      </Field>
    </>
  );
};
