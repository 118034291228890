import { forwardRef, useState } from "react";
import { DatePicker, customDateRangeInput } from "../../atoms/datePicker/DatePicker";
import { Flex, Icon } from "@chakra-ui/react";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";
import { selectLanguage } from "../../../common/user/UserSlice";
import { useAppSelector } from "../../../common/state/store";
import { DashboardDateModel } from "../../../components/dashboard/DashboardModel";

interface Props {
  startDate: Date;
  endDate: Date;
  setTypeDate: ({ from, to, typeDate }: DashboardDateModel) => void;
  onDateRangeChange: (startDate: Date | null, endDate: Date | null) => void;
}

export default function DateRange({ setTypeDate, startDate, endDate, onDateRangeChange }: Props) {
  const [isStartDate, setIsStartDate] = useState(true);
  const CustomInput = forwardRef(customDateRangeInput);
  const language = useAppSelector(selectLanguage);

  const defineLanguage = () => {
    const days = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
    const months = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];
    const localeRu: Locale = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      localize: {
        day: n => days[n],
        month: n => months[n],
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      formatLong: {
        date: () => "MMM dd yyyy",
      },
      code: "ru",
    };

    return language === "ru" ? localeRu : language;
  };

  const onChange = (date: [Date | null, null] | Date | null) => {
    /* the string allows you to correctly determine the date type since datepicker cannot */
    const dateRange = date as [Date | null, null];
    if (isStartDate) {
      onDateRangeChange(dateRange[0], null);
      setIsStartDate(false);
    } else {
      onDateRangeChange(null, dateRange[0]);
      setIsStartDate(true);
    }
  };
  return (
    <Flex
      position="relative"
      alignItems={"center"}
      cursor={"pointer"}
      border={"1px solid #DCE7FB"}
      borderRadius={"8px"}
      bgColor={"white"}
      _hover={{ borderColor: "#325EE6" }}
      transition={"all 0.3s ease"}
    >
      <DatePicker
        locale={defineLanguage()}
        dateFormat="MMM dd yyyy"
        onChange={date => onChange(date)}
        startDate={startDate}
        endDate={endDate}
        minDate={isStartDate ? undefined : startDate}
        maxDate={new Date()}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        selectsRange={true}
        customInput={<CustomInput />}
      />
      <Icon
        right={"16px"}
        position={"absolute"}
        ml={"8px"}
        as={Calendar}
        boxSize="20px"
        color={"#6D5BF7"}
        transition="ease-out .2s"
      />
    </Flex>
  );
}
