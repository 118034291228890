import { FormikErrors } from "formik";
import { BroadcastFormModel, FileModel } from "../../BroadcastModel";
import dayjs from "dayjs";
import { TFunction } from "i18next";

function validateCommonFields(
  values: BroadcastFormModel,
  currentFile: FileModel | null | undefined,
  isExecute: boolean,
  t: TFunction<"translation", "broadcast">,
) {
  const errors: FormikErrors<BroadcastFormModel> = {};

  if (values.isScheduled && dayjs().isAfter(dayjs(values.scheduledDate))) {
    errors.scheduledDate = t("Select future date, please") ?? "";
    values.isRecipient = false;
  }

  if (values.isScheduled && !values.scheduledDate) {
    errors.scheduledDate = t("Select date, please") ?? "";
    values.isRecipient = false;
  }

  if (!values.recipients?.length && isExecute) {
    errors.message = {
      text: t("Select recipients, please") ?? "",
    };
    values.isRecipient = false;
  }

  if (!currentFile && values.message.text.trim().length === 0) {
    errors.message = {
      text: t("Message field can't be empty") ?? "",
    };
    values.isRecipient = false;
  }

  return errors;
}

export function validateNewTextField(
  values: BroadcastFormModel,
  currentFile: FileModel | null | undefined,
  isExecute: boolean,
  t: TFunction<"translation", "broadcast">,
) {
  return validateCommonFields(values, currentFile, isExecute, t);
}

export function validateEditTextField(
  values: BroadcastFormModel,
  executeState: { execute: boolean; dirty: boolean },
  currentFile: FileModel | null | undefined,
  t: TFunction<"translation", "broadcast">,
) {
  return validateCommonFields(values, currentFile, executeState.execute, t);
}

export function validateMessage(message: string, t: TFunction<"translation", "broadcast">) {
  const errors: FormikErrors<BroadcastFormModel> = {};
  if (message.trim().length === 0) {
    errors.message = {
      text: t("Message field can't be empty") ?? "",
    };
  }
  return errors;
}
