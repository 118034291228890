/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomVariableModel } from "../../common/AppEnums";
import { RootState } from "../../common/state/store";
import {
  BroadcastFormModel,
  BroadcastModel,
  BroadcastRecipientModel,
  BroadcastRecipientsItemsModel,
  ContactListModel,
  ContactModel,
  ExcludedRecipient,
  FileModel,
  FileUploadType,
  IncludedRecipient,
  RecipientFiltersModel,
  RecipientFormModel,
  RecipientModel,
  RecipientsImportModel,
} from "./BroadcastModel";
import { itemsEqual } from "@dnd-kit/sortable/dist/utilities";
import { mapContactListToRecipientsList } from "./BroadcastMapper";

export interface BroadcastState {
  broadcast?: BroadcastModel;
  contactList?: ContactListModel;
  recipientList?: RecipientsImportModel | null;
  currentFile?: FileModel | null;
  variables?: CustomVariableModel[];
  broadcastRecipientList?: BroadcastRecipientModel;
  recipientsFilter?: RecipientFiltersModel[] | null;
  includedRecipients?: IncludedRecipient[] | null;
  excludedRecipients?: ExcludedRecipient[] | null;
  initialRecipientsList?: RecipientModel[] | null;
  scrolledNonSelectedRecipients?: RecipientModel[] | null;
  scrolledRecipients?: RecipientFormModel[] | null;
  initialSentForAllFlag?: boolean;
  checkedFlag?: boolean;
  uncheckedFlag?: boolean;
  selectedRecipients?: RecipientModel[] | null;
  recipientsCount?: number;
  currentPage?: number;
  recipientCounter: number;
}

const initialState: BroadcastState = {
  recipientsCount: 0,
  recipientCounter: 0,
};

const mergeUniqueById = (arr1: RecipientModel[], arr2: RecipientFormModel[]) => {
  const map = new Map();
  arr1.forEach(item => map.set(item.contactId, item));
  arr2.forEach(item => map.set(item.contactId, item));
  return Array.from(map.values());
};

export const broadcastSlice = createSlice({
  name: "broadcastSlice",
  initialState,
  reducers: {
    getBroadcast: (state, action: PayloadAction<{ botId: string; broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    getBroadcastCompleted: (state, action: PayloadAction<BroadcastModel>) => {
      return {
        ...state,
        broadcast: {
          ...action.payload,
          recipientsFilter: action.payload.recipientFilter ?? null,
          initialSentForAllFlag: action.payload.sentForAll,
          recipientCounter: action.payload.recipientsCount,
        },
      };
    },
    getBroadcastRecipientList: (
      state,
      action: PayloadAction<{
        botId?: string;
        broadcastId?: string;
        queryFilterParams?: string;
        isScrolling?: boolean;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getBroadcastRecipientListCompleted: (state, action: PayloadAction<BroadcastRecipientModel>) => {
      return {
        ...state,
        broadcastRecipientList: action.payload,
      };
    },
    editBroadcast: (
      state,
      action: PayloadAction<{
        botId: string;
        broadcast: BroadcastFormModel;
        execute: boolean;
        recipientFilter: RecipientFiltersModel[] | null;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getContactList: (
      state,
      action: PayloadAction<{
        botId: string;
        queryFilterParams: string;
        isOnScrollPayload?: boolean;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getDraftContactList: (
      state,
      action: PayloadAction<{
        botId: string;
        broadcastId: string;
        queryFilterParams: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    getContactListCompleted: (state, action: PayloadAction<ContactListModel>) => {
      return {
        ...state,
        contactList: action.payload,
      };
    },
    getSelectedListCompleted: (state, action: PayloadAction<ContactListModel>) => {
      const recipietnsListInit = state.initialRecipientsList ?? [];
      //const recipientsListMutable = state.recipientsList ?? [];
      const selectedItems =
        action.payload.items.length > 0
          ? action.payload.items?.filter(item => item.isSelected === true).map(item => mapContactListToRecipientsList(item))
          : [];
      return {
        ...state,
        initialRecipientsList: recipietnsListInit.length > 0 ? mergeUniqueById(recipietnsListInit, selectedItems) : selectedItems,
        scrolledRecipients: selectedItems,
        scrolledNonSelectedRecipient: action.payload.items.map(item => mapContactListToRecipientsList(item)),
      };
    },
    createBroadcast: (
      state,
      action: PayloadAction<{
        botId: string;
        broadcast: BroadcastFormModel;
        execute: boolean;
        sentForAll: boolean;
        recipientFilter: RecipientFiltersModel[] | null;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    createBroadcastCompleted: (state, action: PayloadAction<BroadcastModel>) => {
      return {
        ...state,
        broadcast: action.payload,
        recipientsFilter: action.payload.recipientFilter ?? null,
      };
    },
    deleteBroadcast: (state, action: PayloadAction<{ broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    cancelBroadcast: (state, action: PayloadAction<{ broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    executeBroadcast: (state, action: PayloadAction<{ botId: string; broadcastId: string }>) => {
      return {
        ...state,
      };
    },
    uploadBroadcastFile: (state, action: PayloadAction<{ file: File; type: FileUploadType }>) => {
      return {
        ...state,
      };
    },
    setCurrentFile: (state, action: PayloadAction<FileModel | null>) => {
      return {
        ...state,
        currentFile: action.payload,
      };
    },
    getCustomVariables: (state, action: PayloadAction<{ botId: string }>) => {
      return {
        ...state,
      };
    },
    getCustomVariablesSucceed: (state, action: PayloadAction<CustomVariableModel[]>) => {
      return {
        ...state,
        variables: action.payload,
      };
    },
    clearState: () => {
      return {
        recipientCounter: 0,
        recipientsCount: 0,
      };
    },
    addBroadcastFilter: (state: BroadcastState, action: PayloadAction<RecipientFiltersModel>) => {
      const broadcastContactFilters = state.recipientsFilter ?? [];
      const actionFilter = broadcastContactFilters.find(filter => filter.typeDiscriminator === action.payload.typeDiscriminator);
      let newFilterArray;
      if (actionFilter) {
        newFilterArray = broadcastContactFilters.map(filter =>
          filter.typeDiscriminator === action.payload.typeDiscriminator
            ? {
                ...filter,
                members: filter.members.some(member => member.filterId === action.payload.members[0].filterId)
                  ? [...filter.members.filter(m => m.filterId !== action.payload.members[0].filterId), action.payload.members[0]]
                  : [...filter.members, action.payload.members[0]],
              }
            : filter,
        );
      } else {
        newFilterArray = [...broadcastContactFilters, action.payload];
      }
      return {
        ...state,
        recipientsFilter: newFilterArray,
      };
    },
    deleteBroadcastFilter: (state, action: PayloadAction<string>) => {
      const broadcastContactFilters = state?.recipientsFilter;
      return {
        ...state,
        recipientsFilter: broadcastContactFilters
          ? broadcastContactFilters.map(filter => ({
              ...filter,
              members: filter.members.filter(member => member.filterId !== action.payload),
            }))
          : undefined,
      };
    },
    clearBroadcastFilters: (state: BroadcastState) => {
      return {
        ...state,
        recipientsFilter: null,
      };
    },
    importRecipientsByFile: (state, action: PayloadAction<{ botId: string | undefined; file: File | null }>) => {
      return {
        ...state,
      };
    },
    setImportResult: (state, action: PayloadAction<RecipientsImportModel | null>) => {
      return {
        ...state,
        recipientList: action.payload,
      };
    },
    setSelectAllState: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        broadcast: state.broadcast
          ? {
              ...state.broadcast,
              sentForAll: action.payload,
            }
          : undefined,
      };
    },
    setSelectAllCheckedFlagState: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        broadcast: state.broadcast
          ? {
              ...state.broadcast,
              checkedFlag: action.payload,
            }
          : undefined,
      };
    },
    setRecipientCounter: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        recipientCounter: action.payload,
      };
    },
    // addToIncludedItems: (state, action: PayloadAction<string>) => {
    //   return {
    //     ...state,
    //     includedRecipients: state.includedRecipients ? [...state.includedRecipients, action.payload] : [action.payload],
    //     excludedRecipients: state.excludedRecipients ? state.excludedRecipients.filter(id => id !== action.payload) : [],
    //   };
    // },
    // removeFromIncludedItems: (state, action: PayloadAction<string>) => {
    //   return {
    //     ...state,
    //     includedRecipients: state.includedRecipients ? state.includedRecipients.filter(id => id !== action.payload) : [],
    //   };
    // },
    // addToExcludedItems: (state, action: PayloadAction<string>) => {
    //   return {
    //     ...state,
    //     excludedRecipients: state.excludedRecipients ? [...state.excludedRecipients, action.payload] : [action.payload],
    //     includedRecipients: state.includedRecipients ? state.includedRecipients.filter(id => id !== action.payload) : [],
    //   };
    // },
    // removeFromExcludedItems: (state, action: PayloadAction<string>) => {
    //   return {
    //     ...state,
    //     excludedRecipietns: state.excludedRecipients ? state.excludedRecipients.filter(id => id !== action.payload) : [],
    //   };
    // },
  },
});

export const {
  getBroadcast,
  getBroadcastCompleted,
  getBroadcastRecipientList,
  getBroadcastRecipientListCompleted,
  editBroadcast,
  createBroadcast,
  createBroadcastCompleted,
  getContactList,
  getDraftContactList,
  getContactListCompleted,
  deleteBroadcast,
  cancelBroadcast,
  executeBroadcast,
  uploadBroadcastFile,
  setCurrentFile,
  getCustomVariables,
  getCustomVariablesSucceed,
  clearState,
  addBroadcastFilter,
  deleteBroadcastFilter,
  clearBroadcastFilters,
  importRecipientsByFile,
  setImportResult,
  setSelectAllState,
  getSelectedListCompleted,
  setRecipientCounter,
  setSelectAllCheckedFlagState,
} = broadcastSlice.actions;

export const selectBroadcastState = (state: RootState) => state.app.broadcastState;
export const selectButtonSelectAllState = (state: RootState) => state.app.broadcastState.broadcast?.sentForAll;
export const selectContactList = (state: RootState) => state.app.broadcastState.contactList;
export const selectBroadcastRecipientList = (state: RootState) => state.app.broadcastState.broadcastRecipientList;
export const selectCustomVariables = (state: RootState) => state.app.broadcastState.variables;
export const selectCurrentFile = (state: RootState) => state.app.broadcastState.currentFile;
export const selectRecipientsFilter = (state: RootState) => state.app.broadcastState.recipientsFilter;
export const selectRecipientsList = (state: RootState) => state.app.broadcastState.recipientList;
export const selectInitialRecipients = (state: RootState) => state.app.broadcastState.initialRecipientsList;
export const selectScrolledRecipients = (state: RootState) => state.app.broadcastState.scrolledRecipients;
export const selectNonScrolledRecipients = (state: RootState) => state.app.broadcastState.scrolledNonSelectedRecipients;
export const selectInitialSentForAllFlag = (state: RootState) => state.app.broadcastState.initialSentForAllFlag;
export const selectBroadcastRecipientCount = (state: RootState) => state.app.broadcastState.recipientCounter;

export default broadcastSlice.reducer;
