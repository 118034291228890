import s from "../../ContactList.module.scss";
import { Avatar, Box, Show, Text } from "@chakra-ui/react";
import React from "react";
import { ContactListModel, ContactModel } from "../../ContactListModel";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { LanguageCodeEnum } from "../../../../common/user/UserModel";
import { FormattedDateTooltip } from "../../../../UI/atoms/formattedDateTooltip/FormattedDateTooltip";
import DotMenuContainer from "../../../../UI/atoms/DotMenu/DotMenuContainer";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "../../../../assets/icons/editIcon.svg";
import { ReactComponent as RedTrashIcon } from "../../../../assets/icons/trash.svg";
import { OverflowEllipsesText } from "../../../../UI/atoms/textOverflow/OverflowEllipsesText";

interface Props {
  apiBaseUrl: string;
  contactList: ContactListModel | undefined;
  navigate: NavigateFunction;
  setDeletePopupState: (value: React.SetStateAction<{ id: string; itemName: string } | null>) => void;
  lng: LanguageCodeEnum;
  search: string;
  loadingContactList: boolean;
}

export const ContactListBig = (props: Props) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const navigate = useNavigate();

  const onEditContactItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.TouchEvent<HTMLButtonElement> | React.DragEvent<HTMLButtonElement>,
    contact: ContactModel,
  ) => {
    e.stopPropagation();
    navigate(`/contacts/${contact.id}`, {
      state: { from: `${window.location.pathname}${window.location.search}` },
    });
  };

  return (
    <Box className={s.contactListGrid}>
      <Box>
        {props.contactList &&
          props.contactList.items.map(contact => (
            <Box
              borderBottom="1px solid"
              borderColor="bgMiddle"
              className={s.contactListItem}
              key={contact.id}
              onClick={() => props.navigate(`/contacts/${contact.id}`)}
              data-pw="contact-list-item"
            >
              <div />
              {contact.avatars.length ? (
                <Avatar
                  className={s.Avatar}
                  name={contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}
                  boxSize="36px"
                  bg="midDeepBlue"
                  color={"white"}
                  loading="eager"
                  src={`${props.apiBaseUrl}/file/${contact.avatars[0][0].fileId}`}
                  data-pw="contact-image-avatar"
                />
              ) : (
                <Avatar
                  className={s.Avatar}
                  boxSize="36px"
                  name={contact.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName}
                  bg="midDeepBlue"
                  color={"white"}
                  data-pw="contact-name-avatar"
                />
              )}
              <Show breakpoint="(min-width: 800px)">
                <OverflowEllipsesText
                  text={contact.firstName}
                  content={
                    <Text data-pw="contact-first-name" variant="Ymedium" className={s.contactInfoField}>
                      {contact.firstName}
                    </Text>
                  }
                />
                <Text data-pw="contact-last-name" variant="Ymedium" noOfLines={1} maxWidth={"130px"} paddingLeft={"16px"}>
                  {contact.lastName}
                </Text>
              </Show>
              <Show breakpoint="(max-width: 800px)">
                <Text data-pw="contact-name" variant="medium" noOfLines={1}>
                  {contact.firstName} {contact.lastName}
                </Text>
              </Show>
              <Text data-pw="contact-username" variant="medium" noOfLines={1} paddingLeft={"16px"}>
                {contact.username ? `@${contact.username}` : contact.externalId}
              </Text>
              <FormattedDateTooltip lng={props.lng} lastModifiedDate={contact.createDate} />
              <Box className={s.boxIconButton} paddingLeft={"16px"}>
                <DotMenuContainer
                  menuItems={[
                    {
                      MenuItem: {
                        icon: <EditIcon width="18px" height="18px" />,
                        text: ct("Edit"),
                        clickEvent: e => onEditContactItem(e, contact),
                      },
                    },
                    {
                      MenuItem: {
                        icon: <RedTrashIcon width="18px" height="18px" />,
                        text: ct("Delete"),
                        clickEvent: e => {
                          e.stopPropagation();
                          props.setDeletePopupState({
                            id: contact.id,
                            itemName: `${contact.firstName ?? ""} ${contact.lastName ?? ""}`,
                          });
                        },
                        color: "mainRed",
                      },
                    },
                  ]}
                  MenuHover={{ bg: "line" }}
                />
              </Box>
              <div />
            </Box>
          ))}
      </Box>
    </Box>
  );
};
