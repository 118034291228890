import { Text } from "@chakra-ui/react";
import { Step } from "react-joyride";
import { TooltipTitleContainer } from "./TooltipTitleContainer";
import { TFunction } from "i18next";

export const GENERAL_TOUR_NAME = "GeneralTour";

export const getSteps = (offset: number, t: TFunction<"translation", "onboardingTour">): Step[] => [
  {
    title: <TooltipTitleContainer titleText="Automation" progressStep="  1/5" />,
    content: <Text>{t("automation")} </Text>,
    target: ".automation",
    placement: "right",
    disableBeacon: true,
    offset: offset,
    floaterProps: {
      disableAnimation: true,
      styles: {
        arrow: {
          length: 9,
          spread: 16,
        },
      },
    },
    spotlightPadding: 0,
  },
  {
    title: <TooltipTitleContainer titleText="Live Chat" progressStep="  2/5" />,
    content: <Text>{t("chats")}</Text>,
    target: ".chats",
    placement: "right",
    offset: offset,
    floaterProps: {
      disableAnimation: true,
      styles: {
        arrow: {
          length: 9,
          spread: 16,
        },
      },
    },
    styles: {
      buttonBack: {
        marginLeft: 0,
        marginRight: "auto",
        order: "-1",
      },
    },
    spotlightPadding: 0,
  },
  {
    title: <TooltipTitleContainer titleText="Broadcasting" progressStep="  3/5" />,
    content: <Text>{t("broadcasting")}</Text>,
    offset: offset,
    floaterProps: {
      disableAnimation: true,
      styles: {
        arrow: {
          length: 9,
          spread: 16,
        },
      },
    },
    target: ".broadcasting",
    placement: "right",
    styles: {
      buttonBack: {
        marginLeft: 0,
        marginRight: "auto",
        order: "-1",
      },
    },
    spotlightPadding: 0,
  },
  {
    title: <TooltipTitleContainer titleText="Contacts" progressStep="  4/5" />,
    content: <Text>{t("contacts")}</Text>,
    target: ".contacts",
    placement: "right",
    offset: offset,
    floaterProps: {
      disableAnimation: true,
      styles: {
        arrow: {
          length: 9,
          spread: 16,
        },
      },
    },
    styles: {
      buttonBack: {
        marginLeft: 0,
        marginRight: "auto",
        order: "-1",
      },
    },
    spotlightPadding: 0,
  },
  {
    title: <TooltipTitleContainer titleText="Dashboard" progressStep="  5/5" />,
    content: <Text>{t("dashboard")}</Text>,
    target: ".dashboard",
    placement: "right",
    offset: offset,
    floaterProps: {
      disableAnimation: true,
      styles: {
        arrow: {
          length: 9,
          spread: 16,
        },
      },
    },
    styles: {
      buttonBack: {
        marginLeft: 0,
        marginRight: "auto",
        order: "-1",
      },
    },
    spotlightPadding: 0,
  },
];
