/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { CreatedBotModel } from "./AddBotModel";
import { AddBotState } from "./AddBotState";

const initialState: AddBotState = {
  isConnectionCompleted: false,
};

export const addBotSlice = createSlice({
  name: "addBotSlice",
  initialState,
  reducers: {
    connectBot: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    connectBotCompleted: (state, action: PayloadAction<CreatedBotModel>) => {
      return {
        ...state,
        isConnectionCompleted: true,
        connectedBotInfo: action.payload,
      };
    },
    clearStore: state => {
      return {
        ...initialState,
      };
    },
  },
});

export const { connectBot, connectBotCompleted, clearStore } = addBotSlice.actions;

export const selectIsConnectionCompleted = (state: RootState) => state.app.addBotState.isConnectionCompleted;
export const selectConnectedBotInfo = (state: RootState) => state.app.addBotState.connectedBotInfo;

export default addBotSlice.reducer;
