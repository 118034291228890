export interface TelegramAuthModel {
  inviteToken: string | null;
  payload: TelegramPayloadModel;
  locale?: Locales;
  fingerprint?: string;
}

interface TelegramPayloadModel {
  auth_date: number;
  first_name: string;
  hash: string;
  id: number;
  last_name: string;
  photo_url: string;
  username: string;
}

export enum Locales {
  "ru" = "ru",
  "en" = "en",
}
