import { Box, Button, Flex, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BotImageStart } from "../../../../assets/images/botImageStart.svg";
import { ReactComponent as BotIcon } from "../../../../assets/icons/sidebar/botIcon.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGA from "../../../../common/ga/GAEventTracker";
import s from "../../AddBot.module.scss";

interface Props {
  clearAddBotStore: () => void;
}

export const BotStart = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });
  const navigate = useNavigate();
  const trackEvent = useGA("BotList");

  useEffect(() => {
    return () => {
      props.clearAddBotStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createBotClick = () => {
    trackEvent("CreateBot");
    navigate("/addbot/new");
  };

  return (
    <Grid
      bg="bgLight"
      w="100%"
      h="100%"
      maxH={"100%"}
      templateColumns={{ base: "1fr", md: "45% 55%", xl: "40% 60%" }}
      overflow={{ base: "auto", md: "hidden" }}
      alignItems="center"
      display={{ base: "flex", md: "grid" }}
      flexDirection={"column-reverse"}
      justifyContent={"center"}
      className={s.existedBotBlock}
    >
      <GridItem display="flex" alignItems="center" justifyContent="center" p={"0 32px"}>
        <Flex direction="column" maxW="380px" textAlign={{ base: "center", md: "left" }}>
          <Text mb={"8px"} variant="h2">
            {t("How do you want to start?")}?
          </Text>
          <Text variant="medium" color="darkGrey">
            {t("In each scenario, we will guide you through easy step-by-step instructions")}
          </Text>
          <Flex flexDirection={["column", "column", "column", "column", "row"]} mt={"20px"} gap="24px">
            <Button variant="dominoViolet" onClick={createBotClick}>
              + {t("Create New Bot")}
            </Button>
            <Button
              onClick={() => navigate("/addbot/existed")}
              color="midDeepBlue"
              fill="midDeepBlue"
              bg="transparent"
              _hover={{ bg: "lightDeepBlue", color: "mainPurple", fill: "mainPurple" }}
            >
              <Icon as={BotIcon} boxSize="24px" mr="8px" />
              <Text variant="medium">{t("Connect Existing Bot")}</Text>
            </Button>
          </Flex>
        </Flex>
      </GridItem>
      <GridItem
        h={{ base: "initial", md: "100%" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={{ base: "initial", md: "white" }}
        p={"0 32px"}
      >
        <Box as={BotImageStart} maxW={{ base: "240px", md: "100%" }} mb={{ base: "16px", md: "0" }} height={"auto"}></Box>
      </GridItem>
    </Grid>
  );
};
