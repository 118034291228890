import { FunctionComponent } from "react";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Icon,
} from "@chakra-ui/react";
import { ReactComponent as BotImageStart } from "../../assets/images/botImageStart.svg";
import { ReactComponent as StarPlan } from "../../assets/icons/starPlan.svg";
import sp from "../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { getTariffPlanAccessability } from "../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../common/AppEnums";
import { useAppSelector } from "../../common/state/store";
import { selectIsOrganizationTrial, selectOrganisation } from "../organisation/OrganisationSlice";
import { TariffPlanRedirectButton } from "../../common/tariffPlan/TariffPlanRedirectButton";
import { useTranslation } from "react-i18next";
import { useMobileView } from "../layout/LayoutHelper/ResolutionHooks";

interface Props {
  title: string;
  description: string;
  createButtonText?: string | null;
  createButtonOnClick?: () => void;
  CustomButton?: JSX.Element;
  Image?: FunctionComponent;
}

export const EmptyListView = ({ title, description, createButtonText, createButtonOnClick, Image, CustomButton }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const organisation = useAppSelector(selectOrganisation);
  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.ActiveFlows);
  const { IsUnlimited, MaxCount } = accessability;
  const isMobile = useMobileView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  return (
    <Grid
      w="100%"
      h={{ base: `calc(100dvh - 120px ${isTrial && isMobile ? "- 60px" : ""})` }}
      templateColumns={{ base: "1fr", md: "45% 55%", xl: "40% 60%" }}
      overflow={{ base: "auto", md: "hidden" }}
      alignItems="center"
      display={{ base: "flex", md: "grid" }}
      flexDirection={"column-reverse"}
      justifyContent={"center"}
    >
      <GridItem display="flex" alignItems="center" justifyContent="center" p={"0 32px"}>
        <Flex direction="column" maxW="380px" w={"100%"} textAlign={{ base: "center", md: "left" }}>
          <Text mb={"15px"} variant="h2">
            {title}
          </Text>
          <Text variant="medium" color="darkGrey">
            {description}
          </Text>
          <Flex
            flexDirection={["column", "column", "column", "column", "row"]}
            alignItems={{ base: "center", md: "left" }}
            mt={"20px"}
            gap="24px"
          >
            {!!CustomButton ? (
              CustomButton
            ) : (
              <>
                {!IsUnlimited &&
                createButtonText === `${t("Create New Flow")}` &&
                organisation?.totalFlow &&
                organisation.totalFlow >= MaxCount ? (
                  <Popover trigger={"hover"} closeOnBlur={false}>
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Button variant="dominoViolet">
                            <Icon className={sp.starPlan} boxSize="20px" as={StarPlan} /> {createButtonText}
                          </Button>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                            <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                            <Flex alignItems="center" justifyContent="center" direction="column">
                              <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                                {tp("Advanced feature")}
                              </PopoverHeader>
                              <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                                {tp("Please upgrade your plan to create more Flows")}
                              </PopoverBody>
                              <TariffPlanRedirectButton onClose={onClose} />
                            </Flex>
                          </PopoverContent>
                        </Portal>
                      </>
                    )}
                  </Popover>
                ) : (
                  <Button variant="dominoViolet" onClick={createButtonOnClick} data-pw="create-button">
                    + {createButtonText}
                  </Button>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </GridItem>
      <GridItem
        h={{ md: "100%" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={{ base: "initial", md: "white" }}
        p={"0 32px"}
      >
        {Image ? (
          <Box as={Image} mb={{ base: "24px", md: "0" }} maxW={{ base: "220px", md: "100%" }} height={"auto"}></Box>
        ) : (
          <Box as={BotImageStart} maxW={{ base: "240px", md: "100%" }} mb={{ base: "16px", md: "0" }} height={"auto"}></Box>
        )}
      </GridItem>
    </Grid>
  );
};
