import React from "react";
import { Tag, TagLabel } from "@chakra-ui/react";
import { BroadcastStatus } from "../../../../BroadcastListModel";
import { useTranslation } from "react-i18next";

interface Props {
  broadcastStatus: BroadcastStatus | undefined;
  variant: string;
}

export const StatusTag = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "broadcastStatuses",
  });

  switch (props.broadcastStatus) {
    case BroadcastStatus.Draft: {
      return (
        <Tag variant={props.variant} border={"1px solid #A67F00"} bg="#FFF7E0" color="#A67F00" data-pw="status-tag">
          <TagLabel>{t(BroadcastStatus.Draft)}</TagLabel>
        </Tag>
      );
    }
    case BroadcastStatus.Scheduled: {
      return (
        <Tag variant={props.variant} border={"1px solid #6D5BF7"} bg="#F1EFFF" color="mainPurple" data-pw="status-tag">
          <TagLabel>{t(BroadcastStatus.Scheduled)}</TagLabel>
        </Tag>
      );
    }
    case BroadcastStatus.Sending: {
      return (
        <Tag variant={props.variant} border={"1px solid #325EE6"} bg="#EBEFFD" color="blueLink" data-pw="status-tag">
          <TagLabel>{t(BroadcastStatus.Sending)}</TagLabel>
        </Tag>
      );
    }
    case BroadcastStatus.Finished: {
      return (
        <Tag variant={props.variant} border={"1px solid #008500"} bg="#E6FAE6" color="#008500" data-pw="status-tag">
          <TagLabel>{t(BroadcastStatus.Finished)}</TagLabel>
        </Tag>
      );
    }
    case BroadcastStatus.Cancelled: {
      return (
        <Tag variant={props.variant} border={"1px solid #232B39"} bg="defaultGrey" color="#232B39" data-pw="status-tag">
          <TagLabel>{t(BroadcastStatus.Cancelled)}</TagLabel>
        </Tag>
      );
    }
    case BroadcastStatus.Failed: {
      return (
        <Tag variant={props.variant} border={"1px solid #A60000"} bg="#FFE6E6" color="#A60000" data-pw="status-tag">
          <TagLabel>{t(BroadcastStatus.Failed)}</TagLabel>
        </Tag>
      );
    }
    default:
      return null;
  }
};
