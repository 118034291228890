import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../common/state/store";
import { SetVariableModel } from "../../../../FlowBuilderModel";
import { selectCustomVariables } from "../../../../FlowBuilderSlice";
import { formatDateTimeToView } from "../../../../../../common/utils/formatDate";

interface Props {
  flowAction?: SetVariableModel;
}

export const SetVariableAction = ({ flowAction }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const variables = useAppSelector(selectCustomVariables);

  const chosenSource = variables?.find(el => {
    const sourceCustomVariableId = flowAction?.sourceCustomVariableId;
    return el.id === sourceCustomVariableId ? true : false;
  });

  const chosenTarget = variables?.find(el => {
    const targetCustomVariableId = flowAction?.targetCustomVariableId;
    return el.id === targetCustomVariableId ? true : false;
  });

  return (
    <Box mt="12px" width="100%" p="16px" bg="bgMiddle" borderRadius="8px">
      <Flex gap="8px">
        <Flex gap="8px" direction="column">
          <Text as={Box} variant="medium" color="darkGrey">
            {t("Set")}
          </Text>
          <Text as={Box} variant="medium" color="darkGrey">
            {t("to")}
          </Text>
        </Flex>
        <Flex gap="8px" direction="column">
          <Text
            width="100%"
            maxW="230px"
            as={Box}
            noOfLines={1}
            variant="medium"
            color={flowAction?.sourceType === "variable" ? "midDeepBlue" : "black"}
          >
            {flowAction?.sourceType === "variable"
              ? chosenSource?.key ?? t("Not set")
              : flowAction?.value
              ? `${formatDateTimeToView(chosenTarget?.type, flowAction.value)}`
              : t("Not set")}
          </Text>
          <Text width="100%" maxW="230px" as={Box} variant="medium" color="midDeepBlue">
            {chosenTarget?.key ?? t("Not set")}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
