import { Avatar, Flex, Text } from "@chakra-ui/react";
import s from "../OperatorViewBox/OperatorViewItem.module.scss";

interface Props {
  operatorName: string;
}

export const OperatorViewItem = (props: Props) => {
  return (
    <Flex className={s.operatorViewConversation}>
      <Avatar size="xs" name={props.operatorName} />
      <Text noOfLines={1} className={s.operatorViewName}>
        {props.operatorName}
      </Text>
    </Flex>
  );
};
