import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { getToursApi } from "./OnboardingToursApi";
import { onboardingSlice, selectTourByName } from "./OnboardingToursSlice";
import { OnboardingState, Tour } from "./OnboardingToursState";
import { completeTourApi } from "./OnboardingToursApi";
import { selectUser, getProfileCompleted } from "../../common/user/UserSlice";
import { UserProfileModel } from "../userProfile/UserProfileModel";
import { flowBuilderSlice, selectFlowList } from "../flowBuilder/FlowBuilderSlice";
import { FlowListModel } from "../flowBuilder/FlowBuilderModel";
import { GENERAL_TOUR_NAME } from "./toursConstants";
import { BadRequestError } from "../../common/ErrorModel";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { mapToursDataToToursModel } from "./OnboardingToursMapper";

export function* onboardingTourSaga() {
  yield takeLatest(getProfileCompleted, getTourSaga);
  yield takeLatest(flowBuilderSlice.actions.saveFlowCompleted, completeGeneralTourFromAutomationSaga);
  yield takeLatest(onboardingSlice.actions.completeTour, completeTourSaga);
}

function* getTourSaga() {
  try {
    yield put(beginScope("getTourSaga"));
    const user: UserProfileModel = yield select(selectUser);
    if (user.username) {
      const tours: OnboardingState = yield call(getToursApi, user.username);

      if (tours.tours) {
        const model = mapToursDataToToursModel(tours.tours);
        yield put(onboardingSlice.actions.setTour(model));
      }
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getTourSaga"));
  }
}

function* completeGeneralTourFromAutomationSaga() {
  try {
    const flowList: FlowListModel | undefined = yield select(selectFlowList);
    const flowListTotalItems: number | undefined = flowList?.totalItems ?? 0;
    const currentTour: Tour | undefined = yield select(selectTourByName, GENERAL_TOUR_NAME);
    if (currentTour?.isCompleted) return;
    if (flowListTotalItems === 0) {
      yield put(onboardingSlice.actions.completeTour(GENERAL_TOUR_NAME));
    }
  } catch (e) {
    yield handleException(e);
  }
}

export function* completeTourSaga(action: PayloadAction<string>) {
  try {
    const tourName = action.payload;
    const currentTour: Tour | undefined = yield select(selectTourByName, tourName);
    if (currentTour) {
      yield call(completeTourApi, tourName);
    }
  } catch (e) {
    if (e instanceof BadRequestError) {
      return;
    }
    yield handleException(e);
  }
}
