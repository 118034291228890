import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AppSettings } from "../../common/AppSettings";

declare const appSettings: AppSettings;

interface TariffPlanRedirectButtonProps {
  onClose: () => void;
}

export const TariffPlanRedirectButton = (props: TariffPlanRedirectButtonProps) => {
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;

  const redirectPlan = () => {
    window.open(appSettings.billingPortalUrl);
  };

  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        props.onClose();
        redirectPlan();
      }}
      variant="dominoTariffPlanBlack"
    >
      {tp("Select Plan")}
    </Button>
  );
};
