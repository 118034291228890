import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { OrganisationTeamItemModel, OrganisationTeamsModel, TeamMemberModel } from "../../../OrganisationModel";
import { NewTeamModal } from "../../NewTeamModal/NewTeamModal";
import { ReactComponent as RedTrashIcon } from "../../../../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/editIcon.svg";
import { ReactComponent as StarPlan } from "../../../../../assets/icons/starPlan.svg";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { CloseIcon } from "@chakra-ui/icons";
import { getTariffPlanAccessability } from "../../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../../../common/AppEnums";
import { TariffPlanRedirectButton } from "../../../../../common/tariffPlan/TariffPlanRedirectButton";
import DotMenuContainer from "../../../../../UI/atoms/DotMenu/DotMenuContainer";
import s from "../TeamsPage.module.scss";
import sp from "../../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";

interface Props {
  teams: OrganisationTeamsModel;
  editMode: {
    teamId: string;
    teamName: string;
  };
  setAddOperatorModalState: Dispatch<
    SetStateAction<{
      includedOperators: TeamMemberModel[];
      teamId: string;
    }>
  >;
  onEditTeam: (team: OrganisationTeamItemModel) => void;
  setEditMode: Dispatch<
    SetStateAction<{
      teamId: string;
      teamName: string;
    }>
  >;
  setDeleteTeamPopupState: Dispatch<
    SetStateAction<{
      id: string;
      itemName: string;
    } | null>
  >;
  setDeleteOperatorPopupState: Dispatch<
    SetStateAction<{
      teamId: string;
      operatorId: string;
      teamName: string;
      operatorName: string;
    } | null>
  >;
  onOpen: () => void;
}

export const TeamListItem = ({
  teams,
  editMode,
  setAddOperatorModalState,
  onEditTeam,
  setEditMode,
  onOpen,
  setDeleteOperatorPopupState,
  setDeleteTeamPopupState,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const [hoveredOperator, setHoveredOperator] = useState("");
  const [focusOnCard, setFocusOnCard] = useState("");
  const [lastName, setLastName] = useState("");
  const { isOpen, onOpen: onOpenModal, onClose } = useDisclosure();
  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.TeamsMembersCount);
  const { MaxCount, IsUnlimited } = accessability;
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  const handleBlur = (el: OrganisationTeamItemModel) => {
    if (editMode.teamName.replace(/ +/g, " ").trim() !== "") {
      onEditTeam(el);
    } else {
      setEditMode({ ...editMode, teamName: lastName });
    }
  };
  return (
    <>
      <Accordion
        key={teams.currentPage}
        allowToggle
        onKeyUp={e => e.preventDefault()}
        allowMultiple
        variant={"baseAccordion"}
        className={s.teamListScrollList}
      >
        {teams?.items.length === 0 ? (
          <Flex className={s.operatorEmptyTeam} w="100%" alignItems="center" justifyContent="center" flexDir="row">
            <Text>{t("No Teams created")}</Text>
            <Button ml="16px" className={s.addKeywordButtonWrp} onClick={onOpenModal}>
              <Box className={s.addKeywordButton}>+ {t("New Team")}</Box>
            </Button>
          </Flex>
        ) : (
          <Box className={s.teamListScrollList}>
            {teams?.items.map(el => {
              return (
                <AccordionItem key={el.id} mb={"8px"} w={"100%"}>
                  <AccordionButton
                    mb="0"
                    className={`${s.teamListItemGrid}`}
                    _expanded={{ borderBottomRadius: "0" }}
                    onMouseEnter={() => setFocusOnCard(el.id)}
                    onMouseLeave={() => setFocusOnCard("")}
                  >
                    <AccordionIcon />
                    {editMode.teamId === el.id ? (
                      <Box display="inline-flex">
                        <Flex w="100%" direction={"column"}>
                          <Input
                            className={s.teamNameInput}
                            autoFocus={true}
                            value={editMode.teamName}
                            bg="white"
                            placeholder={`${t("Enter Team name")}`}
                            onFocus={() => setLastName(editMode.teamName)}
                            onClick={e => e.preventDefault()}
                            onChange={e => setEditMode({ ...editMode, teamName: e.target.value })}
                            onBlur={() => handleBlur(el)}
                            onKeyDown={e => handleKeyPress(e)}
                          />
                          <Flex className={s.operatorsMobile} gap="8px" align="center">
                            <Text className={s.textMobile} color="darkGray" noOfLines={1} variant="medium">
                              {t("Operators")}
                            </Text>
                            <Text textAlign="end" pr="30px">
                              {el.members.length}
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                    ) : (
                      <Flex direction={"column"}>
                        <Flex
                          className={s.teamName}
                          onClick={e => {
                            setEditMode({ teamId: el.id, teamName: el.name });
                            e.stopPropagation();
                          }}
                        >
                          <Text textAlign={"start"} noOfLines={1} mr="10px">
                            {el.name}
                          </Text>
                          <Box w="20px" h="20px" hidden={focusOnCard !== el.id}>
                            <EditIcon fill="inherit" />
                          </Box>
                        </Flex>
                        <Flex className={s.operatorsMobile} gap="8px" align="center">
                          <Text className={s.textMobile} color="darkGray" noOfLines={1} variant="medium">
                            {t("Operators")}
                          </Text>
                          <Text textAlign="end" pr="30px">
                            {el.members.length}
                          </Text>
                        </Flex>
                      </Flex>
                    )}

                    <Flex className={s.operators} gap="8px" align="center">
                      <Text className={s.textMobile} color="darkGray" noOfLines={1} variant="medium">
                        {t("Operators")}
                      </Text>
                      <Text textAlign="end" pr={{ base: "0px", sm: "30px" }}>
                        {el.members.length}
                      </Text>
                    </Flex>
                    <DotMenuContainer
                      menuItems={[
                        {
                          MenuItem: {
                            color: "mainRed",
                            icon: <RedTrashIcon width="18px" height="18px" />,
                            text: ct("Delete"),
                            clickEvent: e => {
                              setDeleteTeamPopupState({
                                id: el.id,
                                itemName: el.name,
                              });
                              e.stopPropagation();
                            },
                          },
                        },
                      ]}
                    />
                  </AccordionButton>
                  <AccordionPanel>
                    {el.members.length ? (
                      <Flex w="100%" alignItems="center" flexWrap="wrap" className={s.operatorItemContainer}>
                        {el.members.map((elem, indx, array) => {
                          return (
                            <>
                              <Button
                                position={"relative"}
                                className={s.button}
                                onMouseEnter={() => setHoveredOperator(elem.userId + el.name)}
                                onMouseLeave={() => setHoveredOperator("")}
                              >
                                <Flex alignItems={"center"} maxW={"100%"}>
                                  <Box
                                    style={{
                                      fontSize: "15px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {elem.username}
                                  </Box>
                                  {hoveredOperator === elem.userId + el.name && (
                                    <CloseIcon
                                      className={s.closeIcon}
                                      onMouseDown={e => e.preventDefault()}
                                      onClick={() =>
                                        setDeleteOperatorPopupState({
                                          teamName: el.name,
                                          operatorName: elem.username,
                                          teamId: el.id,
                                          operatorId: elem.userId,
                                        })
                                      }
                                    />
                                  )}
                                </Flex>
                              </Button>
                              {indx + 1 === array.length && (
                                <>
                                  {!IsUnlimited && el.members.length >= MaxCount ? (
                                    <Popover trigger={"hover"}>
                                      {({ onClose }) => (
                                        <>
                                          <PopoverTrigger>
                                            <Box className={sp.boxHover}>
                                              <Button
                                                className={s.addKeywordButtonWrp}
                                                onClick={e => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                <Box className={s.addKeywordButton}>
                                                  <Icon className={sp.starPlanHover} as={StarPlan} boxSize="20px" />
                                                  {t("Operator")}
                                                </Box>
                                              </Button>
                                            </Box>
                                          </PopoverTrigger>
                                          <Portal>
                                            <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                                              <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                                              <Flex alignItems="center" justifyContent="center" direction="column">
                                                <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                                                  {tp("Advanced feature")}
                                                </PopoverHeader>
                                                <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                                                  {tp("Please upgrade your plan to add more Operators")}
                                                </PopoverBody>
                                                <TariffPlanRedirectButton onClose={onClose} />
                                              </Flex>
                                            </PopoverContent>
                                          </Portal>
                                        </>
                                      )}
                                    </Popover>
                                  ) : (
                                    <Button
                                      className={s.addKeywordButtonWrp}
                                      onClick={e => {
                                        setAddOperatorModalState({ teamId: el.id, includedOperators: el.members });
                                        onOpen();
                                        e.stopPropagation();
                                      }}
                                    >
                                      <Box className={s.addKeywordButton}>+ {t("Operator")}</Box>
                                    </Button>
                                  )}
                                </>
                              )}
                            </>
                          );
                        })}
                      </Flex>
                    ) : (
                      <Flex w="100%" alignItems="center" justifyContent="center" className={s.operatorEmptyItem}>
                        <Text>{t("No Operators added")}</Text>
                        <Button
                          ml="16px"
                          className={s.addKeywordButtonWrp}
                          onClick={e => {
                            setAddOperatorModalState({ teamId: el.id, includedOperators: el.members });
                            onOpen();
                            e.stopPropagation();
                          }}
                        >
                          <Box className={s.addKeywordButton}>+ {t("Operator")}</Box>
                        </Button>
                      </Flex>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Box>
        )}
      </Accordion>
      <NewTeamModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
