import {
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  PlacementWithLogical,
  Tooltip,
} from "@chakra-ui/react";
import s from "./FieldToFilterMenu.module.scss";
//import sf from "../../ComplexFilter.module.scss";
import { ReactComponent as CustomFilterIcon } from "../../../../assets/icons/customFilterIcon.svg";
import { ReactComponent as arrayTypeIcon } from "../../../../assets/icons/variableTypes/arrayTypeIcon.svg";
import { ReactComponent as textTypeIcon } from "../../../../assets/icons/variableTypes/textTypeIcon.svg";
import { ReactComponent as dateTypeIcon } from "../../../../assets/icons/variableTypes/dateTypeIcon.svg";
import { ReactComponent as numberTypeIcon } from "../../../../assets/icons/variableTypes/numberTypeIcon.svg";
import { ReactComponent as booleanTypeIcon } from "../../../../assets/icons/variableTypes/booleanTypeIcon.svg";
import { ReactComponent as catalogTypeIcon } from "../../../../assets/icons/variableTypes/catalogTypeIcon.svg";
import { useTranslation } from "react-i18next";
import { CustomVariableType } from "../../../../common/AppEnums";
import {
  ComplexFilterItemsModel,
  ComplexFilterFieldTypes,
  FieldParamModel,
  FilterParamTypes,
  MenuButtonInfo,
  MenuButtonTypes,
  FilterParams,
} from "../../ComplexFilterModel";
import { useState } from "react";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import { useAppSelector } from "../../../../common/state/store";
import { selectRecipientsList } from "../../../broadcast/BroadcastSlice";

interface Props {
  filterFields: ComplexFilterItemsModel;
  onSetFilterParam: (filterParamType: FilterParamTypes, fieldParam: FieldParamModel, id: string) => void;
  menuButtonProps: MenuButtonInfo;
  filterParamType: FilterParamTypes;
  id: string;
  menuStyle?: string;
  defaultIsOpen: boolean;
  placement?: PlacementWithLogical;
  placementCondition?: PlacementWithLogical;
  filterParams?: FilterParams[];
  isOpenModalFilters?: boolean;
  setIsOpenModalFilters?: (isOpenModalFilters: boolean) => void;
  componentSlice?: string;
}

export const FieldToFilterMenu = (props: Props) => {
  const [searchedText, setSearchedText] = useState("");
  const [searchedValues, setSearchedValues] = useState<ComplexFilterItemsModel | undefined>(props.filterFields);
  const recipientList = useAppSelector(selectRecipientsList);
  const { t } = useTranslation("translation", { keyPrefix: "complexFilter" });
  const trackEvent = useGAWithCustomParameters("Broadcast");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const trackFilterEvent = () => {
    if (window.location.pathname.includes("broadcast")) {
      trackEvent("BroadcastContactsFiltrationClick");
    }
  };

  const onSearchTextChange = (value: string) => {
    setSearchedText(value);
    const searchedVals: ComplexFilterItemsModel = {
      groups:
        props.filterFields?.groups.map(el => {
          const groupItems = el.items.filter(e => e.fieldName.toLowerCase().includes(value.toLowerCase()));
          return {
            ...el,
            items: groupItems,
          };
        }) ?? [],
    };
    if (searchedVals.groups?.length) {
      setSearchedValues(searchedVals);
      return;
    }
    setSearchedValues(undefined);
  };

  const variableTypeIcons = {
    [CustomVariableType.Text]: textTypeIcon,
    [CustomVariableType.Array]: arrayTypeIcon,
    [CustomVariableType.Number]: numberTypeIcon,
    [CustomVariableType.Date]: dateTypeIcon,
    [CustomVariableType.DateTime]: dateTypeIcon,
    [CustomVariableType.Boolean]: booleanTypeIcon,
    [CustomVariableType.Order]: catalogTypeIcon,
  };

  const handleMenuButtonClick = () => {
    if (props.setIsOpenModalFilters && props.filterParams && props.filterParams[0].field) {
      props.setIsOpenModalFilters(!props.isOpenModalFilters);
    } else {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  return (
    <Box className={s.compexFilterMenu}>
      <Menu
        gutter={20}
        variant="dominoGroupedMenu"
        placement={
          props.placement
            ? props.placement
            : props.menuButtonProps.buttonType === MenuButtonTypes.Icon
            ? "right-start"
            : props.componentSlice === "ContactList"
            ? "bottom-start"
            : props.menuStyle === "conditionNode"
            ? "bottom-start"
            : "bottom-start"
        }
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        defaultIsOpen={props.defaultIsOpen}
      >
        {props.menuButtonProps.buttonType === MenuButtonTypes.Icon ? (
          <>
            <MenuButton
              className={
                window.location.pathname.includes("contacts")
                  ? `menuFilterButton menuFilterButton_ContactList`
                  : `menuFilterButton`
              }
              as={IconButton}
              top={window.location.pathname.includes("contacts") ? "4px" : "0"}
              isDisabled={props.menuButtonProps.isMenuButtonDisabled}
              icon={<CustomFilterIcon style={recipientList?.availableRecipients ? { fill: "#E2E8F0", opacity: 0.4 } : {}} />}
              aria-label="Options"
              variant={recipientList?.availableRecipients ? "#E2E8F0" : "dominoFillIconButton"}
              boxSize="24px"
              data-pw="complex-filter-field-trigger"
              onClick={() => {
                trackFilterEvent();
                handleMenuButtonClick();
              }}
            />
            {props.filterParams &&
              props.filterParams[0]?.field &&
              props.setIsOpenModalFilters &&
              !props.isOpenModalFilters &&
              props.componentSlice === "ContactList" && (
                <div className={s.menuButtonFiltersCount}>
                  <p>{props.filterParams.length}</p>
                </div>
              )}
          </>
        ) : (
          <MenuButton
            type="button"
            aria-label="Titles"
            _active={{
              backgrounColor: "white",
              borderColor: "blueLink",
              color: "mainPurple",
            }}
            className={s.triggerLink}
            data-pw="complex-filter-field-trigger"
            onClick={() => {
              handleMenuButtonClick();
            }}
          >
            {props.menuButtonProps.title?.length ? props.menuButtonProps.title : <pre>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</pre>}
          </MenuButton>
        )}
        {isMenuOpen && (
          <MenuList
            className={`${s.variableModal} ${props.menuStyle === "conditionNode" && s.conditionNodeVariableModal} ${
              props.placement && s.broadcastFilter
            } ${props.placementCondition && s.broadcastConditionFilter} ${
              window.location.pathname.includes("contacts") && s.contactList
            }
            }`}
          >
            <Box className={s.searchBox}>
              <InputSearch
                inputGroupStyles={{ w: "auto" }}
                placeholder={t("Search") ?? ""}
                searchText={searchedText}
                setSearchText={onSearchTextChange}
                autoFocus
                onBlur={e => e.target.focus()}
              />
            </Box>
            <Flex flexDir="column" className={s.varList}>
              {searchedValues?.groups?.map((el, index) => {
                return (
                  <MenuGroup key={index} mt="10px" title={el.label ?? ""}>
                    {el.items.length ? (
                      el.items.map((elem, index) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              props.onSetFilterParam(
                                props.filterParamType,
                                {
                                  title: elem.fieldName,
                                  value: elem.fieldValue,
                                  type: elem.type ?? ComplexFilterFieldTypes.Text,
                                  ...(elem.groupType && {
                                    groupType: elem.groupType,
                                  }),
                                },
                                props.id,
                              );
                            }}
                            data-pw={elem.fieldName}
                          >
                            {!!elem.type && <Icon mr="10px" color="darkGrey" boxSize="14px" as={variableTypeIcons[elem.type]} />}
                            <Tooltip placement="top" label={elem.description} fontSize="md">
                              {elem.fieldName}
                            </Tooltip>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <Flex key={"No Fields Found"} alignItems="center" justifyContent="center" h="40px" color="darkGrey">
                        {t("No Fields Found")}
                      </Flex>
                    )}
                  </MenuGroup>
                );
              })}
            </Flex>
          </MenuList>
        )}
      </Menu>
    </Box>
  );
};
