import { store } from "../..";
import { TariffPlanFeatureTypeEnum } from "../AppEnums";
import { TariffPlanFeatureModel, TariffPlanPreferenceModel } from "./TariffPlanModel";

export const getTariffPlanAccessability = (name: TariffPlanFeatureTypeEnum, tariffSelector?: TariffPlanFeatureModel[]) => {
  const tariff = tariffSelector || store.getState().tariffPlanState.features;
  const tariffFeature = tariff.find(feature => feature.featureType === name);
  const tariffPreference = tariffFeature?.preferences ?? [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const accessability: any = {};

  tariffPreference.map((preference: TariffPlanPreferenceModel) => (accessability[preference.key] = preference.value));

  return accessability;
};
