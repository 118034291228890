import { useBrowserFitContent, useTabletView } from "../../LayoutHelper/ResolutionHooks";
import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { UserManagementContainer } from "../../../userManagement/UserManagementContainer";
import { UserManagementHeader } from "../../../userManagement/components/UserManagementHeader";
import { HEADER } from "../../../layout/LayoutHelper/ResolutionConst";

export const UserManagementPage = () => {
  const browserFill = useBrowserFitContent();
  const isTablet = useTabletView();
  const [mobileView, isMobileView] = useState(isTablet);

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <UserManagementHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={mobileView ? window.innerHeight - HEADER - HEADER : browserFill}>
        <UserManagementContainer />
      </Box>
    </Flex>
  );
};
