import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(alertAnatomy.keys);

const dominoWarningAlertVariant = definePartsStyle({
  container: {
    backgroundColor: "#fff3cc",
    padding: "24px",
    border: "1px solid #d9d9d9",
    borderRadius: "8px",
    width: "100%",
  },
});

const variants = {
  dominoWarningAlertVariant: dominoWarningAlertVariant,
};

export const alertTheme = defineMultiStyleConfig({ variants });
