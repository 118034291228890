import { httpGet, httpPost } from "../../common/BaseApi";
import { OperatorJoinData, OperatorLeftData } from "./ChatListData";

export const getConversationList = (botId: string, queryString: string) => {
  return httpGet(`/api/conversation/bot/${botId}${queryString}`);
};

export const getConversationCount = (botId: string, queryString: string) => {
  return httpGet(`/api/conversation/bot/${botId}/count${queryString}`);
};

export const getMyTeams = (page: number) => {
  return httpGet(`/api/team?page=${page}&filters[0].field=myTeams&filters[0].condition=Equal`);
};

export const getOverLimitConversations = (botId: string) => {
  return httpGet(`/api/conversation/getOverLimit/${botId}`);
};

export const postOperatorJoin = (data: OperatorJoinData) => {
  return httpPost(`/api/conversation/operatorJoin`, data);
};

export const postOperatorLeft = (data: OperatorLeftData) => {
  return httpPost(`/api/conversation/operatorLeft`, data);
};

export const getCurrentOperatorViewers = () => {
  return httpGet(`/api/conversation/currentOperators`);
};
