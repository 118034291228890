import { FileUploadType } from "../../components/conversation/ConversationModel";
import { IMAGE_FILE_EXTS } from "../AppEnums";

export function FileValidate(file: File, type: FileUploadType) {
  const fileImageSizeMB = 10;
  const fileDocumentSizeMB = 20;

  let fileSizeB = 1024 * 1024;
  fileSizeB *= type === FileUploadType.photo ? fileImageSizeMB : fileDocumentSizeMB;

  if (file.size > fileSizeB) {
    return { isInvalid: true, error: "File is too big" };
  }

  if (type === FileUploadType.document) {
    if (file.size === 0) {
      return { isInvalid: true, error: "The file should not be empty" };
    }
  }

  if (type === FileUploadType.photo) {
    const ext = file.type.split("/")[1];
    if (!IMAGE_FILE_EXTS.includes(ext)) {
      return { isInvalid: true, error: "This file format isn’t supported" };
    }
  }
  return { isInvalid: false, error: "" };
}

export interface FileValidateModel {
  isInvalid: boolean;
  error: string;
}
