import { Avatar, Box, Flex, Button, useClipboard } from "@chakra-ui/react";
import s from "../../Conversation.module.scss";
import { ConversationMessageModel } from "../../ConversationModel";
import { useTranslation } from "react-i18next";
import { formatMessageDate } from "../../../../common/utils/formatDate";
import { notify } from "../../../../common/notifications/NotificationSlice";
import { useAppDispatch } from "../../../../common/state/store";
import { useEffect } from "react";

interface Props {
  message: ConversationMessageModel;
}

export const ContactMessageItem = (props: Props) => {
  const { contact } = props?.message;
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });
  const phoneNumberInfo = contact?.phoneNumber ?? "";
  const { onCopy, hasCopied } = useClipboard(phoneNumberInfo);
  const messageContactLength = props.message.contact;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (hasCopied) {
      dispatch(notify({ message: `${t("Copied")}!`, type: "success" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied]);

  return (
    <>
      {!!messageContactLength ? (
        <Flex
          flexDirection={"column"}
          id={`contact-${props.message.id}`}
          className={`${s.messageTextWrapper} ${s.messageContactWrapper} ${props.message.replyToMessage && s.messageWithText}`}
        >
          <Box className={s.sharedContactWrapper}>
            <Flex className={s.contactData}>
              <Avatar
                className={s.contactAvatar}
                name={contact?.firstName}
                bg="midDeepBlue"
                color={"white"}
                border="1px solid"
                borderColor="mainPurple"
              />
              <Box>
                <Box className={s.contactName}>
                  {contact?.lastName && <span>{contact?.lastName} </span>}
                  <span>{contact?.firstName}</span>
                </Box>
                {contact?.phoneNumber && <p className={s.phoneNumber}>{contact?.phoneNumber}</p>}
              </Box>
            </Flex>
            <Box margin={"0 auto"} borderTop={"2px solid var(--chakra-colors-line)"}>
              <Button className={s.copyButton} onClick={() => onCopy()}>
                {t("Copy")}
              </Button>
            </Box>
          </Box>
          <Box className={s.date}>{formatMessageDate(props?.message?.date)}</Box>
        </Flex>
      ) : null}
    </>
  );
};
