import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const dominoLightVariant = defineStyle({
  backgroundColor: "#FEF6DC",
  borderRadius: "4px",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
  color: "black",
  padding: "8px",
});

const dominoLiveChatVariant = defineStyle({
  borderRadius: "4px",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
  color: "black",
  padding: "8px",
});

const dominoBroadcastButtonVariant = defineStyle({
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0 2px 10px #232B3914",
  border: "1px solid #DAE1FB",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "20px",
  color: "black",
  padding: "16px",
});

const variants = {
  dominoLight: dominoLightVariant,
  dominoChat: dominoLiveChatVariant,
  dominoBroadcastButton: dominoBroadcastButtonVariant,
};

export const tooltipTheme = defineStyleConfig({ variants });
