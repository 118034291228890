import { Flex, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import { Loading } from "../../common/loading/LoadingStateContainer";
import { RouterContainer } from "../../common/router/RouterContainer";
import { AuthSidebar } from "../authSidebar/AuthSidebar";
import { PublicHeaderContainer } from "../headers/publicHeaderContainer/PublicHeaderContainer";

export const PublicLayout = (children: React.ReactNode, sidebarTitle: string, sidebarDescription?: string) => {
  return (
    <RouterContainer>
      <Grid h="100%" templateRows="auto 1fr" templateColumns="1fr" bg={{ base: "bgLight", md: "white" }} overflow={"auto"}>
        <GridItem>
          <PublicHeaderContainer />
        </GridItem>
        <GridItem position={"relative"}>
          <Loading scope="signIn" />
          <Flex h="100%">
            <AuthSidebar title={sidebarTitle} description={sidebarDescription ?? ""} />
            {children}
          </Flex>
        </GridItem>
      </Grid>
    </RouterContainer>
  );
};
