import { Box, Container, FormControl, FormErrorMessage, FormLabel, Text, Textarea } from "@chakra-ui/react";
import { Field, FieldProps, FormikErrors } from "formik";
import { BroadcastFormModel, FileUploadType } from "../../../../BroadcastModel";
import s from "../../Broadcast.module.scss";
import { InputWrapper } from "../../../../../inputWrapper/InputWrapper";
import { InputType } from "../../../../../inputWrapper/InputWrapperModel";
import { CustomVariableModel, IMAGE_FILE_EXTS } from "../../../../../../common/AppEnums";
import { addButton, deleteButton, editButton, setButtons } from "../../../ButtonsList/utils";
import { Attachments } from "../../../Attachments/Attachments";
import React, { DragEvent, useState, ClipboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { insertStringIntoText } from "../../../../../../common/utils/insertStringIntoText";
import { maxLength } from "../../../../../../common/validation/defaultValidators";
import { CustomVariablesModel } from "../../../../../flowBuilder/FlowBuilderModel";
import { addFormatting } from "../../../../../../common/formattedText/addFormatting";
import { selectCurrentFile } from "../../../../BroadcastSlice";
import { useAppSelector } from "../../../../../../common/state/store";
import { FormatAction } from "../../../../../../common/utils/escapeMarkdown";
import { ButtonsList } from "../../../ButtonsList/ButtonsList";
//import { formatTelegramTextToEdit } from "../../../../../../common/utils/formatTelegramText";

interface Props {
  values: BroadcastFormModel;
  variables: CustomVariableModel[] | undefined;
  setFieldValue: (
    field: string,
    value: any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<BroadcastFormModel>>;
  uploadFile: (file: File, type: FileUploadType) => void;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  messageInvalid: boolean;
  setMessageInvalid: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BroadcastFormMessage = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const tr = useTranslation("translation", { keyPrefix: "conversation" }).t;

  const currentFile = useAppSelector(selectCurrentFile);
  const messageMaxLength = currentFile == null ? 4000 : 1024;

  const [textAreaCursorPosition, setTextAreaCursorPosition] = useState<number | undefined>();
  const [isDragging, setDragging] = useState(false);
  const [messageFirstInit, setMessageFirstInit] = useState(true);

  const onChangeMessageValidation = () => {
    setMessageFirstInit(false);
    if (props.textareaRef.current) {
      props.setMessageInvalid(!props.textareaRef.current.value.length);
      props.setFieldValue("message.text", props.textareaRef.current.value ?? "");
    }
  };

  const onAddEmoji = (
    emojiData: string,
    fieldValue: string,
    inputMaxLength: number,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    const text = insertStringIntoText(fieldValue ?? "", emojiData, textAreaCursorPosition);
    const lengthError = maxLength(inputMaxLength)(text);
    if (lengthError) {
      return;
    }
    setFieldValue("message.text", text);
    if (props.textareaRef.current) {
      props.textareaRef.current.value = insertStringIntoText(
        props.textareaRef.current.value ?? "",
        emojiData,
        textAreaCursorPosition,
      );
    }
  };

  const onAddCustomVariableText = (
    variable: CustomVariablesModel,
    fieldValue: string,
    inputMaxLength: number,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    const text = insertStringIntoText(fieldValue ?? "", "@{" + variable.scope + ":" + variable.key + "}", textAreaCursorPosition);
    const lengthError = maxLength(inputMaxLength)(text);
    if (lengthError) {
      return;
    }
    setFieldValue("message.text", text);
    if (props.textareaRef.current) {
      props.textareaRef.current.value = insertStringIntoText(
        props.textareaRef.current.value ?? "",
        "@{" + variable.scope + ":" + variable.key + "}",
        textAreaCursorPosition,
      );
    }
  };

  const onAddFormatting = (
    action: FormatAction,
    fieldValue: string,
    inputMaxLength: number,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    if (props.textareaRef.current) {
      const newText = addFormatting(action, fieldValue, inputMaxLength, props.textareaRef.current, value => {
        setFieldValue("message.text", value);
      });
      if (newText) {
        props.textareaRef.current.value = newText;
      }
    }
  };

  const onDropFile = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer?.files[0];
    if (file) {
      const [type, ext] = file.type.split("/");
      if (type === "image" && IMAGE_FILE_EXTS.includes(ext)) {
        props.uploadFile(file, FileUploadType.photo);
      } else {
        props.uploadFile(file, FileUploadType.document);
      }
    }
  };

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragging(true);
    } else if (e.type === "dragleave" || e.type === "dragend") {
      setDragging(false);
    }
  };

  const handlePasteFromClipBoard = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    const { clipboardData } = e;
    if (clipboardData && clipboardData.files.length > 0) {
      const file = clipboardData?.files[0];

      if (file && !currentFile) {
        e.preventDefault();
        if (file.type.match("^image/")) {
          props.uploadFile(file, FileUploadType.photo);
        } else {
          props.uploadFile(file, FileUploadType.document);
        }
      }
    }
  };

  const getCharactersNumber = () => {
    if (props.textareaRef.current) {
      return props.textareaRef.current.value.length;
    } else {
      return 0;
    }
  };

  return (
    <Container position={"relative"} onDragEnter={handleDrag} variant="dominoContentBlock">
      <Field name="message.text">
        {({ field, form }: FieldProps<string, BroadcastFormModel>) => (
          <FormControl
            isInvalid={messageFirstInit ? false : props.messageInvalid}
            onChange={onChangeMessageValidation}
            className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveMessage}`}
          >
            <FormLabel mr={0}>{t("Broadcast message")}</FormLabel>
            <Box width="100%">
              <Box className={s.broadcastMessageWrapper}>
                <InputWrapper
                  cursorPosition={textAreaCursorPosition ?? 0}
                  type={InputType.TextArea}
                  maxLength={messageMaxLength}
                  charactersNumber={getCharactersNumber()}
                  emojiProps={{
                    addEmoji: (emojiData: string) => {
                      onAddEmoji(emojiData, field.value, messageMaxLength, props.setFieldValue);
                    },
                  }}
                  addFormatting={action => onAddFormatting(action, field.value, messageMaxLength, props.setFieldValue)}
                  childRef={props.textareaRef}
                  variablesProps={
                    props.variables?.length
                      ? {
                          variables: props.variables,
                          addCustomVariableText: (variable: CustomVariableModel) => {
                            onAddCustomVariableText(variable, field.value, messageMaxLength, props.setFieldValue);
                          },
                        }
                      : undefined
                  }
                  buttonBlockStyle={{ width: "auto" }}
                >
                  <Textarea
                    data-pw="broadcast-message-textarea"
                    ref={props.textareaRef}
                    maxLength={messageMaxLength}
                    resize="none"
                    variant="dominoColorBroadcast"
                    placeholder={t("Broadcast message placeholder") ?? ""}
                    onBlurCapture={e => setTextAreaCursorPosition(e.target.selectionStart)}
                    onSelect={e => setTextAreaCursorPosition(e.currentTarget.selectionStart)}
                    onPaste={handlePasteFromClipBoard}
                  />
                </InputWrapper>
              </Box>

              <ButtonsList
                buttons={props.values.message.buttons}
                addButton={addButton(props.values, props.setFieldValue)}
                editButton={editButton(props.values, props.setFieldValue)}
                deleteButton={deleteButton(props.values, props.setFieldValue)}
                setButtons={setButtons(props.setFieldValue)}
              />
              <Attachments />
            </Box>
            <FormErrorMessage data-pw="broadcast-message-error">
              {props.messageInvalid ? t("Message field can't be empty") : ""}
            </FormErrorMessage>
          </FormControl>
        )}
      </Field>
      {isDragging && (
        <div
          className={s.dragFileElement}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={onDropFile}
        ></div>
      )}
      {isDragging && (
        <div className={s.dragFileContainer}>
          <div className={s.dragFileContainerText}>
            <Text fontSize={"2rem"}>{tr("Drag and drop to attach file")}</Text>
          </div>
        </div>
      )}
    </Container>
  );
};
