import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { BotListHeader } from "../../../botList/components/BotListHeader/BotListHeader";
import { BotListContainer } from "../../../botList/BotListContainer";

export const BotsPage = () => {
  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <BotListHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={"100%"}>
        <Loading />
        <BotListContainer />
      </Box>
    </Flex>
  );
};
