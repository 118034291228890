import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { selectCurrentBot } from "../sidebar/SidebarSlice";
import {
  clearChatListState,
  getChatList,
  getMyTeams,
  selectChatList,
  selectFilter,
  selectMyTeams,
  selectSearchText,
  selectSort,
  selectOverLimitedConversations,
  getOverLimitConversations,
  selectIsPlanUpdated,
  chatListSlice,
  getChatListCount,
} from "./ChatListSlice";
import { ChatList } from "./components/ChatList/ChatList";
import useDebounce from "../../common/hooks/useDebounce";

export const ChatListContainer = () => {
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(selectCurrentBot);
  const chatListRef = useRef<null | HTMLDivElement>(null);
  const chatList = useAppSelector(selectChatList);
  const myTeams = useAppSelector(selectMyTeams);
  const overLimitConversations = useAppSelector(selectOverLimitedConversations);
  const isPlanUpdated = useAppSelector(selectIsPlanUpdated);
  const filter = useAppSelector(selectFilter);
  const searchText = useAppSelector(selectSearchText);
  const sort = useAppSelector(selectSort);

  const debouncedSearchText = useDebounce(searchText, 500);
  const scrollToBottom = () => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (isPlanUpdated) {
      dispatch(chatListSlice.actions.updateChatListAfterPlanUpgradeCompleted());
      if (currentBot) {
        dispatch(
          getChatList({
            botId: currentBot.id,
            search: debouncedSearchText,
            sort,
            filter,
            lastId: "1",
          }),
        );
        dispatch(
          getChatListCount({
            botId: currentBot.id,
            search: debouncedSearchText,
            sort,
            filter,
            lastId: "1",
          }),
        );
      }
      dispatch(getOverLimitConversations());
    }
  }, [currentBot, debouncedSearchText, dispatch, filter, isPlanUpdated, sort]);

  useEffect(() => {
    if (currentBot) {
      scrollToBottom();
      dispatch(
        getChatList({
          botId: currentBot.id,
          search: debouncedSearchText,
          sort,
          filter,
          lastId: "1",
        }),
      );
      dispatch(
        getChatListCount({
          botId: currentBot.id,
          search: debouncedSearchText,
          sort,
          filter,
          lastId: "1",
        }),
      );
      dispatch(getOverLimitConversations());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, sort, filter, debouncedSearchText]);

  useEffect(() => {
    dispatch(getMyTeams({ page: 1 }));
    return () => {
      dispatch(clearChatListState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!chatList) {
    return <></>;
  }

  const getChatListOnScroll = (lastId: string) => {
    if (currentBot) {
      dispatch(
        getChatList({
          botId: currentBot.id,
          search: debouncedSearchText,
          sort,
          filter,
          lastId,
        }),
      );
    }
  };

  return (
    <ChatList
      myTeams={myTeams}
      chatList={chatList}
      chatListRef={chatListRef}
      getChatListOnScroll={getChatListOnScroll}
      overLimitConversations={overLimitConversations}
    />
  );
};
