import { EmptyListView } from "../../../emptyView/EmptyListView";
import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBrowserFitContent } from "../../../layout/LayoutHelper/ResolutionHooks";
import { ReactComponent as ImageSearch } from "../../../../assets/images/imageSearch.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plusIconTile.svg";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/download.svg";

interface Props {
  onImportContactsOpen: () => void;
  onNewContact: () => void;
}

export const ContactListEmptyPage = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "contactList" });
  const importTransl = useTranslation("translation", { keyPrefix: "importContacts" }).t;
  const fillAvailable = useBrowserFitContent();

  return (
    <Flex height={fillAvailable}>
      <EmptyListView
        title={t("You don't have any contacts yet")}
        description={t("Upload your external contacts and use it in Domino")}
        CustomButton={
          <Flex gap={{ base: "16px", xl: "24px" }} flexDirection={["column", "column", "column", "column", "row"]} width={"100%"}>
            <Button variant="dominoViolet" onClick={props.onImportContactsOpen} data-pw="import-contacts-button">
              <Flex gap={"8px"} align={"center"}>
                <ImportIcon />
                {importTransl("Import Contacts")}
              </Flex>
            </Button>
            <Button size="sm" variant="dominoOutlineViolet" onClick={props.onNewContact}>
              <Flex gap={"8px"} align={"center"}>
                <PlusIcon />
                {t("New Contact")}
              </Flex>
            </Button>
          </Flex>
        }
        Image={ImageSearch}
      />
    </Flex>
  );
};
