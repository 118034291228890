import { Box } from "@chakra-ui/react";
import EditContactItem from "../EditContactItem/EditContactItem";
import { ContactVariableModel } from "../../../ContactModel";
import { Dispatch, SetStateAction } from "react";
import { CustomVariableType } from "../../../../../common/AppEnums";

interface Props {
  contactVariables: ContactVariableModel[];
  getValueInput: (contactVar: ContactVariableModel) => JSX.Element;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleOnBlurDescription: (contactVar: ContactVariableModel, variablesDescription: Record<string, unknown> | undefined) => void;
  setVariablesDescription: Dispatch<SetStateAction<Record<string, unknown> | undefined>>;
  variableDeleteConfirm: (variable: ContactVariableModel) => void;
  variablesDescription: Record<string, unknown> | undefined;
  executeMobileModal?: (type: CustomVariableType, contactVar: ContactVariableModel) => void;
}

export const EditContactVariables = (props: Props) => {
  return (
    <Box>
      {props.contactVariables?.map(contactVar => (
        <EditContactItem
          contactVar={contactVar}
          getValueInput={props.getValueInput}
          handleKeyPress={props.handleKeyPress}
          handleOnBlurDescription={props.handleOnBlurDescription}
          setVariablesDescription={props.setVariablesDescription}
          variableDeleteConfirm={props.variableDeleteConfirm}
          variablesDescription={props.variablesDescription}
          key={contactVar.id}
          executeMobileModal={props.executeMobileModal}
        />
      ))}
    </Box>
  );
};
