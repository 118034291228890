import { Center } from "@chakra-ui/react";
import s from "./ScopeSpinner.module.scss";

export const ScopeSpinner = () => {
  return (
    <div className={s.overlay}>
      <Center>
        <svg className={s.spinner} viewBox="0 0 50 50">
          <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
      </Center>
    </div>
  );
};
