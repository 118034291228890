/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { GetTokenAuthCodeRequestModel, GetTokenPasswordRequestModel } from "./LoginModel";

export interface LoginState {
  loginError?: string;
}

const initialState = {};

export const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    getTokenRequested: (state, action: PayloadAction<{ tokenModel: GetTokenPasswordRequestModel; redirectUrl?: string }>) => {
      return {
        ...state,
      };
    },
    getTokenByAuthCodeRequested: (state, action: PayloadAction<GetTokenAuthCodeRequestModel>) => {
      return {
        ...state,
      };
    },
    getTokenFailed: (state, action: PayloadAction<unknown>) => {
      return {
        ...state,
        loginError: action.payload,
      };
    },
  },
});

export const { getTokenRequested, getTokenFailed, getTokenByAuthCodeRequested } = loginSlice.actions;

export const selectLoginError = (state: RootState) => state.app.loginState.loginError;

export default loginSlice.reducer;
