import { ContactVariableModel } from "../../ContactModel";

export function mapValuesToObjectData(contactVariables: ContactVariableModel[] | undefined) {
  return contactVariables?.reduce(
    (variablesObject, currentVariable) => ({
      ...variablesObject,
      [currentVariable.id]: (() => {
        return currentVariable.value ?? "";
      })(),
    }),
    {},
  );
}

export function mapDescriptionToObjectData(contactVariables: ContactVariableModel[] | undefined) {
  return contactVariables?.reduce(
    (variablesObject, currentVariable) => ({
      ...variablesObject,
      [currentVariable.id]: (() => {
        return currentVariable.description ?? "";
      })(),
    }),
    {},
  );
}
