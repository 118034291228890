import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { Loading } from "../../common/loading/LoadingStateContainer";
import { clearStore, connectBot, selectConnectedBotInfo, selectIsConnectionCompleted } from "./AddBotSlice";
import { BotCompletion } from "./components/BotCompletion/BotCompletion";
import { BotStart } from "./components/BotStart/BotStart";
import { ExistedBot } from "./components/ExistedBot/ExistedBot";
import { NewBot } from "./components/NewBot/NewBot";
import * as vars from "../../components/layout/LayoutHelper/ResolutionConst";
import { useMobileView } from "../layout/LayoutHelper/ResolutionHooks";
import { selectIsOrganizationTrial } from "../organisation/OrganisationSlice";

export const AddBotContainer = () => {
  const dispatch = useAppDispatch();
  const isConnectionCompleted = useAppSelector(selectIsConnectionCompleted);
  const connectedBotInfo = useAppSelector(selectConnectedBotInfo);
  const location = useLocation();
  const isMobile = useMobileView();
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  const onConnect = (token: string) => {
    dispatch(connectBot(token));
  };

  const clearAddBotStore = () => {
    dispatch(clearStore());
  };

  useEffect(() => {
    return () => {
      clearAddBotStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      backgroundColor={{ base: "auto", md: "white" }}
      width="100%"
      height={`calc(100dvh - ${vars.HEADER}px ${isTrial && isMobile ? "- 60px" : ""} - 60px)`}
      className={"addBotContainer"}
    >
      <Loading />
      {location.pathname === "/addbot" ? (
        <BotStart clearAddBotStore={clearAddBotStore} />
      ) : isConnectionCompleted && connectedBotInfo ? (
        <BotCompletion connectedBot={connectedBotInfo} clearAddBotStore={clearAddBotStore} />
      ) : (
        <Routes>
          <Route path="/new" element={<NewBot onConnect={onConnect} />} />
          <Route path="/existed" element={<ExistedBot onConnect={onConnect} />} />
        </Routes>
      )}
    </Flex>
  );
};
