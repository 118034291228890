import s from "./SendMessageNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { useTranslation } from "react-i18next";
import { ReactComponent as TelegramIcon } from "../../../../../assets/icons/telegramIcon.svg";
import { Flex, Icon } from "@chakra-ui/react";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import { getButtonsWithExactType } from "../../../utils";
import { SendTextFlowActionModel } from "../../../FlowBuilderModel";

function SendMessageNode(props: { actionData: ActionNodeProps }) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as SendTextFlowActionModel;

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={TelegramIcon} boxSize="32px" />
        <div className={s.textBlock}>
          <div className={s.telegram}>{t("Telegram")}</div>
          <div className={s.type}>{t("Send Message")}</div>
        </div>
      </div>
      {data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length ? (
        <MessageButtonBlock actionData={props.actionData} />
      ) : (
        <Flex justifyContent="center" alignItems="center" className={s.noTextBlock} h="74px">
          {t("Add Text")}
        </Flex>
      )}
    </Flex>
  );
}

export default SendMessageNode;
