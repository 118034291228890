import { Box } from "@chakra-ui/react";
import { CSSProperties } from "react";
import { CustomVariableContextMenu } from "../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { EmojiPropsModel, VariablesPropsModel } from "../../InputWrapperModel";
import { EmojiMenu } from "../emojiMenu/EmojiMenu";
import s from "./InputComponent.module.scss";

interface Props {
  children: React.ReactNode;
  charactersLeft: number;
  variablesProps?: VariablesPropsModel;
  emojiProps?: EmojiPropsModel;
  buttonBlockStyle?: CSSProperties;
}

export const InputComponent = (props: Props) => {
  return (
    <Box position="relative">
      {props.children}
      <Box className={s.textareaButtonBlock} style={props.buttonBlockStyle}>
        <Box className={s.charactersLeft} data-pw="characters-left">
          {props.charactersLeft}
        </Box>
        {props.emojiProps && <EmojiMenu addEmoji={props.emojiProps.addEmoji} />}
        {props.variablesProps && (
          <CustomVariableContextMenu
            selectCustomVariable={props.variablesProps.addCustomVariableText}
            variables={props.variablesProps.variables}
          />
        )}
      </Box>
    </Box>
  );
};
