import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DataMessagesStatisticModel, DataMessagesModel } from "../../../DashboardModel";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

declare type MyTooltop<MyValue extends ValueType, MyName extends NameType> = TooltipProps<MyValue, MyName> & {
  dataChart: DataMessagesModel[] | DataMessagesStatisticModel[];
};

export default function DashboardCustomTooltipChart({ active, payload, dataChart }: MyTooltop<ValueType, NameType>) {
  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });
  if (active && payload && payload.length) {
    return (
      <Box padding="8px" borderRadius="4px" backgroundColor="white" boxShadow="0 0 10px #0000001A">
        <Text lineHeight="16px" fontSize="13px" mb="4px">
          {payload[0].payload.name}
        </Text>
        {payload.map(item => {
          return (
            <Flex lineHeight="16px" fontSize="13px" gap="4px" color={item.color} key={item.name}>
              <Text>{item.name ? t(item.name.toString()) : ""}</Text>
              <Text>{item.value}</Text>
            </Flex>
          );
        })}
      </Box>
    );
  }
  return null;
}
