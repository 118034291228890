import { CustomVariableData } from "../conversation/ConversationData";

export const mapCustomVariableDataToModel = (data: CustomVariableData) => {
  return {
    id: data.id,
    containerId: data.containerId,
    key: data.key,
    type: data.type,
    description: data.description,
  };
};
