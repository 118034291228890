/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { BotListModel, BotModel } from "./SidebarModel";
import { SidebarState } from "./SidebarState";
import { OrganisationSidebarData } from "./SidebarData";

const initialState: SidebarState = {
  isCurrentBotLoading: true,
};

export const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState,
  reducers: {
    getBots: (state: SidebarState, action: PayloadAction<{ page: number; filter?: string }>) => {
      return {
        ...state,
      };
    },
    getBotsCompleted: (state: SidebarState, action: PayloadAction<BotListModel>) => {
      return {
        ...state,
        botList: action.payload,
      };
    },
    getAllOrganisation: (state: SidebarState) => {
      return {
        ...state,
      };
    },
    getAllOrganisationCompleted: (state: SidebarState, action: PayloadAction<OrganisationSidebarData[]>) => {
      return {
        ...state,
        organisationList: action.payload,
      };
    },
    selectBot: (state: SidebarState, action: PayloadAction<BotModel | string>) => {
      return {
        ...state,
      };
    },
    selectBotCompleted: (state: SidebarState, action: PayloadAction<BotModel | undefined>) => {
      return {
        ...state,
        bot: action.payload,
      };
    },
    removeSelectedBot: (state: SidebarState) => {
      return {
        ...state,
        bot: undefined,
      };
    },
    setCurrentBotLoading: (state: SidebarState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isCurrentBotLoading: action.payload,
      };
    },
  },
});

export const {
  getBots,
  getBotsCompleted,
  selectBot,
  selectBotCompleted,
  removeSelectedBot,
  getAllOrganisation,
  getAllOrganisationCompleted,
} = sidebarSlice.actions;

export const selectBots = (state: RootState) => state.app.sidebarState.botList;
export const selectOrganisations = (state: RootState) => state.app.sidebarState.organisationList;
export const selectCurrentBot = (state: RootState) => state.app.sidebarState.bot;
export const selectCurrentBotId = (state: RootState) => state.app.sidebarState.bot?.id;
export const selectIsCurrentBotLoading = (state: RootState) => state.app.sidebarState.isCurrentBotLoading;

export default sidebarSlice.reducer;
