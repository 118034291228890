import { ConversationInfoData } from "../conversation/ConversationData";
import { ConversationInfoModel } from "../conversation/ConversationModel";
import { ActiveJoinOperatorsData, ChatListData, OperatorJoinData, OperatorLeftData } from "./ChatListData";
import { ChatListItemModel, ChatListModel, OperatorJoinModel, OperatorLeftModel } from "./ChatListModel";
import { store } from "../../index";

export const mapChatListDataToModel = (data: ChatListData, count: number): ChatListModel => {
  return {
    ...data,
    totalItems: count,
    items: data.items.map(({ contact, ...rest }) => {
      return {
        ...rest,
        contact: contact
          ? {
              ...contact,
              fullName: `${contact.firstName} ${contact.lastName ?? ""}`.trim(),
            }
          : undefined,
      };
    }),
  };
};

export const mapConversationInfoModelToModelList = (data: ConversationInfoModel): ChatListItemModel => {
  return {
    id: data.id,
    botId: data.botId,
    name: data.name,
    status: data.status,
    lastMessage: data.lastMessage,
    unreadMessageCount: data.unreadMessageCount,
    flowExecutionId: data.flowExecutionId,
    assignedOperator: data.assignedOperator,
    assignedTeam: data.assignedTeam,
    contact: data.contact
      ? {
          ...data.contact,
          fullName: `${data.contact.firstName} ${data.contact.lastName ?? ""}`.trim(),
        }
      : undefined,
    isNew: data.isNew,
    createDate: data.createDate,
  };
};

export const mapConversationInfoDataToModel = (data: ConversationInfoData): ConversationInfoModel => {
  const conversationInfo = store.getState().app.conversationState.conversationInfo;
  if (conversationInfo) {
    return {
      id: data.id,
      botId: data.botId,
      contact: data.contact,
      name: data.name,
      status: data.status,
      lastMessage: data.lastMessage,
      unreadMessageCount: data.unreadMessageCount,
      flowExecutionId: data.flowExecutionId,
      activeFlow: data.activeFlow,
      assignedOperator: conversationInfo.assignedOperator,
      assignedTeam: conversationInfo.assignedTeam,
      createDate: conversationInfo.createDate,
    };
  }
  return {
    id: data.id,
    botId: data.botId,
    contact: data.contact,
    name: data.name,
    status: data.status,
    lastMessage: data.lastMessage,
    unreadMessageCount: data.unreadMessageCount,
    flowExecutionId: data.flowExecutionId,
    activeFlow: data.activeFlow,
    createDate: data.createDate,
  };
};

export const mapOperatorJoinDataToModel = (source: OperatorJoinData) => {
  const model: OperatorJoinModel = {
    operatorId: source.operatorId,
    operatorName: source.operatorName,
    conversationId: source.conversationId,
  };
  return model;
};

export const mapActiveJoinOperatorsDataToModel = (source: ActiveJoinOperatorsData) => {
  const model: OperatorJoinModel = {
    operatorId: source.operatorId,
    operatorName: source.operator,
    conversationId: source.conversationId,
  };
  return model;
};

export const mapListOperatorJoinDataToOperatorJoinModel = (source: OperatorJoinData[]) => {
  const models = source.map(source => {
    return mapOperatorJoinDataToModel(source);
  });
  return models;
};

export const mapListActiveJoinOperatorsDataToModel = (source: ActiveJoinOperatorsData[]) => {
  const models = source.map(source => {
    return mapActiveJoinOperatorsDataToModel(source);
  });
  return models;
};

export const mapOperatorJoinModelToData = (source: OperatorJoinModel) => {
  const data: OperatorJoinData = {
    operatorId: source.operatorId,
    operatorName: source.operatorName,
    conversationId: source.conversationId,
  };
  return data;
};

export const mapOperatorLeftModelToData = (source: OperatorLeftModel) => {
  const data: OperatorLeftData = {
    operatorName: source.operatorName,
  };
  return data;
};
