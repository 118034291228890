import { Dispatch, SetStateAction, useState } from "react";
import { AbsoluteCenter, Box, Divider } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { ButtonTypeDiscriminator, FlowMarkupTypeDiscriminator } from "../../../../common/AppEnums";
import { ReplyKeyboardButtonModel, ButtonModel } from "../../../../common/AppButtonsModel";
import { ChangeButtonPopover } from "../../../modals/changeButtonPopover/ChangeButtonPopover";
import { ReplyButtonPopover } from "../../../modals/changeButtonPopover/ReplyButtonPopover";
import { ButtonsContainerFlowAction, FlowActionModel, FlowActionType, NodeModel } from "../../FlowBuilderModel";
import { selectCustomVariables } from "../../FlowBuilderSlice";
import { useAppSelector } from "../../../../common/state/store";
import { getButtonsWithExactType } from "../../utils";
import { SortableItem } from "../../../dnd/SortableItem/SortableItem";
import s from "../../../modals/changeButtonPopover/ReplyButtonPopover.module.scss";

interface Props {
  buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][];
  node: NodeModel;
  flowAction: FlowActionModel;
  addButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  editButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  deleteButton: (buttonId: string) => void;
  setButtons: (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => void;
}

export const ButtonsList = (props: Props) => {
  const [addButtonState, setAddButtonState] = useState<ButtonModel | ReplyKeyboardButtonModel | null>(null);
  const [editButtonState, setEditButtonState] = useState<ButtonModel | ReplyKeyboardButtonModel | null>(null);
  const variables = useAppSelector(selectCustomVariables);
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      if (
        (props.flowAction as ButtonsContainerFlowAction).replyMarkup?.typeDiscriminator ===
        FlowMarkupTypeDiscriminator.InlineKeyboardMarkup
      ) {
        const items = props.buttons as ButtonModel[][];
        const activeOld = items.find(item => item.find(item => item.id === active?.id));
        const overNew = items.find(item => item.find(item => item.id === over?.id));
        if (activeOld && overNew) {
          const oldIndex = items.indexOf(activeOld);
          const newIndex = items.indexOf(overNew);
          props.setButtons(arrayMove(items, oldIndex, newIndex));
        }
      } else if (
        (props.flowAction as ButtonsContainerFlowAction).replyMarkup?.typeDiscriminator ===
        FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup
      ) {
        const items = props.buttons as ReplyKeyboardButtonModel[][];
        const activeOld = items.find(item => item.find(item => item.id === active?.id));
        const overNew = items.find(item => item.find(item => item.id === over?.id));
        if (activeOld && overNew) {
          const oldIndex = items.indexOf(activeOld);
          const newIndex = items.indexOf(overNew);
          props.setButtons(arrayMove(items, oldIndex, newIndex));
        }
      }
    }
  }

  function isReplyKeyboardButton() {
    if (props.buttons) {
      const buttons = props.buttons as ReplyKeyboardButtonModel[][];
      return buttons.some(buttons =>
        buttons.find(button => button.typeDiscriminator === ButtonTypeDiscriminator.FlowReplyKeyboardButton),
      );
    }
  }
  const items: string[] = [];
  const buttons = getButtonsWithExactType((props.flowAction as ButtonsContainerFlowAction).replyMarkup, props.flowAction);
  if (buttons) {
    buttons.map((button: ButtonModel[] | ReplyKeyboardButtonModel[]) =>
      button.map((button: ButtonModel | ReplyKeyboardButtonModel) => items.push(button.id)),
    );
  }

  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const handleButtonDataChanges = (value: SetStateAction<boolean>) => {
    setIsOpenEdit(value);
  };

  return (
    <Box width="100%" overflow="auto">
      {props.flowAction.typeDiscriminator !== FlowActionType.ShoppingCartFlowAction && (
        <>
          <DndContext
            key={`dndContextR-buttons-list`}
            id={`dndContextR-buttons-list`}
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToFirstScrollableAncestor]}
          >
            <SortableContext id={`sortableContextR-buttons-list`} strategy={verticalListSortingStrategy} items={items}>
              {!isReplyKeyboardButton() &&
                props.buttons.map(inlineButtons =>
                  // eslint-disable-next-line array-callback-return
                  inlineButtons.map(inlineButton => {
                    if (inlineButton.typeDiscriminator !== ButtonTypeDiscriminator.FlowReplyKeyboardButton) {
                      return (
                        <ChangeButtonPopover
                          key={inlineButton.id}
                          button={inlineButton as ButtonModel}
                          variables={variables ?? []}
                          isDefaultPayload={true}
                          buttonState={editButtonState as ButtonModel}
                          setButtonState={setEditButtonState as Dispatch<SetStateAction<ButtonModel | null>>}
                          onDoneForm={props.editButton}
                          deleteButton={props.deleteButton}
                        />
                      );
                    }
                  }),
                )}
            </SortableContext>
          </DndContext>
          {!isReplyKeyboardButton() && (
            <ChangeButtonPopover
              variables={variables ?? []}
              buttonState={addButtonState as ButtonModel}
              setButtonState={setAddButtonState as Dispatch<SetStateAction<ButtonModel | null>>}
              isDefaultPayload={true}
              onDoneForm={props.addButton}
            />
          )}
          {items.length === 0 && (
            <Box position="relative" padding="20px" className={s.dividerText}>
              <Divider paddingTop="4px" />
              <AbsoluteCenter bg="white" px="8px">
                {t("editNodePopup.or")}
              </AbsoluteCenter>
            </Box>
          )}
        </>
      )}
      <DndContext
        key={`dndContextR-Replybuttons-list`}
        id={`dndContextR-Replybuttons-list`}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToFirstScrollableAncestor]}
      >
        <SortableContext id={`sortableContextR-Replybuttons-list`} strategy={verticalListSortingStrategy} items={items}>
          <Box marginTop={1}>
            {isReplyKeyboardButton() &&
              props.buttons.map(replyButtons =>
                // eslint-disable-next-line array-callback-return
                replyButtons.map(replyButton => {
                  if (replyButton.typeDiscriminator === ButtonTypeDiscriminator.FlowReplyKeyboardButton) {
                    return (
                      <SortableItem isOpenEdit={isOpenEdit} id={replyButton.id} handle={true} key={replyButton.id}>
                        <ReplyButtonPopover
                          node={props.node}
                          button={replyButton as ReplyKeyboardButtonModel}
                          flowAction={props.flowAction}
                          variables={variables ?? []}
                          isDefaultPayload={true}
                          deleteButton={props.deleteButton}
                          isButton={true}
                          onButtonDataChanges={handleButtonDataChanges}
                        />
                      </SortableItem>
                    );
                  }
                }),
              )}
          </Box>
        </SortableContext>
      </DndContext>
      {(isReplyKeyboardButton() || items.length === 0) && (
        <Box marginTop={1}>
          <ReplyButtonPopover
            node={props.node}
            variables={variables ?? []}
            isDefaultPayload={true}
            flowAction={props.flowAction}
            onButtonDataChanges={handleButtonDataChanges}
          />
        </Box>
      )}
    </Box>
  );
};
