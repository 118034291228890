import { Box, Icon, Show, Tab, TabList, Tabs } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../common/state/store";
import { selectFlowList, selectTriggerList } from "./AutomationSlice";
import { selectCurrentBot } from "../sidebar/SidebarSlice";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import React, { useCallback } from "react";
import { ReactComponent as QuestionIcon } from "../../assets/icons/questionIcon.svg";
import { TooltipWithTouch } from "../../common/tooltipWithTouch/TooltipWithTouch";
import { TABLET_VIEW } from "../layout/LayoutHelper/ResolutionConst";

export const AutomationLayout = () => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const location = useLocation();
  const navigate = useNavigate();

  const getIndex = useCallback(() => {
    if (location.pathname.includes("flows")) {
      return 0;
    }
    if (location.pathname.includes("triggers")) {
      return 1;
    }
    return 0;
  }, [location.pathname]);

  const flowList = useAppSelector(selectFlowList);
  const triggerList = useAppSelector(selectTriggerList);

  const currentBot = useAppSelector(selectCurrentBot);
  const loadingFlowList = useGetLoadingState("getFlowList");
  const [searchParams] = useSearchParams("");
  const search = searchParams.get("search") ?? "";

  const breakpoint = `(max-width: ${TABLET_VIEW}px)`;

  return (
    <>
      <Show breakpoint={breakpoint}>
        <Box pt={"10px"} height="100%">
          {!currentBot ||
          (!flowList && !triggerList) ||
          (!loadingFlowList && flowList?.items.length === 0 && search === "" && !triggerList) ||
          (!flowList && triggerList && triggerList.totalItems === 0) ||
          (triggerList?.totalItems === 0 && flowList?.totalItems === 0) ? (
            <></>
          ) : (
            <Tabs index={getIndex()} isManual>
              <TabList>
                <Tab data-pw="flows-tab" onClick={() => navigate("automation/flows")}>
                  {t("Flows")}
                </Tab>
                <Tab data-pw="triggers-tab" onClick={() => navigate("automation/triggers")} position={"relative"}>
                  {t("Triggers")}
                  {getIndex() === 1 && (
                    <TooltipWithTouch
                      variant="dominoLight"
                      label={t("Triggers will be activated in sequence specified in the list")}
                      placement="auto"
                      maxW={"270px"}
                    >
                      <Icon
                        as={QuestionIcon}
                        ml={"4px"}
                        boxSize={"15px"}
                        borderRadius={"50%"}
                        fill="darkGrey"
                        _hover={{ fill: "darkPurple" }}
                        position={"absolute"}
                        top={"50%"}
                        right={0}
                        marginTop={"-7px"}
                        marginRight={"-14px"}
                      />
                    </TooltipWithTouch>
                  )}
                </Tab>
              </TabList>
            </Tabs>
          )}
          <Box height="100%">
            <Outlet />
          </Box>
        </Box>
      </Show>
      <Show breakpoint="(min-width: 769px)">
        <Box height="100%">
          {!currentBot ||
          (!flowList && !triggerList) ||
          (!loadingFlowList && flowList?.items.length === 0 && search === "" && !triggerList) ||
          (!flowList && triggerList && triggerList.totalItems === 0) ||
          (triggerList?.totalItems === 0 && flowList?.totalItems === 0) ? (
            <></>
          ) : (
            <Tabs index={getIndex()} isManual>
              <TabList>
                <Tab data-pw="flows-tab" onClick={() => navigate("automation/flows")}>
                  {t("Flows")}
                </Tab>
                <Tab data-pw="triggers-tab" onClick={() => navigate("automation/triggers")} position={"relative"}>
                  {t("Triggers")}
                  {getIndex() === 1 && (
                    <TooltipWithTouch
                      variant="dominoLight"
                      label={t("Triggers will be activated in sequence specified in the list")}
                      placement="auto"
                      maxW={"270px"}
                    >
                      <Icon
                        as={QuestionIcon}
                        ml={"4px"}
                        boxSize={"15px"}
                        borderRadius={"50%"}
                        fill="darkGrey"
                        _hover={{ fill: "darkPurple" }}
                        position={"absolute"}
                        top={"50%"}
                        right={0}
                        marginTop={"-7px"}
                        marginRight={"-14px"}
                      />
                    </TooltipWithTouch>
                  )}
                </Tab>
              </TabList>
            </Tabs>
          )}
          <Box height="100%">
            <Outlet />
          </Box>
        </Box>
      </Show>
    </>
  );
};
