export function EditBroadcastFormTitleValidation(title: string) {
  if (!title.length) {
    const result: BroadcastEditTitleValidation = { isInvalid: true, error: "Title field can't be empty" };
    return result;
  }
  if (title.length > 50) {
    const result: BroadcastEditTitleValidation = {
      isInvalid: true,
      error: "Title field can't be more than 50 symbols",
    };
    return result;
  }
  const result: BroadcastEditTitleValidation = { isInvalid: false, error: "" };
  return result;
}

export interface BroadcastEditTitleValidation {
  isInvalid: boolean;
  error: string;
}

export function ValidateNewTitleInput(title: string) {
  if (!title.length) {
    const result: BroadcastNewTitleValidation = { isInvalid: true, error: "Title field can't be empty" };
    return result;
  }
  if (title.length > 50) {
    const result: BroadcastNewTitleValidation = { isInvalid: true, error: "Title field can't be more than 50 symbols" };
    return result;
  }
  const result: BroadcastNewTitleValidation = { isInvalid: false, error: "" };
  return result;
}

export interface BroadcastNewTitleValidation {
  isInvalid: boolean;
  error: string;
}
