import React, { ChangeEvent, Dispatch, memo, useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Checkbox, useDisclosure, Button } from "@chakra-ui/react";
import { BroadcastFormModel, ContactModel, RecipientFormModel } from "../../BroadcastModel";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import {
  selectContactList,
  selectNonScrolledRecipients,
  selectRecipientsList,
  selectScrolledRecipients,
  setImportResult,
  //setSelectAllState,
} from "../../BroadcastSlice";
import { ImportRecipientsModal } from "../Broadcast/importRecipient/ImportRecipientsModal";
import { selectImportResult } from "../../../contactList/ContactListSlice";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import { FilterParams } from "../../../complexFilter/ComplexFilterModel";
import s from "./RecipientList.module.scss";
import { mapFilterArrayToQueryString } from "../../../complexFilter/ComplexFilterMapper";
import { RecipientsCounter } from "./RecipientsCounter";
import useDebounce from "../../../../common/hooks/useDebounce";

interface Props {
  getContacts: (
    queryFilterString: string | undefined,
    queryParamsString: string | undefined,
    isFilterActive: boolean,
    isOnScrollPayload?: boolean,
  ) => void;
  disabled?: boolean;
  isFilterActive?: boolean;
  wasSelectAllChecked: boolean;
  setWasSelectAllChecked: Dispatch<React.SetStateAction<boolean>> | Dispatch<boolean>;
  getActualSelectAllState?: () => boolean | undefined;
  isFirstInit?: boolean;
  setFirstInit?: React.Dispatch<React.SetStateAction<boolean>>;
  broadcastRecipientsFilter?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
  isNewBroadcast: boolean;
}

export const RecipientList = memo(
  function RecilientList({
    disabled = false,
    getContacts,
    isFilterActive,
    wasSelectAllChecked,
    setWasSelectAllChecked,
    getActualSelectAllState,
    isFirstInit,
    setFirstInit,
    broadcastRecipientsFilter,
    isNewBroadcast,
  }: Props) {
    const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
    const contactList = useAppSelector(selectContactList);
    const contactListRef = useRef<null | HTMLDivElement>(null);
    const [searchContactValue, setSearchContactValue] = useState<string>("");
    const debouncedSearchText = useDebounce(searchContactValue, 500);
    const contactListItems = contactList?.items;
    const recipientList = useAppSelector(selectRecipientsList);
    const pageRecipients = useAppSelector(selectScrolledRecipients);
    const pageAllRecipients = useAppSelector(selectNonScrolledRecipients);

    const dispatch = useAppDispatch();

    const importResult = useAppSelector(selectImportResult);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [disabledContacts, setDisabledContacts] = useState<string[]>([]);

    const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.BroadcastContacts);
    const { MaxCount, IsUnlimited } = accessability;
    const { values, setFieldValue, setFieldTouched } = useFormikContext<BroadcastFormModel>();
    const [isActive, setIsActive] = useState(false);
    const isEmpty = broadcastRecipientsFilter?.[0] && Object.keys(broadcastRecipientsFilter[0]).length === 0;
    const previousPageRef = useRef(contactList?.currentPage);

    useEffect(() => {
      if (!recipientList?.recipients?.length) {
        return;
      } else {
        setFieldValue("recipients", recipientList?.recipients);
        setWasSelectAllChecked(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipientList?.recipients]);

    useEffect(() => {
      if (pageRecipients && pageRecipients?.length) {
        const selectedItems = values.recipients;
        const initialFlag = values.initialSentForAllFlag;
        let newValue;
        if (contactList?.currentPage !== previousPageRef.current) {
          if (initialFlag === true && !wasSelectAllChecked) {
            newValue = selectedItems;
          } else if (wasSelectAllChecked && !isNewBroadcast) {
            newValue = [...selectedItems];
          } else if (values.checkedFlag && pageAllRecipients) {
            newValue = selectedItems ? [...selectedItems, ...pageAllRecipients] : pageAllRecipients;
          } else if (!initialFlag && !wasSelectAllChecked && values.recipientsCount === 0) {
            newValue = selectedItems;
          } else if (pageRecipients) {
            newValue = selectedItems ? [...selectedItems, ...pageRecipients] : pageRecipients;
          }
          setFieldValue("recipients", newValue);
          previousPageRef.current = contactList?.currentPage;
        }
      }
    }, [pageRecipients, pageAllRecipients, contactList?.items]);

    useEffect(() => {
      if (debouncedSearchText !== undefined) {
        if (debouncedSearchText === "") {
          getContactsWithParams("");
        } else {
          getContactsWithParams(debouncedSearchText);
        }
      }
    }, [debouncedSearchText, broadcastRecipientsFilter]);

    useEffect(() => {
      if (importResult) {
        getContactsWithParams(searchContactValue ?? "");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importResult]);

    useEffect(() => {
      if (wasSelectAllChecked && contactListItems) {
        const disabledContactList = contactListItems.slice(MaxCount).map(contact => contact.id);
        setDisabledContacts(disabledContactList);
      } else if (values.recipients?.length === MaxCount && contactListItems) {
        const disabledContactIds = contactListItems
          .filter(contact => !values.recipients.some(recipient => recipient.contactId === contact.id))
          .map(contact => contact.id);
        setDisabledContacts(disabledContactIds);
      }
      //  else {
      //   setDisabledContacts([]);
      //   if (isFirstInit && getActualSelectAllState) {
      //     const state = getActualSelectAllState();
      //     if (isEmpty) return;
      //     if (state && setFirstInit) {
      //       setWasSelectAllChecked(state);
      //     }
      //   }
      //}
    }, [values.recipients, wasSelectAllChecked, contactListItems]);

    useEffect(() => {
      if (isFirstInit && getActualSelectAllState) {
        const state = getActualSelectAllState();
        if (isEmpty) return;
        if (state && setFirstInit) {
          setWasSelectAllChecked(state);
        }
      }
    }, [isFirstInit, getActualSelectAllState]);

    const onContactListChange = (contact: RecipientFormModel) => (e: ChangeEvent<HTMLInputElement>) => {
      let recipientList = [...values.recipients] ?? [];
      if (e.target.checked) {
        recipientList = [...recipientList, contact];
        setFieldValue("includedRecipients", [...(values.includedRecipients ?? []), contact]);
        setFieldValue(
          "excludedRecipients",
          values.excludedRecipients?.filter(con => con.contactId !== contact.contactId),
        );
        if (recipientList.length > MaxCount - 1) {
          setDisabledContacts(
            contactListItems?.filter(c => !recipientList?.some(r => r.contactId === c.id)).map(c => c.id) || [],
          );
        }
        setFieldValue("recipients", recipientList);
      } else {
        if (wasSelectAllChecked) {
          setFieldValue("checkedFlag", true);
          recipientList = contactListItems
            ? contactListItems?.slice(0, MaxCount).map(contact => ({
                contactId: contact.id,
                conversationId: contact.conversationId,
                externalId: contact.externalId,
              }))
            : [];
          setWasSelectAllChecked(false);
        }
        const excludedRec = recipientList.length
          ? recipientList.find(recipient => recipient.contactId === contact.contactId)
          : contact;
        recipientList = recipientList.filter(recipient => recipient.contactId !== contact.contactId);

        if (excludedRec) {
          setFieldValue("excludedRecipients", [...(values.excludedRecipients ?? []), excludedRec]);
          setFieldValue(
            "includedRecipients",
            values.includedRecipients?.filter(con => con.contactId !== excludedRec.contactId),
          );
        }
        if (recipientList.length < MaxCount + 1) {
          setDisabledContacts([]);
        }
        setFieldTouched("recipients", true, false);
        setFieldValue("recipients", recipientList);
      }
    };

    useEffect(() => {
      setSearchContactValue("");
    }, [recipientList?.availableRecipients]);

    const toggleAllLimited = () => {
      if (setFirstInit) {
        setFirstInit(false);
      }
      const { recipients: selectedRecipients } = values;
      const selectedCount = selectedRecipients.length;
      const selectedRecipientIds = new Set(selectedRecipients.map(recipient => recipient.contactId));
      const mapContactList =
        contactListItems?.map(({ id, conversationId, externalId }) => ({
          contactId: id,
          conversationId,
          externalId,
        })) ?? [];
      const isAllContactsSelected = selectedCount === MaxCount;
      const areAllChecked = wasSelectAllChecked || isAllContactsSelected || values.checkedFlag;
      setFieldTouched("recipients", true, false);
      if (areAllChecked) {
        setFieldValue("recipients", []);
        setFieldValue("recipientsCount", 0);
        setFieldValue("excludedRecipients", []);
        setFieldValue("includedRecipients", []);
        if (values.initialSentForAllFlag === false) {
          setFieldValue("checkedFlag", false);
          setFieldValue("uncheckedFlag", true);
        }
        setDisabledContacts([]);
        setWasSelectAllChecked(false);
      } else {
        const newRecipients = [
          ...mapContactList.filter(contact => !selectedRecipientIds.has(contact.contactId)),
          ...selectedRecipients,
        ].slice(0, MaxCount);
        const remainingContacts = mapContactList
          .filter(contact => !selectedRecipientIds.has(contact.contactId))
          .slice(MaxCount)
          .map(contact => contact.contactId);
        setFieldValue("recipients", newRecipients);
        const count = contactList?.totalItems && contactList?.totalItems > MaxCount ? MaxCount : contactList?.totalItems;
        setFieldValue("recipientsCount", count);
        setFieldValue("excludedRecipients", []);
        setFieldValue("checkedFlag", false);
        setFieldValue("uncheckedFlag", false);
        setFieldValue("includedRecipients", []);
        setDisabledContacts(remainingContacts);
        setWasSelectAllChecked(true);
      }
    };

    const toggleAllUnlimited = () => {
      if (setFirstInit) {
        setFirstInit(false);
      }
      const mapContactList =
        contactListItems?.map(contact => ({
          contactId: contact.id,
          conversationId: contact.conversationId,
          externalId: contact.externalId,
        })) ?? [];
      if (wasSelectAllChecked || values.checkedFlag) {
        setWasSelectAllChecked(false);
        setFieldValue("excludedRecipients", []);
        setFieldValue("includedRecipients", []);
        if (values.initialSentForAllFlag === false) {
          setFieldValue("checkedFlag", false);
          setFieldValue("uncheckedFlag", true);
        }
        setFieldTouched("recipients", true, false);
        setFieldValue("recipients", []);
      } else {
        setFieldTouched("recipients", true, false);
        if (Boolean(recipientList?.recipients?.length)) {
          setFieldValue("recipients", recipientList?.recipients);
        } else {
          setFieldValue("recipients", recipientsFilterUnique([...mapContactList, ...values.recipients]));
        }
        setFieldValue("checkedFlag", false);
        setFieldValue("uncheckedFlag", false);
        setFieldValue("excludedRecipients", []);
        setFieldValue("includedRecipients", []);
        setWasSelectAllChecked(true);
      }
    };

    const getContactsWithParams = (searchValue: string, page?: number, isOnScrollPayload?: boolean, filters?: FilterParams[]) => {
      const queryString = createQueryString(searchValue, page);
      let filterQueryString = undefined;
      if (filters && filters[0] && Object.keys(filters[0]).length > 0) {
        filterQueryString = mapFilterArrayToQueryString(filters);
      }
      getContacts(filterQueryString, queryString, false, isOnScrollPayload);
    };

    const getNextPageContacts = (page: number, isOnScrollPayload?: boolean) => {
      const queryString = `size=${20}&page=${page}`;
      getContacts(undefined, queryString, false, isOnScrollPayload);
    };

    const getAdditionalContactsOnScroll = () => {
      if (contactListRef.current) {
        const currentScroll = contactListRef.current.scrollTop;
        const currentHeight = contactListRef.current?.clientHeight;
        const isBottomPosition = currentHeight + currentScroll > contactListRef.current?.scrollHeight - 2;

        if (isActive) {
          contactListRef.current.scrollTop = (contactListRef.current?.scrollHeight - 2) / 2;
          return;
        }

        if (isBottomPosition && contactList) {
          if (contactList.currentPage >= contactList.totalPages) {
            return;
          }
          const lastHeight = (contactListRef.current?.scrollHeight - 2) / 2;

          if (searchContactValue === "" && isEmpty) {
            getNextPageContacts(contactList.currentPage + 1, true);
          } else {
            getContactsWithParams(searchContactValue ?? "", contactList.currentPage + 1, true, broadcastRecipientsFilter);
          }
          if (!isFilterActive) {
            contactListRef.current.scrollTop = lastHeight;
          }
          return;
        }
      }
    };

    const setSearchText = (value: string) => {
      setSearchContactValue(value);
    };

    const createQueryString = (searchValue: string, page?: number) => {
      const searchParams = searchValue || "" ? `&filter=${searchValue}` : "";
      const pageParams = `size=${20}&page=${page ?? 1}`;
      if (searchParams && pageParams && !isFilterActive) {
        return `&${pageParams}${searchParams}`;
      }
      if (pageParams && !searchParams) {
        return `&${pageParams}`;
      }
      if (searchParams && pageParams) {
        return `${searchParams}&${pageParams}`;
      }
      return "";
    };

    const getContactTitle = (contact: ContactModel) => {
      if (contact.firstName || contact.lastName) {
        return `${contact.firstName ?? ""} ${contact.lastName ?? ""}`;
      } else if (!contact.firstName && !contact.firstName) {
        if (contact.username) {
          return contact.username;
        } else {
          return contact.externalId;
        }
      }
    };
    function recipientsFilterUnique(array: RecipientFormModel[]) {
      const cloneArray = array.concat();
      for (let i = 0; i < cloneArray.length; ++i) {
        for (let j = i + 1; j < cloneArray.length; ++j) {
          if (cloneArray[i].contactId === cloneArray[j].contactId) cloneArray.splice(j--, 1);
        }
      }
      return cloneArray;
    }

    const handleCancelClick = () => {
      dispatch(setImportResult({ blocked: null, availableRecipients: null, recipients: null }));
      setWasSelectAllChecked(false);
      setFieldValue("recipients", []);
    };

    //do not remove
    //  const toggleSelectContact = (contactId) => {
    //     return (dispatch, getState) => {
    //         const { includedRecipients, excludedRecipients } = getState().broadcast;
    //         if (includedRecipients.includes(contactId)) {
    //             dispatch({
    //               //remove from included
    //             });
    //             dispatch({
    //                 //add to exluded
    //             });
    //         } else if (excludedRecipients){
    //             dispatch({
    //                 //add to included
    //             });
    //             dispatch({
    //               //remove from exl
    //             });
    //         }
    //     };
    // };}

    return (
      <Box className={s.broadastContactList}>
        <Loading scope={"contactList2"} setIsActive={setIsActive} />
        <Box className={`${s.broadastContactListHeader} ${s.broadastContactListGrid}`}>
          <Checkbox
            data-pw="contact-list-select-all"
            isInvalid={false}
            cursor={recipientList?.recipients ? "not-allowed" : "pointer"}
            isDisabled={
              !contactListItems?.length ||
              debouncedSearchText !== "" ||
              (broadcastRecipientsFilter &&
                broadcastRecipientsFilter[0]?.condition &&
                values.recipients.length > 0 &&
                !wasSelectAllChecked)
            }
            isChecked={wasSelectAllChecked}
            onChange={!IsUnlimited ? toggleAllLimited : toggleAllUnlimited}
            isReadOnly={recipientList?.recipients ? true : disabled}
            variant="dominoGreen"
            p="4px 0 4px"
            mx="4px"
          >
            <Text color="black">{recipientList?.recipients ? t("Selected from file") : t("Select all")}</Text>
          </Checkbox>
          <InputSearch
            placeholder={t("Search by name") ?? ""}
            setSearchText={setSearchText}
            searchText={searchContactValue}
            isDisabled={recipientList?.availableRecipients ? true : false}
          />
        </Box>
        <Box ref={contactListRef} onScroll={getAdditionalContactsOnScroll} className={`${s.broadastContactListScroll}`}>
          {recipientList?.recipients
            ? recipientList?.recipients?.map(contact => {
                return (
                  <Box key={contact.contactId} className={`${s.broadastContactListFlex}`}>
                    <Checkbox
                      data-pw="contact-list-select-item"
                      variant="dominoGreen"
                      p="4px 8px"
                      width="100%"
                      _hover={{ backgroundColor: "defaultGrey" }}
                      isInvalid={false}
                      isReadOnly={disabled}
                      isChecked={true}
                      onChange={onContactListChange({
                        contactId: contact.contactId,
                        conversationId: contact.conversationId,
                        externalId: contact.externalContactId,
                      })}
                      //disabled={true}
                      sx={{
                        ".chakra-checkbox__control[data-disabled]": {
                          bg: "blue",
                          color: "red",
                          bgColor: "white",
                        },
                      }}
                    >
                      <Text data-pw="contact-list-username">
                        {contact.name}
                        {contact.username && <span className={s.contactUserName}>{` @${contact.username}`}</span>}
                      </Text>
                    </Checkbox>
                  </Box>
                );
              })
            : contactListItems?.map(contact => {
                return (
                  <Box key={contact.id} className={`${s.broadastContactListFlex}`}>
                    <Checkbox
                      data-pw="contact-list-select-item"
                      variant="dominoGreen"
                      p="4px 8px"
                      width="100%"
                      _hover={{ backgroundColor: "defaultGrey" }}
                      isInvalid={false}
                      isReadOnly={disabled}
                      isChecked={
                        (wasSelectAllChecked && contactListItems.slice(0, MaxCount).some(c => c.id === contact.id)) ||
                        (values.recipients &&
                          values.recipients?.length > 0 &&
                          values.recipients.some(recipient => recipient.contactId === contact.id))
                      }
                      onChange={onContactListChange({
                        contactId: contact.id,
                        conversationId: contact.conversationId,
                        externalId: contact.externalId,
                      })}
                      disabled={
                        disabledContacts.includes(contact.id) ||
                        (wasSelectAllChecked && isNewBroadcast && (searchContactValue === undefined || searchContactValue === ""))
                      }
                      sx={{
                        ".chakra-checkbox__control[data-disabled]": {
                          bg: "blue",
                          color: "red",
                          bgColor: "white",
                        },
                      }}
                    >
                      <Text data-pw={contact.username}>
                        {getContactTitle(contact)}
                        {contact.username && <span className={s.contactUserName}>{` @${contact.username}`}</span>}
                      </Text>
                    </Checkbox>
                  </Box>
                );
              })}
        </Box>
        <Box className={`${s.broadastContactListFooter} ${values.isRecipient && !values.recipients.length && s.invalid}`}>
          <Flex wrap={"wrap"} gap={"4px"}>
            <Text>
              <span data-pw="total-contacts-count">
                {" "}
                {recipientList?.availableRecipients ? recipientList?.availableRecipients : contactList?.totalItems}{" "}
              </span>
              {`${isFilterActive || searchContactValue ? t("Found") : t("Total")},`}
            </Text>
            <RecipientsCounter
              wasSelectAllChecked={wasSelectAllChecked}
              recipients={values.recipients}
              isUnlimited={IsUnlimited}
              maxCount={MaxCount}
              initialSentForAllFlag={values.initialSentForAllFlag}
              debouncedSearchText={debouncedSearchText}
              includedRecipients={values.includedRecipients?.length ?? 0}
              excludedRecipients={values.excludedRecipients?.length ?? 0}
              broadcastRecipientCount={values.recipientsCount ?? 0}
              checkedAllFlag={values.checkedFlag ?? false}
            />
          </Flex>
          <>
            {!recipientList?.recipients ? (
              <Button variant="dominoOutlineViolet" onClick={onOpen} data-pw="import-contacts-button" disabled={disabled}>
                {t("Import Recipients")}
              </Button>
            ) : (
              <Button
                bgColor="white"
                color="mainRed"
                padding="0 24px"
                onClick={handleCancelClick}
                data-pw="import-contacts-button"
                disabled={disabled}
                className={s.cancelButton}
              >
                {t("Cancel")}
              </Button>
            )}
            {!disabled && <ImportRecipientsModal isOpen={isOpen} onClose={onClose} />}
          </>
        </Box>
      </Box>
    );
  },
  (last, next) => {
    return Object.is(last, next);
  },
);
