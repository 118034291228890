import { call, put, takeLatest } from "redux-saga/effects";
import { SignupModel } from "./SignUpModel";
import { signUpSlice } from "./SignUpSlice";
import { begin, complete } from "../../common/loading/LoadingStateActions";
import { PayloadAction } from "@reduxjs/toolkit";
import * as signUpApi from "./SignUpApi";
import { handleException } from "../../common/SagaHelper";
import { routerSlice } from "../../common/router/RouterSlice";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import GTM from "../../common/ga/GAEventTracker";

export function* signUpSaga() {
  yield takeLatest(signUpSlice.actions.signup, signUp);
}

function* signUp(action: PayloadAction<SignupModel>) {
  try {
    yield put(begin());
    yield call(signUpApi.SignUp, action.payload);

    const trackEvent = GTM("Signup");
    trackEvent("SignupCompleted");

    yield put(
      notificationSlice.actions.notify({
        message: "Successfully signed up!",
        type: "success",
      }),
    );
    yield put(routerSlice.actions.redirect("/auth/signIn"));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}
