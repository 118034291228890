import { Box, Tooltip, useOutsideClick } from "@chakra-ui/react";
import { CSSProperties, useState } from "react";
import { DESKTOP_VIEW } from "../../../components/layout/LayoutHelper/ResolutionConst";
import s from "./OverflowEllipsesText.module.scss";

export interface Props {
  text?: string;
  variant?: string;
  dataPw?: string;
  style?: CSSProperties;
  className?: string;
  content?: React.ReactNode;
}

export const OverflowEllipsesText = ({ text, variant, dataPw, className, style, content, ...props }: Props) => {
  const [contentScrollWidth, setContentWidth] = useState<number | undefined>();
  const [contentClientWidth, setContentChildWidth] = useState<number | undefined>();
  const [isTooltip, setIsTooltip] = useState(false);
  const [isMobileTooltipOpen, setIsMobileTooltipOpen] = useState(false);

  const setRef = (node: HTMLDivElement | HTMLParagraphElement) => {
    if (node) {
      setContentWidth(node.children[0].scrollWidth);
      setContentChildWidth(node.children[0].clientWidth);
      if (contentScrollWidth && contentClientWidth && contentScrollWidth > contentClientWidth) {
        setIsTooltip(true);
      }
    }
  };

  useOutsideClick({
    ref: setRef as unknown as React.RefObject<HTMLDivElement | HTMLParagraphElement | HTMLTextAreaElement | HTMLInputElement>,
    handler: () => setIsMobileTooltipOpen(false),
  });
  if (window.innerWidth >= DESKTOP_VIEW) {
    return (
      <Tooltip
        label={text}
        placement="top"
        isDisabled={!isTooltip}
        variant={"dominoChat"}
        bg={"#FEF6DC"}
        hasArrow={true}
        openDelay={100}
      >
        <Box className={s.OverflowText} width={"100%"} ref={setRef} overflow={"hidden"}>
          {content}
        </Box>
      </Tooltip>
    );
  }
  return (
    <Tooltip
      label={text}
      placement="top"
      isDisabled={!isTooltip}
      marginRight={"20px"}
      variant={"dominoChat"}
      bg={"#FEF6DC"}
      hasArrow={true}
      isOpen={isTooltip && isMobileTooltipOpen}
    >
      <Box
        position={"relative"}
        overflow={"hidden"}
        ref={setRef}
        className={s.OverflowText}
        onClick={() => {
          setIsMobileTooltipOpen(!isMobileTooltipOpen);
        }}
      >
        {content}
      </Box>
    </Tooltip>
  );
};
