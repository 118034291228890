import s from "./DelayActionNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { DelayFlowActionModel } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { Box, Flex, Icon } from "@chakra-ui/react";
import { ReactComponent as ClockIcon } from "../../../../../assets/icons/clockIcon.svg";

export const DelayActionNode = (props: { actionData: ActionNodeProps }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as DelayFlowActionModel;

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        <Icon as={ClockIcon} color="midDeepBlue" boxSize="30px" />
        <div className={s.textBlock}>
          <div className={s.type}>{t("Delay")}</div>
        </div>
      </div>
      {!!data.value ? (
        <Flex flexWrap="wrap" className={s.wrapper}>
          {t("Wait")}&nbsp;
          <Box color="midDeepBlue">
            {data.value}&nbsp;
            {t(`delayNodeTimeUnits.${data.unit}`, { count: parseInt(data.value) })}&nbsp;
          </Box>
          {t("and then continue")}
        </Flex>
      ) : (
        <div className={s.noCondition}>
          <h4>{t("Set Delay Time")}</h4>
        </div>
      )}
    </Flex>
  );
};
