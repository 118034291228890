import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, Location, unstable_useBlocker as useBlocker } from "react-router";
import { Action as HistoryAction } from "history";

export function useCallbackPrompt(when: boolean) {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { page: number } | undefined;
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<{
    location: Location;
  } | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = useCallback(
    (args: { currentLocation: Location; nextLocation: Location; historyAction: HistoryAction }) => {
      if (!confirmedNavigation && args.nextLocation.pathname !== location.pathname && when) {
        setShowPrompt(true);
        setLastLocation({ location: args.nextLocation });
        return true;
      }
      return false;
    },
    [confirmedNavigation, location.pathname, when],
  );

  const blocker = useBlocker(handleBlockedNavigation);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      const hasLocationState = locationState?.page !== undefined;
      const sameSource = location?.pathname?.split("/")?.[1] === lastLocation?.location?.pathname?.split("/")?.[1];
      const additionaParams = hasLocationState && sameSource ? `?page=${locationState.page || 1}` : "";

      navigate(
        `${lastLocation.location?.pathname}${additionaParams}`,
        hasLocationState && sameSource ? { state: lastLocation.location?.state } : {},
      );

      setConfirmedNavigation(false);
      blocker.reset?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  const cancelNavigation = useCallback(() => {
    blocker.reset?.();
    setConfirmedNavigation(false);
    setShowPrompt(false);
  }, [blocker]);

  const confirmNavigation = useCallback(() => {
    blocker.proceed?.();
    setConfirmedNavigation(true);
    setShowPrompt(false);
  }, [blocker]);

  return { showPrompt, confirmNavigation, cancelNavigation };
}
