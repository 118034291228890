import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { Box, Flex, Skeleton } from "@chakra-ui/react";
import s from "../../../DashboardContainer.module.scss";

export default function DashboardEmptyDataChart() {
  const dataChart = [
    { name: "Date xx", Bot: 0 },
    { name: "Date xx", Bot: 0 },
    { name: "Date xx", Bot: 0 },
    { name: "Date xx", Bot: 0 },
    { name: "Date xx", Bot: 0 },
    { name: "Date xx", Bot: 0 },
  ];

  return (
    <Box className={s.flexZeroContainerDashboard} position={"relative"} width={"100%"}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={500} data={dataChart} height={300} margin={{ top: 38, right: 56, left: 20, bottom: 12 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tickMargin={8} dataKey="name" />
          <YAxis />
          <Line key={"name"} dot={{ r: 0 }} type="monotone" dataKey={"Bot"} stroke={"transition"} />
        </LineChart>
      </ResponsiveContainer>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        padding={"35px 55px 40px 80px"}
        top={0}
        position={"absolute"}
        width={"100%"}
        height={"100%"}
      >
        <Skeleton
          speed={0.5}
          startColor="line"
          endColor="bgLight"
          height="100%"
          width="100%"
          border="1px solid"
          borderColor="line"
        />
      </Flex>
    </Box>
  );
}
