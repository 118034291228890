import { Box, Text, Textarea, Input, Flex, Icon } from "@chakra-ui/react";
import s from "../EditContactItem/EditContactItem.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as RedTrashIcon } from "../../../../../assets/icons/trash.svg";
import { ContactVariableModel } from "../../../ContactModel";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { CustomVariableType } from "../../../../../common/AppEnums";
import { EditContactItemMobile } from "./EditContactItemMobile/EditContactItemMobile";
import { useNowWidthView } from "../../../../layout/LayoutHelper/ResolutionHooks";
import { OverflowEllipsesText } from "../../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import DotMenuContainer from "../../../../../UI/atoms/DotMenu/DotMenuContainer";
import { ReactComponent as arrayTypeIcon } from "../../../../../assets/icons/variableTypes/arrayTypeIcon.svg";
import { ReactComponent as textTypeIcon } from "../../../../../assets/icons/variableTypes/textTypeIcon.svg";
import { ReactComponent as dateTypeIcon } from "../../../../../assets/icons/variableTypes/dateTypeIcon.svg";
import { ReactComponent as numberTypeIcon } from "../../../../../assets/icons/variableTypes/numberTypeIcon.svg";
import { ReactComponent as booleanTypeIcon } from "../../../../../assets/icons/variableTypes/booleanTypeIcon.svg";
import { ReactComponent as catalogTypeIcon } from "../../../../../assets/icons/variableTypes/catalogTypeIcon.svg";

interface Props {
  contactVar: ContactVariableModel;
  variablesDescription: Record<string, unknown> | undefined;
  getValueInput: (contactVar: ContactVariableModel) => JSX.Element;
  handleKeyPress: (e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void;
  setVariablesDescription: (value: SetStateAction<Record<string, unknown> | undefined>) => void;
  handleOnBlurDescription: (contactVar: ContactVariableModel, variablesDescription: Record<string, unknown> | undefined) => void;
  variableDeleteConfirm: (variable: ContactVariableModel) => void;
  executeMobileModal?: (type: CustomVariableType, contactVar: ContactVariableModel) => void;
}

export default function EditContactItem({
  contactVar,
  variablesDescription,
  getValueInput,
  handleKeyPress,
  setVariablesDescription,
  handleOnBlurDescription,
  variableDeleteConfirm,
  executeMobileModal,
}: Props) {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const vt = useTranslation("translation", { keyPrefix: "variableTypes" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "contact" });
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const nowWidth = useNowWidthView();
  const [changeDescription, setChangeDescription] = useState(false);

  useEffect(() => {
    changeHeightTextArea();
    // eslint-disable-next-line
  }, [variablesDescription]);

  const changeHeightTextArea = () => {
    if (textareaRef.current) {
      const maxHeightWithoutScroll = 500;
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
      // needed for correct scroll appearing
      if (maxHeightWithoutScroll && scrollHeight >= maxHeightWithoutScroll) {
        textareaRef.current.style.overflow = "scroll";
      } else {
        textareaRef.current.style.overflow = "hidden";
      }
    }
  };

  const variableTypeIcons = {
    [CustomVariableType.Text]: textTypeIcon,
    [CustomVariableType.Array]: arrayTypeIcon,
    [CustomVariableType.Number]: numberTypeIcon,
    [CustomVariableType.Date]: dateTypeIcon,
    [CustomVariableType.DateTime]: dateTypeIcon,
    [CustomVariableType.Boolean]: booleanTypeIcon,
    [CustomVariableType.Order]: catalogTypeIcon,
  };

  return (
    <>
      {nowWidth >= 540 && (
        <Box key={contactVar.id} className={s.row}>
          <Flex align="center" h="100%" w="calc(100% - 10px)">
            <Text className={s.mobileColName}>{t("Name")}</Text>
            <OverflowEllipsesText text={contactVar.key} content={<Text className={s.rowText}>{contactVar.key}</Text>} />
          </Flex>
          <Flex pr="16px" align="center" h="100%">
            <Text className={s.mobileColName}>{t("Type")}</Text>
            <Flex alignItems={"center"}>
              <Icon mr="10px" fill={"#8592A3"} boxSize="14px" as={variableTypeIcons[contactVar.type]} />
              <Text className={s.rowText}> {vt(contactVar.type)}</Text>
            </Flex>
          </Flex>
          <Box>
            <Text className={`${s.mobileColName}`}>{t("Value")}</Text>
            {getValueInput(contactVar)}
          </Box>
          <Box marginRight="10px">
            <Text className={s.mobileColName}>{t("Description")}</Text>

            {changeDescription ? (
              <Textarea
                className={s.textarea}
                onKeyDown={e => handleKeyPress(e)}
                placeholder={`${t("Description")}`}
                autoFocus
                borderColor="transparent"
                variant="dominoOutline"
                rows={1}
                pt="7px"
                pl="15px"
                resize={"none"}
                maxLength={250}
                ref={textareaRef}
                value={variablesDescription && (variablesDescription[contactVar.id] as string)}
                onFocus={e => (e.target.placeholder = t("Enter field's description"))}
                onChange={e => {
                  setVariablesDescription(prevState => ({
                    ...prevState,
                    [contactVar.id]: e.target.value,
                  }));
                }}
                onBlur={e => {
                  e.target.placeholder = t("Description");
                  handleOnBlurDescription(contactVar, variablesDescription);
                  setChangeDescription(false);
                }}
              />
            ) : (
              <OverflowEllipsesText
                text={variablesDescription && (variablesDescription[contactVar.id] as string)}
                content={
                  <Input
                    height="36px"
                    variant="dominoOutline"
                    placeholder={t("Description")}
                    onClick={() => setChangeDescription(true)}
                    value={variablesDescription && (variablesDescription[contactVar.id] as string)}
                    noOfLines={1}
                    maxW="500px"
                  />
                }
              />
            )}
          </Box>
          <Box pt={"2px"}>
            <DotMenuContainer
              menuItems={[
                {
                  MenuItem: {
                    icon: <RedTrashIcon width="18px" height="18px" />,
                    text: ct("Delete"),
                    clickEvent: e => {
                      e.stopPropagation();
                      variableDeleteConfirm(contactVar);
                    },
                    color: "mainRed",
                  },
                },
              ]}
              MenuHover={{ bg: "line" }}
            />
          </Box>
        </Box>
      )}
      {nowWidth < 540 && (
        <EditContactItemMobile
          contactVar={contactVar}
          variablesDescription={variablesDescription}
          getValueInput={getValueInput}
          setVariablesDescription={setVariablesDescription}
          handleOnBlurDescription={handleOnBlurDescription}
          variableDeleteConfirm={variableDeleteConfirm}
          executeMobileModal={executeMobileModal}
        />
      )}
    </>
  );
}
