/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { CustomVariablesModel } from "../flowBuilder/FlowBuilderModel";
import { ContactModel, ContactVariableListModel, ContactVariableModel, UsageContactVariableModel } from "./ContactModel";
import { BotModel } from "../sidebar/SidebarModel";

export interface ContactState {
  contact?: ContactModel;
  contactVariables?: ContactVariableModel[];
  contactVariablesPage?: ContactVariableListModel;
  variableInFlowUsage?: {
    id: string;
    name: string;
  }[];
  variableInBroadcastUsage?: {
    id: string;
    name: string;
  }[];
}

const initialState: ContactState = {};

export const contactSlice = createSlice({
  name: "contactSlice",
  initialState,
  reducers: {
    getContact: (state, action: PayloadAction<{ contactId: string }>) => {
      return {
        ...state,
      };
    },
    getContactSucceed: (state, action: PayloadAction<{ contact: ContactModel }>) => {
      return {
        ...state,
        contact: action.payload.contact,
      };
    },
    getContactVariables: (state, action: PayloadAction<{ contactId: string; page: string; size: string }>) => {
      return {
        ...state,
      };
    },
    getContactVariablesCompleted: (state, action: PayloadAction<ContactVariableListModel>) => {
      return {
        ...state,
        contactVariablesPage: action.payload,
      };
    },
    changeContactVariableValue: (
      state,
      action: PayloadAction<{
        contactId: string;
        contactVariable: ContactVariableModel;
        page?: string;
        size?: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    changeContactVariableDescription: (
      state,
      action: PayloadAction<{
        contactId: string;
        contactVariable: ContactVariableModel;
        page?: string;
        size?: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },
    createCustomVariable: (
      state,
      action: PayloadAction<{
        variable: CustomVariablesModel;
        page?: string;
        size?: string;
      }>,
    ) => {
      return {
        ...state,
      };
    },

    deleteVariable: (state, action: PayloadAction<{ variableId: string; page?: string; size?: string }>) => {
      return {
        ...state,
      };
    },
    checkVariableUsage: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
    checkVariableUsageComplete: (state, action: PayloadAction<UsageContactVariableModel>) => {
      return {
        ...state,
        variableInFlowUsage: action.payload.usageInFlow,
        variableInBroadcastUsage: action.payload.usageInBroadcasts,
      };
    },
    clearVariableUsage: state => {
      return {
        ...state,
        variableInFlowUsage: undefined,
      };
    },

    clearContactState: () => {
      return initialState;
    },

    selectChatBot: (state, action: PayloadAction<{ bot: BotModel; contactId: string }>) => {
      return {
        ...state,
      };
    },
  },
});

export const {
  getContact,
  selectChatBot,
  getContactVariables,
  changeContactVariableValue,
  changeContactVariableDescription,
  createCustomVariable,
  deleteVariable,
  checkVariableUsage,
  checkVariableUsageComplete,
  clearVariableUsage,
  clearContactState,
} = contactSlice.actions;

export const selectContact = (state: RootState) => state.app.contactState.contact;
export const selectContactVariables = (state: RootState) => state.app.contactState.contactVariablesPage?.items;
export const selectPagingContactVariables = (state: RootState) => state.app.contactState.contactVariablesPage;
export const selectVariableInFlowUsage = (state: RootState) => state.app.contactState.variableInFlowUsage;
export const selectVariableInBroadcastUsage = (state: RootState) => state.app.contactState.variableInBroadcastUsage;

export default contactSlice.reducer;
