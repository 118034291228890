import { httpDelete, httpGet, httpPost, httpPut } from "../../common/BaseApi";
import { NewTeamModel, OrganisationModel, OrganisationTeamItemModel } from "./OrganisationModel";

export const getOrganisation = () => {
  return httpGet(`/api/organization`);
};

export const createOrganisation = (organisation: OrganisationModel) => {
  return httpPost(`/api/organization`, organisation);
};

export const getOrganisationUsers = (page: number, filter?: string) => {
  return httpGet(`/api/organization/user?page=${page}&size=10${filter ? `&filter=${filter}` : ""}`);
};

export const getOrganisationTeams = (page: number) => {
  return httpGet(`/api/team?page=${page}&size=10`);
};

export const getIsOrganisationUsersInUse = (userId: string) => {
  return httpGet(`/api/organization/user/${userId}/checkUsed`);
};

export const getIsOrganisationTeamsInUse = (teamId: string) => {
  return httpGet(`/api/team/${teamId}/checkUsed`);
};

export const generateInviteToken = () => {
  return httpPost(`/api/organization/invite/generate`, {});
};

export const updateOrganisation = (organisation: OrganisationModel) => {
  return httpPut(`/api/organization`, organisation);
};

export const deleteUserFromOrganisation = (userId: string) => {
  return httpDelete(`/api/organization/user/${userId}`);
};

export const createOrganisationTeam = (team: NewTeamModel) => {
  return httpPost("/api/team", team);
};

export const deleteOrganisationTeam = (teamId: string) => {
  return httpDelete(`/api/team/${teamId}`);
};

export const editOrganisationTeam = (team: OrganisationTeamItemModel) => {
  return httpPut(`/api/team/${team.id}`, { name: team.name, id: team.id });
};

export const addOperatorsToTeam = (userIds: string[], teamId: string) => {
  return httpPost(`/api/team/${teamId}/assign`, { teamId, userIds });
};

export const deleteTeamOperator = (userIds: string[], teamId: string) => {
  return httpPost(`/api/team/${teamId}/unassign`, { userIds });
};

export const getPlugin = (name: string) => {
  return httpGet(`/api/plugin/${name}`);
};
