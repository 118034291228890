/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { store } from "../index";
import { notificationSlice } from "../common/notifications/NotificationSlice";
import { authSlice } from "../common/auth/AuthSlice";
import { signalRConnection } from "./signalRMiddleware";
import { AppSettings } from "../common/AppSettings";
import { ApiTokenModel } from "../common/auth/AuthModel";

export interface HubSettings {
  appSettings: AppSettings;
  accessTokenFactory: () => Promise<string>;
  authToken: ApiTokenModel;
  isNewToken: boolean;
}

export async function setupHub(
  hubConnection: HubConnection,
  settings: HubSettings,
  next: any,
  action: any,
  hubUrl: string,
): Promise<HubConnection> {
  let isRefreshing = false;

  if (settings.isNewToken) {
    if (hubConnection && hubConnection.state !== "Disconnected") {
      await hubConnection.stop();
    }

    const urlRoot = settings.appSettings.apiBaseUrl;
    const conversationConnectionHub = `${urlRoot}/hubs` + hubUrl;

    const options: IHttpConnectionOptions = {
      transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
      logMessageContent: true,
      logger: LogLevel.Warning,
      accessTokenFactory: settings.accessTokenFactory,
    };

    hubConnection = new HubConnectionBuilder()
      .withUrl(conversationConnectionHub, options)
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect()
      .build();

    signalRConnection.onCreated(hubConnection);

    hubConnection.onclose(error => {
      if (error) {
        store.dispatch(
          notificationSlice.actions.notify({
            message: "Connection problem. Try refresh page...",
            type: "error",
            duration: 0,
          }),
        );
      }
    });

    hubConnection.onreconnecting(() => {
      if (!isRefreshing && settings.authToken && settings.authToken.expiration < new Date()) {
        store.dispatch(
          notificationSlice.actions.notify({
            message: "Connection problem. Reconnecting...",
            type: "warning",
            duration: 1000,
          }),
        );

        store.dispatch(authSlice.actions.refreshToken(settings.authToken));
        isRefreshing = true;
      }
    });

    hubConnection.onreconnected(() => {
      isRefreshing = false;
    });

    if (
      hubConnection.state === HubConnectionState.Connected ||
      hubConnection.state === HubConnectionState.Connecting ||
      hubConnection.state === HubConnectionState.Reconnecting
    ) {
      return next(action);
    }

    await startSignalRConnection(hubConnection);
  }

  return hubConnection;
}

const startSignalRConnection = (hub: HubConnection) =>
  hub
    .start()
    .then()
    .catch(err => console.error("SignalR Connection Error: ", err));
