import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";

export const AddBotHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });

  useDocumentTitle(t("Add Bot"), "Add Bot");

  return (
    <Text variant="h2" color="midDeepBlue" py="16px">
      {t("Add Bot")}
    </Text>
  );
};
