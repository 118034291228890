import { FormControl, Textarea, TextareaProps } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomVariablesModel, FlowActionModel, FlowActionType } from "../../../FlowBuilderModel";
import s from "./MessageTextEditor.module.scss";
import { InputWrapper } from "../../../../inputWrapper/InputWrapper";
import { InputType } from "../../../../inputWrapper/InputWrapperModel";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableModel } from "../../../../../common/AppEnums";
import { maxLength } from "../../../../../common/validation/defaultValidators";
import { insertStringIntoText } from "../../../../../common/utils/insertStringIntoText";
import { addFormatting } from "../../../../../common/formattedText/addFormatting";

interface Props {
  borderRadiusTop?: string;
  borderRadiusBottom?: string;
  autoFocus?: boolean;
  text: string;
  variables: CustomVariablesModel[];
  placeholder?: string;
  isInvalid?: boolean;
  maxLength: number;
  isEmojiDisabled?: boolean;
  maxHeightWithoutScroll?: number;
  TextAreaProps?: TextareaProps | undefined;
  customButton?: JSX.Element;
  flowAction: FlowActionModel;
  validate: (flowAction: FlowActionModel) => void;
  onChange: (text: string) => void;
  addCustomVariableText: (variable: CustomVariablesModel, variableMaxLength: number, cursorPosition?: number) => void;
}

export const MessageTextEditor = ({ autoFocus = true, ...props }: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation("translation", { keyPrefix: "flow.editNodePopup" });
  const [isFocus, setIsFocus] = useState(autoFocus);
  const [cursorPosition, setCursorPosition] = useState<number>(props.text.length);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
      // needed for correct scroll appearing
      if (props.maxHeightWithoutScroll && scrollHeight >= props.maxHeightWithoutScroll) {
        textareaRef.current.style.overflow = "scroll";
      } else {
        textareaRef.current.style.overflow = "hidden";
      }
    }
    // eslint-disable-next-line
  }, [props.text]);

  const onAddEmoji = (emoji: string) => {
    const text = insertStringIntoText(props.text ?? "", emoji, cursorPosition);
    const lengthError = maxLength(props.maxLength)(text);
    if (lengthError) {
      return;
    }
    props.onChange(text);
  };

  const onAddCustomVariable = (variable: CustomVariableModel) => {
    props.addCustomVariableText(variable, props.maxLength, cursorPosition);
  };

  return (
    <FormControl
      borderTopRadius={props.borderRadiusTop}
      borderBottomRadius={props.borderRadiusBottom}
      className={`${s.textAreaBox} ${isFocus && s.textareaFocus}`}
    >
      <InputWrapper
        cursorPosition={cursorPosition}
        type={InputType.TextArea}
        maxLength={props.maxLength}
        charactersNumber={props.text.length}
        variablesProps={{
          addCustomVariableText: onAddCustomVariable,
          variables: props.variables,
          ContextModelType: CustomVariableContextMenuType.NodeEditor,
        }}
        emojiProps={props.isEmojiDisabled ? undefined : { addEmoji: onAddEmoji }}
        customButton={props.customButton}
        childRef={textareaRef}
        addFormatting={
          props.flowAction.typeDiscriminator !== FlowActionType.CurlFlowAction &&
          props.flowAction.typeDiscriminator !== FlowActionType.SendPaymentFlowAction
            ? action => {
                textareaRef.current && addFormatting(action, props.text, props.maxLength, textareaRef.current, props.onChange);
              }
            : undefined
        }
      >
        <Textarea
          autoFocus={autoFocus}
          variant="dominoTextEditorColor"
          ref={textareaRef}
          onChange={e => props.onChange(e.target.value)}
          isInvalid={props.isInvalid}
          onSelect={e => {
            setCursorPosition(e.currentTarget.selectionStart);
          }}
          name="text"
          id="text"
          maxLength={props.maxLength}
          placeholder={props.placeholder ?? `${t("Enter text here")} ...`}
          value={props.text}
          onBlur={() => {
            setIsFocus(false);
          }}
          borderRadius={"none"}
          overflow={"hidden"}
          p={0}
          maxH={props.maxHeightWithoutScroll}
          onFocus={e => e.currentTarget.setSelectionRange(cursorPosition, cursorPosition)}
          {...props.TextAreaProps}
          data-pw="message-text-area"
        />
      </InputWrapper>
    </FormControl>
  );
};
