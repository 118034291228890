import s from "./SignUp.module.scss";
import sm from "../headers/publicHeaderContainer/PublicHeaderContainer.module.scss";
import { Box, Flex, Link, Show } from "@chakra-ui/react";
import { TelegramAuthButton } from "../../common/TelegramAuth/TelegramAuthButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from "../../common/hooks/useDocumentTitle";
import { LandingLinks } from "../../common/utils/landingLinks";

export const SignUp = () => {
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  const { i18n } = useTranslation("translation");
  const langPostfix = i18n.language === "en";
  const navigate = useNavigate();
  useDocumentTitle(t("Sign Up"), "Sign Up");

  return (
    <Flex flexDirection={"column"} w={"100%"} bg={"bgLight"}>
      <Box className={`${sm.authBlock} ${sm.authBlockSingUp}`}>
        <Box className={sm.authForm}>
          <Box>
            <p className={s.mainText}>
              {t(
                "Click the button below to open Telegram app on the desktop. When Telegram chat is opened, follow the instructions.",
              )}
            </p>
            <TelegramAuthButton />
            <Box className={s.authBottomText}>
              <p className={sm.authLeftText}>
                {t("By signing up, you agree to Domino’s")} <br />
                <a
                  className={sm.authTextLink}
                  href={`${langPostfix ? LandingLinks.TermsOfUseEN : LandingLinks.TermsOfUseRU}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {t("Terms of Service")}{" "}
                </a>
                {t("and")}
                <a
                  className={sm.authTextLink}
                  href={`${langPostfix ? LandingLinks.PrivacyPolicyEN : LandingLinks.PrivacyPolicyRU}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  {t("Privacy Policy")}{" "}
                </a>{" "}
                {t("Privacy Policy Domino CRM")}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Show breakpoint="(max-width: 479px)">
        <Flex justifyContent={"center"} className={sm.mobileBottomBlock}>
          <p>{t("Already have an account?")}</p>
          <Link
            color="blueLink"
            _hover={{ color: "mainPurple", textDecoration: "underline" }}
            ml={"8px"}
            onClick={() => navigate("/auth/signIn")}
          >
            {t("Sign In Mobile")}
          </Link>
        </Flex>
      </Show>
    </Flex>
  );
};
