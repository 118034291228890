import { Flex, useDisclosure } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { EditContact } from "../../../../contact/components/EditContact/EditContact";
import { EditContactHeader } from "../../../../contact/components/ContactHeader/EditContactHeader";
import { DeleteContactPopup } from "../../../../contactList/components/DeleteContactPopup";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { selectContact } from "../../../../contact/ContactSlice";
import { useNavigate } from "react-router-dom";
import { deleteContact } from "../../../../contactList/ContactListSlice";

export const EditContactPage = () => {
  const dispatch = useAppDispatch();
  const contact = useAppSelector(selectContact);
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <EditContactHeader onOpen={onOpen} />
      </ProtectedHeaderContainer>
      <EditContact />
      <DeleteContactPopup
        isOpen={isOpen}
        deleteContactState={
          contact ? { id: contact?.id, itemName: `${contact.firstName ?? ""} ${contact.lastName ?? ""}` } : null
        }
        onClose={onClose}
        onDelete={() => {
          if (contact) {
            dispatch(deleteContact({ contactId: contact.id }));
            navigate(-1);
          }
        }}
      />
    </Flex>
  );
};
