import { useEffect, useRef } from "react";
import { AppSettings } from "../AppSettings";
import { useAppDispatch } from "../state/store";
import { TelegramAuth } from "./TelegramAuthData";
import { telegramAuthenticate } from "./TelegramAuthSlice";
import s from "./TelegramAuth.module.scss";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";
import useGA from "../ga/GAEventTracker";

declare const appSettings: AppSettings;

interface Props {
  size?: string;
  requestAccess?: string;
  showUserPic?: string;
}

declare global {
  interface Window {
    onTelegramAuth: (user: TelegramAuth) => void;
  }
}

export const TelegramAuthButton = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams("");
  const redirecturl = searchParams.get("redirect_url");
  const inviteToken = searchParams.get("inviteToken");
  const locale = i18next.languages[0];
  const trackEvent = useGA("Signin");

  window.onTelegramAuth = (user: TelegramAuth) => {
    if (user) {
      dispatch(
        telegramAuthenticate({
          telegramData: inviteToken ? { payload: user, inviteToken } : { payload: user },
          redirectUrlLogin: redirecturl === null ? undefined : decodeURI(redirecturl),
        }),
      );
    }
  };

  const telegramAuth = () => {
    const telegramButton = document.getElementById("TelegramButton");
    const prevScript = telegramButton?.getElementsByTagName("script");
    const prevFrame = telegramButton?.getElementsByTagName("iframe");

    if (prevScript?.length && prevFrame?.length) {
      telegramButton?.removeChild(prevScript[0]);
      telegramButton?.removeChild(prevFrame[0]);
    }

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?21";
    script.setAttribute("data-telegram-login", appSettings.telegramAuthBots[locale]);
    script.setAttribute("data-size", props.size || "large");
    script.setAttribute("data-request-access", props.requestAccess || "write");
    script.setAttribute("data-userpic", props.showUserPic || "false");
    script.setAttribute("data-onauth", "window.onTelegramAuth(user)");
    script.setAttribute("data-radius", "4");
    script.async = true;
    ref.current?.appendChild(script);
  };

  const listenCancelEvent = (e: MessageEvent) => {
    if (e.origin === "https://oauth.telegram.org" && JSON.parse(e.data).event === "unauthorized") {
      trackEvent("TelegramSignupCanceled");
    } else {
      return;
    }
  };

  useEffect(() => {
    window.addEventListener("message", listenCancelEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    telegramAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return <div id={"TelegramButton"} ref={ref} className={s.telegramButton}></div>;
};
