import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Joyride, { EVENTS } from "react-joyride";
import { GENERAL_TOUR_NAME, getSteps } from "./toursConstants";
import { completeTour, selectTourByName, skipTour } from "./OnboardingToursSlice";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { useGAWithCustomParameters } from "../../common/ga/GAEventTracker";

export const GeneralOnboardingTour = () => {
  const { t } = useTranslation("translation", { keyPrefix: "onboardingTour" });
  const trackEvent = useGAWithCustomParameters("OnboardingOne");
  const dispatch = useAppDispatch();
  const generalTourState = useAppSelector(state => selectTourByName(state, GENERAL_TOUR_NAME));
  const isGeneralTourRun = !generalTourState ? false : generalTourState.run;
  const [currentStep, setCurrentStep] = useState(0);
  const [tooltipWidth, setTooltipWidth] = useState("336px");
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const updateTooltipWidth = () => {
      if (window.innerWidth <= 480) {
        setTooltipWidth("328px");
      } else {
        setTooltipWidth("336px");
      }
    };
    updateTooltipWidth();
    window.addEventListener("resize", updateTooltipWidth);
    return () => window.removeEventListener("resize", updateTooltipWidth);
  }, []);

  useEffect(() => {
    const updateOffset = () => {
      if (window.innerWidth <= 640) {
        setOffset(-4);
      } else {
        setOffset(0);
      }
    };
    updateOffset();
    window.addEventListener("resize", updateOffset);
    return () => window.removeEventListener("resize", updateOffset);
  }, []);

  const steps = getSteps(offset, t);

  const customStyles = {
    spotlight: {
      borderRadius: "8px",
    },
    options: {
      arrowColor: "#232B39",
      overlayColor: "rgba(35, 43, 57, 0.5)",
      zIndex: 12000,
      display: "contents",
      position: "absolute",
    },
    tooltipFooter: {
      margin: 0,
      padding: 0,
    },

    tooltip: {
      backgroundColor: "#232B39",
      color: "white",
      borderRadius: "8px",
      padding: "20px",
      width: tooltipWidth,
      minHeight: "148px",
    },
    tooltipTitle: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "20px",
      textAlign: "left" as const,
    },
    tooltipContent: {
      padding: "8px 0 16px 0",
      textAlign: "left" as const,
      fontSize: "13px",
      minHeight: "56px",
    },
    buttonClose: {
      color: "#BCC3CD",
      height: "11.5px",
      width: "11.5px",
      padding: "14.25px",
    },
    buttonNext: {
      backgroundColor: "#6D5BF7",
      color: "white",
      borderRadius: "8px",
      padding: "8px 16px",
      fontSize: "13px",
      minWidth: "83px",
    },
    buttonBack: {
      color: "#BCC3CD",
      alignSelf: "flex-end",
      fontSize: "13px",
      paddingLeft: 0,
    },
    buttonSkip: {
      color: "#BCC3CD",
      fontSize: "13px",
      lineHeight: "16px",
      paddingLeft: 0,
    },
    beacon: {
      display: "none",
    },
  };

  const trackGoogleEvents = (action: string) => {
    if (action === "next") {
      trackEvent("OnboardingOneForward");
    }
    if (action === "close") {
      trackEvent("OnboardingOneClose", "", { step: Number(currentStep + 1) });
    }
    if (action === "prev") {
      trackEvent("OnboardingOneBack");
    }
    if (action === "skip") {
      trackEvent("OnboardingOneLater");
    }
  };

  const handleJoyrideCallback = (data: { status: string; type: string; index: number; action: string }) => {
    const { status, type, index, action } = data;
    trackGoogleEvents(action);
    if (status === "skipped" || status === "finished" || action === "close") {
      dispatch(skipTour(GENERAL_TOUR_NAME));
    }

    if (type === EVENTS.STEP_AFTER && index === steps.length - 1 && action !== "prev") {
      if (generalTourState?.isCompleted) return;
      dispatch(completeTour(GENERAL_TOUR_NAME));
    }
    setCurrentStep(index);
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={isGeneralTourRun}
      steps={steps}
      scrollToFirstStep
      showSkipButton={currentStep === 0}
      locale={{ skip: t("skip"), next: t("next"), back: t("back"), last: t("last") }}
      disableOverlayClose={true}
      styles={customStyles}
      disableScrollParentFix
    />
  );
};
