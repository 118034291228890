import { useBrowserFitContent, useTabletView } from "../../LayoutHelper/ResolutionHooks";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../common/state/store";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { useSearchParams } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { BroadcastHeader } from "../../../broadcastList/components/BroadcastListHeader/BroadcastHeader";
import { BroadcastListContainer } from "../../../broadcastList/BroadcastListContainer";
import { selectBroadcastListState } from "../../../broadcastList/BroadcastListSlice";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../../../sidebar/SidebarSlice";
import * as vars from "../../../layout/LayoutHelper/ResolutionConst";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";

export const BroadcastListPage = () => {
  const browserFill = useBrowserFitContent();
  const isTablet = useTabletView();
  const [mobileView, isMobileView] = useState(isTablet);

  const { broadcastList } = useAppSelector(selectBroadcastListState);
  const loadingBot = useGetLoadingState("getBroadcastList");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  if (mobileView) {
    return (
      <Flex flexDirection={"column"} height={"100%"}>
        <ProtectedHeaderContainer>
          <BroadcastHeader />
        </ProtectedHeaderContainer>
        <Box bg="bgLight" height={window.innerHeight - vars.HEADER - 120 - (isTrial ? 60 : 0)}>
          <Loading />
          <BroadcastListContainer />
        </Box>
      </Flex>
    );
  }

  if (!currentBot && !isCurrentBotLoading && !broadcastList && !loadingBot && search === "") {
    return (
      <Flex flexDirection={"column"} height={"100%"}>
        <ProtectedHeaderContainer>
          <BroadcastHeader />
        </ProtectedHeaderContainer>
        <Box bg="bgLight" height={"100%"}>
          <Loading />
          <BroadcastListContainer />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <BroadcastHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={browserFill}>
        <Loading />
        <BroadcastListContainer />
      </Box>
    </Flex>
  );
};
