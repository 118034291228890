import { useAppDispatch } from "../../../../common/state/store";
import {
  ButtonsContainerFlowAction,
  CustomVariablesModel,
  FileContainerFlowAction,
  FlowActionModel,
  FlowActionType,
  FlowModel,
  NodeModel,
  NodeType,
  NodeValidation,
  TextContainerFlowAction,
} from "../../FlowBuilderModel";
import { editNode, getFlowList, getOperators, getTeams, saveNode, setNodeValidation } from "../../FlowBuilderSlice";
import { CurlNodePopup } from "./CurlNodePopup/CurlNodePopup";
import { DocumentNodePopup } from "./DocumentNodePopup/DocumentNodePopup";
import { ImageNodePopup } from "./ImageNodePopup/ImageNodePopup";
import { MessageNodePopup } from "./MessageNodePopup/MessageNodePopup";
import { QuestionNodePopup } from "./QuestionNodePopup/QuestionNodePopup";
import { FlowTriggerPopup } from "./FlowTriggerPopup/FlowTriggerPopup";
import { VideoNodePopup } from "./VideoNodePopup/VideoNodePopup";
import { AudioNodePopup } from "./AudioNodePopup/AudioNodePopup";
import { validateNode } from "../../NodeValidation";
import { ValidationError } from "../../../../common/ErrorModel";
import { ButtonTypeDiscriminator, FlowMarkupTypeDiscriminator } from "../../../../common/AppEnums";
import {
  ButtonModel,
  InlineKeyboardMarkupModel,
  ReplyKeyboardButtonModel,
  ReplyKeyboardMarkupModel,
  ReplyMarkup,
  ReplyMarkupModel,
} from "../../../../common/AppButtonsModel";
import { maxLength } from "../../../../common/validation/defaultValidators";
import { ConditionNodePopup } from "./ConditionNodePopup/ConditionNodePopup";
import { insertStringIntoText } from "../../../../common/utils/insertStringIntoText";
import { SystemActionNodePopup } from "./SystemActionNodePopup/SystemActionNodePopup";
import { DelayNodePopup } from "./DelayNodePopup/DelayNodePopup";
import { AssignNodePopup } from "./AssignNodePopup/AssignNodePopup";
import { StartFlowNodePopup } from "./StartFlowNodePopup/StartFlowNodePopup";
import CalendarNodePopup from "./CalendarNodePopup/CalendarNodePopup";
import PaymentNodePopup from "./PaymentNodePopup/PaymentNodePopup";
import CartNodePopup from "./CartNodePopup/CartNodePopup";
import { useTranslation } from "react-i18next";

interface Props {
  flow: FlowModel;
  node: NodeModel;
  validation: NodeValidation;
  botUserName: string;
  botId: string;
  deleteEdge: (id: string) => void;
}

export const NodeEditPopup = (props: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });

  const validateEditor = (flow: FlowActionModel) => {
    if (flow) {
      try {
        const result = validateNode(flow, t);
        dispatch(setNodeValidation({ errors: result.errors, nodeId: props.node.id }));
      } catch (e) {
        if (e instanceof ValidationError) {
          dispatch(setNodeValidation({ errors: e.validationData.errors, nodeId: props.node.id }));
        }
      }
    }
  };

  const onDataChange = (flowAction: FlowActionModel) => {
    validateEditor(flowAction);
    dispatch(editNode({ ...props.node, flowAction }));
  };

  const addCustomVariableUrl = (variable: CustomVariablesModel, inputMaxLength: number, cursorPosition?: number) => {
    const fileContainer = props.node.flowAction as FileContainerFlowAction;
    const text = insertStringIntoText(
      fileContainer.fileUrl ?? "",
      "@{" + variable.scope + ":" + variable.key + "}",
      cursorPosition,
    );
    const lengthError = maxLength(inputMaxLength)(text);
    const fileUrlCustomVariableIds = fileContainer.fileUrlCustomVariableIds ? fileContainer.fileUrlCustomVariableIds : [];
    if (!lengthError) {
      onDataChange({
        ...fileContainer,
        fileUrl: text,
        fileUrlCustomVariableIds: [...fileUrlCustomVariableIds, variable.key],
      } as FileContainerFlowAction);
    }
  };

  const addCustomVariableText = (variable: CustomVariablesModel, inputMaxLength: number, cursorPosition?: number) => {
    const textContainer = props.node.flowAction as TextContainerFlowAction;
    const text = insertStringIntoText(textContainer.text ?? "", "@{" + variable.scope + ":" + variable.key + "}", cursorPosition);
    const lengthError = maxLength(inputMaxLength)(text);
    if (!lengthError) {
      onDataChange({ ...textContainer, text } as TextContainerFlowAction);
    }
  };

  const getTeamPage = (page: number, filter?: string) => {
    dispatch(getTeams({ page, filter }));
  };

  const getOperatorPage = (page: number, filter?: string) => {
    dispatch(getOperators({ page, filter }));
  };

  const getFlowListPage = (page: number, filter?: string) => {
    dispatch(getFlowList({ botId: props.botId, page, filter }));
  };

  const saveButton = (button: ButtonModel | ReplyKeyboardButtonModel) => {
    const buttonsContainer = props.node.flowAction as ButtonsContainerFlowAction;
    const markup: ReplyMarkupModel | undefined = buttonsContainer?.replyMarkup;
    let buttons: any[][] = []; /* eslint-disable-line @typescript-eslint/no-explicit-any */
    if (markup) {
      switch (markup.typeDiscriminator) {
        case FlowMarkupTypeDiscriminator.InlineKeyboardMarkup:
          const inline = markup as InlineKeyboardMarkupModel;
          buttons = [...(inline?.buttons ?? [])];
          break;
        case FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup:
          const reply = markup as ReplyKeyboardMarkupModel;
          buttons = [...(reply?.buttons ?? [])];
          break;
      }
    }
    if (!buttons.length) {
      if (
        button.typeDiscriminator ===
        (ButtonTypeDiscriminator.FlowMessageCallbackButton || ButtonTypeDiscriminator.FlowMessageUrlButton)
      ) {
        const inlineButtons: ButtonModel[][] = [];
        buttons = inlineButtons;
      } else {
        const replyButtons: ReplyKeyboardButtonModel[][] = [];
        buttons = replyButtons;
      }
    }
    const foundButton = buttons.find(buttons => buttons.find(desiredButton => desiredButton.id === button.id));
    if (foundButton) {
      if (button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageUrlButton) {
        deleteButtonEdge(foundButton[0].id);
      }
      buttons = buttons.map(urlButtons => urlButtons.map(urlButton => (urlButton.id === button.id ? button : urlButton)));
    } else {
      buttons.push([button]);
    }

    const isReply = button.typeDiscriminator === ButtonTypeDiscriminator.FlowReplyKeyboardButton;
    const replyMarkup: ReplyMarkup = {
      buttons: buttons,
      typeDiscriminator: !isReply
        ? FlowMarkupTypeDiscriminator.InlineKeyboardMarkup
        : FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup,
    };
    dispatch(
      editNode({
        ...props.node,
        flowAction: {
          ...buttonsContainer,
          replyMarkup: replyMarkup,
        } as ButtonsContainerFlowAction,
      }),
    );
  };

  const deleteButtonEdge = (buttonId: string) => {
    const edgeToDelete = props.flow.edges.find(el => el.sourceHandle === buttonId);
    if (edgeToDelete) {
      props.deleteEdge(edgeToDelete?.id);
    }
  };

  const deleteButton = (buttonId: string) => {
    const buttonsContainer = props.node.flowAction as ButtonsContainerFlowAction;
    switch (buttonsContainer.replyMarkup?.typeDiscriminator) {
      case FlowMarkupTypeDiscriminator.InlineKeyboardMarkup:
        const inlineButtons = [...(buttonsContainer.replyMarkup as InlineKeyboardMarkupModel).buttons];
        const buttonForDelete = inlineButtons.find(inlineButtons =>
          inlineButtons.find(inlineButton => inlineButton.id === buttonId),
        );
        const inlineMarkupButtons = inlineButtons.filter(inlineButtons => inlineButtons !== buttonForDelete);
        deleteButtonEdge(buttonId);
        dispatch(
          editNode({
            ...props.node,
            flowAction: {
              ...buttonsContainer,
              fallbackMessage: inlineMarkupButtons.length ? buttonsContainer.fallbackMessage : "",
              isFallback: inlineMarkupButtons.length ? buttonsContainer.isFallback : false,
              replyMarkup:
                inlineMarkupButtons.length !== 0
                  ? { buttons: inlineMarkupButtons, typeDiscriminator: buttonsContainer.replyMarkup.typeDiscriminator }
                  : undefined,
            } as ButtonsContainerFlowAction,
          }),
        );
        break;
      case FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup:
        const replyButtons = [...(buttonsContainer.replyMarkup as ReplyKeyboardMarkupModel).buttons];
        const deleteButton = replyButtons.find(replyButtons => replyButtons.find(replyButton => replyButton.id === buttonId));
        const replyMarkupButtons = replyButtons.filter(replyButtons => replyButtons !== deleteButton);
        deleteButtonEdge(buttonId);
        dispatch(
          editNode({
            ...props.node,
            flowAction: {
              ...buttonsContainer,
              fallbackMessage: replyMarkupButtons.length !== 0 ? buttonsContainer.fallbackMessage : "",
              isFallback: replyMarkupButtons.length !== 0 ? buttonsContainer.isFallback : false,
              replyMarkup:
                replyMarkupButtons.length !== 0
                  ? { buttons: replyMarkupButtons, typeDiscriminator: buttonsContainer.replyMarkup.typeDiscriminator }
                  : undefined,
            } as ButtonsContainerFlowAction,
          }),
        );
        break;
      default:
        return;
    }
  };

  const setButtons = (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => {
    const buttonsContainer = props.node.flowAction as ButtonsContainerFlowAction;
    switch (buttonsContainer.replyMarkup?.typeDiscriminator) {
      case FlowMarkupTypeDiscriminator.InlineKeyboardMarkup:
        dispatch(
          editNode({
            ...props.node,
            flowAction: {
              ...buttonsContainer,
              replyMarkup: {
                buttons: buttons,
                typeDiscriminator: FlowMarkupTypeDiscriminator.InlineKeyboardMarkup,
              } as InlineKeyboardMarkupModel,
            } as ButtonsContainerFlowAction,
          }),
        );
        break;
      case FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup:
        dispatch(
          editNode({
            ...props.node,
            flowAction: {
              ...buttonsContainer,
              replyMarkup: {
                buttons: buttons,
                typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup,
              } as ReplyKeyboardMarkupModel,
            } as ButtonsContainerFlowAction,
          }),
        );
        break;
      default:
        return;
    }
  };

  const onClose = () => {
    dispatch(
      saveNode({
        flow: props.flow,
        node: props.node,
      }),
    );
  };

  const makePopupComponent = (nodeType: NodeType, flowAction?: FlowActionModel) => {
    const validationInfo = {
      ...props.validation,
      isError: props.validation.nodeId === props.node.id && props.validation.errors.length > 0,
    };

    if (nodeType === NodeType.Trigger) {
      return (
        <FlowTriggerPopup
          triggers={props.flow.triggers}
          onClose={onClose}
          validation={props.validation}
          botUserName={props.botUserName}
        />
      );
    }

    if (flowAction) {
      switch (flowAction.typeDiscriminator) {
        case FlowActionType.SendTextFlowAction:
          return (
            <MessageNodePopup
              flowAction={flowAction}
              node={props.node}
              validate={validateEditor}
              validationInfo={validationInfo}
              dataChange={onDataChange}
              addButton={saveButton}
              setButtons={setButtons}
              deleteButton={deleteButton}
              addCustomVariableText={addCustomVariableText}
              editButton={saveButton}
              onClose={onClose}
            />
          );
        case FlowActionType.QuestionFlowAction:
          return (
            <QuestionNodePopup
              flow={props.flow}
              node={props.node}
              botId={props.botId}
              validate={validateEditor}
              validationInfo={validationInfo}
              addCustomVariableText={addCustomVariableText}
            />
          );
        case FlowActionType.SendPhotoFlowAction:
          return (
            <ImageNodePopup
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              validationInfo={validationInfo}
              dataChange={onDataChange}
              addButton={saveButton}
              setButtons={setButtons}
              deleteButton={deleteButton}
              addCustomVariableText={addCustomVariableText}
              addCustomVariableUrl={addCustomVariableUrl}
              editButton={saveButton}
            />
          );
        case FlowActionType.SendDocumentFlowAction:
          return (
            <DocumentNodePopup
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              validationInfo={validationInfo}
              dataChange={onDataChange}
              addButton={saveButton}
              setButtons={setButtons}
              deleteButton={deleteButton}
              addCustomVariableText={addCustomVariableText}
              editButton={saveButton}
            />
          );
        case FlowActionType.CurlFlowAction:
          return (
            <CurlNodePopup
              onDataChange={onDataChange}
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              validationInfo={validationInfo}
            />
          );
        case FlowActionType.SendVideoFlowAction:
          return (
            <VideoNodePopup
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              validationInfo={validationInfo}
              dataChange={onDataChange}
              addButton={saveButton}
              setButtons={setButtons}
              deleteButton={deleteButton}
              addCustomVariableText={addCustomVariableText}
              editButton={saveButton}
            />
          );
        case FlowActionType.SendAudioFlowAction:
          return (
            <AudioNodePopup
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              validationInfo={validationInfo}
              dataChange={onDataChange}
              addButton={saveButton}
              setButtons={setButtons}
              deleteButton={deleteButton}
              addCustomVariableText={addCustomVariableText}
              editButton={saveButton}
            />
          );
        case FlowActionType.ConditionFlowAction:
          return (
            <ConditionNodePopup
              flow={props.flow}
              node={props.node}
              deleteEdge={props.deleteEdge}
              validate={validateEditor}
              onDataChange={onDataChange}
              validationInfo={validationInfo}
            />
          );
        case FlowActionType.SystemActionFlowAction:
        case FlowActionType.SetVariableValueFlowAction:
        case FlowActionType.PutItemIntoArrayFlowAction:
        case FlowActionType.TakeItemFromArrayFlowAction:
          return (
            <SystemActionNodePopup
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              onDataChange={onDataChange}
              validationInfo={validationInfo}
            />
          );
        case FlowActionType.DelayFlowAction:
          return (
            <DelayNodePopup
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              onDataChange={onDataChange}
              validationInfo={validationInfo}
              onClose={onClose}
            />
          );
        case FlowActionType.AssignFlowAction:
          return (
            <AssignNodePopup
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              onDataChange={onDataChange}
              validationInfo={validationInfo}
              getTeamPage={getTeamPage}
              getOperatorPage={getOperatorPage}
              onClose={onClose}
            />
          );
        case FlowActionType.StartSubFlowAction:
          return (
            <StartFlowNodePopup
              flow={props.flow}
              node={props.node}
              validate={validateEditor}
              onDataChange={onDataChange}
              validationInfo={validationInfo}
              getFlowListPage={getFlowListPage}
              onClose={onClose}
            />
          );
        case FlowActionType.SendAppointmentFlowAction:
          return (
            <CalendarNodePopup
              flowAction={flowAction}
              addCustomVariableText={addCustomVariableText}
              node={props.node}
              validate={validateEditor}
              onDataChange={onDataChange}
              validationInfo={validationInfo}
              getFlowListPage={getFlowListPage}
              onClose={onClose}
            />
          );
        case FlowActionType.SendPaymentFlowAction:
          return (
            <PaymentNodePopup
              flowAction={flowAction}
              addCustomVariableText={addCustomVariableText}
              node={props.node}
              validate={validateEditor}
              onDataChange={onDataChange}
              validationInfo={validationInfo}
              getFlowListPage={getFlowListPage}
              onClose={onClose}
            />
          );
        case FlowActionType.ShoppingCartFlowAction:
          return (
            <CartNodePopup
              flowAction={flowAction}
              node={props.node}
              validate={validateEditor}
              validationInfo={validationInfo}
              onDataChange={onDataChange}
              addButton={saveButton}
              setButtons={setButtons}
              deleteButton={deleteButton}
              addCustomVariableText={addCustomVariableText}
              editButton={saveButton}
              onClose={onClose}
            />
          );
        default:
          return <>No editor defined</>;
      }
    }
  };

  return <>{props.node && makePopupComponent(props.node.type, props.node.flowAction)}</>;
};
