import { takeLatest, put, call, select } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { BotListData, MessagesStatisticData, MessagesData } from "./DashboardData";
import * as DashboardApi from "./DashboardApi";
import { selectBots, dashboardSlice } from "./DashboardSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { store } from "../..";
import {
  BotListModel,
  BotModel,
  DataMessagesStatisticModel,
  MessagesModel,
  DashboardDataModel,
  DashboardLocalStorageModel,
  MessagesStatisticModel,
  DataMessagesModel,
} from "./DashboardModel";
import { getBotData } from "../bot/BotApi";
import { formatDateToMonthDay } from "../../common/utils/formatDate";

export function* dashboardSaga() {
  yield takeLatest(dashboardSlice.actions.getBots, getBots);
  yield takeLatest(dashboardSlice.actions.selectBot, selectBot);
  yield takeLatest(dashboardSlice.actions.getSentMessages, getSentMessages);
  yield takeLatest(dashboardSlice.actions.getSentReceivedMessages, getSentReceivedMessages);
}

function* getBots(action: PayloadAction<number>) {
  try {
    const botListdata: BotListData = yield call(DashboardApi.getBots, action.payload);
    const botList = store.getState().app.dashboardState.botList;
    if (botList?.items && action.payload > 1) {
      const updatedItems = botList.items.concat(botListdata.items);
      const model = { ...botListdata, items: updatedItems };
      yield put(dashboardSlice.actions.getBotsCompleted(model));
      return;
    }
    const botFromStorage = getBotFromStorage();
    try {
      const botData: BotModel = yield call(getBotData, botFromStorage?.id);
      saveBotToStorage(botData);
      yield put(dashboardSlice.actions.selectBotCompleted(botData));
    } catch (err) {
      if (botListdata?.items.length > 0) {
        saveBotToStorage(botListdata?.items[0]);
        yield put(dashboardSlice.actions.selectBotCompleted(botListdata?.items[0]));
      }
    }
    yield put(dashboardSlice.actions.getBotsCompleted(botListdata));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(dashboardSlice.actions.setCurrentBotLoading(false));
  }
}

function* selectBot(action: PayloadAction<BotModel | string>) {
  try {
    if (typeof action.payload === "string") {
      const bots: BotListModel | undefined = yield select(selectBots);
      const bot = bots?.items.find(b => b.id === action.payload);
      if (bot) {
        saveBotToStorage(bot);
        yield put(dashboardSlice.actions.selectBotCompleted(bot));
      }
    } else {
      saveBotToStorage(action.payload);
      yield put(dashboardSlice.actions.selectBotCompleted(action.payload));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(dashboardSlice.actions.setCurrentBotLoading(false));
  }
}

function* getSentMessages(action: PayloadAction<DashboardLocalStorageModel>) {
  saveDashboardRequestToStorage(action.payload);
  const requestData: DashboardDataModel = {
    from: action.payload.date.from,
    to: action.payload.date.to,
    botId: action.payload.bot.id,
  };
  try {
    const sentMessagesData: MessagesData = yield call(DashboardApi.getSentMessages, requestData);
    const modal: MessagesModel = mapChartSentMessagesData(sentMessagesData);
    yield put(dashboardSlice.actions.getSentMessagesCompleted(modal));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(dashboardSlice.actions.setCurrentBotLoading(false));
  }
}

function* getSentReceivedMessages(action: PayloadAction<DashboardLocalStorageModel>) {
  const requestData: DashboardDataModel = {
    from: action.payload.date.from,
    to: action.payload.date.to,
    botId: action.payload.bot.id,
  };
  try {
    const sentReceivedMessagesData: MessagesStatisticData = yield call(DashboardApi.getSentReceivedMessages, requestData);
    const modal: MessagesStatisticModel = mapChartSentReceivedMessagesData(sentReceivedMessagesData);
    yield put(dashboardSlice.actions.getSentReceivedMessagesCompleted(modal));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(dashboardSlice.actions.setCurrentBotLoading(false));
  }
}

export const getBotFromStorage = () => {
  const botSessionStorageJson = sessionStorage.getItem("CurrentDashboardBot");
  const botLocalStorageJson = localStorage.getItem("CurrentDashboardBot");
  if (botSessionStorageJson) {
    const bot = JSON.parse(botSessionStorageJson);
    return bot;
  } else if (botLocalStorageJson) {
    const bot = JSON.parse(botLocalStorageJson);
    sessionStorage.setItem("CurrentDashboardBot", JSON.stringify(bot));
    return bot;
  }
  return undefined;
};

const saveBotToStorage = (bot: BotModel) => {
  sessionStorage.setItem("CurrentDashboardBot", JSON.stringify(bot));
  localStorage.setItem("CurrentDashboardBot", JSON.stringify(bot));
};

const saveDashboardRequestToStorage = (request: DashboardLocalStorageModel) => {
  localStorage.setItem("DashboardDate", JSON.stringify(request.date));
  localStorage.setItem("DashboardBot", JSON.stringify(request.bot));
};

export const getDashboardFromStorage = () => {
  const requestDateLocalStorageJson = localStorage.getItem("DashboardDate");
  const requestBotLocalStorageJson = localStorage.getItem("DashboardBot");
  if (requestDateLocalStorageJson && requestBotLocalStorageJson) {
    const requestDate = JSON.parse(requestDateLocalStorageJson);
    const requestBot = JSON.parse(requestBotLocalStorageJson);
    const request = { date: requestDate, bot: requestBot };
    sessionStorage.setItem("DasboardDate", JSON.stringify(requestDate));
    sessionStorage.setItem("DashboardBot", JSON.stringify(requestBot));
    return request;
  }
  return undefined;
};

const mapChartSentMessagesData = (data: MessagesData) => {
  const result: DataMessagesModel[] = [];
  data.data.forEach(item => {
    result.push({
      name: formatDateToMonthDay(item.date),
      bot: item.botCount,
      broadcasts: item.broadcastsCount,
      operators: item.operatorsCount,
      total: item.total,
    });
  });
  const newData: MessagesModel = { ...data, data: result };
  return newData;
};

const mapChartSentReceivedMessagesData = (data: MessagesStatisticData) => {
  const result: DataMessagesStatisticModel[] = [];
  data.data.forEach(item => {
    result.push({
      name: formatDateToMonthDay(item.date),
      sent: item.sent,
      received: item.received,
      total: item.total,
    });
  });
  const newData: MessagesStatisticModel = { ...data, data: result };
  return newData;
};
