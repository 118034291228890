/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import s from "../Conversation.module.scss";
import { ReactComponent as ScrollDownIcon } from "../../../assets/icons/backArrowIcon.svg";
import { ConversationInfoModel, ConversationMessageModel, ConversationMessagesModel, MessageModel } from "../ConversationModel";
import { MessageItem } from "./components/MessageItem";
import { SystemMessageItem } from "./components/SystemMessageItem";
import { NoteMessageItem } from "./components/NoteMessageItem";
import { MessageTypeDiscriminator } from "../ConversationData";
import { useTranslation } from "react-i18next";
import { SearchBox } from "./SearchBox/SearchBox";
import { useAppDispatch } from "../../../common/state/store";
import { setScrollToMessageId } from "../ConversationSlice";

interface Props {
  messageList: ConversationMessagesModel;
  botName: string;
  conversationInfo: ConversationInfoModel;
  getMessages: (messageId: string, searchValue?: string, currentSearchMessagesLength?: number, includeMessageId?: string) => void;
  clearSearchParams: () => void;
  onReply: (reply: {
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
    };
  }) => void;
  scrollToMessageId?: string;
}

let searchedMessageIndex: number | undefined;

export const MessagesList = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "conversation.dates",
  });

  const messageListRef = useRef<null | HTMLDivElement>(null);
  const searchedMessagesRef = useRef<undefined | HTMLDivElement[]>([]);
  const messagesRef = useRef<undefined | HTMLDivElement[]>([]);
  const messageListItems = props.messageList.items.filter(el => {
    const elem = el as ConversationMessageModel;
    return elem?.shoppingCartOrder === undefined || elem?.shoppingCartOrder === null;
  });
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [isScrollButtonActive, setScrollButtonState] = useState(false);
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [prevSearchedMessagesLength, setPrevSearchedMessagesLength] = useState<number>(0);
  const [searchedMessageIdInViewport, setSearchedMessageIdInViewPort] = useState<string>("");
  const [highlightedMessages, setHighlightedMessages] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  const scrollToBottom = () => {
    if (messagesRef.current) {
      const lastIndex = messagesRef.current?.length - 1;
      messagesRef.current[lastIndex]?.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
    }
  };

  const scrollToPrevMessageList = () => {
    if (messageListRef.current) {
      messageListRef.current.style.overflowY = "hidden";
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight - prevScrollHeight;
      messageListRef.current.style.overflowY = "scroll";
    }
  };

  const scrollToMessage = (isNext: boolean) => {
    const searchedMessagesRefLength = searchedMessagesRef.current?.length;
    const messagesRef = searchedMessagesRef.current;

    if (messagesRef && searchedMessagesRefLength) {
      if (searchedMessageIndex === undefined) {
        searchedMessageIndex = searchedMessagesRefLength - 1;
      } else {
        searchedMessageIndex =
          searchedMessagesRefLength !== prevSearchedMessagesLength
            ? searchedMessageIndex + (searchedMessagesRefLength - prevSearchedMessagesLength)
            : searchedMessageIndex;

        const lastItemIndex = searchedMessagesRefLength - 1;
        const isPossibleNext = searchedMessageIndex !== lastItemIndex;
        const isPossiblePrev = searchedMessageIndex - 1 >= 0;

        if (!isPossiblePrev && !isNext) {
          searchedMessagesRef.current = [];
          props.getMessages(props.messageList.lastId, searchedValue);
          return;
        }

        const nextMessageIndexExpression = isPossibleNext ? searchedMessageIndex + 1 : lastItemIndex;
        const prevMessageIndexExpression = isPossiblePrev ? searchedMessageIndex - 1 : 0;
        searchedMessageIndex = isNext ? nextMessageIndexExpression : prevMessageIndexExpression;
      }

      messagesRef?.[searchedMessageIndex]?.scrollIntoView({ behavior: "smooth", block: "end" });
      messagesRef?.forEach(el => {
        el.classList.remove(s.backgroundHiglighted);
      });

      setSearchedMessageIdInViewPort(messagesRef?.[searchedMessageIndex]?.id);
      setPrevSearchedMessagesLength(searchedMessagesRefLength);
      return;
    }

    props.getMessages(props.messageList.lastId, searchedValue);
  };

  const handleScroll = () => {
    const scrollOnTop = messageListRef.current?.scrollTop;

    if (!scrollOnTop) {
      setPrevScrollHeight(messageListRef.current?.scrollHeight ?? 0);
      props.getMessages(props.messageList.lastId);
    }
    handleScrollButtonState();
  };

  const getAdditionalMessages = () => {
    const scrollHeight = messageListRef.current?.scrollHeight;
    const clientHeight = messageListRef.current?.clientHeight;
    let isScroll;
    if (scrollHeight && clientHeight) {
      isScroll = scrollHeight > clientHeight;
    }
    if (!isScroll && props.messageList.totalItems > messageListItems.length) {
      setPrevScrollHeight(messageListRef.current?.scrollHeight ?? 0);
      props.getMessages(props.messageList.lastId);
    }
  };

  const handleScrollButtonState = () => {
    if (messageListRef.current) {
      const scrollHeight = messageListRef.current.scrollHeight;
      const scrollTop = messageListRef.current.scrollTop;
      const scrollPosition = scrollHeight - scrollTop;
      const isBottom = scrollPosition < messageListRef.current?.getBoundingClientRect().bottom;
      setScrollButtonState(!isBottom);
    }
  };

  const isMessageSearched = (messageText: string, searchedValue: string) => {
    if (searchedValue) {
      const isMessage = messageText?.toLocaleLowerCase().includes(searchedValue.toLocaleLowerCase());
      return !!isMessage;
    }
  };

  const onSearchValueChange = (searchValue: string) => {
    setSearchedValue(searchValue);
    clearMessageViewPortData();
  };

  const clearMessageViewPortData = () => {
    searchedMessagesRef.current = [];
    setSearchedMessageIdInViewPort("");
    setPrevSearchedMessagesLength(0);
    searchedMessageIndex = undefined;
  };

  const getPreviousMessageDate = (currentIndex: number, messages: MessageModel[]) => {
    try {
      let previousMessage = messages[currentIndex - 1];
      while (previousMessage.typeDiscriminator === MessageTypeDiscriminator.SystemMessage) {
        previousMessage = messages[--currentIndex - 1];
      }

      return previousMessage?.date;
    } catch (e: unknown) {
      return undefined;
    }
  };

  const onReplyClick = (targetMessageId: string): void => {
    const index = messageListItems.findIndex(x => x.id === targetMessageId);
    const messagesRef = messageListRef?.current;
    if (messagesRef) {
      if (!messagesRef.children.item(index)) {
        props.getMessages(props.messageList.lastId, undefined, undefined, targetMessageId);
      } else {
        dispatch(setScrollToMessageId({ messageId: targetMessageId }));
      }
    }
  };

  useEffect(() => {
    const messagesRef = messageListRef?.current;
    if (props.scrollToMessageId && messagesRef) {
      dispatch(setScrollToMessageId({ messageId: undefined }));
      const index = messageListItems.findIndex(x => x.id === props.scrollToMessageId);
      const item = messagesRef.children.item(index);
      if (item) {
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setHighlightedMessages(value => [...value, props.scrollToMessageId!]);
          item?.scrollIntoView({ behavior: "smooth", block: "center" });
          setTimeout(() => {
            setHighlightedMessages(value => [...value.slice(1, value.length)]);
          }, 3000);
        }, 0);
      }
    }
  }, [props.scrollToMessageId]);

  useEffect(() => {
    if (searchedValue && props.messageList.currentSearch) {
      scrollToMessage(false);
      return;
    }
    scrollToPrevMessageList();
    getAdditionalMessages();
    searchedMessagesRef.current = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageListItems[0]]);

  useEffect(() => {
    if (!isScrollButtonActive) {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageListItems[messageListItems.length - 1]]);

  useEffect(() => {
    props.clearSearchParams();
    if (searchedValue) {
      props.getMessages(props.messageList.lastId, searchedValue, searchedMessagesRef.current?.length);
      if (searchedMessagesRef.current?.length) {
        scrollToMessage(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedValue]);

  const convertDate = (message: MessageModel, prevDate: Date | undefined) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const newDate = new Date(message?.date);

    const day = newDate.getDate();
    const month = monthNames[newDate.getMonth()];
    const dateString = `${day} ${t(`${month}'s`)}`;

    if (prevDate === undefined) return dateString;

    prevDate = new Date(prevDate);

    const prevDay = prevDate.getDate();
    const prevMonth = monthNames[prevDate.getMonth()];
    const prevDateString = `${prevDay} ${t(`${prevMonth}'s`)}`;

    if (prevDateString === dateString) {
      return "";
    }

    return dateString;
  };

  return (
    <div ref={messageListRef} className={s.messageList} onScroll={handleScroll}>
      {messageListItems?.map((message: MessageModel, index, array) => {
        switch (message.typeDiscriminator) {
          case MessageTypeDiscriminator.SystemMessage: {
            return (
              <Box
                key={message.id}
                ref={ref => {
                  if (ref) {
                    messagesRef.current?.push(ref);
                  }
                }}
              >
                <SystemMessageItem message={message} />
              </Box>
            );
          }
          case MessageTypeDiscriminator.NoteTextConversation: {
            return (
              <Box
                key={message.id}
                ref={ref => {
                  if (ref) {
                    messagesRef.current?.push(ref);
                  }
                }}
              >
                <Flex justifyContent={"center"} alignItems={"center"} maxH={"56px"} w={"100%"}>
                  <Box className={s.dateString}>{convertDate(message, getPreviousMessageDate(index, array))}</Box>
                </Flex>
                <NoteMessageItem message={message} />
              </Box>
            );
          }
          case MessageTypeDiscriminator.Message:
          default: {
            return (
              <Box
                key={message.id}
                ref={ref => {
                  if (ref) {
                    messagesRef.current?.push(ref);
                  }
                }}
              >
                <Flex justifyContent={"center"} alignItems={"center"} maxH={"56px"} w={"100%"}>
                  <Box className={s.dateString}>{convertDate(message, getPreviousMessageDate(index, array))}</Box>
                </Flex>
                <div
                  id={message.id}
                  ref={
                    isMessageSearched(message.text, searchedValue)
                      ? ref => {
                          if (ref && !searchedMessagesRef.current?.find(el => el.id === ref.id)) {
                            searchedMessagesRef.current?.push(ref);
                          }
                        }
                      : null
                  }
                >
                  <MessageItem
                    onReplyClick={onReplyClick}
                    onReply={props.onReply}
                    highlightedText={isMessageSearched(message.text, searchedValue) ? searchedValue : ""}
                    isInViewport={message.id === searchedMessageIdInViewport}
                    isHighlighted={highlightedMessages.indexOf(message.id) >= 0}
                    message={message}
                    avatars={props.conversationInfo.contact ? props.conversationInfo.contact.avatars : []}
                    userName={message.direction === "Incoming" ? props.conversationInfo.name : "bot"}
                  />
                </div>
              </Box>
            );
          }
        }
      })}

      {isScrollButtonActive && (
        <IconButton
          isRound={true}
          variant={"unstyled"}
          aria-label="Scroll down"
          fontSize="25px"
          icon={<ScrollDownIcon />}
          boxSize={"32px"}
          className={s.downButton}
          onClick={scrollToBottom}
        />
      )}

      {messageListItems?.length !== 0 && (
        <SearchBox
          isDisabled={{
            nextButton:
              !searchedValue ||
              !searchedMessageIdInViewport ||
              (!!searchedMessagesRef.current && searchedMessageIndex === searchedMessagesRef.current?.length - 1),
            prevButton:
              !searchedValue ||
              !searchedMessageIdInViewport ||
              (!props.messageList.isNextSearch &&
                !!searchedMessagesRef.current &&
                searchedMessageIdInViewport === searchedMessagesRef.current[0]?.id),
          }}
          onSearchValueChange={onSearchValueChange}
          scrollToMessage={scrollToMessage}
          searchedValue={searchedValue}
          isSearchedMessages={!searchedValue.length}
          totalSearchItems={props.messageList.totalSearchItems}
        />
      )}
    </div>
  );
};
