import { call, put, takeLatest } from "redux-saga/effects";
import { begin, complete } from "../../common/loading/LoadingStateActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { routerSlice } from "../../common/router/RouterSlice";
import { botListSlice } from "./BotListSlice";
import { BotModel } from "./BotListModel";
import { handleException } from "../../common/SagaHelper";
import { sidebarSlice } from "../sidebar/SidebarSlice";
import { BotListData } from "./BotListData";
import * as BotListApi from "./BotListApi";
import { organisationSlice } from "../organisation/OrganisationSlice";

export function* botListSaga() {
  yield takeLatest(botListSlice.actions.getBotList, getBotList);
  yield takeLatest(botListSlice.actions.createBotNewFlow, createBotNewFlow);
}

function* getBotList(action: PayloadAction<{ page: number; search: string }>) {
  try {
    yield put(begin());
    const data: BotListData = yield call(BotListApi.getBotList, action.payload.page, action.payload.search);
    const model = { ...data };
    yield put(botListSlice.actions.getBotListCompleted(model));
    yield put(organisationSlice.actions.getOrganisation());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* createBotNewFlow(action: PayloadAction<BotModel>) {
  try {
    yield put(begin());
    yield put(sidebarSlice.actions.selectBot(action.payload));
    const draftFlowId = getDraftFlowId("new");
    yield call(removeDraftFlow, draftFlowId);
    yield put(routerSlice.actions.redirect(`automation/flows/new`));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

const removeDraftFlow = (draftFlowId: string) => {
  localStorage.removeItem(draftFlowId);
};

const getDraftFlowId = (flowId: string) => {
  const draftFlowId = `flowData-${flowId}`;
  return draftFlowId;
};
