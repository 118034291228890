import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import s from "../EditContactItem.module.scss";
import { EditContactMobileEditModal } from "../../EditContactMobileEditModal/EditContactMobileEditModal";
import { useTranslation } from "react-i18next";
import { ContactVariableModel } from "../../../../ContactModel";
import { SetStateAction, useState } from "react";
import { CustomVariableType } from "../../../../../../common/AppEnums";
import { ReactComponent as RedTrashIcon } from "../../../../../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../../../../../assets/icons/editIcon.svg";
import DotMenuContainer from "../../../../../../UI/atoms/DotMenu/DotMenuContainer";
import { OverflowEllipsesText } from "../../../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import { ReactComponent as arrayTypeIcon } from "../../../../../../assets/icons/variableTypes/arrayTypeIcon.svg";
import { ReactComponent as textTypeIcon } from "../../../../../../assets/icons/variableTypes/textTypeIcon.svg";
import { ReactComponent as dateTypeIcon } from "../../../../../../assets/icons/variableTypes/dateTypeIcon.svg";
import { ReactComponent as numberTypeIcon } from "../../../../../../assets/icons/variableTypes/numberTypeIcon.svg";
import { ReactComponent as booleanTypeIcon } from "../../../../../../assets/icons/variableTypes/booleanTypeIcon.svg";
import { ReactComponent as catalogTypeIcon } from "../../../../../../assets/icons/variableTypes/catalogTypeIcon.svg";

interface Props {
  contactVar: ContactVariableModel;
  variablesDescription: Record<string, unknown> | undefined;
  getValueInput: (contactVar: ContactVariableModel) => JSX.Element;
  setVariablesDescription: (value: SetStateAction<Record<string, unknown> | undefined>) => void;
  handleOnBlurDescription: (contactVar: ContactVariableModel, variablesDescription: Record<string, unknown> | undefined) => void;
  variableDeleteConfirm: (variable: ContactVariableModel) => void;
  executeMobileModal?: (type: CustomVariableType, contactVar: ContactVariableModel) => void;
}

export const EditContactItemMobile = ({
  contactVar,
  variablesDescription,
  getValueInput,
  setVariablesDescription,
  handleOnBlurDescription,
  variableDeleteConfirm,
  executeMobileModal,
}: Props) => {
  const t = useTranslation("translation", { keyPrefix: "contact" }).t;
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const vt = useTranslation("translation", { keyPrefix: "variableTypes" }).t;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const variableTypeIcons = {
    [CustomVariableType.Text]: textTypeIcon,
    [CustomVariableType.Array]: arrayTypeIcon,
    [CustomVariableType.Number]: numberTypeIcon,
    [CustomVariableType.Date]: dateTypeIcon,
    [CustomVariableType.DateTime]: dateTypeIcon,
    [CustomVariableType.Boolean]: booleanTypeIcon,
    [CustomVariableType.Order]: catalogTypeIcon,
  };

  return (
    <Box>
      <Box key={contactVar.id} className={s.rowMobile}>
        <Box>
          <Text className={s.mobileColName} pt={"16px"} variant={"medium"}>
            {t("Name")}
          </Text>
          <Text className={s.mobileColName} pt={"8px"} variant={"medium"}>
            {t("Type")}
          </Text>
          <Text className={`${s.mobileColName}`} pt={"8px"} variant={"medium"}>
            {t("Value")}
          </Text>
          <Text className={s.mobileColName} pt={"8px"} mb={"16px"} variant={"medium"}>
            {t("Description")}
          </Text>
        </Box>
        <Box>
          <OverflowEllipsesText
            text={contactVar.key}
            content={
              <Text className={s.rowText} pt={"16px"} variant={"medium"}>
                {contactVar.key}
              </Text>
            }
          />
          <Flex alignItems={"center"} pt={"8px"}>
            <Icon mr="10px" fill={"#8592A3"} boxSize="14px" as={variableTypeIcons[contactVar.type]} />
            <Text className={s.rowText} variant={"medium"}>
              {vt(contactVar.type)}
            </Text>
          </Flex>
          <Text pt={2} className={s.rowText} variant={"medium"}>
            {contactVar.value ? contactVar.value : ct("Not Set")}
          </Text>
          <OverflowEllipsesText
            text={variablesDescription && (variablesDescription[contactVar.id] as string)}
            content={
              <Text className={s.rowText} pt={"8px"} mb={"16px"} variant={"medium"} maxWidth={"250px"} isTruncated>
                {variablesDescription && (variablesDescription[contactVar.id] as string)
                  ? (variablesDescription[contactVar.id] as string)
                  : ct("Not Set")}
              </Text>
            }
          />
        </Box>
        <Box pt={"16px"}>
          <DotMenuContainer
            menuItems={[
              {
                MenuItem: {
                  icon: <EditIcon width="18px" height="18px" color={"black"} />,
                  text: ct("Edit"),
                  clickEvent: () => setIsModalOpen(true),
                },
              },
              {
                MenuItem: {
                  icon: <RedTrashIcon width="18px" height="18px" />,
                  text: ct("Delete"),
                  clickEvent: e => {
                    e.stopPropagation();
                    variableDeleteConfirm(contactVar);
                  },
                  color: "mainRed",
                },
              },
            ]}
            MenuHover={{ bg: "line" }}
          />
        </Box>
      </Box>
      <EditContactMobileEditModal
        onClose={closeModal}
        isOpen={isModalOpen}
        contactVar={contactVar}
        getValueInput={getValueInput}
        variablesDescription={variablesDescription}
        setVariablesDescription={setVariablesDescription}
        handleOnBlurDescription={handleOnBlurDescription}
        executeMobileModal={executeMobileModal}
      />
    </Box>
  );
};
