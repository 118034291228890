import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { useBrowserFitContent, useTabletView } from "../../../LayoutHelper/ResolutionHooks";
import { EditBroadcastHeader } from "../../../../broadcast/components/BroadcastHeader/EditBroadcastHeader";
import { Broadcast } from "../../../../broadcast/components/Broadcast/Broadcast";

export const EditBroadcastPage = () => {
  const browserFill = useBrowserFitContent();
  const isTablet = useTabletView();
  const [mobileView, isMobileView] = useState(isTablet);

  useEffect(() => {
    if (isTablet !== mobileView) {
      isMobileView(isTablet);
    }
  }, [mobileView, isTablet]);

  if (mobileView) {
    return (
      <Flex flexDirection={"column"} height={"100%"}>
        <ProtectedHeaderContainer>
          <EditBroadcastHeader />
        </ProtectedHeaderContainer>
        <Box bg="bgLight" height={window.innerHeight - 60}>
          <Loading />
          <Broadcast isNew={false} />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <EditBroadcastHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={browserFill}>
        <Loading />
        <Broadcast isNew={false} />
      </Box>
    </Flex>
  );
};
