import { Button, Container, Flex, FormControl } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BroadcastFormModel } from "../../../../BroadcastModel";
import { BroadcastState } from "../../../../BroadcastSlice";
import { useGAWithCustomParameters } from "../../../../../../common/ga/GAEventTracker";
import dayjs from "dayjs";
import s from "../../Broadcast.module.scss";

interface CommonProps {
  values: BroadcastFormModel;
  titleInvalid: boolean;
  messageInvalid: boolean;
  wasSelectAllChecked: boolean;
  isNew: boolean;
}

interface EditProps extends CommonProps {
  dirty: boolean;
  setExecuteState: React.Dispatch<React.SetStateAction<{ execute: boolean; dirty: boolean }>>;
  onBroadcastDeleteClick: (id: string, itemName: string) => void;
  isValid: boolean;
  broadcastState: BroadcastState;
}

interface NewProps extends CommonProps {
  onSaveDraft: () => void;
  onExecute: () => void;
}

type Props = EditProps | NewProps;

export const BroadcastFormButtons = (props: Props) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const trackEvent = useGAWithCustomParameters("Broadcast");

  const isValidDate = props.values.isScheduled && dayjs().isAfter(dayjs(props.values.scheduledDate));

  const commonValidation = props.values.isScheduled
    ? !props.titleInvalid && !props.messageInvalid && props.values.scheduledDate && !isValidDate
    : !props.titleInvalid && !props.messageInvalid;

  const validateExecution = () => {
    if (props.wasSelectAllChecked) {
      return !(commonValidation && props.wasSelectAllChecked);
    } else {
      return !(commonValidation && props.values.recipients?.length !== 0);
    }
  };

  const renderButtons = () => {
    if (props.isNew) {
      return (
        <>
          <Button
            size="sm"
            variant="dominoOutlineViolet"
            type="submit"
            isDisabled={validateExecution()}
            onClick={() => {
              trackEvent("Broadcast", "", {
                broadcast_event: "Save",
              });
              (props as NewProps).onSaveDraft();
            }}
            data-pw="broadcast-save-draft-button"
          >
            {t("Save draft")}
          </Button>
          <Button
            data-pw="broadcast-start-button"
            ml="16px"
            size="sm"
            variant="dominoViolet"
            type="submit"
            isDisabled={validateExecution()}
            onClick={() => {
              trackEvent("Broadcast", "", { broadcast_event: "Start" });
              (props as NewProps).onExecute();
            }}
          >
            {props.values?.isScheduled ? t("Schedule broadcast") : t("Start broadcast")}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            isDisabled={validateExecution()}
            size="sm"
            variant="dominoOutlineViolet"
            type="submit"
            onClick={() => {
              trackEvent("Broadcast", "", {
                broadcast_event: "Save",
                total_contacts_count: (props as EditProps).broadcastState.broadcast?.recipientsCount,
                select_contacts_count: (props as EditProps).broadcastState.contactList?.totalItems,
                count: (props as EditProps).broadcastState.broadcast?.message.buttons.length,
              });
              (props as EditProps).setExecuteState({
                execute: false,
                dirty: (props as EditProps).dirty,
              });
            }}
            data-pw="broadcast-save-button"
          >
            {ct("Save")}
          </Button>
          <Button
            data-pw="broadcast-start-button"
            isDisabled={validateExecution()}
            size="sm"
            ml="16px"
            variant="dominoViolet"
            type="submit"
            onClick={() => {
              trackEvent("Broadcast", "", {
                broadcast_event: "Start",
                total_contacts_count: (props as EditProps).broadcastState.broadcast?.recipientsCount,
                select_contacts_count: (props as EditProps).broadcastState.contactList?.totalItems,
                count: (props as EditProps).broadcastState.broadcast?.message.buttons.length,
              });
              (props as EditProps).setExecuteState({
                execute: true,
                dirty: (props as EditProps).dirty,
              });
            }}
          >
            {props.values.isScheduled ? t("Schedule broadcast") : t("Start broadcast")}
          </Button>
          <Button
            size="sm"
            variant="dominoOutlineRed"
            ml="16px"
            onClick={e => {
              trackEvent("Broadcast", "", { broadcast_event: "Delete" });
              e.stopPropagation();
              (props as EditProps).onBroadcastDeleteClick(
                (props as EditProps).broadcastState?.broadcast?.id ?? "",
                (props as EditProps).broadcastState?.broadcast?.name ?? "",
              );
            }}
            data-pw="broadcast-remove-button"
          >
            {ct("Remove")}
          </Button>
        </>
      );
    }
  };

  return (
    <Container backgroundColor="none" variant="dominoContentBlock">
      <FormControl
        style={{ paddingTop: "0px" }}
        className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveButton}`}
      >
        <div />
        <Flex className={s.broadcastFlexButton}>{renderButtons()}</Flex>
      </FormControl>
    </Container>
  );
};
