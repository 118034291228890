import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { Tour, OnboardingState } from "./OnboardingToursState";

export const initialState: OnboardingState = {};

export const onboardingSlice = createSlice({
  name: "onboardingSlice",
  initialState,
  reducers: {
    setTour: (state, action: PayloadAction<Tour[]>) => {
      return { ...state, tours: action.payload };
    },
    startTour: (state, action: PayloadAction<string>) => {
      const existingTour = state?.tours?.find(tour => tour.tourName === action.payload);
      if (existingTour) {
        return {
          ...state,
          tours: state?.tours?.map(tour => (tour.tourName === action.payload ? { ...tour, run: true, isSkip: false } : tour)),
        };
      } else {
        return {
          ...state,
          tours: state.tours
            ? [...state.tours, { tourName: action.payload, isCompleted: false, run: true, isSkip: false }]
            : [{ tourName: action.payload, isCompleted: false, run: true, isSkip: false }],
        };
      }
    },
    skipTour: (state, action: PayloadAction<string>) => {
      const tour = state?.tours?.find(t => t.tourName === action.payload);
      if (tour) {
        tour.run = false;
        tour.isSkip = true;
      }
    },
    completeTour: (state, action: PayloadAction<string>) => {
      const tour = state?.tours?.find(t => t.tourName === action.payload);
      if (tour) {
        tour.isCompleted = true;
        tour.run = false;
        tour.isSkip = false;
      }
    },
  },
});

export const { startTour, completeTour, setTour, skipTour } = onboardingSlice.actions;

export const selectTourByName = (state: RootState, tourName: string) =>
  state.app.onboardingState?.tours?.find((t: Tour) => t.tourName === tourName);
export const selectTours = (state: RootState) => state.app.onboardingState.tours;

export default onboardingSlice.reducer;
