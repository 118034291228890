import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Show,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { ReactComponent as FillPaperIcon } from "../../../../assets/icons/fillPaper.svg";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import s from "./FlowTemplatesModal.module.scss";
import { addNewFlow, getFlowTemplates, selectFlowTemplates } from "../../AutomationSlice";
import FlowTemplateItem from "./FlowTemplateItem/FlowTemplateItem";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const FlowTemplatesModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList.flowTemplates" });
  const dispatch = useAppDispatch();
  const flowTemplates = useAppSelector(selectFlowTemplates);
  const trackEvent = useGAWithCustomParameters("Flow");

  useEffect(() => {
    if (!flowTemplates && isOpen) {
      dispatch(getFlowTemplates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleNewFlow = () => {
    dispatch(addNewFlow("new"));
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay zIndex={10001} />
      <ModalContent
        containerProps={{
          zIndex: "10001",
        }}
        className={s.modalContent}
        data-pw="modal"
      >
        <Show breakpoint="(min-width: 768px)">
          <ModalHeader p={{ base: "24px", sm: "32px" }}>
            <Text variant="h1">{t("Choose one of our Templates or create a Flow from scratch")}</Text>
          </ModalHeader>
        </Show>
        <Show breakpoint="(max-width: 768px)">
          <ModalHeader p={{ base: "24px", sm: "32px" }}>
            <Text variant="h1" fontSize={"19px"} lineHeight={"25px"}>
              {t("Choose one of our Templates or create a Flow from scratch")}
            </Text>
          </ModalHeader>
        </Show>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody
          zIndex="140000"
          overflowY={"auto"}
          px={{ base: "24px", sm: "32px" }}
          pb={{ base: "24px", sm: "32px" }}
          py="0"
          className={s.modalBody}
        >
          <Flex position="relative" flexWrap={"wrap"} gap="24px">
            <Flex
              className={s.startFlow}
              onClick={() => {
                trackEvent("FlowCreate");
                handleNewFlow();
              }}
              data-pw="start-new-flow"
            >
              <FillPaperIcon className={s.startFlowPaper} />
              <Text variant="largeBold">{t("Start from scratch")}</Text>
            </Flex>

            {!flowTemplates ? (
              <>
                {[1, 2, 3].map(el => (
                  <Skeleton
                    speed={0.5}
                    startColor="line"
                    endColor="bgLight"
                    height="220px"
                    width="200px"
                    borderColor="line"
                    borderRadius="8px"
                    key={el}
                  />
                ))}
              </>
            ) : (
              flowTemplates.map(el => (
                <FlowTemplateItem key={el.id} id={el.id} icon={el.thumbnailUrl} label={el.name} description={el.description} />
              ))
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
