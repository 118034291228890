import { Grid, GridItem } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import React, { useRef } from "react";
import { FlowBuilderContainer } from "../../../../../flowBuilder/FlowBuilderContainer";
import { FlowBuilderHeader } from "../../../../../flowBuilder/components/Header/FlowBuilderHeader";
import { Loading } from "../../../../../../common/loading/LoadingStateContainer";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import { selectFlow, setEdges, setNodes } from "../../../../../flowBuilder/FlowBuilderSlice";

export const FlowBuilderPage = () => {
  const dispatch = useAppDispatch();

  const flow = useAppSelector(selectFlow);
  const connectingNodeId = useRef<{ current: string; handleId?: string } | null>(null);

  const onNodeAccordionDelete = () => {
    if (!connectingNodeId.current?.current && flow) {
      const updatedNodes = flow.nodes.filter(el => el.id !== "creation_menu");
      const updatedEdges = flow.edges.filter(el => el.target !== "creation_menu" && el.source !== "creation_menu");

      dispatch(setNodes(updatedNodes));
      dispatch(setEdges(updatedEdges));
    } else {
      connectingNodeId.current = { current: "" };
    }
  };

  return (
    <Grid gridTemplateColumns="1fr" templateRows={{ base: "60px 1fr", md: "60px calc(100vh - 60px);" }}>
      <GridItem>
        <ProtectedHeaderContainer>
          <FlowBuilderHeader />
        </ProtectedHeaderContainer>
      </GridItem>
      <GridItem
        bg="bgLight"
        position={"relative"}
        rowSpan={[2, 2, 2, 1]}
        colSpan={[2, 2, 1]}
        overflow={{ base: "hidden", lg: "visible" }}
      >
        <Loading scope="flowBuilder" />
        <FlowBuilderContainer flow={flow} connectingNodeId={connectingNodeId} onNodeAccordionDelete={onNodeAccordionDelete} />
      </GridItem>
    </Grid>
  );
};
