import { useSelector } from "react-redux";
import { ApplicationState } from "../../state/ApplicationState";

export const useGetLoadingState = (scope: string) => {
  const loadingState = useSelector((state: ApplicationState) => state.loadingState);
  let showSpinner = false;

  if (loadingState) {
    const currentScopeState = loadingState[scope];

    if (currentScopeState) {
      showSpinner = currentScopeState.isLoading;
    }
  }
  return showSpinner;
};
