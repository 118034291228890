import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  Flex,
  Box,
  Text,
  Icon,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useMediaQuery,
  PopoverBody,
} from "@chakra-ui/react";
import { Form, Field, useFormikContext } from "formik";
import { ReactComponent as StarPlan } from "../../../../assets/icons/starPlan.svg";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import s from "./Broadcast.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { BroadcastFormModel, BroadcastMessageTypeDescriminator, FileUploadType } from "../../BroadcastModel";
import {
  BroadcastState,
  selectContactList,
  selectCurrentFile,
  selectCustomVariables,
  uploadBroadcastFile,
} from "../../BroadcastSlice";
import { RecipientList } from "../../components/ContactList/RecipientList";
import { ComplexFilter } from "../../../complexFilter/ComplexFilter";
import { LeaveConfirmationPopup } from "../LeaveConfirmationPopup/LeaveConfirmationPopup";
import {
  ComplexFilterGroupTypes,
  ComplexFilterFieldTypes,
  ConditionsByField,
  FilterParams,
} from "../../../complexFilter/ComplexFilterModel";
import { CustomVariableScope, TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { BroadcastFormTitle } from "../Broadcast/components/BroadcastFormTitle/BroadcastFormTitle";
import { BroadcastFormMessage } from "../Broadcast/components/BroadcastFormMessage/BroadcastFormMessage";
import { BroadcastFormButtons } from "../Broadcast/components/BroadcastFormButtons/BroadcastFormButtons";

interface CommonProps {
  getContacts: (
    queryFilterString: string | undefined,
    queryParamsString: string | undefined,
    isFilterActive: boolean,
    isOnScrollPayload?: boolean,
  ) => void;
  wasSelectAllChecked: boolean;
  setWasSelectAllChecked: Dispatch<React.SetStateAction<boolean>> | Dispatch<boolean>;
  isNew: boolean;
  broadcastRecipientsFilters?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
}

interface EditProps extends CommonProps {
  setExecuteState: Dispatch<
    SetStateAction<{
      execute: boolean;
      dirty: boolean;
    }>
  >;
  onBroadcastDeleteClick: (id: string, itemName: string) => void;
  broadcastState: BroadcastState;
  broadcastRecipientsFilters?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
}

interface NewProps extends CommonProps {
  setIsExecute: Dispatch<SetStateAction<boolean>>;
}

type Props = EditProps | NewProps;

export const BroadcastForm = (props: Props) => {
  const { values, setFieldValue, dirty, isSubmitting, isValid } = useFormikContext<BroadcastFormModel>();
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const accessibility = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.BroadcastContacts);
  const { MaxCount, IsUnlimited } = accessibility;

  const dispatch = useAppDispatch();
  const currentFile = useAppSelector(selectCurrentFile);
  const variables = useAppSelector(selectCustomVariables);
  const contactList = useAppSelector(selectContactList);
  const recipientList = useSelector((state: RootState) => state.app.broadcastState.recipientList);
  const inputRef = useRef<HTMLInputElement>(null);
  const titleInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [titleInvalid, setTitleInvalid] = useState(props.isNew ? true : false);
  const [messageInvalid, setMessageInvalid] = useState(props.isNew ? true : false);
  const [isFilterActive, setFilterActive] = useState(false);
  const [isFirstInit, setFirstInit] = useState(true);
  const [isMobile] = useMediaQuery("(max-width: 800px)");

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const uploadFile = (file: File, type: FileUploadType) => {
    dispatch(uploadBroadcastFile({ file: file, type }));
  };

  const createFieldsToFilter = () => {
    let contactList = [
      { fieldName: "Id", fieldValue: "id" },
      { fieldName: "Username", fieldValue: "username" },
      { fieldName: "First Name", fieldValue: "firstName" },
      { fieldName: "Last Name", fieldValue: "lastName" },
      { fieldName: "Phone Number", fieldValue: "phoneNumber" },
    ];
    contactList = contactList.map(el => {
      return {
        ...el,
        type: ComplexFilterFieldTypes.Text,
        fieldName: t(`fieldToFilter.${el.fieldName}`),
        groupType: ComplexFilterGroupTypes.ContactFields,
      };
    });

    const groupList = [{ label: t("Contacts"), items: contactList }];

    if (variables?.length) {
      const contactVariableList = variables
        ?.filter(el => ConditionsByField.allowedFieldTypes.includes(el.type) && el.scope === CustomVariableScope.Contact)
        .map(el => {
          return {
            fieldName: el.key,
            fieldValue: el.key,
            type: el.type,
            groupType: ComplexFilterGroupTypes.CustomVariables,
            description: el.description,
            id: el.id,
          };
        });
      groupList.push({ label: t("Custom Contact Fields"), items: contactVariableList });
    }

    return { groups: groupList };
  };

  const checkIsSameFile = () => {
    if (
      (props as EditProps).broadcastState?.broadcast?.message.typeDiscriminator !==
      BroadcastMessageTypeDescriminator.BroadcastTextMessage
    ) {
      return (props as EditProps).broadcastState?.broadcast?.message.fileId === currentFile?.file.fileId;
    } else {
      return !currentFile;
    }
  };

  const onExecute = () => {
    console.log("onExecute");

    if (titleInputRef.current && textareaRef.current) {
      values.name = titleInputRef.current.value;
      values.message.text = textareaRef.current.value;
      (props as NewProps).setIsExecute(true);
    }
  };

  const onSaveDraft = () => {
    console.log("onSaveDraft");
    if (titleInputRef.current && textareaRef.current) {
      values.name = titleInputRef.current.value;
      values.message.text = textareaRef.current.value;
      (props as NewProps).setIsExecute(false);
    }
  };

  const getActualSelectAllState = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.value = values.message.text;
    }
    return (props as EditProps).broadcastState?.broadcast?.sentForAll;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.message.text]);

  return (
    <Form>
      <BroadcastFormTitle
        handleKeyPress={handleKeyPress}
        setFieldValue={setFieldValue}
        setTitleInvalid={setTitleInvalid}
        inputRef={inputRef}
        titleInvalid={titleInvalid}
        titleInputRef={titleInputRef}
        isNew={props.isNew}
      />
      <Container variant="dominoContentBlock">
        <Field name="recipients" display="grid" gridTemplateColumns="auto auto">
          {() => (
            <>
              <FormControl
                className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveContactList}`}
              >
                {!IsUnlimited && contactList?.totalItems && contactList?.totalItems > MaxCount ? (
                  <Flex flexDir="column">
                    <Box w="max-content">
                      <Popover trigger={"hover"}>
                        {({ onClose }) => (
                          <>
                            <PopoverTrigger>
                              <Flex className={sp.boxHover}>
                                <Box>
                                  <FormLabel>{t("Contacts list")}</FormLabel>
                                </Box>
                                <Box mt="2px">
                                  <Icon as={StarPlan} boxSize="20px" ml="-4px" />
                                </Box>
                              </Flex>
                            </PopoverTrigger>
                            <Portal>
                              <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                                <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                                <Flex alignItems="center" justifyContent="center" direction="column">
                                  <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                                    {tp("Advanced feature")}
                                  </PopoverHeader>
                                  <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                                    {tp("Please upgrade your plan to select more recipients")}
                                  </PopoverBody>
                                  <TariffPlanRedirectButton onClose={onClose} />
                                </Flex>
                              </PopoverContent>
                            </Portal>
                          </>
                        )}
                      </Popover>
                    </Box>
                    <Box w="60%">
                      <Text fontSize={15} color={"#8592A3"}>
                        {tp(`No more than ${MaxCount} recipients on your current plan`)}
                      </Text>
                    </Box>
                  </Flex>
                ) : (
                  <FormLabel>{recipientList?.recipients ? t("Recipients list") : t("Contacts list")}</FormLabel>
                )}
                <RecipientList
                  getContacts={props.getContacts}
                  isFilterActive={isFilterActive}
                  wasSelectAllChecked={props.wasSelectAllChecked}
                  setWasSelectAllChecked={props.setWasSelectAllChecked}
                  isFirstInit={isFirstInit}
                  setFirstInit={setFirstInit}
                  getActualSelectAllState={getActualSelectAllState}
                  broadcastRecipientsFilter={props.broadcastRecipientsFilters}
                  setBroadcastFilter={props.setBroadcastFilter}
                  isNewBroadcast={props.isNew}
                />
                {variables && (
                  <Flex className={s.flexFilter}>
                    <ComplexFilter
                      placement={isMobile ? "bottom-end" : undefined}
                      classNameIcon={
                        !IsUnlimited && contactList?.totalItems && contactList?.totalItems > MaxCount
                          ? s.iconFilterTarif
                          : s.iconFilter
                      }
                      filterFields={createFieldsToFilter()}
                      getItemsWithFilter={props.getContacts}
                      isActive={isFilterActive}
                      setIsActive={setFilterActive}
                      componentSlice="Broadcast"
                      broadcastRecipientsFilters={props.broadcastRecipientsFilters}
                      setBroadcastFilter={props.setBroadcastFilter}
                      setWasSelectAllChecked={props.setWasSelectAllChecked}
                      setFieldValue={setFieldValue}
                    />
                  </Flex>
                )}
              </FormControl>
            </>
          )}
        </Field>
      </Container>
      <BroadcastFormMessage
        values={values}
        variables={variables}
        textareaRef={textareaRef}
        messageInvalid={messageInvalid}
        setMessageInvalid={setMessageInvalid}
        setFieldValue={setFieldValue}
        uploadFile={uploadFile}
      />
      <BroadcastFormButtons
        values={values}
        titleInvalid={titleInvalid}
        messageInvalid={messageInvalid}
        wasSelectAllChecked={props.wasSelectAllChecked}
        onSaveDraft={onSaveDraft}
        onExecute={onExecute}
        dirty={dirty || !checkIsSameFile()}
        setExecuteState={(props as EditProps).setExecuteState}
        onBroadcastDeleteClick={(id, name) => (props as EditProps).onBroadcastDeleteClick(id, name)}
        isValid={isValid}
        broadcastState={(props as EditProps).broadcastState}
        isNew={props.isNew}
      />
      <LeaveConfirmationPopup dirty={!isSubmitting && (dirty || (props.isNew ? !!currentFile : !checkIsSameFile()))} />
    </Form>
  );
};
