import { Box, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import s from "./BotHeader.module.scss";
import { useTranslation } from "react-i18next";
import { BackButton } from "../../../backButton/BackButton";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export const BotHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "bot.botHeader" });
  const navigate = useNavigate();
  const [locationChangesIndex, setLocationChangesIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    setLocationChangesIndex(x => x - 1);
  }, [location]);

  return (
    <Box className={s.headerContainer}>
      <Box className={s.main}>
        <BackButton onButtonClick={() => navigate(locationChangesIndex)} />
        <Text variant="h2" color="midDeepBlue" py="16px">
          {t("Edit Bot")}
        </Text>
      </Box>
    </Box>
  );
};
