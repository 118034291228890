import {
  Text,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Badge,
  Highlight,
  Input,
  Link,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../common/state/store";
import { BotModel } from "../../BotModel";
import { changeBotToken } from "../../BotSlice";
import s from "../BotInfo/BotInfo.module.scss";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  botInfo: BotModel | undefined;
}

export const RefreshTokenPopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "bot.refreshTokenPopup",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();

  const [tokenText, setTokenText] = useState("");

  const refreshToken = () => {
    if (props.botInfo) {
      dispatch(changeBotToken({ botInfo: props.botInfo, token: tokenText }));
      props.onClose();
    }
  };

  const trackEvent = useGAWithCustomParameters("BotDetails");

  useEffect(() => {
    setTokenText("");
  }, [props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent minW={"300px"} p={{ base: "16px", md: "32px" }} w={"95%"} maxW={"460px"}>
        <ModalHeader p="0">
          <Text variant="h1">{t("Refresh bot token")}</Text>
        </ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }} />
        <ModalBody p="0">
          <Flex pt="32px" pb="24px" direction="column" gap="16px">
            <Flex gap="16px">
              <Badge variant="dominoNumberList">1</Badge>
              <Text variant="medium">
                {t("Open")}{" "}
                <Link color="blueLink" href="https://t.me/BotFather" isExternal className={s.link}>
                  @BotFather
                </Link>{" "}
                {t("in Telegram and click /start")}
              </Text>
            </Flex>
            <Flex gap="16px">
              <Badge variant="dominoNumberList">2</Badge>
              <Text variant="medium">
                <Highlight query={["/revoke", `@${props.botInfo?.username}`]} styles={{ fontWeight: "700" }}>
                  {`${t("Send /revoke and choose")} @${props.botInfo?.username} ${t("to generate a new token")}.`}
                </Highlight>
                <Text color={"#EB5038"}>{t("Warning: your old token will stop working")}</Text>
              </Text>
            </Flex>
            <Flex gap="16px">
              <Badge variant="dominoNumberList">3</Badge>
              <Text variant="medium">{`${t(
                "You will receive message with a new token. Copy this token and paste it here",
              )}`}</Text>
            </Flex>
          </Flex>
          <Text pb="8px">{t("New telegram bot token")}</Text>
          <Input placeholder={t("Enter token") ?? ""} value={tokenText} onChange={e => setTokenText(e.target.value)} />
        </ModalBody>

        <ModalFooter p="32px 0 0 0">
          <Flex w="100%" justifyContent="space-between" alignItems={"flex-end"}>
            <Button variant="dominoOutlineViolet" onClick={props.onClose}>
              {ct("Cancel")}
            </Button>
            <Button
              variant="dominoViolet"
              isDisabled={tokenText.trim().length === 0}
              onClick={() => {
                trackEvent("BotRefresh_Token");
                refreshToken();
              }}
            >
              {t("Refresh")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
