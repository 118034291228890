import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  FormControl,
  Heading,
  Icon,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  OrderedList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import {
  FlowActionModel,
  NodeModel,
  NodeValidationProp,
  PaymentFlowActionModel,
  PaymentProviderType,
} from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, CloseIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import DurationEditor from "../DurationEditor/DurationEditor";
import { ReactComponent as ImageIconGrey } from "../../../../../assets/icons/imageIcon.svg";
import { ReactComponent as questionNodeIcon } from "../../../../../assets/icons/questionNodeIcon.svg";

import s from "./PaymentNodePopup.module.scss";
import sm from "../NodeEditPopup.module.scss";
import { Fallback } from "../Fallback";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { editNode, postFile, selectCustomVariables, selectNodeEditorState } from "../../../FlowBuilderSlice";

import InputVariable from "../../../../../UI/molecules/InputVariable/InputVariable";
import { CustomVariableModel, CustomVariableScope, CustomVariableType } from "../../../../../common/AppEnums";
import { insertStringIntoText } from "../../../../../common/utils/insertStringIntoText";
import { maxLength } from "../../../../../common/validation/defaultValidators";
import { notify } from "../../../../../common/notifications/NotificationSlice";
import { useState } from "react";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { AppSettings } from "../../../../../common/AppSettings";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";

declare const appSettings: AppSettings;

interface Props {
  flowAction: FlowActionModel;
  node: NodeModel;
  getFlowListPage: (page: number, filter?: string) => void;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  addCustomVariableText: (variable: CustomVariableModel, variableMaxLength: number) => void;
  onClose: () => void;
}

export default function PaymentNodePopup(props: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.flowAction as PaymentFlowActionModel;
  const flowAction = {
    ...data,
    order: {
      isAll: data.order ? data.order.isAll : false,
      needEmail: data.order ? data.order.needEmail : false,
      needName: data.order ? data.order.needName : false,
      needPhoneNumber: data.order ? data.order.needPhoneNumber : false,
      needShippingAddress: data.order ? data.order.needShippingAddress : false,
    },
    price: { amount: data.price?.amount || "", currency: data.price?.currency || "RUB" },
    paymentProvider: data.paymentProvider ?? PaymentProviderType.Yookassa,
  } as PaymentFlowActionModel;

  const ImageFieldTypeExt = ".jpeg,.jpg,.png";
  const currentNode = useAppSelector(selectNodeEditorState);
  const dispatch = useAppDispatch();

  const variables = useAppSelector(selectCustomVariables);
  const [imageUrlPopupState, setImageUrlPopupState] = useState(false);
  const [imageUrlState, setImageUrlState] = useState(flowAction.fileUrl ?? "");

  const [showBtnDone, setshowBtnDone] = useState(false);
  const [isChangeUrl, setChangeUrl] = useState(false);
  const [isOpenQuestionHeader, setIsOpenQuestionHeader] = useState(false);
  const [disabledPrice, setDisabledPrice] = useState<string | null>(null);

  if (!currentNode) {
    return <></>;
  }

  const setImageUrl = () => {
    if (validateUrl(imageUrlState)) {
      addImageUrl(imageUrlState);
      setChangeUrl(false);
    }
  };

  const addImageUrl = (imageUrl: string) => {
    const updatedData: PaymentFlowActionModel = {
      ...flowAction,
      file: undefined,
      fileUrl: imageUrl.length ? imageUrl : undefined,
    };
    dispatch(
      editNode({
        ...currentNode,
        flowAction: updatedData,
      }),
    );
  };

  const selectCurrenct = (type: string) => {
    props.onDataChange({
      ...flowAction,
      price: { ...flowAction.price, currency: type },
    } as PaymentFlowActionModel);
  };

  const changeFiscalization = (value: boolean) => {
    const details = value ? Array.from(new Set([...(flowAction.details ?? []), "Email"])) : flowAction.details;
    console.log(details);
    props.onDataChange({
      ...flowAction,
      fiscalization: value,
      details: details,
      paymentProvider: value ? PaymentProviderType.Yookassa : flowAction.paymentProvider,
      order: {
        ...flowAction.order,
        needEmail: (details?.indexOf("Email") ?? -1) >= 0,
      },
    } as PaymentFlowActionModel);
  };

  const selectPaymentProvider = (paymentProvider: PaymentProviderType) => {
    props.onDataChange({
      ...flowAction,
      paymentProvider: paymentProvider,
      fiscalization: paymentProvider === PaymentProviderType.Unknown ? false : flowAction.fiscalization,
    } as PaymentFlowActionModel);
  };

  const validateUrl = (url: string) => {
    if (url.length) {
      /* eslint-disable-next-line no-useless-escape */
      const urlRegex =
        // eslint-disable-next-line no-useless-escape
        /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_,\+.~#?&\/=]*)$/;
      return urlRegex.test(url);
    }
    return true;
  };

  const selectAllDetails = () => {
    if (flowAction.order.isAll) {
      props.onDataChange({
        ...flowAction,
        order: {
          isAll: false,
          needEmail: flowAction.fiscalization,
          needName: false,
          needPhoneNumber: false,
          needShippingAddress: false,
        },
        details: undefined,
      } as PaymentFlowActionModel);
    } else {
      props.onDataChange({
        ...flowAction,
        order: { isAll: true, needEmail: true, needName: true, needPhoneNumber: true, needShippingAddress: true },
        details: ["Name", "Email", "Phone", "Address"],
      } as PaymentFlowActionModel);
    }
  };

  const addDetail = (name: string) => {
    const filtersDetails = flowAction.details ? [...flowAction.details, name] : [name];
    const needEmail = name === "Email" ? true : flowAction.order.needEmail;
    const needName = name === "Name" ? true : flowAction.order.needName;
    const needPhoneNumber = name === "Phone" ? true : flowAction.order.needPhoneNumber;
    const needShippingAddress = name === "Address" ? true : flowAction.order.needShippingAddress;
    props.onDataChange({
      ...flowAction,
      order: {
        isAll: needEmail && needName && needPhoneNumber && needShippingAddress,
        needEmail: needEmail,
        needName: needName,
        needPhoneNumber: needPhoneNumber,
        needShippingAddress: needShippingAddress,
      },
      details: filtersDetails,
    } as PaymentFlowActionModel);
  };

  const removeDetail = (name: string) => {
    const filtersDetails = flowAction.details?.filter(el => el !== name);
    props.onDataChange({
      ...flowAction,
      order: {
        isAll: false,
        needEmail: name === "Email" ? false : flowAction.order.needEmail,
        needName: name === "Name" ? false : flowAction.order.needName,
        needPhoneNumber: name === "Phone" ? false : flowAction.order.needPhoneNumber,
        needShippingAddress: name === "Address" ? false : flowAction.order.needShippingAddress,
      },
      details: filtersDetails,
    } as PaymentFlowActionModel);
  };

  const addCustomVariableDescription = (variable: CustomVariableModel, inputMaxLength: number, cursorPosition?: number) => {
    const textContainer = props.node.flowAction as PaymentFlowActionModel;
    const text = insertStringIntoText(
      textContainer.description ?? "",
      "@{" + variable.scope + ":" + variable.key + "}",
      cursorPosition,
    );
    const lengthError = maxLength(inputMaxLength)(text);
    if (!lengthError) {
      props.onDataChange({ ...textContainer, description: text } as PaymentFlowActionModel);
    }
  };

  const addCustomVariableToken = (variable: CustomVariableModel, cursorPosition?: number) => {
    const textContainer = props.node.flowAction as PaymentFlowActionModel;
    const text = insertStringIntoText(
      textContainer.accessToken ?? "",
      "@{" + variable.scope + ":" + variable.key + "}",
      cursorPosition,
    );

    props.onDataChange({
      ...textContainer,
      accessToken: text,
    } as PaymentFlowActionModel);
  };

  const addCustomVariablePrice = (variable: CustomVariableModel, cursorPosition?: number) => {
    const textContainer = props.node.flowAction as PaymentFlowActionModel;
    const text = insertStringIntoText(
      textContainer.price?.amount ?? "",
      "@{" + variable.scope + ":" + variable.key + "}",
      cursorPosition,
    );

    props.onDataChange({ ...textContainer, price: { ...flowAction.price, amount: text } } as PaymentFlowActionModel);
  };

  const onDataChange = (text: string, file?: File) => {
    const fileSizeMB = 10;
    const fileSizeB = fileSizeMB * 1024 * 1024;
    const extentsion = file?.name.split(".").pop();

    if (file?.size && file.size > fileSizeB) {
      dispatch(notify({ message: "File is too big", type: "error" }));
      return;
    }

    if (!ImageFieldTypeExt.includes(extentsion ?? "")) {
      dispatch(notify({ message: "This file format isn’t supported", type: "error" }));
      return;
    }

    if (file && currentNode) {
      dispatch(postFile({ file, nodeId: currentNode.id }));
      return;
    }

    const newData: PaymentFlowActionModel = {
      ...flowAction,
      text,
    };

    dispatch(
      editNode({
        ...currentNode,
        flowAction: newData,
      }),
    );
  };

  const deleteImageUrl = () => {
    const updatedData: PaymentFlowActionModel = {
      ...flowAction,
      file: undefined,
      fileUrl: undefined,
    };

    dispatch(
      editNode({
        ...currentNode,
        flowAction: updatedData,
      }),
    );

    setImageUrlPopupState(false);
    setImageUrlState("");
  };

  const deleteImageFile = () => {
    const updatedData: PaymentFlowActionModel = {
      ...flowAction,
      fileId: undefined,
    };

    dispatch(
      editNode({
        ...currentNode,
        flowAction: updatedData,
      }),
    );
  };

  const changePrice = (text: string) => {
    const numberRegex =
      // eslint-disable-next-line no-useless-escape
      /^\d+$/;
    if (!numberRegex.test(text) && text.length > 0) {
      return;
    }

    props.onDataChange({
      ...flowAction,
      price: { ...flowAction.price, amount: text },
    } as PaymentFlowActionModel);
  };

  const chosenCatalogCustomVariable = variables?.find(el => {
    const targetCustomVariableId = flowAction.catalog?.customVariableId;
    return el.id === targetCustomVariableId;
  });

  const addCustomVariableCatalog = (variable: CustomVariableModel) => {
    const textContainer = props.node.flowAction as PaymentFlowActionModel;
    setDisabledPrice(textContainer.price?.amount);
    props.onDataChange({
      ...textContainer,
      catalog: { customVariableId: variable.id },
      price: { amount: null, currency: "RUB" },
    } as PaymentFlowActionModel);
  };

  const clearCustomVariableCatalog = () => {
    dispatch(
      editNode({
        ...props.node,
        flowAction: {
          ...flowAction,
          price: { amount: disabledPrice ?? "", currency: "RUB" },
          catalog: null,
        } as PaymentFlowActionModel,
      }),
    );
  };

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={props.onClose} variant="dominoDrawer">
        <DrawerContent>
          <Flex h="44px" gap="8px">
            <DrawerHeader flex={1}>{t("Payment")}</DrawerHeader>
            <Popover placement="bottom-end" trigger="hover">
              <PopoverTrigger>
                <DrawerHeader
                  onMouseEnter={() => setIsOpenQuestionHeader(true)}
                  onMouseLeave={() => setIsOpenQuestionHeader(false)}
                  cursor="pointer"
                  className={`${s.questionNodeIconBox} ${isOpenQuestionHeader && s.questionNodeIconBoxActive}`}
                >
                  <Icon className={s.questionNodeIcon} as={questionNodeIcon} boxSize="24px" />
                </DrawerHeader>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                <PopoverBody bg="#FEF6DC">
                  <Flex fontSize="13px" lineHeight="16px" flexDirection="column">
                    <Text>{t("editNodePopup.Set up the payment:")}</Text>
                    <OrderedList px="4px">
                      <ListItem>
                        {t("editNodePopup.Open")}
                        <Link color="#325EE6" href="https://telegram.me/BotFather" target="_blank">
                          {` @BotFather `}
                        </Link>
                        {t("editNodePopup.in Telegram.")}
                      </ListItem>
                      <ListItem>
                        {t(
                          "editNodePopup.Send /mybots and select the bot you want to connect the payment option to from the list.",
                        )}
                      </ListItem>
                      <ListItem>
                        {t(
                          "editNodePopup.Click Payments and select a provider from the list, resolve legal issues and get shopId.",
                        )}
                      </ListItem>
                      <ListItem>
                        {t("editNodePopup.Go back to @BotFather and send /mybots. Select the bot to set up payment.")}
                      </ListItem>
                      <ListItem>
                        {t(
                          "editNodePopup.Click Payments, select your provider and follow the bot's prompts to enter the shopId. You can usually find it in your personal account.",
                        )}
                      </ListItem>
                    </OrderedList>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
          <DrawerBody>
            <Flex mb="12px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("editNodePopup.Title of service")}*
              </Heading>
            </Flex>
            <MessageTextEditor
              variables={variables ? variables.filter(el => el.scope !== CustomVariableScope.System) : []}
              text={flowAction.text ?? ""}
              maxLength={32}
              isEmojiDisabled
              onChange={t =>
                props.onDataChange({
                  ...flowAction,
                  text: t,
                } as PaymentFlowActionModel)
              }
              validate={props.validate}
              flowAction={flowAction}
              addCustomVariableText={props.addCustomVariableText}
              borderRadiusTop="8px"
              borderRadiusBottom="8px"
              maxHeightWithoutScroll={500}
              TextAreaProps={{ minH: "20px !important" }}
            />
            <Flex mt="24px" mb="12px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("editNodePopup.Description")}*
              </Heading>
            </Flex>
            <MessageTextEditor
              variables={variables ? variables.filter(el => el.scope !== CustomVariableScope.System) : []}
              text={flowAction.description ?? ""}
              isEmojiDisabled
              maxLength={255}
              autoFocus={false}
              onChange={t =>
                props.onDataChange({
                  ...flowAction,
                  description: t,
                } as PaymentFlowActionModel)
              }
              validate={props.validate}
              flowAction={flowAction}
              addCustomVariableText={addCustomVariableDescription}
              borderRadiusTop="8px"
              borderRadiusBottom="8px"
              maxHeightWithoutScroll={500}
            />
            <Heading mt="24px" mb="12px" fontSize={"16px"} fontWeight={"400"}>
              {t(`editNodePopup.PaymentProvider`)}*
            </Heading>
            <Flex mb="12px" alignItems="center">
              <Menu matchWidth>
                <MenuButton
                  border="1px solid #DAE1FB"
                  bg="transparent"
                  textAlign="left"
                  w="100%"
                  as={Button}
                  _active={{ borderColor: "#325EE6" }}
                  _hover={{ borderColor: "#325EE6" }}
                  rightIcon={<ChevronDownIcon />}
                >
                  <Text variant="medium">{t(`editNodePopup.${flowAction.paymentProvider}`)}</Text>
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => selectPaymentProvider(PaymentProviderType.Yookassa)}>
                    {t(`editNodePopup.Yookassa`)}
                  </MenuItem>
                  {!flowAction.fiscalization && (
                    <MenuItem onClick={() => selectPaymentProvider(PaymentProviderType.Unknown)}>
                      {t(`editNodePopup.Unknown`)}
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Flex>
            <Flex mt="24px" mb="12px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("editNodePopup.Payment provider token")}*
              </Heading>
              <Tooltip
                hasArrow
                background="#FEF6DC"
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t(
                  "editNodePopup.Once payment is connected, you will receive a payment token from @BotFather. Please enter it in this field.",
                )}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </Tooltip>
            </Flex>
            <InputVariable
              variables={variables || []}
              selectCustomVariable={addCustomVariableToken}
              setText={t =>
                props.onDataChange({
                  ...flowAction,
                  accessToken: t,
                } as PaymentFlowActionModel)
              }
              onlyVariableType={CustomVariableType.Text}
              onlyVariableScope={[CustomVariableScope.Bot]}
              text={flowAction.accessToken}
            />
            <Flex mt="24px" alignItems="center">
              <Checkbox
                isDisabled={flowAction.paymentProvider === PaymentProviderType.Unknown}
                isChecked={flowAction.fiscalization}
                onChange={e => changeFiscalization(e.target.checked)}
                variant="dominoGreen"
              >
                {t("editNodePopup.Receipt receipt is set up in the online sales register")}
              </Checkbox>
              <TooltipWithTouch
                hasArrow
                mb="1px"
                background="#FEF6DC"
                variant="dominoLight"
                whiteSpace={"pre-wrap"}
                label={t(
                  "editNodePopup.Let us know if you accept payments with fiscalization. This option is configured by your provider",
                )}
              >
                <QuestionOutlineIcon ml={"8px"} mb={"2px"} boxSize={"16px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </TooltipWithTouch>
            </Flex>
            <Flex gap="16px">
              <Flex
                flexDirection="column"
                color={flowAction.catalog && !!flowAction.catalog.customVariableId ? "#8592A3" : "#232B39"}
              >
                <Flex mt="24px" mb="12px" alignItems="center">
                  <Heading fontSize={"16px"} fontWeight={"400"}>
                    {t("editNodePopup.Price")}*
                  </Heading>
                </Flex>
                <InputVariable
                  variables={variables || []}
                  isDisabled={!!flowAction.catalog && !!flowAction.catalog.customVariableId}
                  placeholder={!!disabledPrice ? disabledPrice.toString() : ""}
                  maxLength={10}
                  selectCustomVariable={addCustomVariablePrice}
                  setText={changePrice}
                  text={flowAction.price?.amount || ""}
                  onlyVariableType={CustomVariableType.Number}
                  onlyVariableScope={[CustomVariableScope.Bot, CustomVariableScope.Contact, CustomVariableScope.Flow]}
                />
              </Flex>
              <Flex flexDirection="column">
                <Flex
                  mt="24px"
                  mb="12px"
                  alignItems="center"
                  color={flowAction.catalog && !!flowAction.catalog.customVariableId ? "#8592A3" : "#232B39"}
                >
                  <Heading fontSize={"16px"} fontWeight={"400"}>
                    {t("editNodePopup.Currency")}
                  </Heading>
                </Flex>
                <Menu matchWidth>
                  <MenuButton isDisabled as={Button}>
                    {flowAction.price?.currency || "RUB"}
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => selectCurrenct("RUB")}>RUB</MenuItem>
                    <MenuItem onClick={() => selectCurrenct("USD")}>USD</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
            <Flex flexDirection="column">
              <Flex mt="24px" mb="12px" alignItems="center">
                <Heading fontSize={"16px"} fontWeight={"400"}>
                  {t("editNodePopup.Get data from custom field")}
                </Heading>
              </Flex>
              <Box
                border="1px solid var(--chakra-colors-line)"
                borderRadius="8px"
                _hover={{ borderColor: "var(--chakra-colors-darkPurple)" }}
              >
                <CustomVariableContextMenu
                  mt="0px"
                  selectCustomVariable={addCustomVariableCatalog}
                  clearVariable={clearCustomVariableCatalog}
                  type={CustomVariableContextMenuType.Dropdown}
                  variables={variables?.filter(el => el.type === CustomVariableType.Order) || []}
                  chosenVariable={chosenCatalogCustomVariable}
                  onlyVariableType={CustomVariableType.Order}
                />
              </Box>
            </Flex>
            <Flex mt="24px" mb="12px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("editNodePopup.Details")}
              </Heading>
            </Flex>
            <Menu matchWidth>
              <MenuButton
                border="1px solid #DAE1FB"
                bg="transparent"
                textAlign="left"
                w="100%"
                as={Button}
                _active={{ borderColor: "#325EE6" }}
                _hover={{ borderColor: "#325EE6" }}
                rightIcon={<ChevronDownIcon />}
              >
                <Flex gap="2px">
                  {(!flowAction.details || flowAction.details.length === 0) && (
                    <Text color="#8592A3" variant="medium">
                      {t("editNodePopup.Select")}
                    </Text>
                  )}
                  {flowAction.details?.map((el, ind) => (
                    <Text variant="medium" key={el}>
                      {t(`editNodePopup.${el}`)}
                      {`${flowAction.details?.length !== ind + 1 ? "," : ""}`}
                    </Text>
                  ))}
                </Flex>
              </MenuButton>
              <MenuList>
                <Checkbox
                  onChange={selectAllDetails}
                  isChecked={!!flowAction.order.isAll}
                  _hover={{ bg: "#F1F4F9" }}
                  p="8px"
                  w="100%"
                  variant="dominoGreen"
                >
                  {t("editNodePopup.Select all")}
                </Checkbox>
                <Checkbox
                  isChecked={!!flowAction.order.needName}
                  onChange={() => (flowAction.order.needName ? removeDetail("Name") : addDetail("Name"))}
                  _hover={{ bg: "#F1F4F9" }}
                  p="8px"
                  w="100%"
                  variant="dominoGreen"
                >
                  {t("editNodePopup.Name")}
                </Checkbox>
                <Checkbox
                  isDisabled={flowAction.fiscalization}
                  isChecked={!!flowAction.order.needEmail}
                  onChange={() => (flowAction.order.needEmail ? removeDetail("Email") : addDetail("Email"))}
                  _hover={{ bg: "#F1F4F9" }}
                  p="8px"
                  w="100%"
                  variant="dominoGreen"
                >
                  {t("editNodePopup.Email")}
                </Checkbox>
                <Checkbox
                  isChecked={!!flowAction.order.needPhoneNumber}
                  onChange={() => (flowAction.order.needPhoneNumber ? removeDetail("Phone") : addDetail("Phone"))}
                  _hover={{ bg: "#F1F4F9" }}
                  p="8px"
                  w="100%"
                  variant="dominoGreen"
                >
                  {t("editNodePopup.Phone")}
                </Checkbox>
                <Checkbox
                  isChecked={!!flowAction.order.needShippingAddress}
                  onChange={() => (flowAction.order.needShippingAddress ? removeDetail("Address") : addDetail("Address"))}
                  _hover={{ bg: "#F1F4F9" }}
                  p="8px"
                  w="100%"
                  variant="dominoGreen"
                >
                  {t("editNodePopup.Address")}
                </Checkbox>
              </MenuList>
            </Menu>
            <Box mt="24px" position="relative">
              <FormControl
                borderRadius="8px"
                className={`${sm.itemFileWrp} ${(flowAction.file || flowAction.fileId) && sm.itemFileWrpImg} ${
                  (flowAction.fileUrl || imageUrlPopupState) && sm.itemFileWrpUrl
                } ${sm.itemImage}`}
              >
                <div
                  className={`${s.popupItem} ${s.imageBlock} ${(flowAction.fileUrl || imageUrlPopupState) && s.imageBlockUrl}`}
                >
                  <Loading scope={`postFileEditor${props.node.id}`} />
                  {(flowAction.fileUrl || imageUrlPopupState) && (
                    <>
                      <div onClick={() => deleteImageUrl()} className={sm.deleteIcon}>
                        <CloseIcon boxSize="10px" cursor="pointer" />
                      </div>
                      <p className={s.imgBlockUrlLabel}>{t("editNodePopup.URL")}</p>
                      <Textarea
                        onChange={e => {
                          setImageUrlState(e.target.value);
                          setChangeUrl(true);
                        }}
                        autoFocus={false}
                        onFocus={() => setshowBtnDone(true)}
                        onBlur={() => setshowBtnDone(false)}
                        variant="dominoOutline"
                        name="url"
                        id="url"
                        isInvalid={!validateUrl(imageUrlState)}
                        className={s.textareaUrl}
                        placeholder={`${t("editNodePopup.Enter URL")}`}
                        value={imageUrlState}
                        overflow="auto"
                      ></Textarea>
                      {!validateUrl(imageUrlState) && (
                        <Box color="mainRed" fontSize={"12px"}>
                          <Text>{t("Website address is required")}</Text>
                        </Box>
                      )}
                      {(showBtnDone || isChangeUrl) && (
                        <div onClick={setImageUrl}>
                          <Button variant="dominoViolet" mt="16px" w="100%">
                            {t("editNodePopup.Done")}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  {flowAction.fileId ? (
                    <div className={sm.imageFilteWrp}>
                      <div onClick={() => deleteImageFile()} className={`${sm.deleteIcon} ${sm.deleteIconFile}`}>
                        <CloseIcon boxSize="10px" cursor="pointer" />
                      </div>
                      <label className={s.imageInput}>
                        <img
                          className={s.addedImage}
                          src={flowAction.fileUrl || `${appSettings.apiBaseUrl}/file/${flowAction.fileId}`}
                          alt=""
                        />
                        <input
                          onChange={e => {
                            if (e.target.files) {
                              onDataChange(flowAction.text ?? "", e.target.files[0]);
                            }
                          }}
                          type="file"
                          accept={ImageFieldTypeExt}
                        />
                      </label>
                    </div>
                  ) : (
                    !flowAction.fileUrl &&
                    !imageUrlPopupState && (
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Icon as={ImageIconGrey} boxSize="28px" mb="8px" />
                        <Box textAlign="center">
                          <label className={s.input}>
                            {/* <div className={s.uploadImage}>{t("editNodePopup.Upload Image")}</div> */}
                            <input
                              onChange={e => {
                                if (e.target.files) {
                                  onDataChange(flowAction.text ?? "", e.target.files[0]);
                                }
                              }}
                              type="file"
                              accept={ImageFieldTypeExt}
                            />
                          </label>
                          <span className={s.imageUrlWrapper}>
                            {/* &#160;{t("editNodePopup.or")}&#160; */}
                            <span onClick={() => setImageUrlPopupState(true)} className={s.uploadImage}>
                              {t("editNodePopup.Add Image URL")}
                            </span>
                          </span>
                        </Box>
                        <Text textAlign="center" variant="small">
                          {t("editNodePopup.jpeg, jpg, png up to 10 mb")}
                        </Text>
                      </Box>
                    )
                  )}
                </div>
              </FormControl>
            </Box>
            <Flex mt="24px" mb="16px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("editNodePopup.Duration for payment")}*
              </Heading>
              <Tooltip
                hasArrow
                background="#FEF6DC"
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t(
                  "editNodePopup.Each provider sets their own time for payment. You can set your own time, but it must not exceed the limits set by the provider.",
                )}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </Tooltip>
            </Flex>
            <DurationEditor propsFlowAction={props.flowAction} onDataChange={props.onDataChange} hoursLimit={23} />
            <Text mt="8px" color="#8592A3" variant="medium">
              {t("editNodePopup.Don't forget to include the time when payment is available in the description.")}
            </Text>
            <Fallback
              isNodeFallback
              validate={props.validate}
              flowAction={flowAction}
              dataChange={props.onDataChange}
              maxLength={500}
              text={""}
              onChange={e =>
                props.onDataChange({
                  ...flowAction,
                  fallbackMessage: e,
                } as PaymentFlowActionModel)
              }
            />
          </DrawerBody>
          <Button variant="dominoViolet" w={"auto"} margin={"24px 16px 16px 16px"} onClick={props.onClose} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  );
}
