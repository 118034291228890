import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../common/state/store";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { AutomationLayout } from "../../../automation/AutomationLayout";
import { AutomationHeaderContainer } from "../../../automation/components/Header/AutomationHeaderContainer";
import { clearAutomationState } from "../../../automation/AutomationSlice";
import { Loading } from "../../../../common/loading/LoadingStateContainer";

export const AutomationPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAutomationState());
    };
  }, [dispatch]);

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <AutomationHeaderContainer />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={"100%"}>
        <Loading />
        <AutomationLayout />
      </Box>
    </Flex>
  );
};
