import { ScheduleTriggerTypeDiscriminator } from "../../../../FlowBuilderModel";

export const validateWarnings = (
  typeDiscriminator: ScheduleTriggerTypeDiscriminator,
  onceValue: string,
  endTime: string | null,
  startTime: string | null,
) => {
  const warningArray: string[] = [];
  if (typeDiscriminator === ScheduleTriggerTypeDiscriminator.recur && startTime && new Date(startTime) < new Date()) {
    warningArray.push("Start time is in the past");
  }
  if (onceValue && typeDiscriminator === ScheduleTriggerTypeDiscriminator.once && new Date(onceValue) < new Date()) {
    warningArray.push("Schedule time is in the past");
  }
  if (typeDiscriminator === ScheduleTriggerTypeDiscriminator.recur && endTime && new Date(endTime) < new Date()) {
    warningArray.push("End time is in the past");
  }
  return warningArray;
};
