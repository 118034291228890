import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const dominoOutline = defineStyle({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: "line",
  boxShadow: "none",
  fontSize: "16px",

  _hover: {
    borderColor: "darkPurple",
  },
  _focusVisible: {
    borderColor: "blueLink",
    boxShadow: "none",
  },
  _invalid: {
    borderColor: "mainRed",
  },
});

const dominoColor = defineStyle({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: "defaultGrey",
  boxShadow: "none",
  fontSize: "16px",
  backgroundColor: "defaultGrey",
  minHeight: "100px",
  padding: "8px",
  _hover: {
    borderColor: "darkPurple",
  },
  _focusVisible: {
    borderColor: "blueLink",
    boxShadow: "none",
  },

  _placeholder: {
    color: "darkGrey",
  },
  _invalid: {
    borderColor: "mainRed",
  },
});

const dominoColorBroadcast = defineStyle({
  borderRadius: "8px",
  border: "none",
  paddingBottom: "8px",
  fontSize: "16px",
  backgroundColor: "defaultGrey",
  minHeight: "100px",
  padding: "4px",
  borderBottomRadius: "0",

  _placeholder: {
    color: "darkGrey",
  },
  _invalid: {
    borderColor: "mainRed",
  },
});

const dominoTextEditorColor = defineStyle({
  borderRadius: "8px",
  border: "1px solid",
  borderColor: "defaultGrey",
  boxShadow: "none",
  fontSize: "16px",
  backgroundColor: "defaultGrey",
  minHeight: "100px",
  padding: "8px 8px 0 8px",
  resize: "none",

  _placeholder: {
    color: "darkGrey",
  },
});

export const textareaTheme = defineStyleConfig({
  variants: {
    dominoOutline,
    dominoColor,
    dominoTextEditorColor,
    dominoColorBroadcast,
  },
});
