import { Box, Text } from "@chakra-ui/react";
import s from "./BroadcastHeader.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackButton } from "../../../backButton/BackButton";

export const NewBroadcastHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const navigate = useNavigate();

  return (
    <Box className={s.headerContainer}>
      <Box className={s.main}>
        <BackButton onButtonClick={() => navigate(-1)} />
        <Text className={s.title}>{t("New Broadcast")}</Text>
      </Box>
    </Box>
  );
};
