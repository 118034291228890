import { useBrowserFitContent } from "../../../LayoutHelper/ResolutionHooks";
import React from "react";
import { useAppSelector } from "../../../../../common/state/store";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";
import { useSearchParams } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { FlowListContainer } from "../../../../automation/components/FlowListContainer";
import { selectFlowList } from "../../../../automation/AutomationSlice";
import { TABLET_VIEW } from "../../../LayoutHelper/ResolutionConst";

export const FlowPage = () => {
  const browserFill = useBrowserFitContent();

  const flowList = useAppSelector(selectFlowList);
  const loadingFlows = useGetLoadingState("getFlowList");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";

  if (!flowList && !loadingFlows && search === "") {
    return (
      <Flex flexDirection={"column"} height={"100%"}>
        <Box bg="bgLight" height={"100%"}>
          <FlowListContainer />
        </Box>
      </Flex>
    );
  }

  if (window.innerWidth <= TABLET_VIEW) {
    return (
      <Flex flexDirection={"column"} height={"100%"}>
        <Box bg="bgLight" height={window.innerHeight - 120}>
          <FlowListContainer />
        </Box>
      </Flex>
    );
  }

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <Box bg="bgLight" height={browserFill}>
        <FlowListContainer />
      </Box>
    </Flex>
  );
};
