import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  Icon,
  Container,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
  Box,
  useMediaQuery,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import {
  composeValidators,
  maxLength,
  notHaveOnlySpaces,
  required,
  isNumberValue,
  haveSpecialSymbol,
} from "../../../../common/validation/defaultValidators";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { NewContactFormModel } from "../../ContactListModel";
import { createContact } from "../../ContactListSlice";
import { MultiSelectMenu } from "../../../multiSelectMenu/MultiSelectMenu";
import { ReactComponent as QuestionIcon } from "../../../../assets/icons/questionIcon.svg";
import { useState } from "react";
import { getBots, selectBots } from "../../../sidebar/SidebarSlice";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";

interface Props {
  newContactState: boolean;
  onClose: () => void;
}

const initialFormData: NewContactFormModel = {
  firstName: "",
  lastName: "",
  username: "",
  externalId: "",
  botIds: [],
  phoneNumber: null,
};

export const NewContactModal = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "newContact",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const bots = useAppSelector(selectBots);
  const [botIds, setBotIds] = useState<string[]>([]);
  const [isMobile] = useMediaQuery("(max-width: 1200px)");
  const [isBotsMenuValid, setIsBotsMenuValid] = useState(false);

  const onSave = (values: NewContactFormModel) => {
    dispatch(createContact({ ...values, botIds }));
    props.onClose();
  };

  const getAdditionalBots = () => {
    if (bots) {
      dispatch(getBots({ page: bots.currentPage + 1 }));
    }
  };

  const onBotListChange = (botIds: string[]) => {
    setBotIds(botIds);
  };

  return (
    <Modal isOpen={props.newContactState} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent maxW={{ base: "330px", sm: "460px" }} borderRadius="12px">
        <ModalHeader pt={{ base: "28px", sm: "16px" }}>
          <Text variant="h1">{t("Create New Contact")}</Text>
        </ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <Icon boxSize="20px" as={CrossIcon} />
        </ModalCloseButton>
        <ModalBody paddingTop="0" display="flex" flexDirection="column" gap="8px">
          <Formik onSubmit={onSave} initialValues={initialFormData} enableReinitialize>
            {({ isValid, dirty }) => (
              <Form>
                <Container variant="dominoContentBlock">
                  <Field
                    name="externalId"
                    validate={composeValidators(
                      required(t("Telegram Id can't be empty")),
                      maxLength(20, t("Telegram Id can't be more than 20 symbols")),
                      isNumberValue(t("Number Field Should contains only numbers")),
                    )}
                  >
                    {({ field, form }: FieldProps<string, NewContactFormModel>) => (
                      <FormControl height="93px" isInvalid={!!form.errors.externalId && !!form.touched.externalId} isRequired>
                        <FormLabel>
                          <span>{t("Telegram Id")}</span>
                        </FormLabel>
                        <Input type="number" variant="outline" placeholder={t("Only numbers") ?? ""} {...field} />
                        <FormErrorMessage>{`${form.errors?.externalId}`}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="firstName"
                    validate={composeValidators(
                      notHaveOnlySpaces(t("Can't contain only spaces")),
                      maxLength(50, t("First Name can't be more than 50 symbols")),
                    )}
                  >
                    {({ field, form }: FieldProps<string, NewContactFormModel>) => (
                      <FormControl height="93px" isInvalid={!!form.errors.firstName && !!form.touched.firstName}>
                        <FormLabel>{t("First Name")}</FormLabel>
                        <Input variant="outline" placeholder={t("First Name") ?? ""} {...field} />
                        <FormErrorMessage>{`${form.errors?.firstName}`}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="lastName"
                    validate={composeValidators(
                      notHaveOnlySpaces(t("Can't contain only spaces")),
                      maxLength(50, t("Last Name  field can't be more than 50 symbols")),
                    )}
                  >
                    {({ field, form }: FieldProps<string, NewContactFormModel>) => (
                      <FormControl height="93px" isInvalid={!!form.errors.lastName && !!form.touched.lastName}>
                        <FormLabel>{t("Last Name")}</FormLabel>
                        <Input variant="outline" placeholder={t("Last Name") ?? ""} {...field} />
                        <FormErrorMessage>{`${form.errors?.lastName}`}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="username"
                    validate={composeValidators(
                      notHaveOnlySpaces(t("Can't contain only spaces")),
                      maxLength(50, t("Username field can't be more than 50 symbols")),
                      haveSpecialSymbol(t("Username can't contain @")),
                    )}
                  >
                    {({ field, form }: FieldProps<string, NewContactFormModel>) => (
                      <FormControl height="93px" isInvalid={!!form.errors.username && !!form.touched.username}>
                        <FormLabel>{t("Username")}</FormLabel>
                        <InputGroup variant="outline">
                          <InputLeftElement>@</InputLeftElement>
                          <Input placeholder={t("Username") ?? ""} {...field} />
                        </InputGroup>
                        <FormErrorMessage>{`${form.errors?.username}`}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field
                    name="phoneNumber"
                    validate={composeValidators(
                      notHaveOnlySpaces(t("Can't contain only spaces")),
                      maxLength(50, t("Phone number can't be more than 50 symbols")),
                    )}
                  >
                    {({ field, form }: FieldProps<string, NewContactFormModel>) => (
                      <FormControl height="93px" isInvalid={!!form.errors.phoneNumber && !!form.touched.phoneNumber}>
                        <FormLabel>{t("Phone number")}</FormLabel>
                        <Input type="number" variant="outline" placeholder={t("Phone number") ?? ""} {...field} />
                        <FormErrorMessage>{`${form.errors?.phoneNumber}`}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Box height={"93px"}>
                    <Flex justifyContent={"space-between"} alignItems={"center"}>
                      <Text variant="medium" mb={"8px"}>
                        <span>{t("Select bot(s) from the list")}</span>
                        <Text as="span" color="red">
                          &nbsp;*
                        </Text>
                      </Text>
                      {isMobile ? (
                        <Popover variant="tooltipPopover" placement="bottom-end">
                          <PopoverTrigger>
                            <Icon
                              as={QuestionIcon}
                              ml={"4px"}
                              boxSize={"15px"}
                              borderRadius={"50%"}
                              fill="darkGrey"
                              _hover={{ fill: "darkPurple" }}
                            />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverBody>
                              {t("You need to select one or more bots to which the contact will be linked")}
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      ) : (
                        <TooltipWithTouch
                          variant="dominoLight"
                          whiteSpace={"pre-wrap"}
                          label={t("You need to select one or more bots to which the contact will be linked")}
                        >
                          <Icon
                            as={QuestionIcon}
                            ml={"4px"}
                            boxSize={"15px"}
                            borderRadius={"50%"}
                            fill="darkGrey"
                            _hover={{ fill: "darkPurple" }}
                          />
                        </TooltipWithTouch>
                      )}
                    </Flex>
                    <MultiSelectMenu
                      onChange={onBotListChange}
                      isLastPage={bots?.currentPage === bots?.totalPages}
                      getAdditionalItems={getAdditionalBots}
                      options={bots?.items ?? []}
                      required={true}
                      validationMessage={t("Choose at least one bot")}
                      onValidation={setIsBotsMenuValid}
                    />
                  </Box>
                </Container>
                <Flex paddingBottom="32px" justifyContent="space-between">
                  <Button variant="dominoOutlineViolet" type="button" onClick={props.onClose}>
                    {ct("Cancel")}
                  </Button>
                  <Button variant="dominoViolet" type="submit" isDisabled={!isBotsMenuValid || !isValid || !dirty}>
                    {ct("Create")}
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
