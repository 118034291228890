import { useParams, useSearchParams } from "react-router-dom";
import { PluginAuthContainer } from "../../../pluginAuth/PluginAuthContainer";

export const PluginAuthPage = () => {
  const { pluginName } = useParams();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin") ?? "";

  if (!pluginName) {
    return <></>;
  }

  return <PluginAuthContainer pluginName={pluginName} origin={origin} />;
};
