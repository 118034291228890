import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../state/store";
import { SystemNotificationModel } from "./SystemNotificationModel";
import { SystemNotificationState } from "./SystemNotificationState";

const initialState: SystemNotificationState = {};

export const systemNotificationSlice = createSlice({
  name: "systemNotificationSlice",
  initialState,
  reducers: {
    notify: (state: SystemNotificationState, action: PayloadAction<SystemNotificationModel>) => {
      return {
        ...state,
        notification: action.payload,
      };
    },
    clearNotify: (state: SystemNotificationState) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { notify, clearNotify } = systemNotificationSlice.actions;

export default systemNotificationSlice.reducer;

export const selectSystemNotification = (state: RootState) => state.systemNotificationState.notification;
