import s from "./ButtonClickTriggerPopup.module.scss";
import { CloseIcon } from "@chakra-ui/icons";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Box,
  Button,
  Input,
  Alert,
  AlertIcon,
  Text,
  AlertDescription,
  Heading,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ButtonTriggerMatchType,
  ButtonTriggerMatchTypeName,
  CustomVariablesModel,
  NodeValidation,
  TriggerModel,
  TriggerNodeModel,
  TriggerTypeDiscriminator,
} from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";
import { createCustomVariable } from "../../../FlowBuilderSlice";
import { useAppDispatch } from "../../../../../common/state/store";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";

type Props = {
  isOpen?: boolean;
  triggerNode?: TriggerModel;
  nodeEditor: TriggerNodeModel;
  validation: NodeValidation;
  customVariables: CustomVariablesModel[];
  onCancel: (triggerType: TriggerTypeDiscriminator) => void;
  onSave: (trigger: TriggerModel) => void;
  onValidate: (trigger: TriggerModel) => void;
};

const defaultTriggerNode: TriggerModel = {
  id: "",
  typeDiscriminator: TriggerTypeDiscriminator.ButtonClick,
  isEnabled: true,
  buttonTriggerType: ButtonTriggerMatchType.Is,
  triggerGroupId: Math.floor(Math.random() * 1000),
};

export interface ItemView {
  isActive: boolean;
  value: string;
}

const getInitialButtons = (triggerNode: TriggerModel) => {
  return (
    triggerNode.buttonIds?.map(el => {
      return {
        isActive: false,
        value: el,
      } as ItemView;
    }) || []
  );
};

export const ButtonClickTriggerPopup = ({
  isOpen,
  triggerNode = defaultTriggerNode,
  nodeEditor,
  validation,
  customVariables,
  onCancel,
  onSave,
  onValidate,
}: Props) => {
  const [buttons, setButtons] = useState<ItemView[]>(getInitialButtons(triggerNode));
  const [buttonTriggerInfo, setButtonTriggerInfo] = useState<TriggerModel>(triggerNode);
  const [chosenVariable, setChosenVariable] = useState<CustomVariablesModel | undefined>();
  const { t } = useTranslation("translation", { keyPrefix: "flow.editTriggerPopup" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const vt = useTranslation("validation").t;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      const customVar = customVariables.find(el => triggerNode.targetCustomVariableId === el?.id);
      const newCustomVar = customVariables.find(el => nodeEditor.newVariableId === el?.id);
      if (customVar) {
        setChosenVariable(customVar);
      }
      if (newCustomVar) {
        setChosenVariable(newCustomVar);
      }
    }
    // eslint-disable-next-line
  }, [isOpen, triggerNode, nodeEditor]);

  const deleteItem = (index: number) => {
    const updatedValues = buttons.filter((_, i) => index !== i);
    setButtons(updatedValues);
    validate(updatedValues.map(el => el.value));
  };

  const addItem = () => {
    const updatedValues = [...buttons, { isActive: true, value: "" }];
    setButtons(updatedValues);
  };

  const setValue = (value: string, index: number, isActive: boolean) => {
    let result = buttons.map((el, idx) => {
      if (idx === index) {
        return {
          ...el,
          isActive,
          value,
        };
      }

      return el;
    });

    //TODO: remove empty inactive
    result = result.filter(el => el.isActive || (!el.isActive && el.value.trim().length >= 1));

    setButtons(result);
    validate(result.map(e => e.value)); //TODO: not valid data in not allowed to input
  };

  const onCreate = () => {
    const buttonIds = buttons.map(e => e.value);
    const newTrigger: TriggerModel = {
      ...triggerNode,
      buttonTriggerType: buttonTriggerInfo.buttonTriggerType,
      targetCustomVariableId: chosenVariable?.id,
      buttonIds,
    };

    onSave(newTrigger);
  };

  const validate = (values: string[]) => {
    const newTrigger: TriggerModel = {
      ...triggerNode,
      buttonIds: values,
    };
    onValidate(newTrigger);
  };

  const selectVariable = (variable: CustomVariableModel) => {
    setChosenVariable(variable);
  };

  const onRevoke = () => {
    setChosenVariable(undefined);
    onCancel(triggerNode.typeDiscriminator);
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  return (
    <Drawer
      placement="right"
      size={"xs"}
      isOpen={isOpen ?? false}
      onClose={onRevoke}
      isFullHeight={false}
      blockScrollOnMount={false}
    >
      <DrawerContent p="8px">
        <DrawerHeader className={s.header}>
          <Heading fontSize="17px" color="defaultGreen">
            {t("Button click")}
          </Heading>
        </DrawerHeader>
        <DrawerBody className={s.body} px="14px">
          <Box className={s.content}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Flex alignItems={"center"} fontSize={"15px"}>
                {t("If")}&nbsp;
                <Menu variant="dominoDotsMenu" gutter={0} offset={[0, -2]}>
                  {({ isOpen }) => (
                    <>
                      <MenuButton as={Text} className={s.dropdownText} mt={"2px"} color={isOpen ? "mainPurple" : ""}>
                        {t(
                          `button payload ${
                            ButtonTriggerMatchTypeName[
                              buttonTriggerInfo.buttonTriggerType as keyof typeof ButtonTriggerMatchTypeName
                            ]
                          }`,
                        )}
                      </MenuButton>
                      <MenuList minWidth={"160px"}>
                        {Object.keys(ButtonTriggerMatchType).map((el, i) => {
                          return (
                            <MenuItem
                              key={i}
                              onClick={() =>
                                setButtonTriggerInfo({
                                  ...buttonTriggerInfo,
                                  buttonTriggerType: ButtonTriggerMatchType[el as keyof typeof ButtonTriggerMatchType],
                                })
                              }
                            >
                              {t(`button payload ${ButtonTriggerMatchTypeName[el as keyof typeof ButtonTriggerMatchTypeName]}`)}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Flex>
              <TooltipWithTouch
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t(
                  "To start the flow by button click trigger you need 1. Create Broadcast with button that has a specific payload; 2. Specify the same payload in the trigger as in the button. The flow will be started when contact clicks on a specific button in the chat",
                )}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </TooltipWithTouch>
            </Flex>
            <Flex className={s.item}>
              {buttons.length ? (
                buttons.map((el, index, array) => {
                  return (
                    <>
                      <Button className={`${s.button} ${el.isActive ? s.buttonActive : ""}`}>
                        {/* DEPRECATED: onKeyPress is deprecated */}
                        <InputGroup>
                          {el.isActive ? (
                            <InputGroup>
                              <Input
                                onChange={e => {
                                  setValue(e.target.value, index, el.isActive);
                                }}
                                onBlur={e => setValue(e.target.value, index, false)}
                                value={el.value}
                                onKeyPress={e => e.key === "Enter" && setValue(el.value, index, false)}
                                size="sm"
                                className={s.input}
                                variant="unstyled"
                                autoFocus
                                maxLength={64}
                                paddingRight={0}
                                placeholder={`${t("Type Text")}`}
                              />
                            </InputGroup>
                          ) : (
                            <Box
                              className={s.labelEditable}
                              onClick={() => {
                                if (!el.isActive) setValue(el.value, index, true);
                              }}
                            >
                              <Box className={s.labelValue}>{el.value}</Box>
                            </Box>
                          )}
                          <InputRightElement className={s.closeIconWrp}>
                            <CloseIcon
                              className={s.closeIcon}
                              onMouseDown={e => e.preventDefault()}
                              onClick={() => {
                                deleteItem(index);
                              }}
                            />
                          </InputRightElement>
                        </InputGroup>
                      </Button>
                      {index + 1 === array.length && (
                        <div className={s.addItemButton}>
                          <Button onClick={() => addItem()}>+ {t("Payload")}</Button>
                        </div>
                      )}
                    </>
                  );
                })
              ) : (
                <div className={s.addItemButton}>
                  <Button onClick={() => addItem()}>+ {t("Payload")}</Button>
                </div>
              )}
            </Flex>
            <Box mt={"22px"}>
              <h4>{t("Save Payload to a Custom Field")}</h4>
              <CustomVariableContextMenu
                clearVariable={() => setChosenVariable(undefined)}
                type={CustomVariableContextMenuType.Dropdown}
                selectCustomVariable={selectVariable}
                addVariable={onCreateVariable()}
                variables={customVariables?.filter(el => el.type !== CustomVariableType.Order) || []}
                chosenVariable={chosenVariable}
              />
            </Box>
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={"100%"} flexDirection={"column"}>
            {validation.errors.find(el => el.field === "buttonTrigger") && (
              <Box mb={"24px"}>
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>
                    {vt(validation.errors.find(el => el.field === "buttonTrigger")?.message ?? "")}
                  </AlertDescription>
                </Alert>
              </Box>
            )}
            <Flex w={"100%"} justifyContent={"space-between"}>
              <Button onClick={onRevoke} size={"sm"} variant="dominoOutlineViolet">
                {triggerNode.id ? ct("Discard") : ct("Cancel")}
              </Button>
              <Button onClick={() => onCreate()} size={"sm"} variant="dominoViolet" data-pw="apply-button">
                {triggerNode.id ? ct("Apply") : ct("Create")}
              </Button>
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
