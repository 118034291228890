export function validateCommandName(value: string, t: (key: string) => string) {
  const validCommandNameRegex = /^([a-z-0-9_]*)$/;

  if (value.length < 1) {
    return t("bot.menuCommands.Command name can't be empty");
  }
  if (value.length > 32) {
    return t("bot.menuCommands.The Command name field must be less than 32 characters long");
  }
  if (value.length > 0 && !value.match(validCommandNameRegex)) {
    return t("bot.menuCommands.The command name may only contain lowercase Latin letters, digits, and underscores");
  }
  return "";
}

export function validateCommandDescription(value: string, t: (key: string) => string) {
  if (value.length < 2) {
    return t("bot.menuCommands.Minimum field length 2 characters");
  }
  if (value.length > 256) {
    return t("bot.menuCommands.The field must be less than 256 characters long");
  }
  return "";
}
