import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import botImageHappy from "../../../../assets/images/botImageHappy.svg";
import telegramImage from "../../../../assets/images/telegramImage.svg";
import { ReactComponent as ImportIcon } from "../../../../assets/icons/download.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plusIconTile.svg";
import { CreatedBotModel } from "../../AddBotModel";
import { useAppSelector } from "../../../../common/state/store";
import { selectTrialPlan } from "../../../organisation/OrganisationSlice";
import { useState } from "react";
import { TrialModal } from "../../../organisation/components/TrialPlan/TrilalModal/TrialModal";
interface Props {
  connectedBot: CreatedBotModel;
  clearAddBotStore: () => void;
}

export const BotCompletion = ({ connectedBot, clearAddBotStore }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const tp = useTranslation("translation", { keyPrefix: "addBot.done" }).t;
  const navigate = useNavigate();
  const trialPlan = useAppSelector(selectTrialPlan);
  const [isOpenModal, setIsOpenModal] = useState(true);

  const onOpenAutomationImportFlow = () => {
    navigate("/automation/flows?modal=importFlow");
    clearAddBotStore();
  };

  const onOpenAutomationNewFlow = () => {
    navigate("/automation/flows?modal=newFlow");
    clearAddBotStore();
  };
  return (
    <Flex
      w={"100%"}
      h={"100%"}
      overflow={{ base: "auto", "2xl": "hidden" }}
      flexDirection={{ base: "column-reverse", md: "row" }}
      display={{ base: "grid", md: "flex" }}
    >
      <Flex
        bg="bgLight"
        justifyContent={{ base: "flex-start", md: "center" }}
        alignItems={"center"}
        p={{ base: "0 32px 32px", md: "32px" }}
        w={{ base: "100%", md: "52%", xl: "42%" }}
        fontSize="15px"
        flexDirection="column"
      >
        <Image h={{ base: "48px", md: "64px" }} src={telegramImage}></Image>
        <Heading textAlign={"center"} my={"25px"} lineHeight={"35px"} fontSize={"24px"} fontWeight={"400"}>
          {tp("Congratulations")},<br />
          {`${connectedBot.name} ${tp("is connected")}`}!
        </Heading>
        <Flex
          alignItems="center"
          gap={{ base: "16px", xl: "24px" }}
          flexDirection={["column", "column", "column", "column", "row"]}
          width={"100%"}
          maxW="380px"
        >
          <Button width={"100%"} maxW="380px" variant="dominoViolet" onClick={onOpenAutomationNewFlow} data-pw="new-flow-button">
            <Flex gap={"8px"} align={"center"}>
              <PlusIcon />
              {t("Create New Flow")}
            </Flex>
          </Button>
          <Button
            width={"100%"}
            maxW="380px"
            variant="dominoOutlineViolet"
            onClick={onOpenAutomationImportFlow}
            data-pw="import-flow-button"
          >
            <Flex gap={"8px"} align={"center"}>
              <ImportIcon />
              <Text>{t("Import Flow")}</Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        w={{ base: "100%", md: "48%", xl: "55%" }}
        h={"100%"}
        p={{ base: "32px 32px 0", md: "0 32px" }}
        gridRow={1}
      >
        <Image w={"100%"} maxW={{ base: "220px", md: "460px" }} src={botImageHappy}></Image>
      </Flex>
      {!!trialPlan && trialPlan.isAvailable && (
        <TrialModal trialPlan={trialPlan} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
      )}
    </Flex>
  );
};
