import {
  Button,
  Flex,
  Text,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as StarPlan } from "../../../../assets/icons/starPlan.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plusIconTile.svg";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../../../common/state/store";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { selectOrganisation } from "../../../organisation/OrganisationSlice";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import useDebounce from "../../../../common/hooks/useDebounce";
import { constructSearchParams } from "../../../../common/utils/constructSearchParams";
import s from "./BotListHeader.module.scss";
import { selectTariffPlan } from "../../../../common/tariffPlan/TariffPlanSlice";

export const BotListHeader = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "botList.botListHeader",
  });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  useDocumentTitle(t("Bots"), "Bots");

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentBot = useAppSelector(selectCurrentBot);
  const organisation = useAppSelector(selectOrganisation);
  const tariff = useAppSelector(selectTariffPlan);
  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.BotCount, tariff);
  const { MaxCount, IsUnlimited } = accessability;

  const search = searchParams.get("search") ?? "";
  const [searchText, setSearchText] = useState(search);
  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    constructSearchParams(search, debouncedSearchText, setSearchParams);
  }, [searchText, search, debouncedSearchText, setSearchParams]);

  return (
    <Flex className={s.headerContainer}>
      {currentBot && (
        <>
          <Flex className={s.container}>
            <Text variant="h2" color="midDeepBlue">
              {t("Bots")}
            </Text>
            <InputSearch
              inputGroupStyles={{ ml: "24px", w: "auto" }}
              placeholder={`${t("Search by bot name")}`}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </Flex>
          {!IsUnlimited && organisation?.totalBots && organisation?.totalBots >= MaxCount ? (
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="dominoMd" mt="12px" variant="dominoViolet">
                      <Flex gap={"8px"} align={"center"}>
                        <Icon className={sp.starPlan} boxSize="20px" as={StarPlan} />
                        {t("New Bot")}
                      </Flex>
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to add more Bots")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : (
            <Button className={s.addButton} mt="12px" onClick={() => navigate("/addbot")} size="dominoMd" variant="dominoViolet">
              <Flex gap={"8px"} align={"center"}>
                <PlusIcon />
                {t("New Bot")}
              </Flex>
            </Button>
          )}
        </>
      )}
    </Flex>
  );
};
