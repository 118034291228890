import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import s from "./OrganisationHeader.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import h from "../../../../components/headers/HeaderStyles.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { useEffect, useRef, useState } from "react";
import { selectOrganisation, updateOrganisation } from "../../OrganisationSlice";
import { ReactComponent as EditInputIcon } from "../../../../assets/icons/editInput.svg";
import { ReactComponent as StarPlan } from "../../../../assets/icons/starPlan.svg";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { selectIsOwner } from "../../../../common/user/UserSlice";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import { NewTeamModal } from "../NewTeamModal/NewTeamModal";
import { Route, Routes } from "react-router-dom";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { NewOrganisationModal } from "../NewOrganisationModal/NewOrganisationModal";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { selectTariffPlan } from "../../../../common/tariffPlan/TariffPlanSlice";

export const OrganisationHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const organisation = useAppSelector(selectOrganisation);
  const isOwner = useAppSelector(selectIsOwner);
  const loadingOrganisation = useGetLoadingState("getOrganisation");
  const dispatch = useAppDispatch();
  const [isInputFocused, setInputFocused] = useState(false);
  const [orgName, setOrgName] = useState(organisation?.name);
  const { isOpen: isTeamModalOpen, onOpen: onNewTeam, onClose: onTeamModalClose } = useDisclosure();
  const { isOpen: isOrganisationModalOpen, onOpen: onNewOrganisation, onClose: onOrganisationModalClose } = useDisclosure();
  const first = useRef(null);
  const tariffPlans = useAppSelector(selectTariffPlan);

  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.TeamsCount, tariffPlans);
  const { MaxCount, IsUnlimited } = accessability;

  useEffect(() => {
    setOrgName(organisation?.name.replace(/ +/g, " ").trim());
  }, [organisation?.name]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      (e.target as HTMLInputElement).blur();
    }
  };

  const updateOrgName = () => {
    if (organisation?.name === orgName) return;
    const organizationName = orgName?.replace(/ +/g, " ").trim() ?? "";
    if (organizationName === "") {
      setOrgName(organisation?.name.replace(/ +/g, " ").trim());
      return;
    }
    setOrgName(organizationName);
    dispatch(
      updateOrganisation({
        id: organisation?.id,
        name: organizationName,
        totalFlow: organisation?.totalFlow,
        totalBots: organisation?.totalBots,
        totalContacts: organisation?.totalContacts,
        totalMembers: organisation?.totalMembers,
        totalTeams: organisation?.totalTeams,
        totalTeamsMembers: organisation?.totalTeamsMembers,
      }),
    );
  };

  useDocumentTitle(t("Organisation"), "Organisation");

  return (
    <>
      <Box className={`${h.headerMiddle}  ${!isOwner ? s.noBorder : ""}`}>
        <Flex className={`${s.inputGroup} ${h.titleBlock}`} fill="darkGrey" _hover={{ fill: "darkPurple" }}>
          {loadingOrganisation ? (
            <Skeleton
              speed={0.5}
              startColor="line"
              endColor="bgLight"
              border="1px solid"
              borderColor="line"
              mr="10px"
              w={"200px"}
              maxWidth="200px"
              height="27px"
            ></Skeleton>
          ) : (
            <Flex fill="inherit" visibility={isInputFocused ? "hidden" : "visible"} className={s.preview}>
              <Text wordBreak="break-all" noOfLines={1} variant="h3" color="midDeepBlue" className={h.title}>
                {organisation?.name}
              </Text>
              {isOwner && <EditInputIcon fill="inherit" />}
            </Flex>
          )}

          {isOwner && (
            <Input
              ref={first}
              className={s.input}
              variant="inline"
              size="lg"
              opacity={!isInputFocused ? 0 : 1}
              width={`${(orgName?.length ?? 0) * 14}px`}
              value={orgName ?? ""}
              onFocus={() => {
                setInputFocused(true);
              }}
              onBlur={e => {
                setInputFocused(false);
                updateOrgName();
              }}
              onChange={e => setOrgName(e.target.value)}
              onKeyDown={e => {
                handleKeyPress(e);
              }}
            />
          )}
        </Flex>
        <div className={`${s.buttonsContainer} ${h.InputSearchBlock}`}>
          {!IsUnlimited && organisation?.totalTeams && organisation?.totalTeams >= MaxCount ? (
            <Routes>
              <Route
                path="/organisation/teams"
                element={
                  <Popover trigger={"hover"}>
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Box className={sp.boxHover}>
                            <Button size="sm" variant="dominoOutlineViolet" className={s.newTeam}>
                              <Icon className={sp.starPlanHover} as={StarPlan} boxSize="18px" mr={2} />
                              {t("New Team")}
                            </Button>
                          </Box>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                            <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                            <Flex alignItems="center" justifyContent="center" direction="column">
                              <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                                {tp("Advanced feature")}
                              </PopoverHeader>
                              <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                                {tp("Please upgrade your plan to create more Teams")}
                              </PopoverBody>
                              <TariffPlanRedirectButton onClose={onClose} />
                            </Flex>
                          </PopoverContent>
                        </Portal>
                      </>
                    )}
                  </Popover>
                }
              />
            </Routes>
          ) : (
            <Routes>
              <Route
                path="/organisation/teams"
                element={
                  <Button size="sm" variant="dominoOutlineViolet" onClick={onNewTeam}>
                    + {t("New Team")}
                  </Button>
                }
              />
            </Routes>
          )}
          <Button size="sm" variant="dominoViolet" onClick={onNewOrganisation} className={s.newOrganisation}>
            + {t("New Organisation")}
          </Button>
        </div>
      </Box>
      <NewOrganisationModal isOpen={isOrganisationModalOpen} onClose={onOrganisationModalClose} />
      <NewTeamModal isOpen={isTeamModalOpen} onClose={onTeamModalClose} />
    </>
  );
};
