import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";

interface Props {
  deleteItemState: { teamId: string; operatorId: string; teamName: string; operatorName: string } | null;
  onDelete: (teamId: string, operatorId: string) => void;
  onClose: () => void;
}

export const DeleteOperatorFromTeamPopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "organisation.deletePopup",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const onDeleteClick = () => {
    props.onDelete(props.deleteItemState?.teamId ?? "", props.deleteItemState?.operatorId ?? "");
    props.onClose();
  };

  return (
    <Modal isOpen={Boolean(props.deleteItemState)} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent width={{ base: "300px", sm: "460px" }} maxW="460px" borderRadius="12px">
        <ModalHeader></ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <Icon boxSize="20px" as={CrossIcon} />
        </ModalCloseButton>
        <ModalBody paddingTop="0" display="flex" flexDirection="column" gap="8px">
          <Text pr="10px" variant="h1">
            {t("Do you really want to exclude Operator")} {props?.deleteItemState?.operatorName} {t("from Team")} &quot;
            {props?.deleteItemState?.teamName}&quot; ?
          </Text>
        </ModalBody>
        <ModalFooter paddingBottom="32px" justifyContent="space-between">
          <Button variant="dominoOutlineViolet" onClick={props.onClose}>
            {ct("Cancel")}
          </Button>
          <Button variant="dominoPrimaryRed" mr={3} onClick={onDeleteClick}>
            {ct("Remove")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
