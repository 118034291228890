import { ChevronLeftIcon } from "@chakra-ui/icons";
import s from "./BackButton.module.scss";
interface Props {
  onButtonClick: () => void;
}

export const BackButton = (props: Props) => {
  return (
    <div onClick={props.onButtonClick} className={s.container} data-pw="back-button">
      <ChevronLeftIcon boxSize="32px" />
    </div>
  );
};
