import { useEffect, useMemo, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  Textarea,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import s from "./CurlNodePopup.module.scss";
import { InputWrapper } from "../../../../inputWrapper/InputWrapper";
import { InputType } from "../../../../inputWrapper/InputWrapperModel";
import { CustomVariablesModel } from "../../../FlowBuilderModel";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { insertStringIntoText } from "../../../../../common/utils/insertStringIntoText";
import { maxLength } from "../../../../../common/validation/defaultValidators";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";

interface Props {
  curlValue: string;
  variables: CustomVariablesModel[];
  setCurlValue: (value: string) => void;
  maxLength?: number;
}

const numOfLines = 20;

export const CurlEditorModal = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow.editNodePopup" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const [localCurlValue, setLocalCurlValue] = useState("");
  const [inputCursorPosition, setInputCursorPosition] = useState(props.curlValue.length);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lineCount = useMemo(() => localCurlValue.split("\n").length, [localCurlValue]);
  const linesArr = useMemo(() => Array.from({ length: Math.max(numOfLines, lineCount) }, (_, i) => i + 1), [lineCount]);

  const lineCounterRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleTextareaScroll = () => {
    if (lineCounterRef.current && textareaRef.current) {
      lineCounterRef.current.scrollTop = textareaRef.current.scrollTop;
    }
  };
  const handleClose = () => {
    setLocalCurlValue(props.curlValue);
    onClose();
  };

  const onEdit = () => {
    props.setCurlValue(localCurlValue);
    onClose();
  };

  const onAddCustomVariable = (variable: CustomVariableModel) => {
    const text = insertStringIntoText(
      localCurlValue ?? "",
      "@{" + variable.scope + ":" + variable.key + "}",
      inputCursorPosition,
    );
    const lengthError = maxLength(props.maxLength ?? 0)(text);
    if (!lengthError) {
      setLocalCurlValue(text);
    }
  };

  useEffect(() => {
    setLocalCurlValue(props.curlValue);
  }, [props.curlValue]);

  return (
    <>
      <Button variant="dominoGhost" p="0" onClick={onOpen}>
        {ct("Edit")}
      </Button>

      <Modal initialFocusRef={textareaRef} size="2xl" isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("Edit curl")}</ModalHeader>
          <ModalCloseButton variant="popupCloseButton" />
          <ModalBody>
            <Flex>
              <Box className={s.numbers} ref={lineCounterRef}>
                {linesArr.map(count => (
                  <Box className={`${s.number} ${count <= lineCount ? s.active : ""}`} key={count}>
                    {count}
                  </Box>
                ))}
              </Box>
              <Flex w="100%" flexDir="column">
                <InputWrapper
                  type={InputType.TextArea}
                  maxLength={props.maxLength ?? 2000}
                  charactersNumber={localCurlValue.length}
                  buttonBlockStyle={{ backgroundColor: "white" }}
                  variablesProps={{
                    addCustomVariableText: onAddCustomVariable,
                    variables: props.variables?.filter(v => v.type !== CustomVariableType.Order),
                    ContextModelType: CustomVariableContextMenuType.Default,
                  }}
                  childRef={textareaRef}
                  cursorPosition={inputCursorPosition}
                >
                  <Textarea
                    variant="dominoTextEditorColor"
                    onChange={e => {
                      setLocalCurlValue(e.target.value);
                    }}
                    onSelect={e => setInputCursorPosition(e.currentTarget.selectionStart)}
                    onFocus={e => e.currentTarget.setSelectionRange(inputCursorPosition, inputCursorPosition)}
                    name="curlEditor"
                    id="curlEditor"
                    ref={textareaRef}
                    onScroll={handleTextareaScroll}
                    maxLength={props.maxLength}
                    placeholder={t("Enter curl here") ?? ""}
                    value={localCurlValue}
                    height=" 450px"
                    borderRadius="0"
                    lineHeight="22px"
                    p="0 8px"
                  />
                </InputWrapper>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="dominoOutlineViolet" mr={3} onClick={handleClose}>
              {ct("Cancel")}
            </Button>
            <Button variant="dominoViolet" onClick={onEdit}>
              {ct("Save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
