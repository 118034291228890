/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { PluginAuthState } from "./PluginAuthState";
import { PluginTokenModel } from "./PluginAuthModel";

const initialState: PluginAuthState = {};

export const pluginAuthSlice = createSlice({
  name: "pluginAuthSlice",
  initialState,
  reducers: {
    getPluginToken: (state: PluginAuthState, action: PayloadAction<{ pluginName: string }>) => {
      return {
        ...state,
        token: undefined,
      };
    },
    getPluginTokenCompleted: (state: PluginAuthState, action: PayloadAction<{ token: PluginTokenModel }>) => {
      return {
        ...state,
        token: action.payload.token,
      };
    },
  },
});

export const { getPluginToken, getPluginTokenCompleted } = pluginAuthSlice.actions;

export const selectPluginToken = (state: RootState) => state.app.pluginAuthState.token;

export default pluginAuthSlice.reducer;
