/* eslint-disable react/no-children-prop */
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Heading,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  CustomVariablesModel,
  NodeValidation,
  TriggerModel,
  TriggerNodeModel,
  TriggerTypeDiscriminator,
} from "../../../FlowBuilderModel";
import s from "./CommandTriggerPopup.module.scss";
import { useTranslation } from "react-i18next";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { CustomVariableModel, CustomVariableType } from "../../../../../common/AppEnums";
import { createCustomVariable } from "../../../FlowBuilderSlice";
import { useAppDispatch } from "../../../../../common/state/store";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";

type Props = {
  isOpen?: boolean;
  triggerNode?: TriggerModel;
  nodeEditor: TriggerNodeModel;
  validation: NodeValidation;
  customVariables: CustomVariablesModel[];
  onCancel: (triggerType: TriggerTypeDiscriminator) => void;
  onSave: (trigger: TriggerModel) => void;
  onValidate: (trigger: TriggerModel) => void;
};

const defaultTriggerNode: TriggerModel = {
  id: "",
  typeDiscriminator: TriggerTypeDiscriminator.CommandMatch,
  isEnabled: true,
  triggerGroupId: Math.floor(Math.random() * 1000),
  command: "",
};

export const CommandTriggerPopup = ({
  isOpen,
  triggerNode = defaultTriggerNode,
  validation,
  nodeEditor,
  customVariables,
  onCancel,
  onSave,
  onValidate,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const vt = useTranslation("validation").t;
  const dispatch = useAppDispatch();

  const [command, setCommand] = useState<string>(triggerNode.command ?? "");
  const [lastCommand, setLastCommand] = useState<string>("");
  const [chosenVariable, setChosenVariable] = useState<CustomVariablesModel | undefined>();

  useEffect(() => {
    if (isOpen) {
      const customVariable = customVariables.find(el => triggerNode.targetCustomVariableId === el?.id);
      const newCustomVariable = customVariables.find(el => nodeEditor.newVariableId === el?.id);
      if (newCustomVariable) {
        setChosenVariable(newCustomVariable);
        return;
      }
      if (customVariable) {
        setChosenVariable(customVariable);
        return;
      }

      if (!triggerNode?.command) {
        setCommand("");
      }
      setChosenVariable(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, triggerNode, nodeEditor]);

  const onCommandChange = (command: string) => {
    setCommand(command);
    //validate(command);
  };

  const onCreate = () => {
    const isOnlySpaces = !command.split("").filter(element => element !== " ").length;
    const trimText = !isOnlySpaces ? command.trim() : command;
    setCommand(trimText);
    const newTrigger: TriggerModel = {
      ...triggerNode,
      command: trimText,
      targetCustomVariableId: chosenVariable?.id,
    };

    onSave(newTrigger);

    if (!validate(trimText)) {
      setLastCommand(command.trim());
    }
  };

  const validate = (newCommand: string) => {
    return !newCommand || newCommand.split("").find(element => element === " ") || newCommand.includes("/");
  };

  const selectVariable = (variable: CustomVariableModel) => {
    setChosenVariable(variable);
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  return (
    <Drawer
      placement="right"
      size={"xs"}
      isOpen={isOpen ?? false}
      onClose={() => {
        onCancel(triggerNode.typeDiscriminator);
        if (validate(command)) {
          setCommand(lastCommand);
        }
      }}
      isFullHeight={false}
      blockScrollOnMount={false}
    >
      <DrawerContent p="8px">
        <DrawerHeader className={s.header}>
          <Heading fontSize="17px" color="defaultGreen">
            {t("editTriggerPopup.Command")}
          </Heading>
        </DrawerHeader>
        <DrawerBody px={"14px"}>
          <Flex flexDirection={"column"}>
            <Flex fontSize={"16px"} mt={"18px"} justifyContent={"space-between"} alignItems={"center"}>
              <Flex alignItems={"center"} fontSize={"15px"}>
                <div>
                  {t("editTriggerPopup.If")} {t("editTriggerPopup.command is")}
                </div>
              </Flex>
              <TooltipWithTouch
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t("editTriggerPopup.Set a command. If contact's message includes the command, flow will be started.")}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </TooltipWithTouch>
            </Flex>
            <InputGroup mt="12px">
              <InputLeftElement backgroundColor="defaultGrey" zIndex="0" h="36px" children={"/"} />
              <Input
                pl="50px"
                placeholder={`${t("editTriggerPopup.Enter command")}`}
                value={command}
                maxLength={32}
                onChange={e => onCommandChange(e.target.value)}
              ></Input>
            </InputGroup>
          </Flex>
          <Box mt={"20px"}>
            <h4>{t("editTriggerPopup.Save Payload to a Custom Field")}</h4>
            <CustomVariableContextMenu
              clearVariable={() => setChosenVariable(undefined)}
              mt="12px"
              type={CustomVariableContextMenuType.Dropdown}
              selectCustomVariable={selectVariable}
              addVariable={onCreateVariable()}
              variables={customVariables?.filter(el => el.type !== CustomVariableType.Order) || []}
              chosenVariable={chosenVariable}
            />
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Flex w={"100%"} flexDirection={"column"}>
            {validation.errors.find(el => el.field === "commandTrigger") && (
              <Box mb={"24px"}>
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>
                    {vt(validation.errors.find(el => el.field === "commandTrigger")?.message ?? "")}
                  </AlertDescription>
                </Alert>
              </Box>
            )}
            <Flex w={"100%"} justifyContent={"space-between"}>
              <Button
                onClick={() => {
                  onCancel(triggerNode.typeDiscriminator);
                  if (validate(command)) {
                    setCommand(lastCommand);
                  }
                }}
                size={"sm"}
                variant="dominoOutlineViolet"
              >
                {triggerNode.id ? ct("Discard") : ct("Cancel")}
              </Button>
              <Button onClick={() => onCreate()} size={"sm"} variant="dominoViolet">
                {triggerNode.id ? ct("Apply") : ct("Create")}
              </Button>
            </Flex>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
