import { call, put, takeLatest } from "redux-saga/effects";
import { begin, complete } from "../loading/LoadingStateActions";
import { userSlice } from "./UserSlice";
import { handleException } from "../SagaHelper";
import { UserData } from "./UserData";
import { LanguageCodeEnum, UserModel } from "./UserModel";
import { getUserProfile } from "./UserApi";
import * as FlowBuilderApi from "../../components/flowBuilder/FlowBuilderApi";

import i18n from "../i18next/i18n";
import { UserBillingPlanData } from "../../components/userProfile/UserProfileData";
import { getUserProfileBillingPlan } from "../../components/userProfile/UserProfileApi";
import { UserBillingPlanModel } from "../../components/userProfile/UserProfileModel";
import { OperatorData } from "../../components/flowBuilder/FlowBuilderData";

export function* userSaga() {
  yield takeLatest(userSlice.actions.getProfile, getProfile);
  yield takeLatest(userSlice.actions.getBillingPlan, getBillingPlan);
}

function* getProfile() {
  try {
    yield put(begin());
    const data: UserData = yield call(getUserProfile);
    const model: UserModel = { ...data };
    const language = data.locale;
    i18n.changeLanguage(language);
    yield put(userSlice.actions.getLanguageCompleted(language));
    yield put(userSlice.actions.getProfileCompleted(model));
    const operator: OperatorData = yield call(FlowBuilderApi.getOperatorTitle, model.id);
    if (operator.roles) {
      yield put(userSlice.actions.setRoles(operator.roles));
    }
  } catch (e: unknown) {
    yield put(userSlice.actions.getLanguageCompleted(LanguageCodeEnum.en));
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* getBillingPlan() {
  try {
    const data: UserBillingPlanData = yield call(getUserProfileBillingPlan);
    const model: UserBillingPlanModel = { ...data };
    yield put(userSlice.actions.getBillingPlanSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
