import { drawerAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(drawerAnatomy.keys);

const dominoDrawer = definePartsStyle({
  dialog: {
    padding: "8px",
  },

  body: {
    padding: "24px 8px",
  },

  header: {
    background: "lightDeepBlue",
    borderRadius: "8px",
    fontSize: "17px",
    color: "#3E5E95",
    padding: "10px",
    textAlign: "center",
    position: "relative",
  },

  closeButton: {
    backgroundColor: "transparent",
    color: "darkGrey",
    top: "50%",
    transform: "translateY(-50%)",
    right: "4px",

    _hover: {
      color: "mainPurple",
    },

    _active: {
      color: "mainPurple",
    },
  },
});

export const drawerTheme = defineMultiStyleConfig({
  variants: {
    dominoDrawer,
  },
});
