/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { BotListModel, BotModel, DashboardLocalStorageModel, MessagesStatisticModel, MessagesModel } from "./DashboardModel";
import { DashboardState } from "./DashboardState";

const initialState: DashboardState = {
  isCurrentBotLoading: true,
};

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    getBots: (state: DashboardState, action: PayloadAction<number>) => {
      return {
        ...state,
      };
    },
    getBotsCompleted: (state: DashboardState, action: PayloadAction<BotListModel>) => {
      return {
        ...state,
        botList: action.payload,
      };
    },
    getSentMessages: (state: DashboardState, action: PayloadAction<DashboardLocalStorageModel>) => {
      return {
        ...state,
      };
    },
    getSentMessagesCompleted: (state: DashboardState, action: PayloadAction<MessagesModel>) => {
      return {
        ...state,
        sentMessages: action.payload,
      };
    },
    getSentReceivedMessages: (state: DashboardState, action: PayloadAction<DashboardLocalStorageModel>) => {
      return {
        ...state,
      };
    },
    getSentReceivedMessagesCompleted: (state: DashboardState, action: PayloadAction<MessagesStatisticModel>) => {
      return {
        ...state,
        sentReceivedMessages: action.payload,
      };
    },
    selectBot: (state: DashboardState, action: PayloadAction<BotModel | string>) => {
      return {
        ...state,
      };
    },
    selectBotCompleted: (state: DashboardState, action: PayloadAction<BotModel>) => {
      return {
        ...state,
        bot: action.payload,
      };
    },
    removeSelectedBot: (state: DashboardState) => {
      return {
        ...state,
        bot: undefined,
      };
    },
    setCurrentBotLoading: (state: DashboardState, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isCurrentBotLoading: action.payload,
      };
    },
  },
});

export const {
  getBots,
  getBotsCompleted,
  selectBot,
  selectBotCompleted,
  removeSelectedBot,
  getSentMessages,
  getSentMessagesCompleted,
  getSentReceivedMessages,
  getSentReceivedMessagesCompleted,
} = dashboardSlice.actions;

export const selectBots = (state: RootState) => state.app.dashboardState.botList;
export const selectCurrentBot = (state: RootState) => state.app.dashboardState.bot;
export const selectCurrentBotId = (state: RootState) => state.app.dashboardState.bot?.id;
export const selectIsCurrentBotLoading = (state: RootState) => state.app.dashboardState.isCurrentBotLoading;
export const selectSentMessages = (state: RootState) => state.app.dashboardState.sentMessages;
export const selectSentReceivedMessages = (state: RootState) => state.app.dashboardState.sentReceivedMessages;

export default dashboardSlice.reducer;
