import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, Button, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useCallbackPrompt } from "../../../../common/hooks/useCallbackPrompt";
import s from "./LeaveConfirmationPopup.module.scss";

type Props = {
  dirty: boolean;
};

export const LeaveConfirmationPopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(props.dirty);

  return (
    <Modal isOpen={showPrompt} onClose={cancelNavigation} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent className={s.modalContent} data-pw="leave-modal">
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        />
        <ModalBody paddingTop={"0"}>
          <Text variant="h1" textAlign="center">
            {t("Do you really want to Exit without saving?")}
          </Text>
        </ModalBody>
        <ModalFooter className={s.modalFooter}>
          <Button data-pw="cancel-button" variant="dominoOutlineViolet" mr={3} onClick={cancelNavigation}>
            {ct("Cancel")}
          </Button>
          <Button data-pw="discard-button" variant="dominoPrimaryRed" onClick={confirmNavigation}>
            {t("Discard changes")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
