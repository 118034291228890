import s from "./NodeEditorSpinner.module.scss";
import { useTranslation } from "react-i18next";

export const NodeEditorSpinner = () => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  return (
    <div className={s.overlay}>
      <svg className={s.spinner} viewBox="0 0 50 50">
        <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg>
      <p className={s.text}>
        {t("editNodePopup.Your file is uploading")}…<br />
        {t("editNodePopup.You may continue working on your flow")}
      </p>
    </div>
  );
};
