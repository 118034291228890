import { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { selectBot } from "../../components/sidebar/SidebarSlice";
import { Loading } from "../loading/LoadingStateContainer";
import { useAppDispatch } from "../state/store";

export function SetBot() {
  const { botId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as { location: string } | undefined;

  useEffect(() => {
    if (botId) {
      dispatch(selectBot(botId));
    }
    navigate(locationState?.location ? `/${locationState.location.split("/")[1]}` : "/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Loading />;
}
