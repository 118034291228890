import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "../../../common/hooks/useDocumentTitle";

export const ChatListHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "liveChatHeader" });
  useDocumentTitle(t("Live Chat", "Live Chat"));

  return (
    <Text variant="h2" color="midDeepBlue" py="16px">
      {t("Live Chat")}
    </Text>
  );
};
