import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const lineVariant = definePartsStyle({
  root: {},
  tablist: {
    marginX: "32px",
  },
  tab: {
    padding: "8px",
    color: "midDeepBlue",
    _selected: {
      color: "blueLink",
    },
  },
  tabpanels: {
    height: "calc(100% - 38px)",
  },
  tabpanel: {
    padding: "18px 32px",
    overflowY: "auto",
    maxHeight: "100%",
  },
});

const variants = {
  line: lineVariant,
};

export const tabsTheme = defineMultiStyleConfig({ variants });
