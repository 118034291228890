import { Box } from "@chakra-ui/react";
import { BotVariableModel } from "../../BotModel";
import { BotVariableItem } from "./BotVariableItem/BotVariableItem";
import { useTranslation } from "react-i18next";
import s from "./BotVariables.module.scss";

interface Props {
  variables: BotVariableModel[];
  onVariableCreate: () => void;
  onVariableEdit: (variable: BotVariableModel) => void;
  onVariableDescriptionEdit: (variable: BotVariableModel) => void;
  onVariableDelete: (variable: BotVariableModel) => void;
}

export const BotVariables = ({
  variables,
  onVariableCreate,
  onVariableEdit,
  onVariableDelete,
  onVariableDescriptionEdit,
}: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  return (
    <Box className={s.botVariablesContainer}>
      {/* list */}
      {variables.length ? (
        <Box className={s.listContainer}>
          <Box className={`${s.row} ${s.header}`}>
            <Box>{t("Name")}</Box>
            <Box>{t("Type")}</Box>
            <Box>{t("Value")}</Box>
            <Box>{t("Description")}</Box>
            <Box></Box>
          </Box>
          <Box mb={"36px"} borderRadius="12px" border="1px solid var(--chakra-colors-defaultGrey)" background="white">
            {variables.map(varItem => (
              <BotVariableItem
                key={varItem.id}
                item={varItem}
                onVariableDelete={onVariableDelete}
                onVariableEdit={onVariableEdit}
                onVariableDescriptionEdit={onVariableDescriptionEdit}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box className={s.noData}>{t("No Bot Fields yet")}</Box>
      )}
    </Box>
  );
};
