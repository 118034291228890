import { Box, Button, Flex, Text } from "@chakra-ui/react";
import s from "./ContactHeader.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../../../backButton/BackButton";
import { useTranslation } from "react-i18next";

interface Props {
  onOpen: () => void;
}

export const EditContactHeader = ({ onOpen }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("translation", { keyPrefix: "contact" });

  const handleBackClick = () => {
    const { state } = location;
    if (state?.from) {
      navigate(state.from, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <Box className={s.headerContainer}>
      <Box className={s.main}>
        <Flex gap="8px" align="center">
          <BackButton onButtonClick={() => handleBackClick()} />
          <Text className={s.title}>{t("Edit contact")}</Text>
        </Flex>
        <Button onClick={onOpen} justifySelf="flex-end" variant="dominoOutlineRed">
          {t("Delete Contact")}
        </Button>
      </Box>
    </Box>
  );
};
