import { Box } from "@chakra-ui/react";
import { Entity } from "../formattedText/entity";

export const formatTelegramText = (text: string) => {
  const replaceBoldItalicText = (text: string) => {
    return text.replace(/__\*\*(.*?)\*\*__/gm, "<strong><em>$1</em></strong>");
  };

  const replaceBoldText = (text: string) => {
    return text.replace(/\*\*(.*?)\*\*/gm, "<strong>$1</strong>");
  };

  const replaceItalicText = (text: string) => {
    return text.replace(/__(.*?)__/gm, "<em>$1</em>");
  };

  const replaceMonospaceText = (text: string) => {
    return text.replace(/``(.*?)``/gm, "<code>$1</code>");
  };

  const replaceVariableText = (text: string) => {
    return text.replace(/@{(.*?)}/gm, "<span class=variable >@{$1}</span>");
  };
  const replaceTextLinkText = (text: string) => {
    return text.replace(/\[(.*?)]\((.*?)\)/gm, "<a href=$2 target=_blank class=link >$1</a>");
  };

  let formattedText = replaceBoldItalicText(text);
  formattedText = replaceBoldText(formattedText);
  formattedText = replaceItalicText(formattedText);
  formattedText = replaceMonospaceText(formattedText);
  formattedText = replaceVariableText(formattedText);
  formattedText = replaceTextLinkText(formattedText);

  const createMarkup = (formattedText: string) => {
    return { __html: formattedText };
  };
  return <Box dangerouslySetInnerHTML={createMarkup(formattedText)} data-pw="formattedText" />;
};

export const formatTelegramTextToEdit = (text: string, entity: Entity[]) => {
  enum TypeEntity {
    bold = "*",
    italic = "_",
    code = "`",
    textLinkLeft = "[",
    textLinkRight = "]",
  }

  // const mocText = "Italic Bold ItalicBold BoldItalic Mono URL";
  // const mocEntity = [
  //   {
  //     type: "italic",
  //     offset: 0,
  //     length: 6,
  //     url: undefined,
  //     language: null,
  //     customEmojiId: null,
  //   },
  //   {
  //     type: "bold",
  //     offset: 7,
  //     length: 4,
  //     url: undefined,
  //     language: null,
  //     customEmojiId: null,
  //   },
  //   {
  //     type: "italic",
  //     offset: 12,
  //     length: 10,
  //     url: undefined,
  //     language: null,
  //     customEmojiId: null,
  //   },
  //   {
  //     type: "bold",
  //     offset: 12,
  //     length: 10,
  //     url: undefined,
  //     language: null,
  //     customEmojiId: null,
  //   },
  //   {
  //     type: "bold",
  //     offset: 23,
  //     length: 10,
  //     url: undefined,
  //     language: null,
  //     customEmojiId: null,
  //   },
  //   {
  //     type: "italic",
  //     offset: 23,
  //     length: 10,
  //     url: undefined,
  //     language: null,
  //     customEmojiId: null,
  //   },
  //   {
  //     type: "code",
  //     offset: 34,
  //     length: 4,
  //     url: undefined,
  //     language: null,
  //     customEmojiId: null,
  //   },
  //   {
  //     type: "text_link",
  //     offset: 39,
  //     length: 3,
  //     url: "https://beget.com/ru/kb/how-to/vps/razvertyvanie-django-s-pomoshchyu-nginx-postgresql-i-gunicorn#pri-otkrytii-sayta-otobrazhaetsya-502-kod-otveta",
  //     language: null,
  //     customEmojiId: null,
  //   },
  // ];

  // text = mocText;
  // entity = mocEntity;

  if (!entity?.length) {
    return text;
  }

  let kEntity = 0;
  let result = "";
  for (let i = 0; i < text.length; ) {
    if (
      entity?.length > kEntity + 1 &&
      entity[kEntity]?.offset === i &&
      entity[kEntity]?.type === "bold" &&
      entity[kEntity + 1]?.offset === i &&
      entity[kEntity + 1]?.type === "italic"
    ) {
      result +=
        TypeEntity.bold +
        TypeEntity.bold +
        TypeEntity.italic +
        TypeEntity.italic +
        text.slice(i, i + entity[kEntity]?.length) +
        TypeEntity.italic +
        TypeEntity.italic +
        TypeEntity.bold +
        TypeEntity.bold;
      i = i + entity[kEntity].length;
      kEntity = kEntity + 2;
    } else if (
      entity?.length > kEntity + 1 &&
      entity[kEntity]?.offset === i &&
      entity[kEntity]?.type === "italic" &&
      entity[kEntity + 1]?.offset === i &&
      entity[kEntity + 1]?.type === "code"
    ) {
      result +=
        TypeEntity.italic +
        TypeEntity.italic +
        TypeEntity.code +
        TypeEntity.code +
        text.slice(i, i + entity[kEntity]?.length) +
        TypeEntity.code +
        TypeEntity.code +
        TypeEntity.italic +
        TypeEntity.italic;
      i = i + entity[kEntity].length;
      kEntity = kEntity + 2;
    } else if (
      entity?.length > kEntity + 1 &&
      entity[kEntity]?.offset === i &&
      entity[kEntity]?.type === "code" &&
      entity[kEntity + 1]?.offset === i &&
      entity[kEntity + 1]?.type === "italic"
    ) {
      result +=
        TypeEntity.code +
        TypeEntity.code +
        TypeEntity.italic +
        TypeEntity.italic +
        text.slice(i, i + entity[kEntity]?.length) +
        TypeEntity.italic +
        TypeEntity.italic +
        TypeEntity.code +
        TypeEntity.code;
      i = i + entity[kEntity].length;
      kEntity = kEntity + 2;
    } else if (
      entity?.length > kEntity + 1 &&
      entity[kEntity]?.offset === i &&
      entity[kEntity]?.type === "bold" &&
      entity[kEntity + 1]?.offset === i &&
      entity[kEntity + 1]?.type === "code"
    ) {
      result +=
        TypeEntity.bold +
        TypeEntity.bold +
        TypeEntity.code +
        TypeEntity.code +
        text.slice(i, i + entity[kEntity]?.length) +
        TypeEntity.code +
        TypeEntity.code +
        TypeEntity.bold +
        TypeEntity.bold;
      i = i + entity[kEntity].length;
      kEntity = kEntity + 2;
    } else if (
      entity?.length > kEntity + 1 &&
      entity[kEntity]?.offset === i &&
      entity[kEntity]?.type === "code" &&
      entity[kEntity + 1]?.offset === i &&
      entity[kEntity + 1]?.type === "bold"
    ) {
      result +=
        TypeEntity.code +
        TypeEntity.code +
        TypeEntity.bold +
        TypeEntity.bold +
        text.slice(i, i + entity[kEntity]?.length) +
        TypeEntity.bold +
        TypeEntity.bold +
        TypeEntity.code +
        TypeEntity.code;
      i = i + entity[kEntity].length;
      kEntity = kEntity + 2;
    } else if (
      entity.length >= kEntity + 1 &&
      entity[kEntity]?.offset === i &&
      entity[kEntity]?.type === "italic" &&
      entity[kEntity + 1]?.offset === i &&
      entity[kEntity + 1]?.type === "bold"
    ) {
      result +=
        TypeEntity.italic +
        TypeEntity.italic +
        TypeEntity.bold +
        TypeEntity.bold +
        text.slice(i, i + entity[kEntity]?.length) +
        TypeEntity.bold +
        TypeEntity.bold +
        TypeEntity.italic +
        TypeEntity.italic;
      i = i + entity[kEntity]?.length;
      kEntity = kEntity + 2;
    } else if (entity.length >= kEntity + 1 && entity[kEntity]?.offset === i && entity[kEntity]?.type === "bold") {
      result +=
        TypeEntity.bold + TypeEntity.bold + text.slice(i, i + entity[kEntity]?.length) + TypeEntity.bold + TypeEntity.bold;
      i = i + entity[kEntity]?.length;
      kEntity++;
    } else if (entity?.length >= kEntity + 1 && entity[kEntity]?.offset === i && entity[kEntity]?.type === "italic") {
      result +=
        TypeEntity.italic +
        TypeEntity.italic +
        text.slice(i, i + entity[kEntity]?.length) +
        TypeEntity.italic +
        TypeEntity.italic;
      i = i + entity[kEntity]?.length;
      kEntity++;
    } else if (entity?.length >= kEntity + 1 && entity[kEntity]?.offset === i && entity[kEntity]?.type === "code") {
      result +=
        TypeEntity.code + TypeEntity.code + text.slice(i, i + entity[kEntity]?.length) + TypeEntity.code + TypeEntity.code;
      i = i + entity[kEntity]?.length;
      kEntity++;
    } else if (entity?.length >= kEntity + 1 && entity[kEntity]?.offset === i && entity[kEntity]?.type === "text_link") {
      result +=
        TypeEntity.textLinkLeft +
        text.slice(i, i + entity[kEntity]?.length) +
        TypeEntity.textLinkRight +
        `(${entity[kEntity]?.url})`;
      i = i + entity[kEntity]?.length;
      kEntity++;
    } else {
      result += text[i];
      i++;
    }
  }
  return result;
};

///Do not remove, modify the component later

export const formatTelegramTextEdit = (text: string, entity: Entity[]) => {
  if (entity?.length < 1) {
    return text;
  }

  let formattedText = text;

  const sortEntityByOffset = entity?.length > 1 ? [...entity]?.sort((a, b) => a.offset - b.offset) : entity;

  for (let i = sortEntityByOffset?.length; i > 0; i--) {
    let start;
    let end;

    switch (sortEntityByOffset[i]?.type) {
      case "bold":
        start = "**";
        end = "**";
        break;
      case "italic":
        start = "__";
        end = "__";
        break;
      case "code":
        start = "``";
        end = "``";
        break;
      case "text_link":
        formattedText =
          formattedText.slice(0, sortEntityByOffset[i]?.offset) +
          `[${formattedText.slice(
            sortEntityByOffset[i].offset,
            sortEntityByOffset[i]?.offset + sortEntityByOffset[i]?.length,
          )}]` +
          `(${sortEntityByOffset[i]?.url})` +
          formattedText.slice(sortEntityByOffset[i]?.offset + sortEntityByOffset[i]?.length);
        continue;
      default:
        continue;
    }

    formattedText =
      formattedText.slice(0, sortEntityByOffset[i]?.offset) +
      start +
      formattedText.slice(sortEntityByOffset[i]?.offset, sortEntityByOffset[i]?.offset + sortEntityByOffset[i]?.length) +
      end +
      formattedText.slice(sortEntityByOffset[i]?.offset + sortEntityByOffset[i]?.length);
  }
  return formattedText;
};
