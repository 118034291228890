import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Icon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../common/state/store";
import { ReactComponent as CrossIcon } from "../../../../assets/icons/cross.svg";
import { cancelBroadcast } from "../../BroadcastSlice";
import s from "./CancelBroadcastPopup.module.scss";

interface Props {
  cancelBroadcastState: { id: string; itemName: string } | null;
  onClose: () => void;
}

export const CancelBroadcastPopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "broadcastList.cancelPopup",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const dispatch = useAppDispatch();

  const onDeleteClick = () => {
    dispatch(cancelBroadcast({ broadcastId: props.cancelBroadcastState?.id ?? "" }));
    props.onClose();
  };

  return (
    <Modal isOpen={Boolean(props.cancelBroadcastState)} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent className={s.modalContent}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <Icon boxSize="20px" as={CrossIcon} />
        </ModalCloseButton>
        <ModalBody paddingTop="0">
          <Text pr="10px" variant="h1">
            {t("Do you really want to cancel the post")} &quot;
            {props?.cancelBroadcastState?.itemName}&quot;
          </Text>
        </ModalBody>
        <ModalFooter className={s.modalFooter}>
          <Button data-pw="cancel" variant="dominoOutlineViolet" onClick={props.onClose}>
            {ct("Cancel")}
          </Button>
          <Button data-pw="confirm" variant="dominoPrimaryRed" mr={3} onClick={onDeleteClick}>
            {t("Confirm Cancellation")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
