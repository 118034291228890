import s from "../../../UserProfile.module.scss";
import { Icon, Text } from "@chakra-ui/react";
import { TooltipWithTouch } from "../../../../../common/tooltipWithTouch/TooltipWithTouch";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as QuestionIcon } from "../../../../../assets/icons/questionIcon.svg";

export const UserProfilePushTitle = () => {
  const { t } = useTranslation("translation", { keyPrefix: "userProfile" });
  return (
    <div className={s.contentItemTitle}>
      <Text variant="h2" display={"flex"} alignItems={"center"}>
        {t("Push notification")}
        <TooltipWithTouch
          variant="dominoLight"
          label={t("Select bot to receive notifications about dialogues assigned to you or your team")}
          placement="auto"
          maxW={"270px"}
        >
          <Icon
            as={QuestionIcon}
            ml={"4px"}
            boxSize={"15px"}
            borderRadius={"50%"}
            fill="darkGrey"
            _hover={{ fill: "darkPurple" }}
          />
        </TooltipWithTouch>
      </Text>
    </div>
  );
};
