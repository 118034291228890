import { Button, Flex, Text, Input, FormControl } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface Props {
  onSubmit: (text: string, url: string) => void;
}

export const AddLinkModal = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "commonWords" });
  const [text, setText] = useState<string | null>();
  const [url, setUrl] = useState<string | null>();
  const isTextError = text?.length === 0;
  const isUrlError = url?.length === 0 || (!!url ? !/^(https):\/\/[^ "]+$/.test(url) : false);

  const handleLinkTextChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (value.length === 0 || value[0] !== " ") {
      setText(value);
    }
  };
  const handleSubmit = () => {
    if (text && url) {
      props.onSubmit(text.trim(), url);
      setText(null);
      setUrl(null);
    }
  };

  return (
    <Flex gap={"8px"} direction={"column"}>
      <Text>{t("Link text")}</Text>
      <FormControl isInvalid={isTextError}>
        <Input
          placeholder={t("Link text placeholder")}
          value={text ?? ""}
          onChange={handleLinkTextChange}
          data-pw="link-text"
        ></Input>
      </FormControl>
      <Text>{t("Link url")}</Text>
      <Input
        isInvalid={isUrlError}
        placeholder={t("Link url placeholder")}
        value={url ?? ""}
        onChange={e => setUrl(e.target.value)}
        data-pw="link-url"
      ></Input>
      <Button
        data-pw="add-link-button"
        isDisabled={isTextError || isUrlError || !text || !url}
        variant="dominoViolet"
        onClick={e => handleSubmit()}
      >
        {t("Add link")}
      </Button>
    </Flex>
  );
};
